import ListVehicle from 'components/Customs/CardVehicle/ListVehicle'
import styles from 'styles/Motorcycle.module.css'

const GroupMotorcycle = ({ listMoto, title, description, info_image, classBtn, 
	bgColor, classTitle,  orderText, orderImage, lastPage }) => {
		
	return (
		<div className="container">
			<section className={`${styles["moto-type__container"]} ${styles[classTitle]}`}>
				<div className={`${orderText} ${styles["moto-type__item"]}`}>
					<div className={styles["moto-type__group"]}>
						<p className={styles["moto-type__subtitle"]}>Motos</p>
						<h3 className={styles["moto-type__title"]}>{title}</h3>
					</div>
					<p className={styles["moto-type__info"]}>{description}</p>
				</div>
				<div className={`${orderImage} ${styles["moto-type__image"]}`}>
					<img src={info_image} alt={title} className={styles["moto-type__img"]} />
				</div>
			</section>

			<section className={styles["moto-type__findout"]}>
				<hr className={styles["moto-findout__line"]} />
				<p className={`${styles["moto-findout__title"]} ${styles[bgColor]}`}>Descubre</p>
				<ListVehicle listMoto={listMoto} lastPage={lastPage} />
			</section>	
		</div>
	)
}

export default GroupMotorcycle