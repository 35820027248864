import { getFeaturedCars } from 'services/actions/carActions'
import { getListSupplier } from 'services/actions/providerAction'
import { getListInitialServices } from 'services/actions/serviceActions'
import { useDispatch, useSelector } from 'react-redux'

export const useFeatured = (initialValue = '', load=true) => {

	const dispatch = useDispatch()

	const {  cars, providers, services  } = useSelector((state) => {
		return {
			cars: state.cars,
			providers: state.providers,
			services: state.services
		}
	})

	const { listFeaturedCars } = cars
	const { listSuppliers } = providers
	const { categoryProviders } = services

	const getLoadProvider = async () => {
		if(categoryProviders.length===0){
			await dispatch(getListInitialServices())
		}
		if(listSuppliers.length===0){
			await dispatch(getListSupplier(false))
		}		
	}

	const getLoadListProvider = async () => {
		if(categoryProviders.length===0){
			return await dispatch(getListInitialServices())
		}		
		return false
	}

	const getLoadListSuppliers = async () => {
		if(listSuppliers.length===0){
			return await dispatch(getListSupplier(false))
		}		
		return false
	}

	const getListFeatured = async (typeFeaturedCar) => {
		await dispatch(getFeaturedCars(typeFeaturedCar))	
	}

	return {
		listFeaturedCars,
		categoryProviders,
		getLoadProvider,
		getLoadListProvider,
		getLoadListSuppliers,
		getListFeatured
	}
}