import { vTypeTransmision, vTypeAuto, vTypeCombustible, vTypeCarroceria, vOrigin,
    vPerformance, vSeatMove, vTraction } from 'data/Normal'
import { listInterestAll } from 'data/Interests'
//import { allInterestNew, allInterestUsed, allBodyWorkNew, allBodyWorkUsed } from '../data/Preferences'
/**import { 
    generateYear, generateKm, getItemsOfSelected, getItemsSendForm, verifyCheckList,
    getInterestOfSelected
} from '../utils/tools'**/
import { generateKm } from 'utils/tools'

const year_initial = process.env.REACT_APP_YEAR_INITIAL
const RANGE_MAX_PRICE = 100000000
const RANGE_MAX_KM = 100000


export const busquedaInicial = () => {

    const generateYear = () => {

        let yearSelectList = []

        const dateCurrent = new Date()
        let month = dateCurrent.getMonth() + 1
        let max = dateCurrent.getFullYear()

        if(month >= 8){
            max = dateCurrent.getFullYear() + 1
        }

        for(let i =max; i >=  Number(year_initial); i--){
            yearSelectList.push({value: i, title: i, slug: i })
        }

        return yearSelectList
    }

    const kms = generateKm(0, RANGE_MAX_KM)

    const years = generateYear()

    const valueNormal = {
        vTypeAuto,
        vTransmision: vTypeTransmision,        
        vTypeRange: { min: 0, max: RANGE_MAX_PRICE },
        vTypeYear: { min: years[years.length-1].value, max: years[0].value },
        vTypeKm: { min: kms[0].value, max: kms[kms.length-1].value },
        vCarroceria: vTypeCarroceria,
        vCombustible: vTypeCombustible,
        vMarca: [],
        vModelo: [],
        vOrigin,
        vPerformance, 
        vSeatMove, 
        vTraction
    }

    const valueInterest = {
        vPrice: {min: 0, max: RANGE_MAX_PRICE },
        vTypeAuto,
        listInterestAll
    }
    
    return {
        valueNormal,
        years,
        kms,
        valueInterest
    }

}