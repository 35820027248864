import styles from "styles/store.module.css"

const Information = ({ title, value, isClass, isVisibleIcon, openModelMap }) => {
	return (
		<div className={styles["panel-item"]}>
			{isVisibleIcon && <span className={`${styles["panel-item__imagen"]} ${styles[isClass]}`}></span> }
			<div className={styles["feature--details"]}>
				<p className={styles["panel-item__title"]}>{title}</p>
				{
					title==="Teléfono" ? (
						value?.length>0 && value.map((el, index) => {
							if(el.type==="separador"){
								return el.caracter;
							}else{
								return <a key={index} href={`tel:${el.phone}`} className={styles["panel-item__value"]}>{el.item}</a>;
							}
						})
					) : title==="N.° de local" ? (
						<a  href="!#" onClick={(event)=>openModelMap(event)} className={`${styles["panel-item__value"]} ${styles["panel-item__value--red"]}`}>{value}</a>
					) : (
					<p className={styles["panel-item__value"]}>{value}</p>
					)
				}				
			</div>
		</div>
	)
}

export default Information