import { 
	LIST_INITIAL_SERVICE,
	RESULT_SERVICES_SEARCH,
	LOADING_SEARCH_SERVICE,

	SELECTED_SERVICE,
	LIST_INITIAL_TIRE,
	FILTER_MEASURE_TIRES,
	LOADING_RESULT_TIRES,
	SELECTED_TIRE_QUOTE,
	SEND_MEASURE_TIRES,

	SELECTED_VALUE_FORM,
	SELECT_ITEM_LIST_FORM
 } from 'services/types/serviceType'

const INITIAL_STATE = {
	categoryProviders: [],
	listServices: {},
	servicesAll: [],
	listServicesHome: [],
	resultServices: { isLoading: false, list: [], visible: false, service: {}},
	serviceSelected: {},

	listTires: [],
	isViewTires: false,
	measures: [],
	resultTires: { isLoading: false, list: [], visible: false},

	
	
	tireSelectedQuote: [],
	valueSearchMeasure: {profile: "", width: "", ring: "", search: false },
	
	itemSelectedListForm: {},

	loadingCategoriesProvider: true,
	isDataService: true,
	viewPageService: [],
	listMenuServicesAll: {
		listOne: [],
		listTwo: []
	}
}

const serviceReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case LIST_INITIAL_SERVICE:
			return {
				...state,
				listServices: action.payload.listServices,
				listServicesHome: action.payload.home,
				servicesAll: action.payload.servicesAll,
				categoryProviders: action.payload.categoryProviders,
				viewPageService: action.payload.viewPageService,
				listMenuServicesAll: action.payload.listMenuServicesAll
			}
		case RESULT_SERVICES_SEARCH:
			return {
				...state,
				resultServices: action.payload
			}
		case LOADING_SEARCH_SERVICE:
			return {
				...state,
				resultServices: {
					...state.resultServices,
					isLoading: action.payload
				}
			}
		case SELECTED_SERVICE:
			return {
				...state,
				serviceSelected: action.payload.service,
				loadingCategoriesProvider: action.payload.loading,
				isDataService: action.payload.isDataService
			}

			
		case LIST_INITIAL_TIRE:
				return {
					...state,
					listTires: action.payload.listTires,
					isViewTires: action.payload.isViewTires,
					measures: action.payload.measures
				}
		case LOADING_RESULT_TIRES:
			return {
				...state,
				resultTires: {
					...state.resultTires,
					isLoading: action.payload,
					visible: action.payload
				}
			}
		case FILTER_MEASURE_TIRES:
			return {
				...state,
				resultTires: {
					...state.resultTires,
					list: action.payload.listResultTires,
					isLoading: action.payload.isLoading,
					visible: action.payload.visible
				}
			}
		case SEND_MEASURE_TIRES:
			return {
				...state,
				resultTires: action.payload.tires,
				tireSelectedQuote: action.payload.tireSelectedQuote
			}
		case SELECTED_TIRE_QUOTE:
			return {
				...state,
				tireSelectedQuote: action.payload
			}
		case SELECTED_VALUE_FORM:
			return {
				...state,
				valueSearchMeasure: action.payload
			}
		case SELECT_ITEM_LIST_FORM:
			return {
				...state,
				itemSelectedListForm: action.payload.itemSelectedListForm
			}
		default: return state
	}
}

export default serviceReducer