import { Modal } from 'react-bootstrap'
import ListItems from 'components/Searching/Interests/ListItems'

const ModalOption = ({ isOpen,  closeModal, items }) => {

	return (
		<Modal show={isOpen} onHide={closeModal} centered>
			<div className="modal__header modal__header--left">
      				<div className="modal__title--insterest">
                <img src={items.info.imagen} alt="Intereses" className="mr-3" />
                <p className="title--modal">{items.info.title}</p>
              </div>
              <p className="subtitle--modal">{items.info.subtitle}</p>
      			</div>
			<div className="modal__container">
				<ListItems items={items.listado} />				  
			</div>
			<div className="modal__footer">
				<div className="w-100 mt-5">
		    		<button type="button" className="btn btn-primary btn-lg w-100" onClick={closeModal}>OK</button>
		    	</div>
			</div>
		</Modal>
	)
}

export default ModalOption