import { useState } from 'react'
import { useModal } from 'hooks/useModal'
import { useForm } from 'react-hook-form'

import { useDispatch } from 'react-redux'
import { sendFormNotification } from 'services/actions/notificationAction';

export const useFormContact = () => {

	const dispatch = useDispatch()	
	const { register, handleSubmit, reset } = useForm()
	const { isOpen, closeModal, setIsOpen } = useModal(false)
	const [ isLoading, setIsLoading ] = useState(false)
	const [ messageForm, setMessageForm ] = useState({error: false, title: '', subtitle: '', btnTitle: ''})

	const onSubmit = async (data, infoTransportation) => {

		setIsLoading(true)
		setMessageForm({error: false, title: '', subtitle: '', btnTitle: ''})

		const itemTransportation = infoTransportation
		delete itemTransportation.caracteristPanel
		delete itemTransportation.isVisibleFinancingPrice

		const detItem = {
			...infoTransportation, 
			listFeatures: infoTransportation.listFeatures[0].listado
		}

		const dataForm = {
			items: [ detItem ],
			name: data.name,
			telephone: data.telephone,
			email: data.email,
			message: data.message
		}
		const response = await dispatch(sendFormNotification(dataForm))
		

		if(response.status){
			setMessageForm({
				error: true, 
				title: '¡Gracias!', 
				subtitle: 'Tu solicitud ha sido enviada con éxito pronto tendrás noticias nuestras',
				btnTitle: 'Ok'
			})
			reset({
				name: '',
				email: '',
				telephone: '',
				message: ''
			})
		}else{
			setMessageForm({
				error: false, 
				title: '¡Ups!', 
				subtitle: 'Ha ocurrido error en el envío del formulario. Por favor intenta nuevamente',
				btnTitle: 'Reintentar'
			})
		}
		setIsLoading(false)
		setIsOpen(true)
	}

	return {
		register,
		handleSubmit,
		onSubmit,
		isOpen,
		closeModal,
		messageForm,
		isLoading
	}

}