import { Modal } from 'react-bootstrap'
import closed_menu from 'assets/images/icons/delete-menu.svg'


const ModalAdd = ({ isOpen, closeModal, isClass, children }) => {

	return (
		<Modal show={isOpen} onHide={closeModal} className={isClass} centered dialogClassName="modal-90w">
        <div>
        	<div className="modal__closed" onClick={closeModal}>
				<img src={closed_menu} alt="Cerrar" />
			</div>
        </div>
        <Modal.Body>
        	{children}
        </Modal.Body>
      </Modal>
	)
}

export default ModalAdd