const Information = () => {
	return (
		<div className="row bg-main bg-main--used">
			{/**<div className="col-12">
				<div className="container--information">
					<p className="main__title--information">Solo en Movicenter</p>
					<p className="main__call--information">encuentra más de 500 autos usados,</p>
					<p className="main__title--information">todo en un mismo lugar</p>
				</div>
			</div>**/}
		</div>
	)
}

export default Information