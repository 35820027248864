import { useState } from 'react'
import { getDetailById, getListCars, resetSelectedCarQuote, getListCarsCaravan, 
	selectedCarQuote, filterByModel, updateOrderListCars, saveParamsSearchUrl } from 'services/actions/concessionaireActions'
import { useFormContactResult } from "hooks/Contact/useFormContactResult";
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useFeatured } from 'hooks/useFeatured'


export const useFunctionBaseConcesionaire = () => {

	const dispatch = useDispatch()
	const history = useHistory()
	const [ textModel, setTextModel ] = useState("-")
	const [ menuSort, setMenuSort ] = useState(false)

	const { concessionaire, providers } = useSelector((state) => {
		return {
			concessionaire: state.concessionaire,
			providers: state.providers
		}
	})

	const { detailConcessionare, listCarsAssign, 
			carSelectedQuote, isLoadingSearch, listModels } = concessionaire
	const { listSuppliers  } = providers
	
	const { getLoadProvider } = useFeatured("", false)
	
	const { handleActive, disabledBtn, register,  handleSubmit, 
		onSubmit, message, isOpen, closeModal, isLoading,
		isOpenForm, handleQuoteCar, closeModalForm, errors } = useFormContactResult()


	const getDataInitialHome = async (slug) => {
		if(listSuppliers.length===0){
			await getLoadProvider();
		}
		
		dispatch(resetSelectedCarQuote())

		let response = await dispatch(getDetailById(slug))
	
		if(response.status){
			if(slug==="chile-caravan" || slug==="omnia-fitness"){
				response = await dispatch(getListCarsCaravan())
			}else{
				response = await dispatch(getListCars())
			}
			
		}
	}

	const goTocarFile = (event, keyCar) => {
		history.push(`/autos/${keyCar}`)
	}

	const historyBack = () => {
		history.go(-1)
	}

	const selectedQuote = (event, id, name) => {
		event.stopPropagation()
		
		if(id!==null){
			if(name!==""){
				handleQuoteCar(id, name)
			}else{
				dispatch(selectedCarQuote(id))
			}
			
		}		
	}

	const handleMoveTo = (event, idPanel) => {
		event.preventDefault()
		document.getElementById(idPanel).scrollIntoView(true)
	}

	const searchListCarByFilter = (event) => {
		event.preventDefault()
		dispatch(filterByModel(textModel))
	}

	const updateOrder  = () => {
		setMenuSort(!menuSort)
		dispatch(updateOrderListCars(menuSort ? 'asc' : 'desc'))
	}

	const getParamsUrl = (params) => {
		dispatch(saveParamsSearchUrl(params))
	}
	
	const actions = {
		getDataInitialHome,
		historyBack,
		goTocarFile,
		selectedQuote,
		handleMoveTo,
		setTextModel, 
		searchListCarByFilter,
		updateOrder,
		getParamsUrl
	}

	const state = {
		detailConcessionare, 
		listCarsAssign, 
		carSelectedQuote, 
		isLoadingSearch,
		listModels,
		textModel,
		menuSort
	}

	const formElement = {
		handleActive, disabledBtn, register,  handleSubmit, 
		onSubmit, message, isOpen, closeModal, isLoading,
		isOpenForm, handleQuoteCar, closeModalForm, errors
	}

	return {
		actions,
		state,
		formElement
	}

}
