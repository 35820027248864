const Information = () => {

	return (
		<div className="row bg-main bg-main--new">
			<div className="col-12">
				<div className="information">
					<p className="information__title1--new">Cumple</p>
					<p className="information__title2--new">el sueño</p>
					<p className="information__call--new">Del auto 0 KM</p>
				</div>
			</div>
		</div>
	)
}

export default Information