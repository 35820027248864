import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import { sendFormNotification } from 'services/actions/notificationAction';
import { getSelectedItemService } from 'services/actions/serviceActions'
import { useGoogleRecaptcha } from 'hooks/Contact/useGoogleRecaptcha';

export const useFormContactServiceProvider = () => {

	const dispatch = useDispatch()	
	const history = useHistory()
	const location = useLocation()
	const { getToken } = useGoogleRecaptcha();
	
	const { register, handleSubmit, errors, reset } = useForm();
	const [ disabledBtn, setDisabledBtn ] = useState(true);
	const [ isOpenForm, setIsOpenForm ] = useState(false);
	const [ isOpen, setIsOpen ] = useState(false);
	const [ isLoading, setIsLoading ] = useState(false);

	const [ message, setMessage ] = useState({
		error: false, 
		title: '¡Ups!', 
		subtitle: 'Ha ocurrido error en el envío del formulario. Por favor intenta nuevamente', 
		btnTitle: 'Reintentar',
		success: false
	})


	const closeModal = () => setIsOpen(false)

	const closeModalForm = () => setIsOpenForm(false)

	const handleActive = (event) => setDisabledBtn(!event.target.checked);

	const handleQuoteCar = (id, name) => {
		dispatch(getSelectedItemService(id))	
		
		setIsOpenForm(!isOpenForm)
	}

	const onSubmit =  async (data, infoSendItem, provider=null) => {
		try{
			setIsLoading(true)
			setMessage({error: false, title: '', subtitle: '', btnTitle: '', success: false});
			setDisabledBtn(true);
			
			const tokenCaptcha = await getToken();
	
			let dataItem = Array.isArray(infoSendItem) ? infoSendItem[0] : infoSendItem
			let dataForm = {
				items: [ dataItem ],
				name: data.name,
				telephone: "+569"+data.telephone,
				email: data.email,
				message: data.message,
				category_provider_id: infoSendItem?.id,
				token: tokenCaptcha
			}

			if(provider!==null){
				dataForm = {
					...dataForm,
					provider: provider?.name
				}
			}
	
	
			const response = await dispatch(sendFormNotification(dataForm, location.pathname))
			
			if(response.status){
				/**setMessage({
					error: false, 
					title: '¡Gracias!', 
					subtitle: 'Tu solicitud ha sido enviada con éxito pronto tendrás noticias nuestras',
					btnTitle: 'Ok',
					success: true
				})**/
				reset({
					name: '',
					telephone: '',
					email: '',
					message: ''
				})
				setIsLoading(false)
				setIsOpenForm(!isOpenForm)
				setDisabledBtn(false)
				history.push('/success')
			}else{
				setMessage({
					error: false, 
					title: '¡Ups!', 
					subtitle: 'Ha ocurrido error en el envío del formulario. Por favor intenta nuevamente',
					btnTitle: 'Reintentar',
					success: false
				})
				setIsLoading(false)
				setIsOpenForm(!isOpenForm)
				setIsOpen(true)
				setDisabledBtn(false)
			}	
		}catch(error){
			setMessage({
				error: false, 
				title: '¡Ups!', 
				subtitle: 'Ha ocurrido un error en el envío del formulario. Por favor recargue la página e intenta nuevamente',
				btnTitle: 'Reintentar',
				success: false
			})
			setIsLoading(false)
			setIsOpenForm(!isOpenForm)
			setIsOpen(true)
			setDisabledBtn(false)
		}
			
	}

	return {
		handleActive,
		disabledBtn,
		register, 
		handleSubmit, 
		onSubmit,
		message,
		isOpen, 
		closeModal,
		isLoading,
		errors,
		isOpenForm,
		handleQuoteCar,
		closeModalForm
	}
}