import deleteI from 'assets/images/icons/delete.svg'

const Item = ({ option, deleteOption }) => {
	return (
		<div className="option-item-insterest">
			<p className="option-item-text">{option.title}</p>
			<div className="option-item-search--delete mr-3">
				<img src={deleteI} alt="Eliminar item" onClick={(event)=>deleteOption(event, option)} />
			</div>
		</div>
	)
}


export default Item