import NotificationForm from 'components/Customs/Modal/NotificationForm';
import styles from 'styles/contact.module.css';
import { useFormContactGlobal } from 'hooks/Contact/useFormContactGlobal';

const FormContactGlobal = ({ infoSendItem, styleForm, stock=true  }) => {

	const { handleActive, disabledBtn, register, handleSubmit, 
		onSubmit, isOpen, closeModal, message, 
		isLoading, errors } = useFormContactGlobal();


	return (
		<>
			<div className={`card ${styles["card__container"]} ${styles[styleForm?.main]}`}>
				<div className={`${styles["card__body"]} ${styles["padding-body"]}`}>
					<form 
						className={`${styles["form-custom"]}`} 
						id="frm-contact" 
						onSubmit={handleSubmit(data => onSubmit(data, infoSendItem))}
					>
						<p className={styles[styleForm?.title]}>
							{
								!stock ?
									"Déjanos tus datos. Te informaremos cuando el vehículo este disponible"
									:
									"Contacta a un vendedor"
							}
						</p>
						<div className="form-custom__options">
							<div className={styles["form-custom__row"]}>
								<div className={`form-group ${styles[styleForm?.group]} w-100`}>
							    	<label htmlFor="name">Nombre</label>
							    	<input 
							    		type="text" 
								    	className={`form-control ${styles[styleForm?.input]}`} 
								    	aria-describedby="nameHelp"
								    	name="name" 
								    	id="name"
										maxLength={160}
								    	ref={register({required: {value: true, message: 'Requerido'}})} 
										onKeyPress={(event) => {
											if (!/[a-zA-Z Ññ]/.test(event.key)) {
											event.preventDefault();
											}
										}}
							    	/>
									{errors.name && (
										<span className="text-danger">
											{errors?.name?.message}
										</span>
									)}
								</div>
							</div>
							<div className={styles["form-custom__row"]}>
								<div className={`form-group ${styles[styleForm?.group]}`}>
							    	<label htmlFor="email">Correo</label>
							    	<input 
							    		type="email" 
								    	className={`form-control ${styles[styleForm?.input]}`}  
								    	id="email"
							    		aria-describedby="emailHelp" 
								    	name="email" 
								    	maxLength={160}
								    	ref={register({
											required: {value: true, message: 'Requerido'},
											//eslint-disable-next-line
											pattern: { value: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, message: 'Email inválido'}
										})} 
										onKeyPress={(event) => {
											if (!/[0-9@a-zA-ZÑñ.]/.test(event.key)) {
											event.preventDefault();
											}
										}}
							    	/>
									{errors.email && (
										<span className="text-danger">
											{errors?.email?.message}
										</span>
									)}
								</div>
								<div className={`form-group ${styles[styleForm?.group]}`}>
							    	<label htmlFor="telephone">Teléfono</label>
									<p className="span-telephone-contact-car"> +569</p>
							    	<input 
							    		type="text" 
										className={`form-control ${styles[styleForm?.input]} ${styles["form__contact-input--telephone-car"]}`} 
								    	id="telephone"
							    		aria-describedby="telephoneHelp" 
								    	name="telephone" 
										maxLength={8}
										ref={register({
											required: {value: true, message: 'Requerido'},
											minLength: { value: 8, message: 'Mínimo 8 Caracteres'},
											  maxLength: { value: 8, message: 'Máximo 8 Caracteres'},
										})} 
										pattern="[0-9]+"
										onKeyPress={(event) => {
											if (!/[0-9]/.test(event.key)) {
											event.preventDefault();
											}
										}} 
							    	/>
									{errors.telephone && (
										<span className="text-danger">
											{errors?.telephone?.message}
										</span>
									)}
								</div>
							</div>
							<div className={styles["form-custom__row"]}>
								<div className={`form-group ${styles[styleForm?.group]} w-100`}>
							    	<label htmlFor="message">Mensaje</label>
							    	<textarea 
							    		name="message" 
							    		id="message" 
							    		rows="2" 
							    		cols="2"
							    		className={`form-control ${styles[styleForm?.input]}`} 
							    		aria-describedby="messageHelp"  
									    ref={register({required: {value: true, message: 'Requerido'}})} 
							    	/>
									{errors.message && (
										<span className="text-danger">
											{errors?.message?.message}
										</span>
									)}
								</div>
							</div>
							<div className={`form-group ${styles["form-custom__type"]}`}>
							    <input 
							    	className={`${styles["form-custom--check"]} ${styles["form-custom--checkbox"]} btn-captcha-check`} 
							    	type="checkbox" 
							    	value={true} 
							    	id="acceptTerm" 
							    	name="acceptTerm"
							    	defaultChecked
							    	onChange={(event)=>handleActive(event)}
							    	//disabled={disabledBtn}
							    />
							    <label 
							    	className={styles[styleForm?.labelCheck]}
							    	htmlFor="acceptTerm">
							    	Acepto <a 
								    	href="https://movicenter-web.s3.us-east-2.amazonaws.com/docs/TEERMINOS+Y+CONDICIONES+MOVICENTER.pdf" 
										target="_blank"
										rel="noreferrer"
									>términos y condiciones</a> y políticas de privacidad
								    
							    </label>
							</div>
							<div className={`form-group ${styles[styleForm?.groupBtn]}`}>
								<button 
									type="submit" 
									className={`btn btn-primary ${styles["form-custom__button"]} btn-captcha-form`}
									disabled={disabledBtn}
								>{isLoading ? "Procesando..." : "Contactar"}</button>
							</div>
						</div>
					</form>
				</div>
			</div>
			{ isOpen && 
				<NotificationForm 
					show={isOpen} 
					handleClose={closeModal} 
					message={message}
					dialogClassName=""
				/>
			}
		</>
	);
};

export default FormContactGlobal;