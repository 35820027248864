import styles from "styles/vehicle.module.css"

const FeaturesVehicle = ({ title, value, icon}) => {

	return (
		<div className={styles["features-item"]}>
			<div className={styles["features-item__icons"]}>
				<div className={styles["features-item__icon"]}>
					<img src={icon} alt="Cilindrada" className={styles["features-item__image"]} />
				</div>
			</div>
			<div className={styles["features-item__description"]}>
				<p className={styles["features-item-title"]}>{title}</p>
				<p className={styles["features-item-value"]}>{value}</p>
			</div>
		</div>
	)
}

export default FeaturesVehicle