import API from 'services/context/api';
import {
	LIST_INITIAL_BRAND,
	UPDATE_SELECTED_BRAND,
	VISIBLE_LIST_MODEL,
	LIST_BRAND_MODEL_SELECTED,
	LIST_SELECTED_MODEL,
	DELETE_BRAND_MODEL_SELECTED,
	TYPE_BRAND_SELECTED,
	UPDATE_VISIBLE_BRAND,
	UPDATE_LIST_MODEL,
	RESET_TAB_BRAND_MODEL_SELECTED,
	RESET_SEARCH_BY_URL_BRAND_MODEL,
	LOADING_SEARCH_BRANDS,
	LOADING_BRAND
} from 'services/types/marcaType';

import { listLogoBrands } from 'utils/logoBrands';
import endPoints from 'services/routes/';
import { v4 as uuidv4 } from 'uuid'
import { TOKEN_SITE_WEB } from "data/variableKey";


export const requestSuccess = (type, payload) => {
    return { type, payload };
};

const checkListModelos = (modelos, modelosDefault, idBrand) => {
	let listModels = modelosDefault;
	
	for(let index in modelos){
		let modelId = listModels.findIndex(item => item.label.toLowerCase()===modelos[index].name.toLowerCase());
		if(modelId===-1){
			let slug = modelos[index].name.toLowerCase().replaceAll(" ", "-")
			listModels.push({
				...modelos[index],
				slug,
			  	valueForm: modelos[index].name.toLowerCase(),
			  	visible: false,
			  	id_marca: idBrand,
			  	id: uuidv4(),
			  	value: slug,
			  	label: modelos[index]?.name.toUpperCase(),
				checked: false,
				title: modelos[index]?.name.toUpperCase(),
				name: "vModelo"  
			});
		};
	};

	listModels.sort((a, b) => {
	    if(a.label > b.label) return 1;
	    if(a.label < b.label) return -1;

	    return 0;
	});

	return listModels;
}

export const getLoadingBrand = () => async (dispatch, getState) => {
	dispatch(requestSuccess(LOADING_BRAND, true));		
}

export const getDataInitialBrand = () => async (dispatch, getState) => {
	try{
		dispatch(requestSuccess(LOADING_BRAND, true));

	  	let response = await API.getApi({
									endPoint: endPoints.brands.getCarBrands, 
									nameAPI: TOKEN_SITE_WEB, 
									isToken: true,
									isFormData: false
								});

	  	let { data, status, message } = response.data;

	  	let brandsUsedNewSelected = [];

	  	if(Object.keys(data).length>0) {
	  		
	  		for (const [clave, brands ] of Object.entries(data)){
	  			let brandsFilterNull = brands.filter(item=>item.name!==null);
	  			let typeSelected = (clave==="new") ? "car-new" : "car-used";
				  
	  			for(const index in brandsFilterNull){
					
	  				let brandId = brandsUsedNewSelected.findIndex(item => item.label.toLowerCase()===brandsFilterNull[index]?.name.toLowerCase());
	  				let listModels = [];

		  			if(brandId===-1){
						const id = uuidv4()
		  				const logo = listLogoBrands.find(item => item.name.toLowerCase() === brandsFilterNull[index].name.toLowerCase());
		  				listModels = checkListModelos(brandsFilterNull[index].modelos, listModels, id);
		  				let slugBrand = brandsFilterNull[index].name.toLowerCase().replaceAll(" ", "-")
		  				brandsUsedNewSelected.push({
							...brandsFilterNull[index], 
							slug: slugBrand,
							valueForm: brandsFilterNull[index].name.toLowerCase(),
							logo: logo?.url, 
							modelos: listModels,
							visible: false,
							type: [ typeSelected ],
							typeNew: clave==="new" ? true : false,
							typeUsed: clave==="used" ? true : false,
							id,
							value: slugBrand,
							label: brandsFilterNull[index].name.toUpperCase(),
							checked: false,
							title: brandsFilterNull[index].name.toUpperCase(),
							name: "vMarca"
		  				});
		  			}else{
		  				let typeBrand = brandsUsedNewSelected[brandId].type;
		  				listModels = checkListModelos(brandsFilterNull[index].modelos, brandsUsedNewSelected[brandId]?.modelos, brandsUsedNewSelected[brandId]?.id);

		  				brandsUsedNewSelected[brandId].type=[...typeBrand, typeSelected];
		  				brandsUsedNewSelected[brandId].modelos = listModels;

		  				if(clave==="new"){
		  					brandsUsedNewSelected[brandId].typeNew = true
		  				}
		  				if(clave==="used"){
		  					brandsUsedNewSelected[brandId].typeUsed = true
		  				}
		  			}
	  			}				  
			}
	  	}

	  	brandsUsedNewSelected.sort((a, b) => {
		    if(a.label > b.label) return 1;
		    if(a.label < b.label) return -1;

		    return 0;
		});

	  	const payload = { brands: brandsUsedNewSelected, isLoading: false };
		
		dispatch(requestSuccess(LIST_INITIAL_BRAND, payload));

		return { status, message };
    }catch(error){
      	return { status: false, message: error };
    }
};

export const getUpdateVisibleBrand = (typeBrand) => (dispatch, getState) => {
	try{
		const { listBrands } = getState().marcas;

		let selectedBrans = [];
		
		if(typeBrand==="car-new"){
			selectedBrans = listBrands.filter(item => item.typeNew===true)
		}

		if(typeBrand==="car-used"){
			selectedBrans = listBrands.filter(item => item.typeUsed===true)
		}

		if(typeBrand==="other"){
			selectedBrans = listBrands
		}

		const payload = { 
			isLoadingBrands: false,
			brandsSelected: selectedBrans
		}
	
		dispatch(requestSuccess(UPDATE_VISIBLE_BRAND, payload));

		return { status: true, message: "" };
	 }catch(error){
      	return { status: false, message: error };
    }
};

export const getUpdateFalseBrand = () => (dispatch, getState) => {

	const { listBrands } = getState().marcas;

	const listAllBrand = listBrands.map(item => {
		return {
			...item,
			visible: false
		}
	});

	dispatch(requestSuccess(UPDATE_VISIBLE_BRAND, listAllBrand));
};

export const loadingLoadBrands = (value) => (dispatch, getState) => {
	const payload = { isLoadingBrands: value}
	dispatch(requestSuccess(LOADING_SEARCH_BRANDS, payload));
};



export const getSelectedTypeBrand = (typeBrand) => (dispatch, getState) => {
	try{
		const { listBrands, listBrandsSelected } = getState().marcas;
		
		let brandsSelected = []; 

		let otherTypeCar = (typeBrand==='car-new') ? 'car-used' : 'car-new';

		const otherBrands = listBrands.find(x=>x.type===otherTypeCar);

		const brandMain = listBrands.find(x=>x.type===typeBrand);

		const brands = listBrands.filter(x=>x.visible===true);

		if(brands.length===2){
			brandsSelected = listBrandsSelected;

			const copieBrands = JSON.parse(JSON.stringify(brandMain.brands));
			for(const key in copieBrands){
				
				const selectedBrand = copieBrands[key];

				const searchBrand = brandsSelected.findIndex(item=>item.name.toLowerCase()===selectedBrand.name.toLowerCase());
				
				if(searchBrand === -1){
					brandsSelected.push(selectedBrand);
				}else {
					
					if(brandMain.visible){
						let models = brandsSelected[searchBrand].modelos.concat(selectedBrand.modelos);
						brandsSelected[searchBrand].modelos = verifyArrayModels(models);
					}else{
		
						const otherExistBrand = otherBrands.brands.find(item=> item.name.toLowerCase()===selectedBrand.name.toLowerCase());

						if(otherExistBrand){
							brandsSelected[searchBrand].modelos = otherExistBrand.modelos;
							brandsSelected= brandsSelected.filter(item=> item.name.toLowerCase()===otherExistBrand.name.toLowerCase());
						}
					}		
				}
			}
		}else if(brands.length===1){
			if(brandMain.visible){
				brandsSelected= JSON.parse(JSON.stringify(brandMain.brands));
			}else{
				brandsSelected= JSON.parse(JSON.stringify(otherBrands.brands));
			}
			
		}

		if(brandsSelected.length>0){
			brandsSelected.sort((a, b) => {
			    if(a.name > b.name) return 1;
			    if(a.name < b.name) return -1;

			    return 0;
			});
		}
		
		const payload = { brandsSelected, isLoadingBrands: false };
		
		dispatch(requestSuccess(TYPE_BRAND_SELECTED, payload));
		
    }catch(error){
      return {status: false, message:error};
    }
};

const verifyArrayModels = (listDefault) => {
	let models =  [];
	let nameModel = [];
	for(const key in listDefault){
		if(!nameModel.includes(listDefault[key].name)){
			models.push(listDefault[key]);
			nameModel.push(listDefault[key].name);
		}
	}

	return models;
};

export const getSelectedBrand= (id, check) => (dispatch, getState) => {
	try{

		let { listBrandsSelected, listModelByBrandSelected } = getState().marcas;

		let itemsBrand = listBrandsSelected;
		let listModel = listModelByBrandSelected;

		const index = itemsBrand.findIndex(marca => marca.id===id);
		
		if(index!==-1){
			
			itemsBrand[index].visible = typeof check !=="undefined" ? check : !itemsBrand[index].visible;

			if(itemsBrand[index].visible){
				let checkArray = listModelByBrandSelected.filter(item => item.id === id)
				if(checkArray.length===0){
					listModel.unshift(itemsBrand[index])	
				}else{
					listModel = listModelByBrandSelected;
				}
				
			}else{
				listModel = listModelByBrandSelected.filter(item => item.id!== itemsBrand[index].id);
			}

			const payload = { brands: itemsBrand, models: listModel };

			dispatch(requestSuccess(UPDATE_SELECTED_BRAND, payload));

			return { status: true, message: '' };
		}
		return { status:false, message: 'Error al buscar el id de la marca'};
    }catch(error){
      return {status: false, message:error};
    }
};

export const getSelectedBrandAndModel = () => (dispatch, getState) => {
	try{
		const { listBrandsSelected } = getState().marcas;

		let itemBrands = [];
		let itemModels = [];

		const brandSelected = listBrandsSelected.filter(item=> item.visible===true);
	
		for (let clave in brandSelected){

		  if(brandSelected[clave].visible){
		  	itemBrands.push({ 
		  			id: brandSelected[clave].id, 
		  			title: brandSelected[clave].name, 
		  			value: brandSelected[clave].name, 
		  			checked: true,
					slug: brandSelected[clave].slug,
					valueForm: brandSelected[clave].valueForm
		  	});

		  	const selected = brandSelected[clave].modelos.filter(modelo => modelo.visible===true);
		  	if(selected.length>0){
		  		for (let key in selected){
		  			itemModels.push({ 
		  				id: selected[key].id, 
		  				title: selected[key].name, 
		  				id_marca: brandSelected[clave].id, 
		  				value:selected[key].name, checked: true,
						slug: selected[key].slug,
						valueForm: selected[key].valueForm
		  			});
		  		}
		  	}
		  }
		}

        const payload = { brands: itemBrands, models: itemModels };
       
		dispatch(requestSuccess(LIST_BRAND_MODEL_SELECTED, payload));

		return { status: true, message: '' };

    }catch(error){
      return {status: false, message:error};
    }
};

export const getVerifyStatusModel = (idModelo, idMarca, checked) => (dispatch, getState) => {
	try{
		const { listBrandsSelected, listModelByBrandSelected } = getState().marcas;

		let brands = listBrandsSelected;
		let models = listModelByBrandSelected;

		const index = brands.findIndex(marca => marca.id===idMarca);
		if(index!==-1){
			const indexModel = brands[index].modelos.findIndex(modelo => modelo.id===idModelo);
			if(indexModel!==-1){
				brands[index].modelos[indexModel].visible = checked;
			}
		}    	

    	const indexSelected = models.findIndex(marca => marca.id===idMarca);
    	if(indexSelected!==-1){
    		const indexModelSelected  = models[indexSelected].modelos.findIndex(modelo => modelo.id===idModelo);
    		if(indexModelSelected!==-1)	{
    			models[indexSelected].modelos[indexModelSelected ].visible = checked;
    		}
    	}

    	const payload = { brands, models };

		dispatch(requestSuccess(LIST_SELECTED_MODEL, payload));
    }catch(error){
      return {status: false, message:error};
    }
};

export const getFilter = (textBrand) => (dispatch, getState) => {
	try{
		//const { listBrandsSelected } = getState().marcas
	
		/**const filterBrand = listBrandsSelected.filter(item => {
			if(item.name.toUpperCase().includes(textBrand.toUpperCase())){
				return item
			}
		})**/

	}catch(error){
      return {status: false, message:error}
    }
}

export const updateModelsSelected = () => (dispatch, getState) => {
	try{
		const { listBrandsSelected } = getState().marcas;
	
		const payload = listBrandsSelected.filter(item => item.visible===true);
		dispatch(requestSuccess(UPDATE_LIST_MODEL, payload));
	}catch(error){
      return {status: false, message:error};
    }
};

export const getModelByBrandId = (brand) => (dispatch, getState) => {
	try{
		const payload = [ brand ];

		dispatch(requestSuccess(VISIBLE_LIST_MODEL, payload));
    }catch(error){
      return {status: false, message:error};
    }
};



export const getSelectedModel = () => (dispatch, getState) => {
	try{
		const { listModelByBrandSelected } = getState().marcas;

		let groupBrand = listModelByBrandSelected.map(item => {
			return {
				label: item.name,
				options: item.modelos
			}
		})
		let modelsSlected = listModelByBrandSelected.map(item => item.modelos)
		let payload = {
			models: modelsSlected.length>0 ? modelsSlected[0] : modelsSlected,
			groupBrand
		};
    	
		dispatch(requestSuccess(VISIBLE_LIST_MODEL, payload));
    }catch(error){
      return {status: false, message:error};
    }
};

export const deleteItemSelected = (type, item) => (dispatch, getState) => {

	const { selectedBrandAndModel, listBrandsSelected } = getState().marcas;

	let listBrandsAll = selectedBrandAndModel.brands;
	let listado = listBrandsSelected;
	let listModels = selectedBrandAndModel.models;
	
	if(type==="marca"){
		listBrandsAll = listBrandsAll.filter(x=>x.id!==item.id);

		const index = listBrandsSelected.findIndex(marca => marca.id===item.id);
    	listado[index].visible = !listado[index].visible;

    	listModels = listModels.filter(x => x.id_marca!==item.id);
	}

	if(type==="modelo"){
	
		listModels = listModels.filter(x=>{
			if(x.id!==item.id){
				return x;
			}else{
				if(x.id_marca!==item.id_marca){
					return x;
				}
			}
			return false;
		})

		listModels = listModels.filter(x=>x!==false);
		
		const indexBrand = listado.findIndex(marca => marca.id===item.id_marca);
    	const indexModel = listado[indexBrand].modelos.findIndex(modelo => modelo.id===item.id);
    	listado[indexBrand].modelos[indexModel].visible = !listado[indexBrand].modelos[indexModel].visible;
	}

	const modelsSlected = listado.filter(x=>x.visible===true);

	const payload = { items:{ brands: listBrandsAll, models: listModels}, listBrands: listado,  modelsSlected };

	dispatch(requestSuccess(DELETE_BRAND_MODEL_SELECTED, payload));

};

 export const resetBrandModelChangeTab = () => (dispatch, getState) => {
 	try{
		const { listBrandsSelected } = getState().marcas;
		
		const listBrandsReset = listBrandsSelected.map(brand => {
			brand.visible=false;
			const models = brand.modelos.map(model => {
				model.visible= false;
				return model;
			});
			brand.modelos = models;
			return brand;
		});

		const payload = {
			selectedBrandModel: {brands: [], models: []},
			listModelSelected: [],
			listBrands: listBrandsReset
		};

		dispatch(requestSuccess(RESET_TAB_BRAND_MODEL_SELECTED, payload));
		return {status: true, message:""};
	}catch(error){
      return {status: false, message:error};
    }
 };

 export const addSelectedDefaultResultByUrl = (arraySearchParams, listBrandsSelectedEdit) => (dispatch, getState) => {
	
	const listBrandsReset = {
		brands: arraySearchParams["vMarca"],
		models: arraySearchParams["vModelo"]
	};

	let listModels = listBrandsSelectedEdit.filter(item => item.visible===true);

	const payload = { listBrandsReset, listBrandsSelectedEdit, listModels };

	dispatch(requestSuccess(RESET_SEARCH_BY_URL_BRAND_MODEL, payload));
 };