import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { sendFormNotification, sendFormNotificationByBrand } from 'services/actions/notificationAction';

import { resetSelectedCarQuote, selectedCarQuote } from 'services/actions/concessionaireActions';
import { useHistory, useLocation } from 'react-router-dom'
import { useGoogleRecaptcha } from 'hooks/Contact/useGoogleRecaptcha';

export const useFormContactStore = () => {

	const dispatch = useDispatch()	
	const history = useHistory()
	const location = useLocation()
	const { getToken } = useGoogleRecaptcha();

	const { categoryProviders } = useSelector((state) => state.services)

	const { register, reset, handleSubmit, errors } = useForm();
	const [ disabledBtn, setDisabledBtn ] = useState(true);
	const [ isOpen, setIsOpen ] = useState(false);
	const [ isLoading, setIsLoading ] = useState(false);
	const [ message, setMessage ] = useState({
		error: false, 
		title: '¡Ups!', 
		subtitle: 'Ha ocurrido error en el envío del formulario. Por favor intenta nuevamente', 
		btnTitle: 'Reintentar',
		success: false
	});

	const closeModal = () => setIsOpen(false)

	const handleActive = (event) => setDisabledBtn(!event.target.checked);

	const onSubmit =  async (data, provider, itemService) => {
		try{
			setIsLoading(true)
			setMessage({error: false, title: '', subtitle: '', btnTitle: '', success: true })
			setDisabledBtn(true)
			
			const tokenCaptcha = await getToken();
	
			const category = categoryProviders.find(item => item.name===itemService)
		
			let dataForm = {
				name: data.name,
				telephone: "+569"+data.telephone,
				email: data.email,
				message: data.message,
				provider,
				token: tokenCaptcha
			}
	
			if(itemService.length>0){
				dataForm = {
					...dataForm,
					items: itemService,
					category_provider_id: category?.id
				}
			}
	
			let response = ""
	
			if(itemService.length>0){
			   response = await dispatch(sendFormNotification(dataForm, location.pathname))
			} else {
				response = await dispatch(sendFormNotificationByBrand(dataForm, location.pathname))
			}
	
			if(response.status){
				/**setMessage({
					error: false, 
					title: '¡Gracias!', 
					subtitle: 'Tu solicitud ha sido enviada con éxito pronto tendrás noticias nuestras',
					btnTitle: 'Ok',
					success: true
				})**/
				reset({
					name: '',
					telephone: '',
					email: '',
					message: ''
				})
				dispatch(resetSelectedCarQuote())
				const clist = document.querySelectorAll(".result-item__quote-check");
				for (const el of clist) {
					el.checked = false;
				}
				setDisabledBtn(false)
				setIsLoading(false)
				history.push('/success')
			}else{
				setMessage({
					error: false, 
					title: '¡Ups!', 
					subtitle: 'Ha ocurrido error en el envío del formulario. Por favor intenta nuevamente',
					btnTitle: 'Reintentar',
					success: false
				})
				setDisabledBtn(false)
				setIsLoading(false)
				setIsOpen(true)
			}
		}catch(error){
			setMessage({
				error: false, 
				title: '¡Ups!', 
				subtitle: 'Ha ocurrido un error en el envío del formulario. Por favor recargue la página e intenta nuevamente',
				btnTitle: 'Reintentar',
				success: false
			})
			setDisabledBtn(false)
			setIsLoading(false)
			setIsOpen(true)
		}
				
	}

	const handleQuoteCar = (id, name) => {
		dispatch(selectedCarQuote(id))
		setIsOpen(!isOpen)
	}

	return {
		handleActive,
		disabledBtn,
		register, 
		handleSubmit, 
		onSubmit,
		message,
		isOpen, 
		closeModal,
		isLoading,
		errors,
		handleQuoteCar
	}
}