import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"


const SkeletonCardService = () => {

	return (
		<div className="service__details">
            <Skeleton className="service__image" height={190} />
		</div>

	)
}

export default SkeletonCardService