import car_default from 'assets/images/card-default.png'
import { formatMonto } from 'utils/tools'

//const type_env = process.env.REACT_APP_ENVIRONMENT

export const sortSearch = (typeSort, listCars) => {
	switch (typeSort) {
		case 'lower_price':
			//Menor a mayor
			return listCars.sort(((a, b) => Number(a.price) - Number(b.price)))
		case 'higher_price':
			//Mayor a menor
			return listCars.sort(((a, b) => Number(b.price) - Number(a.price)))
	  	case 'lower_km':
			//Menor a mayor
			return listCars.sort(((a, b) => Number(a.mileage) - Number(b.mileage)))
	  	case 'higher_km':
			//Mayor a menor
			return listCars.sort(((a, b) => Number(b.mileage) - Number(a.mileage)))
		case 'lower_year':
			//Menor a mayor
			return listCars.sort(((a, b) => Number(a.year) - Number(b.year)))
	  	case 'higher_year':
			//Mayor a menor
			return listCars.sort(((a, b) => Number(b.year) - Number(a.year)))
	  default:
	    return listCars
	}
}

export const generateListOrdenCar = (resultCars) => {

	let arrvalueUSD= []
	let arrInitial= []
					
	for(let index in resultCars){
		let data = resultCars[index]
		if(data.listPriceView.includes("USD")){
			arrvalueUSD.push(data)
		}else{
			arrInitial.push(data)
		}
	}

	if(arrvalueUSD.length > 0){
		arrInitial = [...arrInitial, ...arrvalueUSD]
	}

	return arrInitial

}

export const formatResultCars = (cars, categoryProviders, typeFeaturedOrRecommended, listSuppliers) => {
	
	let dataCar = cars.filter(item => item !==null).filter(item => item.key !== "de_undefined");
	let carsResult = []
	
	for(let index in dataCar){
		let carItem = dataCar[index]

		const item = Array.isArray(carItem) ? carItem[0] : carItem

		const type = (item.type==="api_nuevos") ? 'Nuevo' : 'Usado'
		const typeSearch = (type==='Usado') ? 'Autos Usados' : 'Autos Nuevos'
		const typeKey = (type==='Usado') ? 'used' : 'new'
		const typekeyRecommended = (type==='Usado') ? 'car-used' : 'car-new'
		
		const category = categoryProviders.find(item => item.name===typeSearch)
		
		let photos = (type==="Nuevo") ? JSON.parse(item.photos) : item.photos

		const photoMain = photos?.length>0 ? photos[0] : car_default

		let listFeatures = (type==="Nuevo") ? generateListFeatures(item)  : item.listFeatures

		let listSpecific = (type==="Nuevo") ? generateListSpecific(item) : item.listSpecific

		let valueBrand = item.brand

		let infoBrand = null
	
		if(typeKey==='used'){
			const vendeId = listFeatures.findIndex(x => x.title==="Vende")
			if(vendeId!==-1){
		
				infoBrand = listSuppliers.find(x=> x.name?.toLowerCase()===listFeatures[vendeId]?.value?.toLowerCase())

				if(infoBrand){
					valueBrand = infoBrand?.name

				}else{
					infoBrand = listSuppliers.find(x=> x.search_name_api?.toLowerCase()===listFeatures[vendeId]?.value?.toLowerCase())
					if(infoBrand){
						valueBrand = infoBrand?.name
					}					
				}
				
				listFeatures[vendeId] = { title: "Vende", value: valueBrand}
				
				
				/**if(type_env==="development" && listFeatures[vendeId]?.value==="OLX Movicenter"){
					valueBrand = "OLX (Movicenter 1)"
					listFeatures[vendeId]= { title: "Vende", value: "OLX (Movicenter 1)"}
				}else if(item.concessionaire?.toLowerCase()==="kovacs"){
					valueBrand = "kovacs"
					listFeatures[vendeId] = { title: "Vende", value: valueBrand}
				}else if(listFeatures[vendeId]?.value==="Usados Picarte"){
					valueBrand = item?.concessionaire
					listFeatures[vendeId] = { title: "Vende", value: valueBrand}
				}else if(item?.concessionaire?.toLowerCase()===("Movicenter").toLowerCase()){
					valueBrand = item?.concessionaire
					listFeatures[vendeId] = { title: "Vende", value: "OLX (Movicenter 1)"}
				}else if(item?.concessionaire?.toLowerCase()===("Usados Movicenter SAIS").toLowerCase()){
					valueBrand = item?.concessionaire
					listFeatures[vendeId] = { title: "Vende", value: "SALAZAR ISRAEL"}
				}else if(item?.concessionaire?.toLowerCase()===("Usados Movicenter Portillo").toLowerCase()){
					valueBrand = item?.concessionaire
					listFeatures[vendeId] = { title: "Vende", value: "PORTILLO"}
				}else{
					valueBrand = listFeatures[vendeId]?.value					
				}	**/	
			}
		}
		
		

		if(typeKey==='new'){
			const vendeIndex = listFeatures.findIndex(x => x.title==="Vende")
			
			if(vendeIndex!==-1){
				infoBrand = listSuppliers.find(x=> x.name?.toLowerCase()===valueBrand?.toLowerCase())
				listFeatures[vendeIndex].value = infoBrand?.name
			}
		}

		let localIdMap = ''
		let localMap = {}

		if(infoBrand && Object.keys(infoBrand).length>0){
			if(infoBrand?.mapaIdLocals.length>0){
				localIdMap = infoBrand?.mapaIdLocals[0]
			}
			localMap = infoBrand
		}
		
	
		let priceLockDown = verifyPrice(item.price, typeKey)

		let listPriceView = ''
		if(item?.listPrice && type==="Nuevo"){
			listPriceView = verifyPrice(item.listPrice, typeKey)
		}else {
			listPriceView = priceLockDown
		}
		
		let mileage = (typeof item.mileage !=="undefined" && item.mileage !==null) ? item.mileage : 0

		let name = (type==="Nuevo") ? `${item.brand} ${item.model} ${item.year}`: item.name

		//let stock = typeof item?.stock==="undefined" ? true : item?.status_id===1 ? true : false 
	
		let stock = typeof item?.status_id === "undefined" ? true : item?.status_id===1 ? true : false 


		carsResult.push({
			bodyWork: item.bodyWork,
			name,
			brand: item.brand,
			key: item.key,
			concessionaire: item.concessionaire ?? item.brand,
			country: item.country,
			fuel: item.fuel,
			id: item.id,
			model: item.model,
			performance: item.performance,
			price: item.price,
			seatAmount: item.seatAmount,
			traction: item.traction,
			transmission: item.transmission,
			year: item.year,
			type,
			localIdMap,
			localMap,
			photoMain,
			isMyRouter: false,
			isCompare: false,
			isBanner: false,
			typeKey,
			priceFrom: item?.listPrice,
			categoryProviderId: category?.id,
			typeFeaturedOrRecommended,
			infoBrand: {...infoBrand, typeSearch},
			typekeyRecommended,
			priceLockDown,
			listPriceView,
			mileage: formatMonto(mileage),
			listFeatures,
			listSpecific,
			photos,
			status_id: typeof item?.status_id==="undefined" ? 1 : item?.status_id,
			isSend: false,
			stock
		})
	}
	
	return carsResult
}

const generateListFeatures = (car) => {
	let listFeatures = []

	if(typeof car?.category!=="undefined" && car?.category!==null){
		listFeatures.push({title: "Categoria", value: ""})
	}
	if(typeof car?.mileage!=="undefined" && car?.mileage!==null){
		listFeatures.push({title: "Kilometraje", value: car?.mileage})
	}
	if(typeof car?.year!=="undefined" && car?.year!==null){
		listFeatures.push({title: "Año", value: car.year})
	}
	if(typeof car?.version!=="undefined" && car?.version!==null && car?.version!==""){
		listFeatures.push({title: "Version", value: car.version})
	}
	if(typeof car?.cilindrada!=="undefined" && car?.cilindrada!==null && car?.cilindrada!==""){
		listFeatures.push({title: "Cilindrada", value: car.cilindrada})
	}
	if(typeof car?.fuel!=="undefined" && car?.fuel!==null){
		listFeatures.push({title: "Tipo Combustible", value: car.fuel})
	}
	if(typeof car?.transmission!=="undefined" && car?.transmission!==null){
		listFeatures.push({title: "Transmisión", value: car.transmission})
	}
	if(typeof car?.color!=="undefined" && car?.color!==null && car?.color!==""){
		listFeatures.push({title: "Color", value: car.color})
	}
	if(typeof car?.seller!=="undefined" && car?.seller!==null){
		listFeatures.push({title: "Vende", value: car.seller})	}
	if(typeof car?.traction!=="undefined" && car?.traction!==null){
		listFeatures.push({title: "Tracción", value: car.traction})
	}
	if(typeof car?.performance!=="undefined" && car?.performance!==null){
		listFeatures.push({title: "Rendimiento Km/L", value: car.performance})
	}
	if(typeof car?.country!=="undefined" && car?.country!==null){
		listFeatures.push({title: "Procedencia", value: car.country})
	}
	if(typeof car?.seatAmount!=="undefined" && car?.seatAmount!==null && car.seatAmount!=="undefined"){
		listFeatures.push({title: "Cantidad de asientos", value: car.seatAmount})
	}

	return listFeatures
}


const generateListSpecific= (car) => {
	let listSpecific = []

	if(typeof car?.seat_cumshots!=="undefined" && car?.seat_cumshots!==null && car.seat_cumshots!==0){
		listSpecific.push({title: `${car.seat_cumshots} Corridas de asientos`, value: ""})
	}
	if(typeof car?.amount_airbags!=="undefined" && car?.amount_airbags!==null && car.amount_airbags!==0 && car.amount_airbags!=="undefined"){
		listSpecific.push({title: `${car.amount_airbags} Airbags`, value: ""})
	}
	if(car?.air_conditioner && car.air_conditioner==="true"){
		listSpecific.push({title: "Aire acondicionado", value: ""})
	}
	if(car?.alarm && car.alarm==="true"){
		listSpecific.push({title: "Alarma", value: ""})
	}
	if(car?.glassswing && car.glassswing==="true"){
		listSpecific.push({title: "Alzavidrios eléctricos", value: ""})
	}
	if(car?.central_lock && car.central_lock==="true"){
		listSpecific.push({title: "Cierre centralizado", value: ""})
	}
	if(car?.steering_wheel_controls && car.steering_wheel_controls==="true"){
		listSpecific.push({title: "Controles del volante", value: ""})
	}
	if(car?.mobile_connectivity && car.mobile_connectivity==="true"){
		listSpecific.push({title: "Conectividad móvil", value: ""})
	}
	if(car?.electric_mirrors && car.electric_mirrors==="true"){
		listSpecific.push({title: "Espejos eléctricos", value: ""})
	}
	if(car?.tires && car.tires==="true"){
		listSpecific.push({title: "Llantas", value: ""})
	}
	if(car?.digital_radio && car.digital_radio==="true"){
		listSpecific.push({title: "Radio digital", value: ""})
	}
	if(car?.sunroof && car.sunroof==="true"){
		listSpecific.push({title: "Techo corredizo", value: ""})
	}
	if(car?.adjustable_steering_wheel && car.adjustable_steering_wheel==="true"){
		listSpecific.push({title: "Volante ajustable", value: ""})
	}
	return listSpecific
}

const verifyPrice = (price, type) => {
	
	let priceFormat = `$ ${formatMonto(price)}`
	
	if(type==="new" && Number(price) < 300000){
		priceFormat = `USD ${formatMonto(price)}`
	}

	return priceFormat
}

export const getListModel = (listCars) => {
	let listModels = [] 
	for(let index in listCars){

		const isFind = listModels.find(item => item===listCars[index]?.model)
		if(!isFind){
			listModels.push(listCars[index]?.model)
		}
	}

	listModels.sort((a, b) => {
	    if(a> b) return 1;
	    if(a < b) return -1;

	    return 0;
	});
	return listModels;
}