import { INITIAL_CONFIG, PAGE_SELECTED_LAST } from 'services/types/configType'

const INITIAL_STATE = {
	isVisibleModal: false,
	pageLast: ""
}

const configReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case INITIAL_CONFIG:
			return {
				...state,
				isVisibleModal: action.payload
			}
		case PAGE_SELECTED_LAST:
			return {
				...state,
				pageLast: action.payload
			}
		default: return state
	}
}

export default configReducer