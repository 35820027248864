//import { createRef } from 'react'
import CardFilterNormal from 'components/Customs/Panels/CardFilterNormal'

const CarroceriaList = ({ vCarroceria, register, setValue, selectedUpdateItem }) => {


  const selectedAll = (event) => {
    for(let i=0; i< vCarroceria.length; i++){
        setValue(`${vCarroceria[i].name}[${i}].checked`, event.target.checked)
    }

  }

 

	return (
        <div className="search__main--bodywork">
    		  <CardFilterNormal title="Tipo de carrocería" isClass="search_carroceria mb-1" isClassMb={true}>
        			<div className="carousel-items">
              {
                    vCarroceria.map((item, key) => (
                          <div key={key} className="carroceria__imagen">
                            <input
                                ref={register}
                                name={`${item.name}[${key}].name`}
                                defaultValue={item.value}
                                type="hidden"
                            />
                            <input
                                ref={register}
                                name={`${item.name}[${key}].slug`}
                                defaultValue={item.slug}
                                type="hidden"
                            />
                            <input 
                                className={`carroceria_check ${item.classIcon}`} 
                                type="checkbox" 
                                name={`${item.name}[${key}].checked`}
                                id={item.value} 
                                defaultChecked={item.checked}
                                ref={register}
                                onClick={() => selectedUpdateItem(item)}
                            />

                            <label className={`carroceria__text`} htmlFor={item.value}>
                              {item.title}
                            </label>
                        </div>
                    ))
                  }
        			</div>
              <div className="search__type mt-4 mb-0">
                  <div className="form-check">
                    <input className="search-form-check" type="checkbox" value="" id="defaultCheck1" onChange={(event)=>selectedAll(event)} />
                    <label className="search-form-label custom-label-all" htmlFor="defaultCheck1">
                      Mostrar todos
                    </label>
                  </div>
              </div>
    	   	</CardFilterNormal>
          
        </div>
	)
}

export default CarroceriaList