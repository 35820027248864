import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"


const SliderImageLoading = () => {

	return (
		<section className="container">
			<div className="row header__template--default">
				<div className="col-12">
					<div className="header-home slider-home-loading">
                        <Skeleton height="95%" width="100%" className="slider-home-loading__skeleton" />
					</div>
				</div>
			</div>
		</section>
	)
}

export default SliderImageLoading