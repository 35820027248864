import { Accordion, Card, useAccordionToggle } from 'react-bootstrap'

import Arrow from 'assets/images/icons/down-arrow-2.svg'
import ArrowUp from 'assets/images/icons/up-arrow-black.svg'

const CardAccordion = ({title, index, activeKey, children}) => {

	const CustomerHead = ({children, eventKey, callback}) => {
	     const decoratedOnClick = useAccordionToggle(
		    eventKey,
		    () => callback && callback(eventKey),
		  );
	    return (<div className='accordion-header d-flex w-100 justify-content-between' onClick={decoratedOnClick}>
	    			<p>{children}</p>
	    			<img src={activeKey === eventKey ? Arrow : ArrowUp} alt="" />
	    		</div>)
	  }

	return (
		<Card>
		    <Card.Header>
	          <CustomerHead  eventKey={`${index}`}>{title}</CustomerHead>
	        </Card.Header>
		    <Accordion.Collapse eventKey={`${index}`}>
		      <Card.Body>
		      	{children}
		      </Card.Body>
		    </Accordion.Collapse>
	  	</Card>
	)
}

export default CardAccordion