import { getCarsCompare } from 'services/actions/carActions'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import compare_desktop from 'assets/images/icon/compare_desktop.svg'

const ButtonCompare = ({ count, title }) => {

	const dispatch = useDispatch()
	const location = useLocation()
	const history = useHistory()

	const goToCompare = () => {
		const response = dispatch(getCarsCompare())
		if(response.status){
			history.push("/compare/cars", {
				prevPath: location?.pathname,
				name: title
			})
		}
	}

	return (
		<>
			<div className="result-foooter">
				<div className="search-foooter">
					<div className="container container-btn-rc">
						{count>0 && (
				    		<button 
				    			type="button" 
				    			className="btn btn-primary btn-lg w-100" 
				    			onClick={()=>goToCompare()}
				    		>{`Comparar (${count})`}</button>
				    	)}
				    	{/**countRoute>0 && (
				    		<Link
				    			className="btn btn-primary btn-lg w-100" 
				    			to="/vehiculos/ruta"
				    		>{`Ver favoritos (${countRoute})`}</Link>
						)**/}
			    	</div>
				</div>
			</div>

			{count>0 && (
				<section className="d-result-compare__button">
					<div className="d-result-compare" onClick={()=>goToCompare()}>
						<img src={compare_desktop} alt="Compara tus autos" />
						<p>{`Compara tus autos (${count})`}</p>
					</div>
				</section>
			)}
			
			{/**countRoute>0 && (
				<Link to="/vehiculos/favoritos">
					<section className="d-result-compare__button d-result-route__button">
						<div className="d-result-compare">
							<img src={route_desktop} alt="Ver favoritos" />
							<p>{countRoute}</p>
						</div>
					</section>
				</Link>
			)**/}
			
		</>
	)
}

export default ButtonCompare