import logo from 'assets/images/logo.png'
import { Link } from 'react-router-dom'
import { listMenu } from "routes/route-main"
import instagram from 'assets/images/social-media/instagram.svg'
import facebook from 'assets/images/social-media/facebook.png'
import youtube from 'assets/images/social-media/youtube.png'
import twitter from 'assets/images/social-media/twitter.svg'
import IconSocialMedia from './IconSocialMedia'
import { useEffect, useState } from 'react'

const dateCurrent = new Date()
const yearFooter = dateCurrent.getFullYear()

const Footer = ({ actionAdvertising, listMenuServicesAll }) => {

	const [listMenuAll, setListMenuAll ] = useState([...listMenu]);
	/**const openActionModal = (type) => {
		actionAdvertising({ action_type: type })
	}**/

	useEffect(() => {
		
		if(Object.keys(listMenuServicesAll)?.length>0){
			let data = [...listMenuAll];
			if(listMenuServicesAll?.listOne?.length>0){
				const servicesIndex = listMenuAll.findIndex(item => item.text==="Servicios");

				if(servicesIndex !== -1){
					data[servicesIndex].listOne = listMenuServicesAll?.listOne;
					data[servicesIndex].listTwo = listMenuServicesAll?.listTwo;
				}
				setListMenuAll([...data])
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listMenuServicesAll?.listOne?.length])


	return (
			<div className="row bg-orange">
				<div className="col-12">
					<div className="container footer__container pl-md-0 pr-md-0">
						<div className="footer__logo">
							<img src={logo} alt="Logo" />
						</div>
						<div className="footer__list_link footer__schedule">
							<p><span className="schedule__title">Movicenter</span></p>
							<ul>
								{
									listMenuAll?.length>0 && listMenuAll?.filter(item=> item.link===true && item.visibleDsk===true).map((menu, key) => 
										<li key={key}><Link to={menu.to}>{menu.text}</Link></li>
									)
								}
								{/**<li className="list-event">
									<button type="button" 
										className="btn footer-event"
										onClick={()=>openActionModal("compara")}
									>Cotiza tu seguro</button>
								</li>**/}
								{/**<li className="list-event">
									<button type="button" 
										className="btn footer-event"
										onClick={()=>openActionModal("olx")}
									>Vende tu auto</button>
								</li>**/}
								{/**<li className="list-event">
									<button type="button" 
										className="btn footer-event"
										onClick={()=>openActionModal("compara")}
									>Cotiza tu seguro</button>
								</li>**/}
							</ul>
						</div>
						<div className="footer__schedule ">
						{
									listMenuAll?.length>0 && listMenuAll?.filter(item=> item.link===false).map((menu, key) => (
										<div key={key}>
											<p><span className="schedule__title">{menu.text}</span></p>
											<ul>
												{menu?.listOne?.length > 0 && menu?.listOne?.map((item, key) => 
													<li key={key} className="footer-services">
														<Link to={item.url} className="footer-services--link">{item.name}</Link>
													</li> 
												)}
												{menu?.listTwo?.length > 0 && menu?.listTwo?.map((item, key) => 
													<li key={key} className="footer-services">
														<Link to={item.url} className="footer-services--link">{item.name}</Link>
													</li> 
												)}
											</ul>
										</div>
									))
								}							
						</div>
						<div className="footer__schedule">
							<p><span className="schedule__title">Horario</span></p>

							<div className="schedule__cars">
								<p>Autos Nuevos & Usados</p>							
								<p>Lunes a Domingo,</p>							
								<p>de 10:00 a 20:00 hrs.</p>							
							</div>	

							<div className="schedule__services">
								<p>Servicio Automotriz</p>							
								<p>Lunes a Domingo,</p>							
								<p>de 10:00 a 20:00 hrs.</p>							
							</div>	

							<p className="mt-5"><span className="schedule__title">Términos y Condiciones</span></p>						
							<div className="schedule__cars">
								<a 
									href="https://movicenter-web.s3.us-east-2.amazonaws.com/docs/TEERMINOS+Y+CONDICIONES+MOVICENTER.pdf" 
									target="_blank"
									rel="noreferrer"
								>
									Ver Términos y Condiciones
								</a>							
							</div>
							<div className="schedule__cars mt-1">
								<a 
									href="https://movicenter-web.s3.us-east-2.amazonaws.com/docs/POLITICA+DE+COOKIES.pdf" 
									target="_blank"
									rel="noreferrer"
								>
									Ver Política de Cookies
								</a>							
							</div>
							

							<div className="schedule__cars mt-3">
								<a 
									href="https://policies.google.com/terms?hl=es-419" 
									target="_blank"
									rel="noreferrer"
								>
									Ver Condiciones del Captcha
								</a>							
							</div>
							<div className="schedule__cars mt-1">
								<a 
									href="https://policies.google.com/privacy?hl=es-419" 
									target="_blank"
									rel="noreferrer"
								>
									Ver Política del Captcha
								</a>							
							</div>
						</div>
						<div className="footer__schedule">
							<p><span className="schedule__title">Contacto</span></p> 

							<div className="schedule__cars">
								<p>Servicio al cliente +56 2 2797 4600</p>								
								<a href="mailto:info@movicenter.cl">info@movicenter.cl</a>
							</div>

							<p className="mt-5"><span className="schedule__title">Dirección</span></p> 

							<div className="schedule__cars">
								<p>Av. Américo Vespucio 1155, Huechuraba, Santiago, Chile.</p>								
							</div>

							<div className="social_media">
								<IconSocialMedia imagen={instagram} title="Instagram" url="https://www.instagram.com/movicenter/" />
								<IconSocialMedia imagen={facebook} title="Facebook" url="https://www.facebook.com/Movicenter/" />
								<IconSocialMedia imagen={twitter} title="Twitter" url="https://twitter.com/movicenter/" />
								<IconSocialMedia imagen={youtube} title="Youtube" url="https://www.youtube.com/user/MovicenterChile" />
							</div>

						</div>
					</div>
				</div>
				
				<div className="footer__copyright">
					<div className="col-12">
						<p className="text__copyright">{`Copyright © ${yearFooter} MOVICENTER. Todos los derechos reservados.`}</p>
					</div>
				</div>
			</div>

	)
}


export default Footer