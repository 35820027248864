import { 
	LIST_TYPE_MOTORCYCLE,
	LIST_BRAND_MOTORCYCLE,
	LIST_FEATURED_MOTO,
	DETAIL_MOTORCYCLE,
	LOADING_MOTORCYCLE,
	
	LOADING_HOME_MOTORCYCLE,
	LOADING_FEATURED_MOTORCYCLE
 } from 'services/types/motorcycleType'

const INITIAL_STATE = {
	listTypeMotorcycle: [],
	listBrandMotorcycle: [],
	motoScooter: {},
	motoSport: {},
	motoTrail: {},
	motoATV: {},
	featuredMoto: { isLoading: false, list: []},
	infoMotorcycle: {},
	isLoadingMoto: false,
	isLoadingMotoHome: false
}

const motorcycleReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case LOADING_HOME_MOTORCYCLE:
			return {
				...state,
				isLoadingMotoHome: action.payload.loading
			}
		case LIST_TYPE_MOTORCYCLE:
			return {
				...state,
				listTypeMotorcycle: action.payload.listTypeMotorcycle,
				motoScooter: action.payload.motoScooter,
				motoSport: action.payload.motoSport,
				motoTrail: action.payload.motoTrail,
				motoATV: action.payload.motoATV,
				isLoadingMotoHome: action.payload.loading
			}
		case LOADING_FEATURED_MOTORCYCLE:
			return {
				...state,
				featuredMoto: action.payload
			}

		case LIST_BRAND_MOTORCYCLE:
			return {
				...state,
				listBrandMotorcycle: action.payload.listBrandMotorcycle
			}
		case LIST_FEATURED_MOTO:
			return {
				...state,
				featuredMoto: action.payload.featuredMoto
			}
		case DETAIL_MOTORCYCLE:
			return {
				...state,
				infoMotorcycle: action.payload.infoMotorcycle,
				isLoadingMoto: action.payload.isLoading
			}
		case LOADING_MOTORCYCLE:
			return {
				...state,
				isLoadingMoto: action.payload
			}
		default: return state
	}
}

export default motorcycleReducer