import CardTitle from "components/QuoteCar/Sire/Custom/CardTitle"
import { Controller } from "react-hook-form"
import { useDataOwner } from "hooks/Quotes/Sire/useDataOwner"
import MaskedInput from 'react-text-mask'
import RutTextMask from 'rut-text-mask'



const FormOwner = ({ nextFormNamePrev}) => {

    const { register, onSubmit, handleSubmit, control, listInfoCompara, 
        errors, handleBlurRut, dataFormQuote, openCalendar, validateDate,
        onChangeInput } = useDataOwner()

    return (
        <CardTitle title="Datos del propietario" activeKey={2}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group quote-contact__form mt-5">
                    <label htmlFor="rut" className="quote-contact__label">Rut</label>
                    <Controller
                        render={(props) => (
                            <MaskedInput
                                mask={RutTextMask}
                                className="form-control quote-contact__input" 
                                placeholder="Ej: 12.345.678-9"
                                id="rut" 
                                name="rut"
                                value={props.value}
                                onChange={(value)=>{
                                    props.onChange(value)
                                    onChangeInput(value, "text", "rut")
                                }}
                                onBlur={(event) => handleBlurRut(event)}
                            />
                        )}
                        control={control}
                        name="rut"  
                        defaultValue={dataFormQuote?.rut}
                        rules={{required: {value: true, message: 'Requerido'}}}
                    />
                    {errors.rut && (
                        <p className="text-error">
                            {errors?.rut?.message}
                        </p>
                    )}
                </div>
                <div className="form-group quote-contact__form">
                    <label htmlFor="name" className="quote-contact__label">Nombre</label>
                    <input 
                        type="text" 
                        className="form-control quote-contact__input"  
                        id="name" 
                        name="name"
                        ref={register({required: {value: true, message: 'Requerido'}})}
                        onChange={(event)=>onChangeInput(event, "text", "name")}
                    />
                    {errors.name && (
                        <p className="text-error">
                            {errors?.name?.message}
                        </p>
                    )}
                </div>
                <div className="form-group quote-contact__form">
                    <label htmlFor="lastname" className="quote-contact__label">Apellido</label>
                    <input 
                        type="text" 
                        className="form-control quote-contact__input"  
                        id="lastname" 
                        name="lastname"
                        ref={register({required: {value: true, message: 'Requerido'}})}
                        onChange={(event)=>onChangeInput(event, "text", "lastname")}
                    />
                    {errors.lastname && (
                        <p className="text-error">
                            {errors?.lastname?.message}
                        </p>
                    )}
                </div>
                <div className="form-group quote-contact__form">
                    <label htmlFor="dateOfBirth" className="quote-contact__label">Fecha de Nacimiento</label>
                    <input 
                        type="date" 
                        className="form-control quote-contact__input"  
                        id="dateOfBirth" 
                        name="dateOfBirth"
                        ref={register({required: {value: true, message: 'Requerido'}})}
                        min="1930-01-01"
                        max={new Date().toJSON().split('T')[0]}
                        onChange={(event)=>validateDate(event)}
                    />
                    <button type="button" className="btn-calendar" onClick={()=>openCalendar()}>Open</button>
                    {errors.dateOfBirth && (
                        <p className="text-error">
                            {errors?.dateOfBirth?.message}
                        </p>
                    )}
                </div>
                <div className="form-group quote-contact__form">
                    <label htmlFor="comuna" className="quote-contact__label">Comuna</label>
                    <Controller
                        control={control}
                        name="comuna"  
                        rules={{required: {value: true, message: 'Requerido'}}}
                        render={(props) => (
                            <select 
                                value={props.value}
                                className="form-control form-quote__select form-quote__select--height" 
                                id="comuna" 
                                onChange={(value)=>{
                                    props.onChange(value)
                                    onChangeInput(value, "select", "comuna")
                                }}
                            >
                                <option value="">{listInfoCompara.isProcess ? "Cargando data" : "Seleccione comuna" }</option>
                                {
                                    listInfoCompara?.comunas?.length>0 && 
                                    listInfoCompara?.comunas?.map((item, index) =>
                                        <option key={index} value={item.key}>{item.label}</option>
                                    )
                                }
                            </select>
                        )}
                        defaultValue={dataFormQuote?.comuna}
                    />
                    {errors.comuna && (
                        <p className="text-error">
                            {errors?.comuna?.message}
                        </p>
                    )}
                </div>
                <p className="quote-sire__used">¿Qué uso le das a tu vehículo?</p>
                <section className="quote-sire__inspection">
                    <div className="quote-sire__item">
                        <input 
                            type="radio" 
                            className="form-quote__checkbox" 
                            name="vehicle_use_type" 
                            id="vehicle_use_type_personal"
                            value="private" 
                            defaultChecked
                            ref={register({required: {value: true, message: 'Requerido'}})}
                            onChange={(event)=>onChangeInput(event, "radio", "vehicle_use_type")}
                        />
                        <div className="quote-sire__selected">
                            <p className="quote-sire__item-title">Uso particular</p>
                            <p className="quote-sire__item-subtitle">Vehículos de uso cotidiano y personal.</p>
                        </div>
                    </div>
                    <div className="quote-sire__item">
                        <input 
                            type="radio" 
                            className="form-quote__checkbox" 
                            name="vehicle_use_type" 
                            id="vehicle_use_type_comercial"
                            value="commercial"
                            ref={register({required: {value: true, message: 'Requerido'}})}
                            onChange={(event)=>onChangeInput(event, "radio", "vehicle_use_type")}
                        />
                        <div className="quote-sire__selected">
                            <p className="quote-sire__item-title">Uso comercial</p>
                            <p className="quote-sire__item-subtitle">Vehículos que prestan servicios con fines comerciales a tiempo completo o parcial.</p>
                        </div>
                    </div>
                </section>
                <button type="submit" className="btn btn-primary btn__quote-car mb-4">Continuar</button>
                <p className="quote-sire__step" onClick={()=>nextFormNamePrev(0)}>Paso anterior</p>
            </form>
        </CardTitle>
    )
}

export default FormOwner