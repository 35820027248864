import { Link } from 'react-router-dom'

const ItemService = ({ title, imagen, url, lastPage }) => {
	
	return (
		<Link 
			to={{
				pathname: url,
				state: lastPage
			}}
		>
			<div className="services-item">
				<p className="services-item__title">{title}</p>
				<div className="services-item__image">
					<img src={imagen} alt={title} />
				</div>
			</div>
		</Link>
	)
}

export default ItemService