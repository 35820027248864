import BgCurve from 'components/Motorcycle/Custom/BgCurve'
import MediaQuery from 'react-responsive'
import styles from 'styles/Motorcycle.module.css'
import group_accesorio from 'assets/images/motorcycle/group-accesorio.png'
import { PathTypeCurve } from 'utils/MotorcycleFunction/TypeCurve'
import { Link } from "react-router-dom"

const SectionMotorcycle = ({ listTypeMotorcycle }) => {

	return (		
		<section className="row">
			<MediaQuery minWidth={767}>
				<BgCurve
					pathDefault={PathTypeCurve.picture1.mobile}
					pathDsk={PathTypeCurve.picture1.dsk}
					bgColor="#F0F0F0"
				/>
			</MediaQuery>
			<div className="col-12">
				<div className={`container text-center`}>
					<div className={styles["moto-need__header"]}>
						<hr className={`${styles["moto-findout__line-title"]} ${styles["line-home"]} mt-5`} />
						<div className={`${styles["moto-need__image"] } ${styles["home-moto__image"]}`}>
							<img src={group_accesorio} alt="Accesorios" className={styles["moto-accesorio"]} />
						</div>
					</div>
					<h3 className={styles["moto-home__title"]}>Motos en Movicenter</h3>
					<p className={`mb-0 ${styles["moto-home__info"]}`}>Tenemos todos los estilos de motos que buscas.</p>
					<section className={styles["moto-home__container"]}>
						{
							listTypeMotorcycle.length>0 &&
							listTypeMotorcycle.map((item, key) => 
								<div key={key} className={styles["moto-home__item"]}>
									<img src={item.info_image} alt={item.title} className="w-100" />
									<div className={styles["moto-home__type"]}>
										<p className={styles["moto-home_type-title"]}>Motos {item.title}</p>
									</div>
								</div>
							)
						}
					</section>
					<div>
						<Link to="/motos" className={`btn btn-primary ${styles["moto-home__btn"]}`}>Encuentra el tuyo</Link>
					</div>
				</div>
			</div>
		</section>
	)
}

export default SectionMotorcycle