import { Modal } from 'react-bootstrap'
import closed_menu from 'assets/images/icons/delete-menu.svg'
import { Link } from "react-router-dom"
import { useState, useEffect } from 'react'

const timerPopup = process.env.REACT_APP_TIMER_POPUP ?? 10;

const ModalInitial = ({ isOpen,  closeModal, isClass, imagens, handleClickModal }) => {

	const [ seconds, setSeconds ] = useState(timerPopup);

	useEffect(() => {
		const timer = setInterval(() => {
		  setSeconds(seconds - 1);
		}, 1000);

		if(seconds <=0){
			clearInterval(timer);
			closeModal();
		}
				   // clearing interval
		return () => clearInterval(timer);
	  });

	return (
		<Modal show={isOpen} onHide={closeModal} className="p-0" centered dialogClassName="modal-home">
				<div className="modal__closed modal__closed--temporizador" onClick={closeModal}>
					<p className="modal__closed--subtitle">Cerrando en {seconds} ...</p>
					<div>
						<img src={closed_menu} alt="Cerrar" className="modal__closed--icon" />
					</div>	
				</div>
				<div 
					className={`modal-home__image ${(imagens?.action_type!=="url" && imagens?.action_type!=="N/A") ? "advertising-cursor" : ""}`} 
					onClick={()=>handleClickModal(imagens)}
				>
					{
						(imagens?.action_type==="url" && imagens?.url_banner!=="") ? (
							<Link to={imagens?.url_banner}>
								<picture>
									<source media="(min-width: 768px)" srcSet={imagens?.ic_desktop_main} />
									<img src={imagens?.ic_mobile_main} alt="Banner" />
								</picture>
							</Link>
						) : (
							<picture>
								<source media="(min-width: 768px)" srcSet={imagens?.ic_desktop_main} />
								<img src={imagens?.ic_mobile_main} alt="Banner" />
							</picture>
						)
					}
					
				</div>
	
      	</Modal>
	)
}

export default ModalInitial