import { useState, useEffect } from 'react'
import { getBrandOlx, getVersionOlx, getModelOlx, getYearOlx, saveFormDataOLX } from "services/actions/sureOlxActions"
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'


export const useOlxFormMain = () => {

    const dispatch = useDispatch()
    const [activeKey, setActiveKey] = useState("")
    const [ proccessForm, setProcessForm ] = useState({ loading: false, message: "", status: false })
    const { sureOlx, cars } = useSelector((state) => {
		return {
			sureOlx: state.sureOlx,
			cars: state.cars
		}
	})

	const { listBrandOlx, listModelOlx, listYearOlx, listVersionOlx, listMileagesOlx } = sureOlx
	const { infoCar } = cars

    const { register, control, handleSubmit, errors, setValue, getValues } = useForm({
		defaultValues: {
			brand: "",
			year: "",
			model: "",
			mileage: "",
			telephone: "",
			email: ""
		}
	})

    const onSubmit =  (data) => {
        try {
            if(proccessForm.status===true){
                setProcessForm({ loading: false, message: `Debe aceptar los términos y condiciones`, status: true})
                return false
            }
            
            setProcessForm({ loading: true, message: "", status: true })
    		if(data.acceptTerm==="true"){
    			dispatch(saveFormDataOLX(data)) 
    		}else{
    			setProcessForm({ loading: false, message: `Debe aceptar los términos y condiciones`, status: true })
    		}
        }catch(error){
            setProcessForm({ loading: false, message: "Hubo un error al procesar la cotización", status:false })
        }
	}

    const searchModelGlobal = async (event, initial, valueInitial, valueModel) => {
        const value = initial ? valueInitial : event.target.value
        setValue("brand", value)
        
        const body = { value: valueModel, isSearch: true }
        const responseModel = await dispatch(getModelOlx(body, getValues("brand")))
        
        if(responseModel.status===false){
            if(responseModel.selected.key!==""){
                setValue("model", responseModel.selected.key)
            }

            if(getValues("brand")!==""){
                setActiveKey(activeKey => responseModel.positionTab)
            }
        }
        return responseModel
	}

    const searchYearGlobal = async (event, initial, valueInitial, valueYear) => {
        const value = initial ? valueInitial : event.target.value
        
        setValue("model", value)

        const body = { value: valueYear, isSearch: true }

        const responseYear = await dispatch(getYearOlx(body, getValues("brand"), getValues("model")))
        if(responseYear.status===false){
            
            if(responseYear.selected.key!==""){
                setValue("year", responseYear.selected.key)
            }

            if(getValues("model")!==""){
                setActiveKey(activeKey => responseYear.positionTab)
            }
        }

        return responseYear
	}

    const searchVersionGlobal = async (event, initial, valueInitial) => {
        const value = initial ? valueInitial : event.target.value
        
        setValue("year", value)

        const body = { value: "", isSearch: true }

        const responseVersion = await dispatch(getVersionOlx(body, getValues("brand"), getValues("model"), getValues("year")))
        if(responseVersion.status===false){
            setActiveKey(activeKey => responseVersion.positionTab)
        }
	}

    const searchKmGlobal = (event, type, tab) => {
        if(event.target.value!==""){
            setValue(type, event.target.value)
            setActiveKey(activeKey => tab)
        }
    }

    const geAlltBrandsOlx = async () => {
        const data = {
			brand: {value: infoCar?.brand, isSearch: true },
			model: {value: infoCar?.model, isSearch: true },
			year: {value: infoCar?.year, isSearch: true },
			trim: {value: "", isSearch: true }
		}

        await dispatch(getBrandOlx(data.brand, false))

        /**if(response.status===false && infoCar?.brand!==""){
            const responseModel = await searchModelGlobal(
                null, 
                true, 
                infoCar?.brand ? response.selected.key : "",
                infoCar?.model
            )
            
            if(responseModel?.status===false && getValues("model")!==""){
                const responseYear = await searchYearGlobal(
                    null, 
                    true, 
                    infoCar?.model ? responseModel.selected.key : "",
                    infoCar?.year
                )

                if(responseYear?.status===false && getValues("year")!==""){
                    await searchVersionGlobal(
                        null, 
                        true, 
                        infoCar?.year ? responseYear.selected.key : ""
                    )
                }
            }
            
        }**/
    }

    const handleActive = (event) => {
        setProcessForm({ loading: false, message: "", status: !event.target.checked})
    }

    useEffect(()=> {
        geAlltBrandsOlx()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return {
        register,
        handleSubmit,
        onSubmit,
        control,
        searchModelGlobal,
        listBrandOlx,
        activeKey,
        setActiveKey,
        listModelOlx,
        getValues,
        searchYearGlobal,
        listYearOlx,
        listVersionOlx,
        searchKmGlobal,
        listMileagesOlx,
        proccessForm,
        searchVersionGlobal,
        errors,
        handleActive
    }
}