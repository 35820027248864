import { useEffect } from "react"
import BaseLayoutTire from 'components/Layout/BaseLayoutTire'
import ListItemService from 'components/Services/ResultSearchTire/ListItemService'
import ButtomQuote from 'components/Services/ResultSearchTire/ButtomQuote'

import { homeTire } from 'data/DescripcionHead.json'
import { useLocation } from 'react-router-dom'
import { useSearchTire } from "hooks/Tires/useSearchTire"


const HomeSearchByTire = () => {

	const location = useLocation()
	
	const { state, actions } = useSearchTire()

	const { resultTires, valueSearchMeasure, metaHead, tireSelectedQuote } = state 

	const { getSearchInitial } = actions

	useEffect(() => {
		getSearchInitial(location, "")
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [valueSearchMeasure?.search])

	return (
		<BaseLayoutTire
			infoMetaHead={Object.keys(metaHead).length===0 ? homeTire : metaHead}
			title={Object.keys(metaHead).length===0 ? 
				"Distintas Medidas y Marcas de Neumáticos" : 
				typeof metaHead?.titleH1!=="undefined" ? metaHead?.titleH1 : "Distintas Medidas y Marcas de Neumáticos"}
		>
			{
				!resultTires.isLoading && (
					<ListItemService {...resultTires} />
				)
			}
			{tireSelectedQuote?.length>0 && 
				<ButtomQuote count={tireSelectedQuote?.length} />}
		</BaseLayoutTire>
	)
}

export default HomeSearchByTire