import TagTitle from 'components/Customs/Titles/TagTitle'
import BotonUbication from 'components/Customs/Mapa/BotonUbication'


const Ubication = ({ isClass, isClassTitle }) => {
	return (
		<>
			<TagTitle 
				classContainer="tag_container--default "
				title="Visítanos" 
				isClassTitle={isClassTitle}
			/>
			<div className={`row col-gray-mapa ${isClass==="" ? "" : `${isClass}`}`}>
				<div className="col-12">
					<div className="container">
						<div className="blog__map blog__mapa--default">
							<iframe 
								loading="lazy" 
								allowFullScreen 
								 title="Mapa"
								 className="map-frame"
								src="https://www.google.com/maps/embed/v1/place?q=place_id:EkRBdi4gQW3DqXJpY28gVmVzcHVjaW8gMTE1NSwgSHVlY2h1cmFiYSwgUmVnacOzbiBNZXRyb3BvbGl0YW5hLCBDaGlsZSIxEi8KFAoSCYO0p9lZxmKWEd3ccUZahUf6EIMJKhQKEgkdCpHA-cRilhHZgbDvJr04Rg&key=AIzaSyB5R3VnUdGbH9hasvxNJ9itWpZhcIRPh0o"
								>
							</iframe>
						</div>
						<div className="card card__container border-20 card-ubication">
								<div className="card__body">
							    	<p className="blog__text--mapa">Av. Américo Vespucio 1155, Huechuraba, Santiago, Chile.</p>
						    		<BotonUbication 
										url="https://www.waze.com/ul?preview_venue_id=189597114.1896233287.588586&navigate=yes"  
										isClass="btn__icon btn__icon--waze"
										title="Como llegar con waze"
										btnColor="btn-primary"
									/>
						    		<BotonUbication 
										url="https://www.google.com/maps/dir//-33.3708241,-70.6639784/@-33.370824,-70.663978,16z?hl=es-ES"
										isClass="btn__icon btn__icon--pin"
										title="Como llegar Google Maps"
										btnColor="btn-primary"
									/>
						    		<BotonUbication 
										//url="https://www.google.com/maps/@-33.3732341,-70.6638325,3a,90y,25.97h,88.9t/data=!3m7!1e1!3m5!1sAF1QipPptkiCa-jBiFQddSH_ujfB21NDW9HlCtvyPOpz!2e10!3e12!7i3840!8i1920"
										url="https://goo.gl/maps/K6qgqme31d4JfowT8"
										isClass="btn__icon btn__icon--grados"
										title="Hacer tour virtual"
										btnColor="btn-secondary"
									/>
								</div>
							</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Ubication