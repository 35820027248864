import { useState } from 'react'
import { showAndHiddenFormOLX } from "services/actions/sureOlxActions"
import { useDispatch, useSelector } from 'react-redux'


export const useOlxMain = () => {

    const dispatch = useDispatch()
    const [ isVisibleCar, setIsVisibleCar ] = useState(false)
    
    const { resultQuote, isVisibleFormOLX, visibleTextZeroPrice  } = useSelector((state) => state.sureOlx)
    
    const closeModalCarOlx = (visibleForm) => {
		setIsVisibleCar(!isVisibleCar)
		dispatch(showAndHiddenFormOLX(visibleForm))
	}

    return {
        closeModalCarOlx,
        resultQuote,
        isVisibleFormOLX,
        visibleTextZeroPrice
    }
}