import GroupMotorcycle from 'components/Motorcycle/GroupMotorcycle'
import InfoTypeMotorcycleLoading from 'components/Motorcycle/Skeleton/InfoTypeMotorcycleLoading'
import BgCurve from 'components/Motorcycle/Custom/BgCurve'

import styles from 'styles/Motorcycle.module.css'

const DetailMotorcycle = ({ bgColor, infoMoto, typeViewMoto, classBtn, classTitle, 
	orderText, orderImage, loading, lastPage }) => {

	return (
		<>
			{ loading && (
				<InfoTypeMotorcycleLoading 
					classBtn={classBtn}
					bgColor={bgColor}
					typeViewMoto={typeViewMoto}
					classTitle={classTitle}
					orderText={orderText}
					orderImage={orderImage}
				/>
			)}
			{(!loading && Object.keys(infoMoto).length>0) && (
				<section className="row" id={infoMoto?.idPanel}>
					<div className={`col-12 ${styles[bgColor]}`}>
						<GroupMotorcycle 
							{...infoMoto}  
							classBtn="moto-findout__sport"
							bgColor={bgColor}
							classTitle={classTitle}
							orderText={orderText}
							orderImage={orderImage}
							lastPage={lastPage}
						/>
					</div>
					<div className={styles["moto-findout__footer"]}>
						{
							typeViewMoto==="curve" ?
								(
									<BgCurve
										pathDefault={infoMoto?.pathPicture.mobile}
										pathDsk={infoMoto?.pathPicture.dsk}
										bgColor="#F0F0F0"
									/>
								) : <div className={styles["moto-findout__white"]}></div>
						}
						
						{/**<div className={`${styles["moto-findout__view"]} ${styles[bgColor]}`}>
							<Link to="/motos" className={styles["moto-findout__link"]}>
								Ver todas
							</Link>
						</div>**/}
					</div>
				</section>
			)}
		</>
	)
}

export default DetailMotorcycle