import { Link } from 'react-router-dom'

const Item = ({ post, isDescriptionView = true }) => {


	return (
		<div className="blog-card">
			<div className="blog-card__image">
				<Link className="blog__info--link" to={`/blog/${post.slug}`}>
					<img alt={post.acf.imagen_de_cabecera_movil.sizes.large} className="card-image" src={post.acf.imagen_de_cabecera.sizes.large} />
				</Link>
			</div>
			
			<div className="blog__text">
				<Link className="blog__info--link" to={`/blog/${post.slug}`}>
					<p className="title-main__blog">{post.title.rendered}</p>
				</Link>
				{
					isDescriptionView && <Link className="blog__text--link" to={`/blog/${post.slug}`}>+ Ver más</Link>
				}
				
			</div>
		</div>
	)
}

export default Item