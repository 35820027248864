import { useState } from 'react'
import { Accordion } from 'react-bootstrap'
import CardAccordion from 'components/Customs/Accordion/CardAccordion'
import DetailFeatureTransportation from 'components/Customs/Information/DetailFeatureTransportation'

const FeaturesTypeTransportation = ({ listFeatures }) => {

	const [activeKey, setActiveKey] = useState('0')


	return (
		<div className="accordion-car__row">
			<div className="accordion-car">
				<Accordion activeKey={activeKey} onSelect={(event) => setActiveKey(event)}>
					{
						listFeatures.map((info, key) => (
							 <CardAccordion
							  		title={info.title}
							  		key={info.id}
							  		index={key}
							  		activeKey={activeKey}
							 	>
							 	<div className="details-car">
						      		{
						      			info.listado.map((item, key) => 
						      				<DetailFeatureTransportation 
						      					key={key} 
						      					title={item.title} 
						      					value={item.value} 
						      					isVisibleSubtitle={info.isVisibleSubtitle} 
						      			/>)
						      		}
						      		{ info.listado.length===0 && 
						      			<p>No hay información disponible</p>
						      		}
						      	</div>	
							</CardAccordion>
						))
					}
				</Accordion>
			</div>
		</div>
	)
}

export default FeaturesTypeTransportation