import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import styles from 'styles/Motorcycle.module.css'

const ItemMotorcycleLoading = () => {

	return (
		<div className={styles["moto-findout__item"]}>
			<div className={styles["moto-findout__logo"]}>
				<Skeleton count={1} className="w-100" />
			</div>
			<div className={styles["moto-findout__image"]}>
				<Skeleton count={1}  className={styles["moto-findout__img"]} />
			</div>
			<div className={`${styles["moto-findout__description"]} mt-3 mb-0`}>
				<p className={styles["moto-findout__brand"]}><Skeleton count={1}/></p>
				<p className={styles["moto-findout__name"]}><Skeleton count={1}/></p>
				<p className={styles["moto-findout__price"]}><Skeleton count={1}/></p>
			</div>
			<div>
				<Skeleton count={1} height={30} />
			</div>
		</div>
	)
}

export default ItemMotorcycleLoading