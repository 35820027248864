import mainlayers from 'components/Customs/Mapa/locals.json';

export const formatListStore = (data, categories) => {
	
	const localsFormat = data.local_number.split(",").map(local => 
							local.endsWith("_") ? 
								local.substring(0, local.length - 1).replace("_", "-").trim() : 
								local.trim()
							);
	
	let sLocals = formatFormLocal(localsFormat);
	
	let mapaIdLocals = localsFormat.map(local => {
		const idLayer =  mainlayers.layers.find(layer =>layer.namelayer.trim().toLowerCase()===local.trim().toLowerCase())
		if(idLayer){
			return idLayer.id
		}
		return ""
	});
	mapaIdLocals = mapaIdLocals.filter(item => item!=="")
	
	let locals =  localsFormat.join(", ");
	
	let categoryName = data?.type?.toString().split(",").map(cat => {
			return categories.find(x => Number(x.id)===Number(cat))?.name
		}).join(",");
	
	categoryName = (categoryName!=="Autos Nuevos" && categoryName!=="Autos Usados" 
						&& categoryName!=="Accesorios" && categoryName!=="Casas Rodantes" && categoryName!=="Gimnasio") 
						? "Neumáticos y Servicios" : categoryName
				
	const categoryNTitle = categoryName.split(",").map(item => {
		if(item==="Nuevos" || item==="Usados"){
			return `Autos ${item}`
		}
		return item
	}).join(",");

	const localMap = {
	    type: categoryNTitle, 
	    local_number: locals, 
	    name: data.name, 
	    logo: data?.icon, 
	    mapaIdLocals
	};

	let telephoneFormat = [];
	const telephoneMap = data.telephone.split("/");
	
	for(let i=0; i < telephoneMap.length; i++){
		const item = telephoneMap[i];
		const phone = (item.includes("+")) ? item : `+${item}`;

		telephoneFormat.push({ type: "phone", phone, item, caracter: "" });
		if(i!==telephoneMap.length-1){
			telephoneFormat.push({ type: "separador", phone: "", item: "", caracter: "/" });
		}
	}	

	let dataFormat = {
		name: data.name,
		description: data.description,
		sLocals,
		locals,
		id: data?.id,
		slug: data?.slug,
		status_id: data?.status_id,
		cover_image: typeof data?.cover_image === "undefined" ? "" : data?.cover_image,
		cover_image_mobile: typeof data?.cover_image_mobile === "undefined" ? "" : data?.cover_image_mobile,
		icon: data?.icon,
		categoryName,
		mapaIdLocals,
		categoryNTitle,
		telephone: data.telephone,
		localMap,
		telephoneFormat,
		type: data.type,
		email: data.email,
		search_name_api: data?.search_name_api
	};
	
	return dataFormat;
};


const formatFormLocal = (locals) => {
	let listLocals = locals.map(item => { return { local: item }});
	return listLocals;
};

export const groupStorePerTwo = (listStore) => {
	let listGroupPerTwo = []
	let initial = 0
	let indiceCurrent = 0

	for(let index in listStore){		

		listGroupPerTwo[indiceCurrent] = initial===0 ? [] : listGroupPerTwo[indiceCurrent]

		listGroupPerTwo[indiceCurrent].push(listStore[index])

		indiceCurrent = initial===1 ? (indiceCurrent+1) : indiceCurrent
		initial= initial===1 ? 0 : (initial+1)			
	}

	return listGroupPerTwo;
};
