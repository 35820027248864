import PanelCarVehicle from 'components/Customs/CardVehicle/PanelCarVehicle'
import styles from "styles/vehicle.module.css"
import FeaturesVehicle from "components/Customs/CardVehicle/FeaturesVehicle"

const CardVehicle = ({ photos, brand, infoBrand, title, caracteristPanel }) => {

	return (
		<PanelCarVehicle photos={photos}>
			<section className={styles["group__item"]}>
				<div className={styles["group__item-description"]}>
					<p className={styles["group__item-type"]}>Moto</p>
					<p className={styles["group__item-title"]}>MODELO KPR200 (LF200-10P)</p>
					<p className={styles["group__item-price"]}>$ 2.290.000 <span className={styles["group__item-taxes"]}>(IVA Incluido)</span></p>
				</div>
				<div className={styles["group__item-brand"]}>
					<img src={infoBrand?.icon} alt={title} className={styles["group__item-image"]} />
				</div>
			</section>
			<section className={styles["group__features"]}>
				{
					caracteristPanel.length>0 &&
					caracteristPanel.map((item, key) => 
						<FeaturesVehicle key={key} {...item} />
					)
				}
			</section>
			<div className={styles["disclaime-price"]}>
				<p className={styles["disclaime-price__text"]}>
					* Precios, equipamiento, características e información sujetos a cambio sin previo aviso. Las imágenes son meramente referenciales y pueden no coincidir con los productos exhibidos y ofrecidos en concesionarios, ni su idoneidad para los fines que se pretende satisfacer. Contacte un vendedor para verificar los datos publicados.
				</p>
			</div>
			<section className={styles["group__footer"]}>
				<div className={styles["group__footer-item"]}>
					<button className={`btn btn-primary ${styles["group__footer-btn"]}`}>Ver moto</button>
				</div>
				<div className={`${styles["group__footer-item"]} ${styles["group__footer-container"]}`}>
					<button className={styles["group__footer-link"]}>Compartir</button>
				</div>
			</section>
		</PanelCarVehicle>
	)
}

export default CardVehicle