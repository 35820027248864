import { 
	LIST_INITIAL_SOAP,
	CHANGE_STATUS_MODAL_SOAP,
	CHANGE_LIST_SOAP
 } from 'services/types/soapType'

const INITIAL_STATE = {
	listSureSoap: [],
	listOriginalSureSoap: [],
    isLoadingSoap: false,
	isModalSoap: false,
	listFiltered: []
}

const soapReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case LIST_INITIAL_SOAP:
			return {
				...state,
				listSureSoap: action.payload.listSureSoap,
                isLoadingSoap: action.payload.isLoadingSoap,
                listFiltered: action.payload.listFiltered,
                listOriginalSureSoap: action.payload.listOriginalSureSoap
			}
		case CHANGE_STATUS_MODAL_SOAP:
			return {
				...state,
				isModalSoap: action.payload.isModalSoap
			}
		case CHANGE_LIST_SOAP:
			return {
				...state,
				listSureSoap: action.payload.listSureSoap
			}
		default: return state
	}
}

export default soapReducer