const Cards = ({ classContainer, classBody, children }) => {

	return (
		<div className={`card card__container ${classContainer}`}>
			<div className={`card__body ${classBody}`}>
		    	{ children }
			</div>
		</div>
	)
}

export default Cards