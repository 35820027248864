import ItemMotorcycle from 'components/Customs/CardVehicle/ItemMotorcycle'
import ItemMotorcycleLoading from 'components/Customs/Skeleton/ItemMotorcycleLoading'
import Slider from 'components/Customs/Carousel/Slider'
import { SwiperSlide } from 'swiper/react'
import styles from 'styles/Motorcycle.module.css'

const Featured = ({ classSlide, options, lastPage  }) => {

	return (
		<div className="featured__container">
			<Slider 
  				options= {{
  					className: `featured-moto__slide-w ${styles[classSlide]}`,
  					navigation: false,
  					pagination: false,
  					spaceBetween: 15,
  					breakpoints:{
                            "300": {
                              "slidesPerView": 2,
                              "spaceBetween": 15
                            },
  						  "375": {
                              "slidesPerView": 2,
                              "spaceBetween": 25
                            },
                            "600": {
                              "slidesPerView": 3,
                              "spaceBetween": 15
                            },
                            "768": {
                              "slidesPerView": 3,
                              "spaceBetween": 40
                            },
                            "992": {
                              "slidesPerView": 4,
                              "spaceBetween": 40
                            },
                            "1200": {
                              "slidesPerView": 4,
                              "spaceBetween": 25
                            }
                      }
  				}}
			>
				{
          options.isLoading && (
            Array.from([1, 2, 3, 4]).map((item, key) => (
              <SwiperSlide key={key} className={styles["swiper-slide-w"]}>
                <ItemMotorcycleLoading />
              </SwiperSlide>
            ))
          ) 
        }
        {(!options.isLoading && options.list.length>0) && options.list.map((item, key) => 
							<SwiperSlide key={key} className={styles["swiper-slide-w"]}>
								<ItemMotorcycle key={key} {...item} lastPage={lastPage} />
							</SwiperSlide>
					)
				}
			</Slider>
		</div>
	)
}

export default Featured