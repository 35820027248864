import Slider from 'components/Customs/Carousel/Slider'
import DetailCar from 'components/Compare/DetailCar'

import { SwiperSlide } from 'swiper/react'

const CarouselCar = ({ listado }) => {

	return (
		<Slider 
			options= {{
				className: "slide-compare",
				navigation: false,
				pagination: false,
				breakpoints:{
                      "300": {
                        "slidesPerView": 2,
                        "spaceBetween": 15
                      },
                      "600": {
                        "slidesPerView": 3,
                        "spaceBetween": 15
                      },
                      "768": {
                        "slidesPerView": 3,
                        "spaceBetween": 0
                      },
                      "992": {
                        "slidesPerView": 3,
                        "spaceBetween": 0
                      },
                      "1200": {
                        "slidesPerView": 3,
                        "spaceBetween": 0
                      }
                }
			}}
		>
			{
				listado.map((item, key) =>
					<SwiperSlide key={key}><DetailCar {...item}  /></SwiperSlide>
				)
			}
		</Slider>
	)
}

export default CarouselCar