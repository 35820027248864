import { useState } from 'react'
import FilterNormal from 'components/Result/Filter/Mobile/FilterNormal'
import ItemSearchNormal from 'components/Result/Filter/Custom/ItemSearchNormal'

import delete_img from 'assets/images/icons/delete-result.svg'

const FilterSelectedNormalMobile = ({  menuFilter, initialDataNormal, setMenuFilter, itemOfSearch }) => {

	const [ visible, setVisible ] = useState(false)
	const [ typeModule, setTypeModule ] = useState('')

	const closeItem = () => {
		setVisible(false)
	}

	const visibleItem = (item) => {
		setVisible(true)
		setTypeModule(item)
	}

	const closeMenuFilter = () => {
		setMenuFilter(false)
		setVisible(false)
		setTypeModule('')
	}

	return (
		<>
			{
				menuFilter && (
					<div className="options-filter bg-white">
						<div className="options-sort__header options-filter__header">
							<div className="options__return">
							{visible && <span onClick={closeItem}></span>}
							<p>Búsqueda de auto</p></div>
							<img src={delete_img} alt="Cerrar" onClick={()=>closeMenuFilter()} />
						</div>
						<ItemSearchNormal itemOfSearch={itemOfSearch} />
						<FilterNormal 
							defaultValues={initialDataNormal} 
							typeModule={typeModule}
							visibleItem={visibleItem}
							visible={visible}
						/>
					</div>
				)
			}
		</>
	)
}

export default FilterSelectedNormalMobile