import {
	LOADING_TOKEN
} from 'services/types/secureType';

const INITIAL_STATE = {
	loadingSecure: true
}

const secureReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case LOADING_TOKEN:
			return {
				...state,
				loadingSecure: action.payload
			}
		default: return state
	}
}

export default secureReducer