import { useState } from "react";
import { getListInitialServices } from 'services/actions/serviceActions'
import { getListProviderAutorized } from 'services/actions/providerAction'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

export const useAuthorizedStores = () => { 

    const dispatch = useDispatch()
    const history = useHistory()
    const [ isLoading, setIsLoading ] = useState(false);
    const { providers, services  } = useSelector((state) => {
        return {
            providers: state.providers, 
            services: state.services
        }
    })
    const { listProviderAutorized } = providers
	const { categoryProviders } = services;

    const getInitialList = async () => {
      setIsLoading(true);
      if(categoryProviders.length===0){
        await dispatch(getListInitialServices())
    }
      await dispatch(getListProviderAutorized(true));
      setIsLoading(false);	
    }

    const gotToBack = () => {
		history.go(-1)
	}


    const state = {
        listProviderAutorized,
        isLoading
    }

    const actions = {
        getInitialList,
        gotToBack
    }


    return {
        state,
        actions
    }
}