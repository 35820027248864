import { useState, useEffect } from "react"
import Layout from 'components/Layout/Layout'
import ModalSuccess from 'components/QuoteCar/Soap/ModalSuccess'
import { sendSuccesInsurance } from "services/actions/soapAction"
import { useHistory, useParams } from "react-router-dom"
import { useDispatch } from 'react-redux'

const Success = () => {

    const dispatch = useDispatch()
    const history = useHistory()
    const { insuranceId } = useParams()
    const [ successModal, setSuccessModal ] = useState(true)

    const handleCloseSucess = () => {
        setSuccessModal(false)
        
        if(JSON.parse(localStorage.getItem("location-current"))){
            const data = JSON.parse(localStorage.getItem("location-current"))
            history.push(data)
        }else{
            history.push("/")
        }
    }

    const getSuccessInsurance = async (id) => {
        await dispatch(sendSuccesInsurance(id))
    }

    useEffect(() => {
        getSuccessInsurance(insuranceId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [insuranceId])

    return (
        <Layout
			classHeader="row header__white"
            hiddenColor={true}
            isVisibleFooter={true}
            visibleLine={true}
            infoMetaHead={{}}
            classDefaultHeader="row header-top header__white"
            classTopHeader=""
		>
            <div className="container-soap">
                {successModal && 
                    <ModalSuccess show={successModal} handleClose={handleCloseSucess} />}
            </div>
		</Layout>
    )
}

export default Success