import NotificationForm from 'components/Customs/Modal/NotificationForm';
import styles from 'styles/contact.module.css';
import { useFormContactService } from 'hooks/Contact/useFormContactService';

const FormContactService = ({ itemService, styleForm, provider }) => {

	const { handleActive, disabledBtn, register, handleSubmit, errors,
		onSubmit, isOpen, closeModal, message, isLoading } = useFormContactService();
	

	return (
		<>
			<div className="container">
				<div className={`card ${styles["card__container"]} ${styles[styleForm?.main]}`}>
					<div className={`${styles["card__body"]} ${styles["padding-body"]}`}>
						<hr id="form" className="hidden" />
						<form 
							className="form-contact" 
							onSubmit={handleSubmit(data => onSubmit(data, provider, itemService))}
						>
							<div className={styles["form-contact__text"]}>
								<div className={styles["form-contact__container"]}>
									<p className={styles["form-contact__title"]}>Formulario de contacto</p>
									<p className={styles["form-opciones__subtitle"]}>Rellena tus datos</p>
								</div>
								<p className={styles["service-opciones__help"]}>* Campos obligatorios</p>
							</div>
							<div className="form-opciones__form">
								<div className={styles["form-opciones__group"]}>
									<div className={`form-group ${styles["form-group-row"]} mr-md-3`}>
									    <label htmlFor="name">Nombre*</label>
									    <input 
									    	type="text" 
									    	className={`form-control ${styles["form__contact-input"]}`} 
									    	aria-describedby="nameHelp"
									    	name="name" 
									    	id="name"
									    	maxLength={160}
									    	ref={register({required: {value: true, message: 'Requerido'}})} 
											onKeyPress={(event) => {
												if (!/[a-zA-Z Ññ]/.test(event.key)) {
												event.preventDefault();
												}
											}}
									    />
									    {errors.name && (
									    	<span className="text-danger">
									      		{errors?.name?.message}
									    	</span>
									    )}
									</div>
									<div className={`form-group ${styles["form-group-row"]} ml-md-3`}>
									    <label htmlFor="telephone">Teléfono *</label>
										<p className="span-telephone-contact"> +569</p>
									    <input 
									    	type="text" 
											className={`form-control ${styles["form__contact-input"]} ${styles["form__contact-input--telephone"]}`}
									    	aria-describedby="telephoneHelp"
									    	name="telephone" 
									    	id="telephone"
									    	maxLength={8}
									    	ref={register({
												required: {value: true, message: 'Requerido'},
												minLength: { value: 8, message: 'Mínimo 8 Caracteres'},
                          						maxLength: { value: 8, message: 'Máximo 8 Caracteres'},
											})} 
											pattern="[0-9]+"
											onKeyPress={(event) => {
												if (!/[0-9]/.test(event.key)) {
												event.preventDefault();
												}
											}} 
									    />
										{errors.telephone && (
									    	<span className="text-danger">
									      		{errors?.telephone?.message}
									    	</span>
									    )}
									</div>
								</div>
								<div className={`form-group ${styles["form-group-row"]}`}>
								    <label htmlFor="email">Email*</label>
								    <input 
								    	type="email" 
								    	className={`form-control ${styles["form__contact-input"]}`} 
								    	 aria-describedby="emailHelp" 
								    	 name="email" 
								    	id="email"
								    	maxLength={160}
								    	ref={register({
											required: {value: true, message: 'Requerido'},
											//eslint-disable-next-line
											pattern: { value: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, message: 'Email inválido'}
										})} 
										onKeyPress={(event) => {
											if (!/[0-9@a-zA-ZÑñ.]/.test(event.key)) {
											event.preventDefault();
											}
										}}
								    />
								    {errors.email && (
								    	<span className="text-danger">
								      		{errors?.email?.message}
								    	</span>
								    )}
								</div>
								
								<div className={`form-group ${styles["form-group-row"]}`}>
							    	<label htmlFor="message">Mensaje</label>
							    	<textarea 
							    		name="message" 
							    		id="message" 
							    		rows="2" 
							    		cols="2"
							    		className="form-control route__form-input"
							    		aria-describedby="messageHelp"  
									    ref={register({required: {value: true, message: 'Requerido'}})} 
							    	/>
									{errors.message && (
										<span className="text-danger">
											{errors?.message?.message}
										</span>
									)}
								</div>
								 <div className={`form-group ${styles["form-group-checkbox"]}`}>
								    <input 
								     	className={`${styles["form__contact-check"]} btn-captcha-check` } 
								     	type="checkbox" 
								     	value={true}
								     	id="acceptTerm" 
								     	name="acceptTerm" 
								     	ref={register}
								     	onChange={(event)=>handleActive(event)}
								     />
								     <label 
								    	className={styles["form__contact-label"]} 
								    	htmlFor="acceptTerm">
								    	Acepto <a 
									    	href="https://movicenter-web.s3.us-east-2.amazonaws.com/docs/TEERMINOS+Y+CONDICIONES+MOVICENTER.pdf" 
											target="_blank"
											rel="noreferrer"
										>términos y condiciones</a> y políticas de privacidad
									    
								    </label>
								  </div>
								
								<div className="form-group">
									<button 
										type="submit" 
										className="btn btn-primary btn-lg w-100 btn-captcha-form"
										disabled={disabledBtn}
									>{isLoading ? "Procesando..." : "Enviar"}</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			{isOpen && 
				<NotificationForm 
					show={isOpen} 
					handleClose={closeModal} 
					message={message}
				/>
			}
		</>
	);
};

export default FormContactService;