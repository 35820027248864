import { Link } from "react-router-dom";
import styles from "styles/store.module.css"

const ItemResult = ({ id, name, photoMain, priceLockDown, transmission, selectedQuote, keyCar, lastPage }) => {
	
	return (
		<Link	
			to={{
				pathname: `/autos/${keyCar}`,
				state: lastPage
			}}
		>
			<div className={styles["item"]}>
				<p className={styles["item-title"]}>{name}</p>
				<div className={styles["item-container-photo"]}>
					<img src={photoMain} alt={name} className={styles["item-photo"]} />
				</div>
				<p className={styles["item-price"]}>Desde {priceLockDown} </p>
				<p className={styles["item-trasmission"]}>{transmission}</p>
				<div className={styles["item-container-check"]}  onClick={(event)=>selectedQuote(event, null)}>
					<input 
						className={styles["item-check"]} 
						type="checkbox" 
						value={id} 
						id={`tire_${id}`} 
						onChange={(event)=>selectedQuote(event, id, "")}
						/>
					<label 
						className={styles["item-check-label"]}
						htmlFor={`tire_${id}`} 
					>Cotizar</label>
				</div>
			</div>
		</Link>
	)
}

export default ItemResult