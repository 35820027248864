import ItemService from 'components/Services/ResultSearchService/ItemService'
import BannerEvent from 'components/Customs/Banner/Horizontal/BannerEvent'


const StoreResult = ({ advertisingTire, resultServices, actionAdvertising, lastPage }) => {

    return (
        <>
            {
				advertisingTire?.length>0 && (
					<BannerEvent 
						imagen={advertisingTire[0]}
						functionModal={actionAdvertising}
						title="Banner" 
						classContainer="mb-5 mt-4" 
						classPicture="car-banner__tired pl-0 pr-0"
					/>
				)
			}
            
            <div className="row bg-gray">
				<div className="col-12">
					<div className="container services-container">
						<h2> Neumáticos </h2>
						<p className="services-container--small">
							{resultServices?.list.length} Resultados de la búsqueda
						</p>
						<div className="services-primary">
							{
								resultServices?.list.map((item, key) => 
										<ItemService
											key={key}
											title={item.name}
											imagen={item.cover_image_mobile}
											url={`/servicio-automotriz/neumaticos/tienda/${item.slug}`}
											lastPage={lastPage}
										/>
								)
							}
						</div>
					</div>
				</div>
			</div>

			
        </>
    )
}

export default StoreResult