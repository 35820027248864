import { useEffect } from "react";
import CardFilterNormal from 'components/Customs/Panels/CardFilterNormal'
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Controller } from "react-hook-form"
import { NumericFormat } from "react-number-format";


const InputSliderRange = ({ register, min, max, minValRef, maxValRef, control, setValue, classMain, handleClickUpdateResult }) =>{

	const onChangeRange = (value) => {
	  minValRef.current = value[0]
	  maxValRef.current = value[1]
	  setValue("price_from", minValRef.current)
	  setValue("price_to", maxValRef.current)	
	}

	const handleChangePrice = (event, type) => {
		const { value } = event.target
		if(type==="min"){
			onChangeRange([ parseFloat(value.replaceAll(".", "")), maxValRef.current ])
		}else if(type==="max"){
			onChangeRange([ minValRef.current, parseFloat(value.replaceAll(".", "")) ])
		}
	  }

	useEffect(() => {
		minValRef.current = min
	  	maxValRef.current = max
		setValue("price", [min, max])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [min, max])


	return (
		 <CardFilterNormal title="Precio" isClass={`search_type_details flex-column pt-3 ${classMain}`}>
			{
				minValRef.current!==null && (
					<div className="container-input">
						<div className="input-group container-input-group range-price">
							<div className="input-group-prepend">
								<span className="input-group-text price_from_text">$</span>
							</div>
							<Controller
								control={control}
								name="price_from"  
								render={(props) => (
									<NumericFormat
										type="text"
										name="price_from" 
										className="container-input__number" 
										id="price_from" 
										value={minValRef.current}
										maxLength={11}
										thousandsGroupStyle="thousand"
										thousandSeparator="."
										decimalSeparator=","
										onChange={(value)=>{
											props.onChange(value)
											handleChangePrice(value, "min")
										}}
										onKeyPress={(event) => {
											if (!/[0-9]/.test(event.key)) {
											event.preventDefault();
											}
										}}
										onBlur={() => handleClickUpdateResult({type: "vTypeRange"})}
									/>
								)}
							/>
						</div>
						<div className="input-group container-input-group range-price">
							<div className="input-group-prepend">
								<span className="input-group-text price_from_text">$</span>
							</div>
							<Controller
								control={control}
								name="price_to"  
								render={(props) => (
									<NumericFormat
										type="text"
										name="price_to" 
										className="container-input__number" 
										id="price_to" 
										value={maxValRef.current}
										maxLength={11}
										thousandsGroupStyle="thousand"
										thousandSeparator="."
										decimalSeparator=","
										onChange={(value)=>{
											props.onChange(value)
											handleChangePrice(value, "max")
										}}
										onKeyPress={(event) => {
											if (!/[0-9]/.test(event.key)) {
											event.preventDefault();
											}
										}}
										onBlur={() => handleClickUpdateResult({type: "vTypeRange"})}
									/>
								)}
							/>
						</div>
					</div>
				)
			}
			
			
			<div className="input-range-price width-price">
				<Controller
                    control={control}
                    name="price"  
                    render={(props) => (
                        <Slider 
	    					range 
	    					allowCross={false} 
	    					value={props.value}
	    					onChange={(value)=>{
                                props.onChange(value)
                                onChangeRange(value)
                            }}
							onAfterChange={(event)=>handleClickUpdateResult({type: "vTypeRange"})}
	    					min={0}
							max={100000000}
							//marks={marksList}
							step={500000}
							name="price"  
	    				/>
                    )}
					
                    defaultValue={[min, max]}
                />
				<input type="hidden" name="price_value" id="price_value" value="price" ref={register} />
				
			  </div>
    	</CardFilterNormal>
	)
}

export default InputSliderRange