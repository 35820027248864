import styles from 'styles/Motorcycle.module.css'
import { Link } from 'react-router-dom'

const ItemMotorcycle = ({ brand, title, financingPrice, photoMain, icon, id, lastPage }) => {

	return (
		<Link 
			to={{
				pathname: `/motos/${id}`,
				state: lastPage
			}}
		>
			<div className={styles["moto-findout__item"]}>
				<div className={styles["moto-findout__logo"]}>
					<img src={icon} alt="Logo lifan" className="w-100" />
				</div>
				<div className={styles["moto-findout__image"]}>
					<img src={photoMain} alt="Foto 1" className={styles["moto-findout__img"]} />
				</div>
				<div className={styles["moto-findout__description"]}>
					<p className={styles["moto-findout__brand"]}>{brand}</p>
					<p className={styles["moto-findout__name"]}>{title}</p>
					<p className={styles["moto-findout__price"]}>{financingPrice}</p>
				</div>
				<div className={styles["moto-findout__boton"]}>
					<button 
						className={`btn btn-primary ${styles["moto-findout__primary"]}`}>
						Ver moto
					</button>
				</div>
			</div>
		</Link>
	)
}

export default ItemMotorcycle