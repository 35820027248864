import { useState } from 'react'
import { SwiperSlide } from 'swiper/react'
import Slider from 'components/Customs/Carousel/Slider'

import { Link } from 'react-router-dom'

const Catalogy = ({ 
	titleFilter,  listado, classImagen, classCarousel, children, lastPage, groupList,
	classCol="col-12", classContainer="container"
}) => {

	const [ activeSelect, setActiveSelect ] = useState('selectbox__select')
	const [ activeOption, setActiveOption ] = useState('selectbox__opciones')

	const visibleOption = () => {
		const classSelect = activeSelect
		const classOption = activeOption
		if(activeSelect.includes('active')){
			setActiveSelect(classSelect.replace('active', ''))
			setActiveOption(classOption.replace('active', ''))
		}else {
			setActiveSelect(`${classSelect} active`)
			setActiveOption(`${classOption} active`)
		}
	}

	return (
		
		<div className="row row-top-brand mb-5">
			<div className={`col-12  panels_container`}>
				<div className="container bg-gray pb-5">
					<div className="panels__catalogy">
						{children}
						<div className="catalogy_form">
							<div className="catalogy-selectbox">
								<div className={activeSelect} onClick={()=>visibleOption()}>
									<div className="selectbox__select--contenido">
										<h3 className="titulo">{titleFilter}</h3>
									</div>
									<span className={`catalogy_down_arrow ${activeSelect?.includes("active") ? "catalogy_down_arrowY" : ""}`} ></span>
								</div>

								<div className={activeOption} id="opciones">
									{
										listado.map((item,key) => (
											<Link 
												key={key} 
												to={{
													pathname: item?.url,
													state: lastPage
												}}
												className="selectbox__opciones-item"
											>
												<div className="selectbox__opciones--opcion">
													<h2 className="titulo-item">{item.name}</h2>
												</div>
											</Link>
										))
									}
								</div>
							</div>
						</div>
					</div>

					<div className="catalogy__images">
						<Slider
							options= {{
								className: `${classCarousel}`,
								grabCursor: true,
								autoplay: { delay: 4000 },
								speed: 500,
								loop: false,
								navigation: false,
								pagination: false,
								breakpoints:{
										"300": {
											"slidesPerView": 3,
										},
										"768": {
											"slidesPerView": 6
										}
								}
							}}
							
						>
							{
								groupList.map((listado,key) => (
									<SwiperSlide key={key}> 
									{
										listado.map((item,key) =>
											<Link 
												key={key}
												to={{
													pathname: item?.url,
													state: lastPage
												}}
											>
												<div key={key} className={`marcas__images ${classImagen}`}>
													<img src={item?.icon} alt={item?.title} />
												</div>
											</Link>
										)
									}
									</SwiperSlide>
								))
							}
						</Slider>
					</div>
				</div>
				
			</div>
		</div>
	)
}

export default Catalogy