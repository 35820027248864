const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles: CSSProperties = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const GroupLabelBrand = (item) => {

	return (
		<div style={groupStyles}>
	    <span>{item.label}</span>
	    <span style={groupBadgeStyles}>{item.options.length}</span>
	  </div>
	)
}

export default GroupLabelBrand