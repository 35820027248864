import API from 'services/context/api';
import {
	LIST_RESULT_CARS,
	UPDATE_RESULT_CAR,
	DETAILS_CAR,
	ADD_CAR_MY_ROUTE,
	LOADING_SEARCH_LOADING,
	ADD_COMPARE_CARS,
	//LOADING_SEND_MY_ROUTE,
	LIST_FEATURED_CARS,
	LIST_RECOMMENDED_CARS, 
	SELECTED_CAR_QUOTE,
	SELECTED_CARS_ROUTE,
	UPDATE_INFINITE_SCROLL_CAR,
	INITIAL_DATA_CAR_FILE,
	SELECTED_SEND_FORM_MY_ROUTE
} from 'services/types/carType';
import { generateCars } from 'utils/tools';
import { TOKEN_SITE_WEB } from "data/variableKey";
import { sortSearch, formatResultCars, generateListOrdenCar } from 'utils/toolCar';
import endPoints from 'services/routes/';

export const requestSuccess = (type, payload) => {
    return { type, payload }
};

export const loadingSearchCombined = (value) => (dispatch, getState) => {
	const payload = value;
    dispatch(requestSuccess(LOADING_SEARCH_LOADING, payload));
};

export const getResultCars = (typeSelectedTab) => async (dispatch, getState) => {
	try{
		const { itemsForm, selectedTab, visibleModal } = getState().search;
		const { listCarMyRoute } = getState().cars;
		const { listSuppliers } = getState().providers;
		const { categoryProviders } = getState().services;
		const { advertisingResult } = getState().advertisings;

		let payload = {};
		let code = false;
		let message = '';

		//dispatch(requestSuccess(LOADING_SEARCH_LOADING, true ));
		
		if(Object.keys(itemsForm).length>0){
			let response = await API.postApi({
									endPoint: endPoints.car.getCarsFilterCombined, 
									data: itemsForm,
									nameAPI: TOKEN_SITE_WEB, 
									isToken: true,
									isFormData: false
								});	

		  	let { data, status } = response.data;

		  	code = status;
		  	
		  	if(data!==null && data.length>0){
		 		
		 		const listKeys = data.map(item => item.key).filter(item=> typeof item!=="undefined");
			
		 		let results = formatResultCars(data, categoryProviders, '', listSuppliers);

				 results.sort(((a, b) => Number(a.price) - Number(b.price)))

		 		results = generateListOrdenCar(results)
			
		 		let advertisingsResultCar = advertisingResult.slice(0,3);
		 		
		  		let listResultCarsOriginal = generateCars(results, advertisingsResultCar);
			
				const count = listResultCarsOriginal.filter(item => item.isBanner===false)?.length || 0;

				if(listCarMyRoute.length>0){
					for(let key in listCarMyRoute){
						const indexCar =listResultCarsOriginal.findIndex(item => Number(item.id)===Number(listCarMyRoute[key].id));
						if(indexCar!==-1){
							listResultCarsOriginal[indexCar].isMyRouter=true;
						}
					}
				}

				let resultCars = listResultCarsOriginal.slice(0, 20);
			
				payload = { 
					results: resultCars, 
					countResult: count, 
					isLoading: false, 
					listKeys, 
					listCarsOriginal: listResultCarsOriginal,
					hasMoreCar: resultCars.length >= listResultCarsOriginal.length ? false : true, 
					initialViewCar: false
				};
			
				localStorage.setItem("selectedTab", JSON.stringify(selectedTab));
				
				dispatch(requestSuccess(LIST_RESULT_CARS, payload ));
		  	}else{
		  		payload = { results: [], countResult: 0, isLoading: false, listCarsOriginal: [], hasMoreCar: false,  initialViewCar: false };
		  	}
		  }else{
			payload = { results: [], countResult: 0, isLoading: false, listCarsOriginal: [], hasMoreCar: false,  initialViewCar: false };			
		  }	  	
		
		dispatch(requestSuccess(LIST_RESULT_CARS, payload ));
		
		return { status: code, message, visibleModal };

    }catch(error){
      	return { status: false, message: error };
    }
};

export const resultResultCars = () => async (dispatch, getState) => {
	const payload = { 
		results: [], 
		countResult: 0, 
		isLoading: false, 
		listCarsOriginal: [], 
		hasMoreCar: false, 
		initialViewCar: false 
	};  	
	
	dispatch(requestSuccess(LIST_RESULT_CARS, payload ));
};

export const updateInfiniteScrollCar = () => (dispatch, getState) => {
	try{
		let { listCarsOriginal, listResultCar, initialViewCar } = getState().cars;

		if(!initialViewCar){
			const maxScroll = listResultCar.length + 20;
			const minScroll = listResultCar.length;

			const newListCars = listCarsOriginal;
			let listado = newListCars.slice(minScroll, maxScroll);
			const updateListCars = [...listResultCar, ...listado ];

			const payload = {
				listCarItem: updateListCars,
				hasMoreCar: updateListCars.length >= listCarsOriginal.length ? false : true 
			}; 
			dispatch(requestSuccess(UPDATE_INFINITE_SCROLL_CAR, payload));
		}
		
    }catch(error){
      return {status: false, message:error};
    }
};

export const getDetailsCar = (key) => async (dispatch, getState) => {
	try{
		const { listSuppliers } = getState().providers
		const { categoryProviders } = getState().services;
		const { listCarMyRoute } = getState().cars;
		const body = { key: key };
		let typeRecommended = '';

		let response = await API.postApi({
									endPoint: endPoints.car.getCarsKey, 
									data: body,
									nameAPI: TOKEN_SITE_WEB, 
									isToken: true,
									isFormData: false
								})

		let { data, message } = response.data;

		if(Object.keys(data).length>0){
			
			const results = formatResultCars([data], categoryProviders, '', listSuppliers);
			typeRecommended = results[0].typekeyRecommended;
			let isVerifiedMyRoute = listCarMyRoute.find(item => item.id===results[0].id)
			if(isVerifiedMyRoute){
				results[0].isMyRouter = true
			}

			const payload = {
				infoCar: results[0], 
				listKeys: key
			}
	
			dispatch(requestSuccess(DETAILS_CAR, payload ));
		}
		
		return { status: Object.keys(data).length>0 ? true: false, message, typeRecommended };
	}catch(error){
      	return { status: false, message: error };
    }
};

export const addRemoveComparar = (value, id) => (dispatch, getState) => {
	try{
		const { listResultCar } = getState().cars;

		const results = listResultCar;
		let message = '';
		let error = false;

		const index = results.findIndex(item => item.id===id);
		results[index].isCompare = value;

		let listCompareCars = results.filter(item =>item.isCompare===true);

		if(listCompareCars.length>3){
			results[index].isCompare = false;
			message= 'Max comparar 3 autos';
			error = true;
			listCompareCars = results.filter(item =>item.isCompare===true);
		}
		const payload ={ results, listCompareCars };

		dispatch(requestSuccess(UPDATE_RESULT_CAR, payload));

		return { error, message };
	}catch(error){
      	return { status: false, message: error };
    }
};

export const addRemoveMyRoute = (id, value) => (dispatch, getState) => {
	
	const { listResultCar, listCarMyRoute, infoCar } = getState().cars;

	const results = listResultCar;
	let listCars = [];

	const index = results.findIndex(item => Number(item.id)===Number(id));
	if(index!==-1){
		results[index].isMyRouter = value;
	}

	let verifyExiste = listCarMyRoute.find(car => Number(car.id)===Number(id));
	let detailCar = {};

	if(typeof verifyExiste === 'undefined' && index!==-1 && value){
		listCars = [...listCarMyRoute, results[index]];
		if(typeof infoCar !== 'undefined' && Object.keys(infoCar).length>0){
			detailCar=results[index];
		}
		
	}else if(typeof infoCar !== 'undefined' && Object.keys(infoCar).length>0 && value){
		
		detailCar = {...infoCar, isMyRouter: !infoCar.isMyRouter};
		listCars = [...listCarMyRoute, infoCar];
	}

	if(!value){
		listCars = listCarMyRoute.filter(item => Number(item.id)!==Number(id));
		detailCar=infoCar;
	}

	const payload ={ carMyRote: listCars, detailCar };

	localStorage.setItem("my-route", JSON.stringify(listCars));

	dispatch(requestSuccess(ADD_CAR_MY_ROUTE, payload));

	return { error: true, message: "Se ha agregado con éxito" };
};

export const getCarsCompare = (id, value) => (dispatch, getState) => {
	
	const { listResultCar } = getState().cars;

	const listCompares = listResultCar.filter(item => item.isCompare===true);

	const payload ={ listCompareCars: listCompares };

	dispatch(requestSuccess(ADD_COMPARE_CARS, payload));

	return { status: true, message: "Se ha agregado con éxito" };
};

export const typeSortByCar = (typeSort) => (dispatch, getState) => {
	try{
		const { listResultCar } = getState().cars;
		const { advertisingResult } = getState().advertisings;

		let listCars = listResultCar.filter(item => item.isBanner===false);

		let resultSearch = sortSearch(typeSort, listCars);

		resultSearch = generateListOrdenCar(resultSearch)

		let advertisingResultCar = advertisingResult.slice(0,3);

		const resultCars = generateCars(resultSearch, advertisingResultCar);

		const count = resultCars.filter(item => item.isBanner===false)?.length || 0;

		const payload = { results: resultCars, countResult: count, isLoading: false  };

		dispatch(requestSuccess(LIST_RESULT_CARS, payload));

	}catch(error){
      	return { status: false, message: error };
    }
};


export const getFeaturedCars = (typeFeatured) => async (dispatch, getState) => {
	try{
		const { listSuppliers } = getState().providers;
		const { categoryProviders } = getState().services;

		dispatch(requestSuccess(LIST_FEATURED_CARS, { isLoading: true, list: []}));

		const body =  { "vTypeAuto": [typeFeatured] };

		let response = await API.postApi({
								endPoint: endPoints.car.getDestacados, 
								data: body,
								nameAPI: TOKEN_SITE_WEB, 
								isToken: true,
								isFormData: false
							});

		let { data, status, message } = response.data;	

		if(data!=null && data.length>0){
			
			const resultCars = formatResultCars(data, categoryProviders, 'featured', listSuppliers);
	
			const payload = { isLoading: true, list: resultCars };

			dispatch(requestSuccess(LIST_FEATURED_CARS, payload ));	
		}
		
		return { status, message };

    }catch(error){
      	return { status: false, message: error };
    }
};

export const getRecommendedCars = (typeRecommended) => async (dispatch, getState) => {
	try{
		const { listSuppliers } = getState().providers;
		const { categoryProviders } = getState().services;

		dispatch(requestSuccess(LIST_RECOMMENDED_CARS, { isLoading: true, list: []}));

		const body =  { vTypeAuto: typeRecommended, price: 10000000, mileage: 0 };

		let response = await API.postApi({
								endPoint: endPoints.car.getCarsRecommended, 
								data: body,
								nameAPI: TOKEN_SITE_WEB, 
								isToken: true,
								isFormData: false
							});

		let { data, status, message } = response.data;	

		if(data!=null && data.length>0){
			const resultCars = formatResultCars(data, categoryProviders, 'recommended', listSuppliers);
			
			const payload = { isLoading: true, list: resultCars };

			dispatch(requestSuccess(LIST_RECOMMENDED_CARS, payload ));	
		}
		
		return { status, message };

    }catch(error){
      	return { status: false, message: error };
    }
};

export const getQuoteCar = (id) => (dispatch, getState) => {
	try{		
		const { listResultCar } = getState().cars;
		const payload = listResultCar.find(item => item.id===id);
		dispatch(requestSuccess(SELECTED_CAR_QUOTE, payload ));	

    }catch(error){
      	return { status: false, message: error };
    }
};



export const sendFormScheduledSearch = (email) => async (dispatch, getState) => {
	try{

		let { itemsForm, selectedTab } = getState().search;
		let { listKeys } = getState().cars;

		let isFromInterest = (selectedTab==='search-normal') ? false : true;

		let body = { 
			email, 
			searched: { ...itemsForm, isFromInterest, keys: listKeys }
		};

		let response = await API.postApi(endPoints.car.scheduledSearch, body);
		
		let { isSuccessFull, message } = response.data;

		return { status: isSuccessFull, message };
    }catch(error){
      return {status: false, message:error};
    }
};

export const resetSelectedCarMyRoute = (listCarSelected) => (dispatch, getState) => {
	try{
		localStorage.setItem("my-route", JSON.stringify(listCarSelected));
		dispatch(requestSuccess(SELECTED_CARS_ROUTE, listCarSelected));
    }catch(error){
      return {status: false, message:error};
    }
};   

export const resetDataCarSelected = () => (dispatch, getState) => {
	const payload = { 
		infoCar: {
			    bodyWork: "",
			    name: "",
			    brand: "",
			    key: "",
			    concessionaire:"",
			    country: "",
			    fuel: "",
			    id: "",
			    model: "",
			    performance: "",
			    price: "",
			    seatAmount: "",
			    traction: "",
			    transmission: "",
			    year: "",
			    type: "",
			    localIdMap: "",
			    localMap: {},
			    photoMain: "",
			    isMyRouter: false,
			    isCompare: false,
			    isBanner: false,
			    typeKey: "",
			    categoryProviderId: 0,
			    typeFeaturedOrRecommended: "",
			    infoBrand: {},
			    typekeyRecommended: "",
			    priceLockDown: "",
			    listPriceView: "",
			    mileage: "",
			    listFeatures: [],
			    listSpecific: [],
			    photos: []
		}
	};
	dispatch(requestSuccess(INITIAL_DATA_CAR_FILE, payload));
}; 

export const selectedSendFormMyRoute = (value, check) => (dispatch, getState) => {
	try{
		const { listCarMyRoute } = getState().cars;

		let listCarMyRouteUpdate = listCarMyRoute

		let myRouteIndex = listCarMyRouteUpdate.findIndex(item => item.key===value)

		if(myRouteIndex!==-1){
			listCarMyRouteUpdate[myRouteIndex].isSend=check

			let payload = { listCarMyRouteUpdate }

			dispatch(requestSuccess(SELECTED_SEND_FORM_MY_ROUTE, payload));
		}

    }catch(error){
      return {status: false, message:error};
    }
};  
