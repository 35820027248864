
const CardOtherService = ({ id, name, photoMain, selectedQuote }) => {

	return (
		<div className="service__details--other" onClick={(event)=>selectedQuote(event, id, name)}>
			<div className="result-item--other">
				<img src={photoMain} alt="Removolque" className="result-item__other--img" />
			</div>
		</div>
	)
}

export default CardOtherService