import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { sendFormNotification, sendFormNotificationByBrand } from 'services/actions/notificationAction';

import { resetSelectedCarQuote } from 'services/actions/concessionaireActions';
import { useHistory, useLocation } from 'react-router-dom'

import { useGoogleRecaptcha } from 'hooks/Contact/useGoogleRecaptcha';

export const useFormContactService = () => {

	const dispatch = useDispatch()	
	const history = useHistory()
	const location = useLocation()

	const { getToken } = useGoogleRecaptcha();

	const { categoryProviders } = useSelector((state) => state.services)

	const { register, reset, handleSubmit, errors } = useForm();
	const [ disabledBtn, setDisabledBtn ] = useState(true);
	const [ isOpen, setIsOpen ] = useState(false);
	const [ isLoading, setIsLoading ] = useState(false);
	const [ message, setMessage ] = useState({
		error: false, 
		title: '¡Ups!', 
		subtitle: 'Ha ocurrido error en el envío del formulario. Por favor intenta nuevamente', 
		btnTitle: 'Reintentar',
		success: false
	})

	const closeModal = () => setIsOpen(false)

	const handleActive = (event) => setDisabledBtn(!event.target.checked);

	const onSubmit =  async (data, provider, itemService) => {

		try {
			setIsLoading(true)
			setMessage({error: false, title: '', subtitle: '', btnTitle: '', success: true })
			setDisabledBtn(true)
			
			const tokenCaptcha = await getToken();
			const category = categoryProviders.find(item => item.name===itemService)
		
			let dataForm = {
				name: data.name,
				telephone: "+569"+data.telephone,
				email: data.email,
				message: data.message,
				category_provider_id: category?.id,
				token: tokenCaptcha
			}
	
	
			if(typeof itemService!=="undefined" && itemService!==null){
				dataForm = {
					...dataForm,
					items: [{description: itemService}]				
				}
			}
	
			if(provider!==null){
				dataForm = {
					...dataForm,
					provider
				}
			}
			
			let response = ""
		
			if(itemService!==null){
			   response = await dispatch(sendFormNotification(dataForm, location.pathname))
			} else {
				response = await dispatch(sendFormNotificationByBrand(dataForm, location.pathname))
			}
	
			if(response.status){
				/**setMessage({
					error: false, 
					title: '¡Gracias!', 
					subtitle: 'Tu solicitud ha sido enviada con éxito pronto tendrás noticias nuestras',
					btnTitle: 'Ok',
					success: true
				})**/
				reset({
					name: '',
					telephone: '',
					email: '',
					message: ''
				})
				dispatch(resetSelectedCarQuote())
				setDisabledBtn(false)
				setIsLoading(false)
				history.push('/success')
			}else{
				setMessage({
					error: false, 
					title: '¡Ups!', 
					subtitle: 'Ha ocurrido error en el envío del formulario. Por favor intenta nuevamente',
					btnTitle: 'Reintentar',
					success: false
				})
				setDisabledBtn(false)
				setIsLoading(false)
				setIsOpen(true)
			}
		}catch(error){
			setMessage({
				error: false, 
				title: '¡Ups!', 
				subtitle: 'Ha ocurrido un error en el envío del formulario. Por favor recargue la página e intenta nuevamente',
				btnTitle: 'Reintentar',
				success: false
			})
			setDisabledBtn(false)
			setIsLoading(false)
			setIsOpen(true)
		}
		
	}

	return {
		handleActive,
		disabledBtn,
		register, 
		handleSubmit, 
		onSubmit,
		message,
		isOpen, 
		closeModal,
		isLoading,
		errors
	}
}