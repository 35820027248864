
const ItemRemolque = ({ id, name, photoMain, selectedQuote }) => {

	return (
		<div className="service-result-removolque" onClick={(event)=>selectedQuote(event, id, name)}>
			<div className="result-item__removolque">
				<img src={photoMain} alt="Removolque" className="result-item__removolque--img" />
			</div>
			<p className="result-removolque--title">{name} </p>
		</div>
	)
}

export default ItemRemolque