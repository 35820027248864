import MediaQuery from 'react-responsive'

const BgCurve = ({ pathDefault, pathDsk, bgColor }) => {

	return (
		<>
			<MediaQuery maxWidth={767}>
				<div style={{ height: "100px", overflow: "hidden", width: "100%"}} >
					<svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: "100%", width: "100%" }}>
						<path d={pathDefault} style={{stroke: "none", fill: bgColor}}/>
					</svg>
				</div>		
			</MediaQuery>
			<MediaQuery minWidth={767}>
				<div style={{ height: "150px", overflow: "hidden", width: "100%"}} >
					<svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: "100%", width: "100%" }}>
						<path d={pathDsk} style={{stroke: "none", fill: bgColor}}></path></svg>
				</div>
			</MediaQuery>
		</>
	)
}

export default BgCurve