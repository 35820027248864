import { useRef } from 'react'
import { Modal } from 'react-bootstrap'
import MapFileCar from 'components/Customs/Mapa/MapFileCar'
import closed_menu from 'assets/images/icons/delete-result.svg'

const UbicationModal = ({ show, handleClose, localMap }) => {
	
	const mapVector = useRef();

	return (
		<Modal
	        show={show}
	        onHide={handleClose}
	        backdrop="static"
	        keyboard={false}
	      >
	      	<div>
	        	<div className="modal__closed" onClick={handleClose}>
					<img src={closed_menu} alt="Cerrar" />
				</div>
	        </div>
	        <Modal.Body>
	          	<MapFileCar 
	          		localMap={localMap} 
	          		classMap="map-concessionaire" 
	          		classTitle="file-to-car__title-des" 
	          		mapVector={mapVector}
	          	/>
	        </Modal.Body>
	    </Modal>
	)
}

export default UbicationModal