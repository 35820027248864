import { SwiperSlide } from 'swiper/react'

import Slider from 'components/Customs/Carousel/Slider'

const SliderMarca = ({ listado }) => {

	return (
		<div className="container">
			<div className="row">
				<div className="col-12 carousel_tire">
					<p className="carousel_tire__title">Las mejores 
					<span className="panel__title--orange"> marcas </span> están en Movicenter</p>
					<Slider
						options= {{
							className: "tires__carousel",
					        slidesPerView: 4, 
								slidesPerColumn: 2,
								slidesPerGroup: 4,
								spaceBetween: 0,
								slidesPerColumnFill: "row",
								grabCursor: true,
								navigation: false,
								pagination: true,
								breakpoints:{
						              "200": {
						                "slidesPerView": 3,
						                "spaceBetween": 15,
						                "slidesPerColumn": 2,
						                "slidesPerGroup": 3
						              },
						              "600": {
						                "slidesPerView": 4,
						                "spaceBetween": 5,
						                "slidesPerColumn": 2,
						                "slidesPerGroup": 4
						              },
						              "768": {
						                "slidesPerView": 4,
						                "spaceBetween": 15,
						                "slidesPerColumn": 2,
						                "slidesPerGroup": 4
						              },
						              "1024": {
						                "slidesPerView": 4,
						                "spaceBetween": 5,
						                "slidesPerColumn": 2,
						                "slidesPerGroup": 4
						              }
						        }
						}}
						
				    >
				  		{
				  			listado.map((item,key) =>
				  				<SwiperSlide key={key}>
				  					<div className={`tires__imagen`}>
										<img src={item.imagen} alt={item.title} />
									</div>
				  				</SwiperSlide>
				  			)
				  		}
				  	</Slider>
								

					
				</div>
			</div>
		</div>
	)
}

export default SliderMarca