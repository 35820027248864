import { useEffect } from "react";
import { getListInitialTires, searchServiceBySlug, searchTireByMeasure, getListInitialServices } from 'services/actions/serviceActions'
import { useDispatch, useSelector } from "react-redux";
import { useAdvertising } from 'hooks/useAdvertising';


export const useMainTires = () => {

	const dispatch = useDispatch()
	const { listTires, measures, resultTires, tireSelectedQuote, resultServices, categoryProviders } = useSelector((state) => state.services)

	const { advertisingTire, actionAdvertising, getAdvertisingInitial } = useAdvertising()

	const getDataInitial = async () => {
		dispatch(getListInitialTires())
		await getAdvertisingInitial();
		await dispatch(searchServiceBySlug("neumaticos"))
		if(categoryProviders.length===0){
			await dispatch(getListInitialServices())
		}

	}

	useEffect(() => {
		getDataInitial()
		//ReactGA.pageview(window.location.pathname)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const getSearchInitial = async (data) => {
		await dispatch(searchTireByMeasure(data))
	}

	const state = {
		measures,
		advertisingTire,
		resultServices,
		listTires,
		tireSelectedQuote,
		resultTires
	}

	const actions = {
		actionAdvertising,
		getSearchInitial
	}

	return {
		state,
		actions
	}
}