import { Link } from 'react-router-dom'

const ItemFeature = ({ d_key, photoMain, type, name, priceLockDown, SlideClass, lastPage }) => {


	return (
		<Link 
			to={{
				pathname: `/autos/${d_key}`,
				state: lastPage
			}}
		>
			<div className={`card card__container card__feature ${SlideClass}`}>
				<div className="card__body">
			    	<div className="featured__image">
			    		<img src={photoMain} alt={name}  />
			    	</div>
			    	<div className="featured__item__container">
			    		<p className="featured__type">{type}</p>
			    		<p className="featured__title">{name}</p>
			    		<p className="featured__price">{priceLockDown}</p>
			    	</div>
				</div>
			</div>
		</Link>
	)
}

export default ItemFeature