import { Modal } from 'react-bootstrap'
import LottieTire from 'components/Template/Loading/LottieTire'

const ModalSpinnerTire = ({ message }) => {

	return (
		<Modal
        show={true}
        backdrop="static"
        keyboard={false}
        className="modal-content-spinner"
        dialogClassName="modal-spinner"
      >
        <LottieTire 
				title={message}
				classLoading="loading-result"
				width={600}
			/>
      </Modal>
	)
}

export default ModalSpinnerTire