import info from 'assets/images/icon/info.svg'

const MessageInfo = ({ title, classMessage}) => {

	return (
		<div className={`message-alert ${classMessage}`}>
	    	<div className="message-alert--icon">
	    		<img src={info} alt="Icono Información" />
	    	</div>
	    	<p>{title}</p>
	    </div>
	)
}

export default MessageInfo