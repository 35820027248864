import { SET_DATA_FORM } from 'services/types/notificationType';

const INITIAL_STATE = {
	dataFormNotification: {},
	urlLast: ""
}

const notificationReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case SET_DATA_FORM:
			return {
				...state,
				dataFormNotification: action.payload.data,
				urlLast: action.payload.urlLast
			}
		default: return state
	}
}

export default notificationReducer