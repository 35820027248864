import { useEffect, useState } from 'react'
import BotonUbication from 'components/Customs/Mapa/BotonUbication'
import Cards from 'components/Customs/Panels/Cards'
import mapLocalJson from 'components/Customs/Mapa/locals.json'
import TooltipLocal from 'components/Customs/Mapa/TooltipLocal'
import { VectorMap } from '@south-paw/react-vector-maps'
import mapaLocal from 'assets/images/mapa-local.svg'


const MapFileCar = ({ classMap, localMap, classTitle, mapVector }) => {
	
	const [ styleTooltip, setStyleTooltip ] = useState({x: 0, y: 0})

	useEffect(() => {

		if(typeof mapVector.current!=="undefined" && mapVector.current!==null){
			localMap?.mapaIdLocals.forEach(element => {
				if(element!==null){
					mapVector.current.querySelector("svg").querySelector('path[id='+element+']').setAttribute("class","selected")
				}			
	        });
        }
	    
		if(typeof mapVector.current!=="undefined" && mapVector.current!==null){

			let path = mapVector.current.querySelector("svg").querySelector('path[id='+localMap?.mapaIdLocals[0]+']')
		    if(path!==null){ 
		        const position = path.getBBox()

		        const positionX = position.x - 100
		        const positionY = position.y + position.height
		        setStyleTooltip({x: positionX, y: positionY })
		    }
		}
	    // eslint-disable-next-line react-hooks/exhaustive-deps
	  }, [localMap])

	return (
		<div className={`row ${classMap}`}>
			<div className="col-12">
				
					<Cards classBody="" classContainer="file-to-car">
						<p className={`file-to-car__title ${classTitle!=='' ? classTitle : ''}`}>Donde puedo <span className="panel__title--orange">encontrar</span> este auto</p>
						<div className="service-map__image mapa-local" ref={mapVector}>
							<img src={mapaLocal} alt="Mapa" />
      						<VectorMap 
				                {...mapLocalJson}  
				                checkedLayers={[localMap?.mapaIdLocals]} 
				                className="mapa-local-path"  
				            />  
				            <TooltipLocal {...localMap} styleTooltip={styleTooltip}  />
						</div>
						<div className="w-100">
							<BotonUbication 
								url="https://www.waze.com/ul?preview_venue_id=189597114.1896233287.588586&navigate=yes" 
								isClass="btn__icon btn__icon--waze"
								title="Como llegar con waze"
								btnColor="btn-primary"
							/>
							<BotonUbication 
								url="https://www.google.com/maps/dir//-33.3708241,-70.6639784/@-33.370824,-70.663978,16z?hl=es-ES"
								isClass="btn__icon btn__icon--pin"
								title="Como llegar Google Maps"
								btnColor="btn-primary"
							/>
							<BotonUbication 
								//url="https://www.google.com/maps/@-33.3732341,-70.6638325,3a,90y,25.97h,88.9t/data=!3m7!1e1!3m5!1sAF1QipPptkiCa-jBiFQddSH_ujfB21NDW9HlCtvyPOpz!2e10!3e12!7i3840!8i1920"
								url="https://goo.gl/maps/K6qgqme31d4JfowT8"
								isClass="btn__icon btn__icon--grados"
								title="Hacer tour virtual"
								btnColor="btn-secondary"
							/>
						</div>
					</Cards>
					
		
			</div>
		</div>
	)
}

export default MapFileCar