import { Link } from 'react-router-dom';

const PostCards = ({isClass, slug, acf, formatted_date, title, _embedded }) => {

    return (
         <div className="blog-item">
            <div className="menu__desktop--link card-posts">
                <Link to={`/blog/${slug}`}>
                    <div 
                        className="blog-card__image" 
                    >
                        <img 
                            alt={acf.etiquetas[0].name } 
                            className="blog__preview__img" 
                            src={acf.imagen_de_cabecera.sizes.large}
                        />
                    </div>
                    <div className="card__content">
                        <p className="blog_main--title">{title.rendered}</p>
                        <p className="description-main__blog">{acf.descripcion}</p>
                        <p className="subtitle-main__blog">Por 
                            <span className="subtitle-author">Movicenter</span> 
                            <span className="subtitle-date">{formatted_date}</span> 
                            Categoría: <span className="subtitle-category">{acf.etiquetas[0].name}</span>
                        </p>
                        
                        
                        <p className="blog__text--link">+ Ver más</p>
                    </div>
                </Link>
            </div>                 
        </div>
    )
}

export default PostCards;