import { 
	LIST_INITIAL_BRAND,
	UPDATE_SELECTED_BRAND,
	VISIBLE_LIST_MODEL,
	LIST_BRAND_MODEL_SELECTED,
	LIST_SELECTED_MODEL,
	DELETE_BRAND_MODEL_SELECTED,
	UPDATE_VISIBLE_BRAND,
	UPDATE_LIST_MODEL,
	RESET_TAB_BRAND_MODEL_SELECTED,
	RESET_SEARCH_BY_URL_BRAND_MODEL,
	LOADING_SEARCH_BRANDS,
	LOADING_BRAND
} from 'services/types/marcaType'

const INITIAL_STATE = {
	listModel: [],
	listBrands: [],
	selectedBrandAndModel: { brands: [], models: [] },
	listModelByBrandSelected: [],
	listBrandsSelected: [],
	isLoadingBrands: false,
	groupBrand: []
}

const marcaReducer1 = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case LOADING_BRAND:
			return {
				...state,
				isLoadingBrands: action.payload
			}
		case LIST_INITIAL_BRAND:
			return {
				...state,
				listBrands: action.payload.brands,
				isLoadingBrands: action.payload.isLoading
			}
		case UPDATE_SELECTED_BRAND:
			return {
				...state,
				listBrandsSelected: action.payload.brands,
				listModelByBrandSelected: action.payload.models
			}
		case VISIBLE_LIST_MODEL:
			return {
				...state,
				listModel: action.payload.models,
				groupBrand: action.payload.groupBrand
			}
		case LIST_BRAND_MODEL_SELECTED:
			return {
				...state,
				selectedBrandAndModel: action.payload
			}
		case LIST_SELECTED_MODEL:
			return {
				...state,
				listBrandsSelected: action.payload.brands,
				listModelByBrandSelected: action.payload.models
			}
		case DELETE_BRAND_MODEL_SELECTED:
			return {
				...state,
				listBrandsSelected: action.payload.listBrands,
				selectedBrandAndModel: action.payload.items,
				listModelByBrandSelected: action.payload.modelsSlected
			}
		case UPDATE_VISIBLE_BRAND:
			return {
				...state,
				listBrandsSelected: action.payload.brandsSelected,
				isLoadingBrands: action.payload.isLoadingBrands
			}
		case UPDATE_LIST_MODEL:
			return {
				listModelByBrandSelected: action.payload
			}
		case RESET_TAB_BRAND_MODEL_SELECTED:
			return {
				...state,
				selectedBrandAndModel: action.payload.selectedBrandModel,
				listModelByBrandSelected: action.payload.listModelSelected,
				listBrandsSelected: action.payload.listBrands,
				listModel: action.payload.listModelSelected
			}
		case RESET_SEARCH_BY_URL_BRAND_MODEL:
			return {
				...state,
				selectedBrandAndModel: action.payload.listBrandsReset,
				listBrandsSelected: action.payload.listBrandsSelectedEdit,
				listModelByBrandSelected: action.payload.listModels				
			}
		case LOADING_SEARCH_BRANDS:
			return {
				...state,
				isLoadingBrands: action.payload.isLoadingBrands
			}
		default: return state
	}
}

export default marcaReducer1