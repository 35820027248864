import { 
	LIST_ADVERTISING_INITIAL,
	GET_BANNER_ID,
	GET_CONFIG_TIME_SLIDER,
	LOADING_SLIDER
} from 'services/types/advertisingType'
import { home_main, home_new, home_used } from "data/BannerSliderHome.json"

const INITIAL_STATE = {
	listAdvertising: [],
	advertisingHomeMain: [],
	advertisingHomeNew: [],
	advertisingHomeUsed: [],
	advertisingTire: [],
	advertisingServices: [],
	advertisingResult: [],
	advertisingCars: [],
	advertisingMoto: [],
	advertisingPageMoto: [],
	advertisingTypeMoto: [],
	advertisingElectrical: [],
	banner_id: null,
	bannerSliderHome: home_main,
	bannerSliderHomeNew: home_new,
	bannerSliderHomeUsed: home_used,
	advertisingSliderPrincipal: [],
	advertisingSliderNew: [],
	advertisingSliderUsed: [],
	pageDurationTimerPrincipal: 0,
	pageDurationTimerNew: 0,
	pageDurationTimerUsed: 0,
	listPages: [],
	loadingSlider: true
}

const advertisingReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case LIST_ADVERTISING_INITIAL:
			return {
				...state,
				listAdvertising: action.payload.listAdvertising,
				advertisingHomeMain: action.payload.advertisingHomeMain,
				advertisingHomeNew: action.payload.advertisingHomeNew,
				advertisingHomeUsed: action.payload.advertisingHomeUsed,
				advertisingTire: action.payload.advertisingTire,
				advertisingServices: action.payload.advertisingServices,
				advertisingResult: action.payload.advertisingResult,
				advertisingCars: action.payload.advertisingCars,
				advertisingMoto: action.payload.advertisingMoto,
				advertisingPageMoto: action.payload.advertisingPageMoto,
				advertisingTypeMoto: action.payload.advertisingTypeMoto,
				advertisingElectrical: action.payload.advertisingElectrical,
				advertisingSliderPrincipal: action.payload.advertisingSliderPrincipal,
				advertisingSliderNew: action.payload.advertisingSliderNew,
				advertisingSliderUsed: action.payload.advertisingSliderUsed,
				loadingSlider: action.payload.loadingSlider
			}
		case GET_BANNER_ID:
			return {
				...state,
				banner_id: action.payload.banner_id
			}
		case GET_CONFIG_TIME_SLIDER:
			return {
				...state,
				pageDurationTimerPrincipal: action.payload.pageDurationTimerPrincipal,
				pageDurationTimerNew: action.payload.pageDurationTimerNew,
				pageDurationTimerUsed: action.payload.pageDurationTimerUsed,
				listPages: action.payload.listPages
			}
		case LOADING_SLIDER:
			return {
				...state,
				loadingSlider: action.payload
			}
		default: return state
	}
}

export default advertisingReducer