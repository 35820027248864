import CarouselItem from 'components/Customs/DeleteItem/CarouselItem'
import { deleteItemSearchNormalInterest } from 'services/actions/searchActions'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

const ItemSearchNormal = ({ itemOfSearch }) => {

	const dispatch = useDispatch()
	const history = useHistory()

	const deleteItem = (event, item, type="") => {

		const response = dispatch(deleteItemSearchNormalInterest(item, type))
		if(response.status){
			let url = `/autos/${response.url}`
			if(response.url==="nuevos") {
				url = `/s/autos/${response.url}/`
			}else if(response.url==="usados") {
				url = `/s/autos/${response.url}/`
			}
			history.push(url)
		}
	}	

	return (
		<div className="col-12 option-filter-d mb-2 mt-4">
			<div className="d-flex">
				<section className="option-search__container">
					{
						itemOfSearch.length>0 ? (
							<CarouselItem 
								isClass="option-search__slider"
								listado={itemOfSearch}
								deleteItem={deleteItem}
							/>
						) : (
							<p className="option-filter__text">No hay filtros seleccionada</p>
						)
					}
					
				</section>
			</div>
		</div>
	)
}

export default ItemSearchNormal