
const MainPanel = ({ title, imgMobile, imgDsk, children }) => {

	return (
		<>
		<section className="row tires__template--default">
			<div className="header-tires">
				<div className="slider-tires">
					<div className="slider-tires__image">
						<picture>
							<source media="(min-width: 600px)" srcSet={imgDsk} />
							<img 
								src={imgMobile}
								width="100%"
								alt="Movicenter"
								className="header-tires__img"
							/>
						</picture>
					</div>
				</div>
			</div>
		</section>
		<div className="row">
			<div className="col-12">
				<div className="container d__header">
					<h1 className="services__title__tire">{title}</h1>
				</div>
				{children}
			</div>
		</div>
		</>
	)
}

export default MainPanel