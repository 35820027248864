import { selectedValueForm } from 'services/actions/serviceActions'
import { listMetaTire } from 'hooks/MetaHead/ListMetaTire.json'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'


export const useSearchMeasure = (isFilterByMarca) => {

	const history = useHistory()
	const location = useLocation()
	const dispatch = useDispatch()
	const { valueSearchMeasure } = useSelector((state) => state.services)

	const { register, handleSubmit } = useForm({})

	const onSubmit =  async (data) => {

		let info = data
		dispatch(selectedValueForm({...info, search: !valueSearchMeasure?.search}))

		if(!isFilterByMarca){
			let tireMetaExiste = listMetaTire.find(item => item.value?.width?.toString()===data?.width.toString()
										&& item.value?.ring.toString()===data?.ring.toString()
										&& item.value?.profile.toString()===data?.profile.toString()
										)

			if(tireMetaExiste){
				history.push(`${tireMetaExiste.urlFriendly}`)	
			}else{
				history.push(`/servicio-automotriz/neumaticos/busqueda`)
			}		
			
		}else{
			history.push(location?.pathname)
		}
	}

	return {
		register, 
		handleSubmit,
		onSubmit,
		valueSearchMeasure
	}
}