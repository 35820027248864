export const listLogoBrands = [
	{ name: 'summit', url: 'https://api-news.movicenter.cl/logos-marcas/auto-summit.png' },
	{ name: 'baic', url: 'https://api-news.movicenter.cl/logos-marcas/baic.png' },
	{ name: 'bmw', url: 'https://api-news.movicenter.cl/logos-marcas/bmw.png' },
	{ name: 'brilliance', url: 'https://api-news.movicenter.cl/logos-marcas/brilliance.png' },
	{ name: 'changan', url: 'https://api-news.movicenter.cl/logos-marcas/changan.png' },
	{ name: 'chevrolet', url: 'https://api-news.movicenter.cl/logos-marcas/chevrolet.png' },
	{ name: 'citroen', url: 'https://api-news.movicenter.cl/logos-marcas/citroen.png' },
	{ name: 'ditec', url: 'https://api-news.movicenter.cl/logos-marcas/ditec.png' },
	{ name: 'faw', url: 'https://api-news.movicenter.cl/logos-marcas/faw.png' },
	{ name: 'ford', url: 'https://api-news.movicenter.cl/logos-marcas/ford.png' },
	{ name: 'geely', url: 'https://api-news.movicenter.cl/logos-marcas/geely.png' },
	{ name: 'gildemeister', url: 'https://api-news.movicenter.cl/logos-marcas/gildemeister.png' },
	{ name: 'great-wall', url: 'https://api-news.movicenter.cl/logos-marcas/great-wall.png' },
	{ name: 'haval', url: 'https://api-news.movicenter.cl/logos-marcas/haval.png' },
	{ name: 'honda', url: 'https://api-news.movicenter.cl/logos-marcas/honda.png' },
	{ name: 'hyundai', url: 'https://api-news.movicenter.cl/logos-marcas/hyundai.png' },
	{ name: 'jac', url: 'https://api-news.movicenter.cl/logos-marcas/jac.png' },
	{ name: 'jetour', url: 'https://api-news.movicenter.cl/logos-marcas/jetour.png' },
	{ name: 'kia', url: 'https://api-news.movicenter.cl/logos-marcas/kia.png' },
	{ name: 'kyc', url: 'https://api-news.movicenter.cl/logos-marcas/kyc.png' },
	{ name: 'lifan', url: 'https://api-news.movicenter.cl/logos-marcas/lifan.png' },
	{ name: 'mahindra', url: 'https://api-news.movicenter.cl/logos-marcas/mahindra.png' },
	{ name: 'maxus', url: 'https://api-news.movicenter.cl/logos-marcas/maxus.png' },
	{ name: 'mazda', url: 'https://api-news.movicenter.cl/logos-marcas/mazda.png' },
	{ name: 'mg', url: 'https://api-news.movicenter.cl/logos-marcas/mg.png' },
	{ name: 'nissan', url: 'https://api-news.movicenter.cl/logos-marcas/nissan.png' },
	{ name: 'olx', url: 'https://api-news.movicenter.cl/logos-marcas/olx.png' },
	{ name: 'opel', url: 'https://api-news.movicenter.cl/logos-marcas/opel.png' },
	{ name: 'peugeot', url: 'https://api-news.movicenter.cl/logos-marcas/peugeot.png' },
	{ name: 'pompeyo', url: 'https://api-news.movicenter.cl/logos-marcas/pompeyo.png' },
	{ name: 'portillo', url: 'https://api-news.movicenter.cl/logos-marcas/portillo.png' },
	{ name: 'renault', url: 'https://api-news.movicenter.cl/logos-marcas/renault.png' },
	{ name: 'seat', url: 'https://api-news.movicenter.cl/logos-marcas/seat.png' },
	{ name: 'subaru', url: 'https://api-news.movicenter.cl/logos-marcas/subaru.png' },
	{ name: 'suzuki', url: 'https://api-news.movicenter.cl/logos-marcas/suzuki.png' },
	{ name: 'toyota', url: 'https://api-news.movicenter.cl/logos-marcas/toyota.png' },
	{ name: 'volkswagen', url: 'https://api-news.movicenter.cl/logos-marcas/volkswagen.png' },
	{ name: 'volvo', url: 'https://api-news.movicenter.cl/logos-marcas/volvo.png' },
	{ name: 'zx-auto', url: 'https://api-news.movicenter.cl/logos-marcas/zx-auto.png' }
]

export const modelo1 = [
	{id: 1, name: 'BMW x1', visible: false },
	{id: 2, name: 'BMW x2', visible: false },
	{id: 3, name: 'BMW x3', visible: false },
	{id: 4, name: 'BMW x4', visible: false },
	{id: 5, name: 'BMW x5', visible: false },
	{id: 6, name: 'BMW x6', visible: false },
	{id: 7, name: 'BMW i3', visible: false },
	{id: 8, name: 'BMW ix', visible: false },
	{id: 9, name: 'BMW Serie 3', visible: false },
	{id: 10, name: 'BMW Serie 4coupé', visible: false },
	{id: 11, name: 'BMW 303e iperformance', visible: false },
	{id: 12, name: 'BMW M240i coupé', visible: false }
]