import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import styles from 'styles/Motorcycle.module.css'

const BrandMotorcycle = ({ listBrandMotorcycle, isLoading }) => {

	return (
		<>
			{ isLoading && (
				Array.from([1, 2, 3, 4]).map((item, key) => (
					<div className={styles["brand-item"]} key={key}>
						<Skeleton count={1} className={styles["brand-item__image"]} />
					</div>
				))
			)}
			{
				(!isLoading && listBrandMotorcycle.length>0) &&
				listBrandMotorcycle.map((item, key) => (
					<div className={styles["brand-item"]} key={key}>
						<img src={item.icon} alt={item.brand} className={styles["brand-item__image"]} />
					</div>
				))
			}
		</>
	)
}

export default BrandMotorcycle