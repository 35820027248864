import SelectedMarcaAndModelo from 'components/Searching/Ordinary/SelectedMarcaAndModelo'
import GroupLabelBrand from 'components/Customs/Searching/GroupLabelBrand'
import Select from 'react-select'


const PanelSelectBrandModel = ({ listItem, isLoading, listItemPanel, deleteItem, 
	valueOptions, title, openPanelItem, isOpenPanel, refItem, 
	handleSelectItem }) => {

	return (
		<div ref={refItem} className={`group-${title==="Marcas" ? "brand" : "model"}__clic`} tabIndex="0">
	    	<div 
	    		className={`search-form-select ${isOpenPanel ? 'search-selected' : ''}`} 
	    		onClick={()=>openPanelItem(title)}
	    	>
	    		<p 
	    		className={`form-select__title mb-0 ${isOpenPanel ? 'search-form-selected' : ''}`}
	    		onClick={()=>openPanelItem(title)}>{title}</p>
	    		<SelectedMarcaAndModelo 
	    			items={listItemPanel} 
	    			title={isLoading ? `Cargando las ${title}...` : `Seleciona todas tus ${title}`}
	    			text={title?.toLowerCase()} 
	    			visibleMarcas={()=>openPanelItem()} 
	    			deleteItem={deleteItem}
	    			isOpenPanel={isOpenPanel}
	    		/>
	    	</div>
    		{isOpenPanel  &&
    			<Select
			      closeMenuOnSelect={false}
			      isMulti
			      options={listItem}
			      className={`select-multiple__brand`}
			      classNamePrefix={`multiple__brand`}
			      theme={(theme) => ({
				      ...theme,
				      borderRadius: 0,
				      colors: {
				        ...theme.colors,
				        primary25: '#ffe1ce',
				        primary: '#f47422',
				      },
				    })}
			      	isClearable={false}
			      	noOptionsMessage={()=> "No hay opciones disponible"}
			      	placeholder={isLoading ? `Cargando...` : `Buscar`}
	    			menuIsOpen={true}
	    			onChange={(event)=>handleSelectItem(event)}
	    			value={valueOptions}
	    			formatGroupLabel={GroupLabelBrand}
	    			components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
			    />
			}
		</div>
	);
};

export default PanelSelectBrandModel;