import carroceria_white_1 from 'assets/images/carroceria/gris/camioneta.png'
import carroceria_white_2 from 'assets/images/carroceria/gris/city.png'
import carroceria_white_3 from 'assets/images/carroceria/gris/coupe.png'
import carroceria_white_4 from 'assets/images/carroceria/gris/hatchback.png'
import carroceria_white_5 from 'assets/images/carroceria/gris/sedan.png'
import carroceria_white_6 from 'assets/images/carroceria/gris/station_wagon.png'
import carroceria_white_7 from 'assets/images/carroceria/gris/suv.png'
import carroceria_white_8 from 'assets/images/carroceria/gris/comercial.svg'

import carroceria_orange_1 from 'assets/images/carroceria/naranja/camioneta_select.png'
import carroceria_orange_2 from 'assets/images/carroceria/naranja/city_select.png'
import carroceria_orange_3 from 'assets/images/carroceria/naranja/coupe_select.png'
import carroceria_orange_4 from 'assets/images/carroceria/naranja/hatchback_select.png'
import carroceria_orange_5 from 'assets/images/carroceria/naranja/sedan_select.png'
import carroceria_orange_6 from 'assets/images/carroceria/naranja/station_wagon_select.png'
import carroceria_orange_7 from 'assets/images/carroceria/naranja/suv_select.png'
import carroceria_orange_8 from 'assets/images/carroceria/naranja/comercial_select.svg'

export const vTypeTransmision = [
	{
        value: 'automatico', 
        checked: false, 
        name: 'vTransmision', 
        title: 'Automáticos', 
        valueForm: 'automatica,transmision automatica',
        slug: 'automaticos'
    },
	{
        value: 'mecanico', 
        checked: false, 
        name: 'vTransmision', 
        title: 'Mecánica', 
        valueForm: 'mecanica,manual,transmision mecanica',
        slug: 'mecanica' 
    }
]

export const vTypeAuto = [
	{
        value: 'car-new', 
        checked: true, 
        name: 'vTypeAuto', 
        title: 'Auto Nuevo', 
        valueForm: 'car-new',
        slug: 'nuevos' 
    },
	{
        value: 'car-used', 
        checked: false, 
        name: 'vTypeAuto', 
        title: 'Auto Usado', 
        valueForm: 'car-used',
        slug: 'usados'
    }
]

export	const vTypeCombustible = [
	{
        value: 'diesel', 
        checked: false, 
        name: 'vCombustible', 
        title: 'Diesel', 
        valueForm: 'diesel',
        slug: 'diesel'
    },
	{
        value: 'bencina', 
        checked: false, 
        name: 'vCombustible', 
        title: 'Bencina', 
        valueForm: 'gasolina,bencina',
        slug: 'bencina'
    },
	{
        value: 'hibrido', 
        checked: false, 
        name: 'vCombustible', 
        title: 'Híbrido', 
        valueForm: 'hibrido',
        slug: 'hibrido'
    },
    {
        value: 'electrico', 
        checked: false, 
        name: 'vCombustible', 
        title: 'Eléctrico', 
        valueForm: 'electrico',
        slug: 'electrico'
    }
]

export	const vTypeCarroceria = [
    {
        id: 1, 
        imagenWrite: carroceria_white_7, 
        name:'vCarroceria', 
        value: 'SUV', 
        title: 'SUV', 
        checked: false, 
        imagenOrange: carroceria_orange_7, 
        valueForm:'suv',
        classIcon: 'icon--suv',
        slug: 'suv'
    },
    {
        id: 2, 
        imagenWrite: carroceria_white_4, 
        name:'vCarroceria', 
        value: 'Hatchback', 
        title: 'Hatchback', 
        checked: false, 
        imagenOrange: carroceria_orange_4, 
        valueForm: 'hatchback',
        classIcon: 'icon--hatchback',
        slug: 'hatchback'
    },
    {
        id: 3, 
        imagenWrite: carroceria_white_3, 
        name:'vCarroceria', 
        value: 'Coupé', 
        title: 'Coupé', 
        checked: false, 
        imagenOrange: carroceria_orange_3, 
        valueForm: 'coupe',
        classIcon: 'icon--coupe',
        slug: 'coupe'
    },
    {
        id: 4, 
        imagenWrite: carroceria_white_5, 
        name:'vCarroceria', 
        value: 'Sedan', 
        title: 'Sedan', 
        checked: false, 
        imagenOrange: carroceria_orange_5,
        valueForm: 'sedan',
        classIcon: 'icon--sedan',
        slug: 'sedan'
    },
    {
        id: 5, 
        imagenWrite: carroceria_white_2, 
        name:'vCarroceria', 
        value: 'City', 
        title: 'City', 
        checked: false, 
        imagenOrange: carroceria_orange_2, 
        valueForm: 'city,citycar',
        classIcon: 'icon--city',
        slug: 'citycar'
    },
	{
        id: 6, 
        imagenWrite: carroceria_white_1, 
        name:'vCarroceria', 
        value: 'Camioneta', 
        title: 'Camioneta', 
        checked: false, 
        imagenOrange: carroceria_orange_1, 
        valueForm: 'camioneta,pick up,cabina simple,doble cabina',
        classIcon: 'icon--camioneta',
        slug: 'camioneta'
    },	
	{
        id: 7, 
        imagenWrite: carroceria_white_6, 
        name:'vCarroceria', 
        value: 'Station Wagon', 
        title: 'Station Wagon', 
        checked: false, 
        imagenOrange: carroceria_orange_6, 
        valueForm: 'station wagon',
        classIcon: 'icon--station_wagon',
        slug: 'station-wagon'
    },
    {
        id: 8, 
        imagenWrite: carroceria_white_8, 
        name:'vCarroceria', 
        value: 'Comercial', 
        title: 'Comercial', 
        checked: false, 
        imagenOrange: carroceria_orange_8, 
        valueForm:'comercial',
        classIcon: 'icon--comercial',
        slug: 'comercial'
    }
]

export	const vOrigin = [
	{ 
        id: 1, 
        value: 'Alemania',
        checked: false, 
        name: 'vOrigin', 
        title: 'Alemania',
        valueForm: 'Alemania', 
        slug: 'alemania' 
    },
    { 
        id: 2, 
        value: 'Brasil',
        checked: false, 
        name: 'vOrigin', 
        title: 'Brasil',
        valueForm: 'Brasil', 
        slug: 'brasil' 
    },
    { 
        id: 3,
        checked: false, 
        value:'chinos', 
        name: 'vOrigin', 
        title: 'China', 
        valueForm:'chinos,china', 
        slug: 'chinos' 
    },
    { 
        id: 4,  
        checked: false, 
        value: 'coreanos', 
        name: 'vOrigin',
        title: 'Corea del Sur', 
        valueForm: 'coreanos,Corea del Sur', 
        slug: 'coreanos' 
    },
    { 
        id: 5,  
        checked: false, 
        value: 'Espana', 
        name: 'vOrigin', 
        title: 'España',
        valueForm: 'Espana', 
        slug: 'espana' 
    },
    { 
        id: 6, 
        checked: false, 
        value: 'Estados Unidos', 
        name: 'vOrigin', 
        title: 'Estados Unidos',
        valueForm: 'Estados Unidos', 
        slug: 'estados-unidos' 
    },
    { 
        id: 7, 
        checked: false, 
        value: 'Francia', 
        name: 'vOrigin', 
        title: 'Francia',
        valueForm: 'Francia', 
        slug: 'francia' 
    },
    { 
        id: 8, 
        checked: false, 
        value: 'India', 
        name: 'vOrigin', 
        title: 'India',
        valueForm: 'India', 
        slug: 'india' 
    },
    { 
        id: 9, 
        checked: false, 
        value: 'Inglaterra', 
        name: 'vOrigin', 
        title: 'Inglaterra',
        valueForm: 'Inglaterra', 
        slug: 'inglaterra' 
    },
    { 
        id: 10, 
        checked: false, 
        value: 'Italia', 
        name: 'vOrigin', 
        title: 'Italia',
        valueForm: 'Italia', 
        slug: 'italia' 
    },
    { 
        id: 11, 
        checked: false, 
        value: 'japoneses', 
        name: 'vOrigin', 
        title: 'Japón',
        valueForm: 'japon,japoneses', 
        slug: 'japoneses' 
    },
    {
         id: 12, 
         checked: false, 
         value: 'Malasia', 
         name: 'vOrigin', 
         title: 'Malasia',
         valueForm: 'Malasia', 
         slug: 'malasia' 
    },
    {
        id: 13, 
        checked: false, 
        value: 'Marruecos', 
        name: 'vOrigin', 
        title: 'Marruecos',
        valueForm: 'Marruecos', 
        slug: 'marruecos' 
    },
    { 
        id: 14, 
        checked: false, 
        value: 'Republica Checa',
        name: 'vOrigin', 
        title: 'República Checa',
        valueForm: 'Republica Checa', 
        slug: 'republica-checa' 
    },
    { 
        id: 15, 
        checked: false, 
        value: 'Rumania',
        name: 'vOrigin', 
        title: 'Rumania',
        valueForm: 'Rumania', 
        slug: 'rumania' 
    },
    { 
        id: 16, 
        checked: false, 
        value: 'Rusia', 
        name: 'vOrigin',
        title: 'Rusia', 
        valueForm: 'Rusia', 
        slug: 'rusia' 
    },
    { 
        id: 17, 
        checked: false, 
        value: 'Serbia', 
        name: 'vOrigin', 
        title: 'Serbia',
        valueForm: 'Serbia', 
        slug: 'serbia' 
    },
    { 
        id: 18, 
        checked: false, 
        value: 'Suecia', 
        name: 'vOrigin',
        title: 'Suecia', 
        valueForm: 'Suecia', 
        slug: 'suecia' 
    },
    { 
        id: 19, 
        checked: false, 
        value: 'Turquía', 
        name: 'vOrigin',
        title: 'Turquía', 
        valueForm: 'Turquía', 
        slug: 'turquia' 
    }
]

export	const vPerformance = [
	{ 
        id: 1, 
        slug: 'p-9-kilometros', 
        checked: false, 
        valueInt: {min: 0, max: 10}, 
        value: "0-10",
        name: 'vPerformance', 
        title: 'Menos de 10 Km/L ',
        valueForm: 'performance_1'  
    },
    { 
        id: 2, 
        title: '10 a 15 Km/L', 
        slug: 'p-15-kilometros', 
        checked: false, 
        valueInt: {min: 10, max: 15}, 
        value: "10-15",
        name: 'vPerformance', 
        valueForm: 'performance_2' 
    },
    { 
        id: 3, 
        title: 'Más de 15 Km/L', 
        slug: 'p-16-kilometros', 
        checked: false, 
        valueInt: {min: 15, max: 100}, 
        value: "15-100",
        name: 'vPerformance', 
        valueForm: 'performance_3'  
    }
]

export	const vSeatMove = [
	{ 
        id: 1, 
        title: '2 corridas', 
        checked: false, 
        value: 2, 
        name: 'vSeatMove', 
        valueForm: '2-corridas', 
        slug: '2-corridas' 
    },
    { 
        id: 2, 
        title: '3 corridas',
        checked: false, 
        value: 3, 
        name: 'vSeatMove', 
        valueForm: '3-corridas', 
        slug: '3-corridas' 
    }
]

export	const vTraction = [
    { 
        id: 1, 
        title: '4x2', 
        checked: false, 
        value: '4x2', 
        name: 'vTraction', 
        valueForm: '4x2', 
        slug: '4x2' 
    },
    { 
        id: 2, 
        title: '4x4', 
        checked: false, 
        value: '4x4', 
        name: 'vTraction', 
        valueForm: '4x4', 
        slug: '4x4' 
    }
]