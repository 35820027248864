import { Controller } from "react-hook-form"
import FormOptions from "components/QuoteCar/Cars/Formulario/FormOptions"
//import search_white from "../../../assets/images/search-white.svg"
import { useOlxFormMain } from "hooks/Quotes/Car/useOlxFormMain"

const FormMain = () => {
    
   
    const { register, handleSubmit, onSubmit, control, searchModelGlobal, listBrandOlx, listModelOlx,
        activeKey, setActiveKey, getValues, searchYearGlobal, listYearOlx, listVersionOlx, searchKmGlobal, 
        listMileagesOlx, proccessForm, searchVersionGlobal, errors, handleActive } = useOlxFormMain(false)


    return (
        <section>
            <p className="ctitle-quote">Vende tu auto aquí</p>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group form-group__quote mt-5">
                    <label htmlFor="brand">Buscar por marca de auto</label>
                    <Controller
                        control={control}
                        name="brand" 
                        rules={{ required: {value: true, message: 'Requerido'} }}
                        render = {(props) => {
                            return (
                            <select 
                                value={props.value}
                                className="form-control form-quote__select form-quote__select--top"  
                                id={props.name}                    
                                onChange={e => {                              
                                    searchModelGlobal(e, false, "", "")
                                }}                            
                            >
                                <option value="">{listBrandOlx.isProcess ? "Cargando data" : "Seleccione marca" }</option>
                            {
                                listBrandOlx?.list?.length>0 && 
                                listBrandOlx?.list?.map((item, index) =>
                                    <option key={index} data-id={item.label} value={item.key}>{item.label}</option>
                                )
                            }
                            </select>
                        )}}
                        defaultValue={""}
                    />
                    { listBrandOlx.error===true && <p className="text-error mt-3">Se ha generado un error, vuelva a intentarlo en unos minutos</p>}
                </div>
                <FormOptions 
                    activeKey={activeKey} 
                    setActiveKey={setActiveKey} 
                    control={control}
                    register={register}
                    getValues={getValues}
                    listModelOlx={listModelOlx}
                    searchYearGlobal={searchYearGlobal}
                    listYearOlx={listYearOlx}
                    listVersionOlx={listVersionOlx}
                    searchKmGlobal={searchKmGlobal}
                    listMileagesOlx={listMileagesOlx}
                    searchVersionGlobal={searchVersionGlobal}
                    errors={errors}
                />
                <div className="form-group form-group-checkbox quote-group__main">
                    <input 
                        className="form__contact-check" 
                        type="checkbox" 
                        value={true}
                        id="acceptTerm" 
                        name="acceptTerm"
                        defaultChecked 
                        ref={register}
                        onChange={(event)=>handleActive(event)}
                     />
                     <label 
                        className="form__contact-label" 
                        htmlFor="acceptTerm">
                        Acepto <a 
                            href="https://movicenter-web.s3.us-east-2.amazonaws.com/docs/TEERMINOS+Y+CONDICIONES+MOVICENTER.pdf" 
                            target="_blank"
                            rel="noreferrer"
                        >términos y condiciones</a> y políticas de privacidad
                        
                    </label>
                </div>
               <button 
                    type="submit" 
                    className={`btn  btn-quote-sire mb-4 ${(proccessForm.status ? true : parseInt(activeKey)>3 ? false : true) ? "btn-secondary" : "btn-primary"}`}
                    disabled={proccessForm.status ? true :parseInt(activeKey)>3 ? false : true}
                >{proccessForm.loading ? "Procesando..." : "Obtener cotización" }</button>
            </form>
        </section>
    )
}

export default FormMain