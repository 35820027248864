import { useState } from "react";
import { sendFormScheduledSearch } from 'services/actions/carActions'

import { useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';


export const useFormSchedule = () => {

	const dispatch = useDispatch()	

	const { register, handleSubmit, errors } = useForm()
	const [ disabledBtn, setDisabledBtn ] = useState(false);
	const [ isOpen, setIsOpen ] = useState(false);
    const [ isOpenNoti, setIsOpenNoti ] = useState(false);
	const [ isLoading, setIsLoading ] = useState(false);
	const [ message, setMessage ] = useState({
		error: false, 
		title: '¡Ups!', 
		subtitle: 'Ha ocurrido error en el envío del formulario. Por favor intenta nuevamente', 
		btnTitle: 'Reintentar',
		success: false
	})


	const closeModal = () => setIsOpen(isOpen => !isOpen)
    const closeModalNoti = () => setIsOpenNoti(isOpenNoti => !isOpenNoti)

	const handleActive = (event) => setDisabledBtn(!event.target.checked);

	const onSubmit =  async (data, e) => {
		e.preventDefault()
        setIsLoading(true)
		setMessage({error: false, title: '', subtitle: '', btnTitle: '', success: false})

		const response = await dispatch(sendFormScheduledSearch(data.email))
	
		if(response.status){
			setMessage({
				error: false, 
				title: '¡Gracias!', 
				subtitle: 'Tu solicitud ha sido enviada con éxito pronto tendrás noticias nuestras',
				btnTitle: 'Ok'
			})
		}else{
			setMessage({
				error: true, 
				title: '¡Ups!', 
				subtitle: 'Ha ocurrido error en el envío del formulario. Por favor intenta nuevamente',
				btnTitle: 'Reintentar'
			})
		}
		setIsOpen(!isOpen)
		setIsOpenNoti(true)
        setIsLoading(false)
	}

	return {
		handleActive,
		disabledBtn,
		register, 
		handleSubmit, 
		onSubmit,
		message,
		isOpen, 
		closeModal,
		isLoading,
		errors,
        closeModalNoti,
        isOpenNoti
	}
}