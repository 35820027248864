import { 
	STATUS_SCANEO,
	LOADING_SCANEO
} from 'services/types/couponType'

const INITIAL_STATE = {
	loadingScaneo: false,
	message: { success: false, title: "", subtitle: "", btnTitle: ""}
}

const concourseReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case STATUS_SCANEO:
			return {
				...state,
				loadingScaneo: action.payload.loadingScaneo,
				message: action.payload.message
			}
		case LOADING_SCANEO:
			return {
				...state,
				loadingScaneo: action.payload,
			}
		default: return state
	}
}

export default concourseReducer