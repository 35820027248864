
const CardService = ({ background_image, name, id, selectedQuote }) => {

	return (
		<div className="service__details" onClick={(event)=>selectedQuote(event, id, name)}>
			<img src={background_image} alt={name} className="service__image"/>
			<div className="opacity-services"></div>
			<span className="service__title">{ name }</span>
		</div>

	)
}

export default CardService