import { useState, useEffect, useRef} from 'react'
import { useForm, useFieldArray } from 'react-hook-form'
import InputSliderRange from 'components/Customs/InputForm/InputSliderRange'
import CarroceriaList from 'components/Searching/Ordinary/CarroceriaList'
import  PanelSelectBrandModel from 'components/Customs/Searching/PanelSelectBrandModel'

import {  generateUrlSearchNormal, deleteItemBrandSelected, deleteItemModelSelected, generateItemMarcaModelForm,
	saveItemSearchNormal } from 'services/actions/searchActions'
import { getUpdateVisibleBrand, 
	 resetBrandModelChangeTab, loadingLoadBrands } from 'services/actions/marcaActions'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import useComponentVisible from "hooks/Main/useComponentVisible"


const vTypeCarAll = {
    id: "43229d0d-9c9f-4b90-99a9-2c1c478f0854",
    value: "car-all",
    checked: true,
    name: "vTypeAuto",
    title: "Todos",
    valueForm: "car-all",
    slug: "todos"
}

const NormalSearch = ({ isVisibleTypeAuto, defaultValues, typeHome, lastPage }) => {

	const dispatch = useDispatch()
	const history = useHistory()
	const minValRef = useRef(null);
	const maxValRef = useRef(null);
	const refTypeCar = useRef(null);

	const [ typeCarSelected, setTypeCarSelected ] = useState({ title: "Auto Nuevo", value: "car-new"})

	const { ref: refBrand, 
		isComponentVisible: isOpenBrand, 
		openPanelItem: openPanelBrand,
		handleSelectBrand,
		valueOptions: valueOptionsBrand 
	} = useComponentVisible(false);

	const { ref: refModel, 
		isComponentVisible: isOpenModel, 
		openPanelItem: openPanelModel,
		handleSelectModel,
		valueOptions: valueOptionsModel 
	} = useComponentVisible(false);

	const [ loading, setLoading ] = useState(false)
    
    
	const { isLoadingBrands  } = useSelector((state) => state.marcas)
	const { itemSelectedBrandANDModel  } = useSelector((state) => state.search)
	const { register, control, handleSubmit, getValues, setValue, reset } = useForm({defaultValues})

	const { fields: vTypeAuto } = useFieldArray({ control, name: "vTypeAuto" })
	const { fields: vCarroceria } = useFieldArray({ control, name: "vCarroceria" })


	const deleteItem = (e, item) => {
		e.stopPropagation()
	
		if(item?.name==="vMarca"){
			dispatch(deleteItemBrandSelected(item))
		}else{
			dispatch(deleteItemModelSelected(item))
		}		
	}

	const onSubmit = async (data) => {
	
		setLoading(true)

		let dataBrandModel = dispatch(generateItemMarcaModelForm())
		const listMarca = dataBrandModel?.data?.marcas
		const listModelo = dataBrandModel?.data?.modelos

		let body = { ...data }

		if(typeof data?.vTypeAuto==="undefined" && !isVisibleTypeAuto){

			const formTypeAuto = [
				 	{ name: "car-new", checked: typeHome==="car-new" ? true : false, slug: "nuevos" },
   					{ name: "car-used", checked: typeHome==="car-used" ? true : false, slug: "usados" }
				]
			body = { ...body, vTypeAuto: formTypeAuto }
		}else{
		
			let vTypeAuto = []
		
			if(typeCarSelected?.value==="car-all"){
				vTypeAuto = defaultValues?.vTypeAuto.map(item => {
										return {
											...item,
											checked: true
										}
							}) 
			}else {
				vTypeAuto = defaultValues?.vTypeAuto.map(item => {
										return {
											...item,
											checked: item?.value===typeCarSelected?.value ? true : false
										}
							}) 
			}
			body = { 
				...body, 
				vTypeAuto
			}
		}
	
		body = { 
			...body, 
			vMarca: listMarca, 
			vModelo: listModelo, 
			vTypeRange: {min: Number(minValRef.current), max: Number(maxValRef.current) } ,
			vTypeKm: "",
			vTypeYear: ""
		}
		
		delete body?.price_value
		delete body?.price_from
		delete body?.price_to

		let response = dispatch(generateUrlSearchNormal(body))

		setLoading(false)
		if(response.status) {
			let slugUrl = `/autos/${response.slug}`
			if(response.slug==="nuevos/" || response.slug==="usados/"){
				slugUrl = `/s/autos/${response.slug}`
			}

			history.push(slugUrl, lastPage)
		}
	}

	const selectedTypeAuto = (event, typeCar) => {
		event.preventDefault()
		
		dispatch(loadingLoadBrands(true))
		let responseReset = dispatch(resetBrandModelChangeTab())
		if(responseReset.status){
			if(typeCar?.value==="car-all"){
				dispatch(getUpdateVisibleBrand('other'))
			}else{
				dispatch(getUpdateVisibleBrand(typeCar?.value))
			}
		}
		selectedUpdateItem()
		visibleOption()
		setTypeCarSelected(typeCar)
		//setVisibleBrand(filterSelected.length > 0 ? true : false)
	}

	const selectedUpdateItem = () => {
		let data = getValues()
	
		let body = { 
			...data, 
			vTypeRange: {min: Number(minValRef.current), max: Number(maxValRef.current) },
		}
	
		dispatch(saveItemSearchNormal(body))
	}

	useEffect(() => {
		reset(defaultValues)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultValues])


	const [ activeSelect, setActiveSelect ] = useState('selectbox__select selectbox__select--car')
	const [ activeOption, setActiveOption ] = useState('selectbox__opciones')
	const [ openTypeCar, setOpenTypeCar ] = useState(false)

	const visibleOption = () => {
		const classSelect = activeSelect
		const classOption = activeOption
		if(openTypeCar){
			setActiveSelect(activeSelect => classSelect.replace('active', ''))
			setActiveOption(activeOption => classOption.replace('active', ''))
			setOpenTypeCar(false)
		}else {
			setActiveSelect(activeSelect => `${classSelect} active`)
			setActiveOption(activeOption => `${classOption} active`)
			setOpenTypeCar(true)
		}
	}

	useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

	const handleClickOutside = (event) => {	
	
		if(!event.target.classList.contains("typeCarSelect")){
		
			if (refTypeCar.current && !refTypeCar.current.contains(event.target)) {
				if(refTypeCar.current.className.includes('active')){
					
					setActiveSelect(activeSelect => 'selectbox__select selectbox__select--car')
					setActiveOption(activeOption => 'selectbox__opciones')
					setOpenTypeCar(false)
				}
			}
		}
        
    };


	return (
		<form onSubmit={handleSubmit(onSubmit)} className="w-100">
			<section className="search__main">
				<div className="search__main--type search-type-car">
					{/** isVisibleTypeAuto && <TypeAuto vTypeAuto={vTypeAuto} register={register} selectedTypeAuto={selectedTypeAuto} /> **/}
					{
						isVisibleTypeAuto && (
							<div className={`mb-5 w-100`}>
								<label htmlFor="vTypeAuto" className="search__type--title">Tipo</label>
								<div className="typeCarSelect catalogy_form catalogy_form--car">
									<div className="typeCarSelect -selectbox">
										<div id="typeCarSelect" className={`typeCarSelect ${activeSelect}`} onClick={()=>visibleOption()}>
											<div className="typeCarSelect selectbox__select--contenido">
												<h3 className="typeCarSelect titulo--car">{typeCarSelected?.title}</h3>
											</div>
											<span className={`typeCarSelect catalogy_down_arrow ${activeSelect?.includes("active") ? "catalogy_down_arrowY" : ""} `}></span>
										</div>

										<div ref={refTypeCar} className={activeOption} id="opciones">
											<div className="selectbox__opciones-item">
												<div 
													className="selectbox__opciones--opcion" 
													onClick={(event)=>selectedTypeAuto(event, vTypeCarAll)}
												>
													<h2 className="titulo-item">Todos</h2>
												</div>
											</div>
											{
												vTypeAuto.map((item,key) => (
													<div 
														key={key} 
														className="selectbox__opciones-item"
													>
														<div className="selectbox__opciones--opcion" onClick={(event)=>selectedTypeAuto(event, item)}>
															<h2 className="titulo-item">{item.title}</h2>
														</div>
													</div>
												))
											}
										</div>
									</div>
								</div>
							</div>
						)
					}			
						
					<InputSliderRange 
						register={register} 
						min={Number(defaultValues?.vTypeRange?.min)} 
						max={Number(defaultValues?.vTypeRange?.max)}
						minValRef={minValRef} 
						maxValRef={maxValRef} 
						control={control}
						setValue={setValue}
						classMain=""
						handleClickUpdateResult={selectedUpdateItem}
					/>
				</div>


				<CarroceriaList 
					getValues={getValues} 
					vCarroceria={vCarroceria} 
					register={register} 
					control={control} 
					setValue={setValue}
					selectedUpdateItem={selectedUpdateItem}
				/>

				<div className="search__type mb-0 search__main--brand-model">
					<PanelSelectBrandModel
						listItem={defaultValues?.vMarca}
						isLoading={isLoadingBrands}
						listItemPanel={itemSelectedBrandANDModel?.brands}
						deleteItem={deleteItem}
						valueOptions={valueOptionsBrand}
						title="Marcas"
						openPanelItem={openPanelBrand}
						isOpenPanel={isOpenBrand}
						refItem={refBrand}
						handleSelectItem={handleSelectBrand}
					/>
					
					<PanelSelectBrandModel
						listItem={itemSelectedBrandANDModel?.brands}
						isLoading={false}
						listItemPanel={itemSelectedBrandANDModel?.models}
						deleteItem={deleteItem}
						valueOptions={valueOptionsModel}
						title="Modelos"
						openPanelItem={openPanelModel}
						isOpenPanel={isOpenModel}
						refItem={refModel}
						handleSelectItem={handleSelectModel}
					/>
					<button type="submit" className="btn btn-primary btn-lg btn__search__link w-100" disabled={loading}>{ loading ? 'Buscando...' : 'Buscar Autos'}</button>
				</div>
			</section>

			{/**<AdvancedSearch 
				filter={filter} 
				filterAdvanced={filterAdvanced}
				register={register} 
				vTransmision={vTransmision} 
				vCombustible={vCombustible} 
				vTypeYear={defaultValues.vTypeYear}
				vTypeKm={defaultValues.vTypeKm}
				openAdvanced={openAdvanced}
				listYear={listYear}
				listKm={listKm}
				control={control}
				isVisibleSearch={isVisibleSearch}
				setValue={setValue}
						/>**/}

		</form>
	)
}


export default NormalSearch