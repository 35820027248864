const PanelService = ({title, children, backgroundMobile, classReturn, gotToBack, classTitle }) => {

	return (
		<div className="row">
			<div className={`service__backdrop ${backgroundMobile}`}></div>		
			<div className="col-12">
				<div className="container d__header">
					<div className={classReturn} onClick={()=>gotToBack()}>
						<span></span>
					</div>
					<h1 className={classTitle}>{title}</h1>
					{children}
				</div>
				
			</div>
		</div>
	)
}	

export default PanelService