import API from 'services/context/api';
import { 
	CONCESSIONAIRE_DETAIL_BY_ID,
	LOADING_SEARCH,
	SELECTED_CARS_QUOTE,
	LIST_CARS_BY_CONCESSIONAIRE,
	UPDATE_FILTER_BY_MODEL
} from 'services/types/concessionaireType';
import { vehicle_remolque, data_omnia_fitness } from "utils/dataBasicFija"
import { formatResultCars, getListModel } from 'utils/toolCar';
import { formatListStore } from 'utils/StoreFunction/toolStore';
import endPoints from 'services/routes/';
import { TOKEN_PROVIDER, TOKEN_SITE_WEB } from "data/variableKey";

//const type_env = process.env.REACT_APP_ENVIRONMENT

export const requestSuccess = (type, payload) => {
    return { type, payload };
};

export const getDetailById = (slug) => async (dispatch, getState) => {
	try{
		const { categoryProviders } = getState().services;

		dispatch(requestSuccess(LOADING_SEARCH, {status: true, message: 'Espere un momento'}));

		let payload = { detail: {}, isLoading: {status: false, message: ''} };

		let response = await API.getApi({
									endPoint: endPoints.store.providerBySlug(slug), 
									nameAPI: TOKEN_PROVIDER, 
									isToken: true,
									isFormData: false
								});

		let { data, success, message } = response.data;

		let titleStore = "";

		if(success){
	
			let localMap = formatListStore(data, categoryProviders);

			const provider = {
				...localMap,
				id: data.id,
				type: data.type
			};
		
			titleStore = provider.name;

			payload = { detail: provider, isLoading: {status: false, message: ''} };
		}
	
		dispatch(requestSuccess(CONCESSIONAIRE_DETAIL_BY_ID, payload));

		return { status: success, message, title: titleStore };
    }catch(error){
    	dispatch(requestSuccess(LOADING_SEARCH, {status: false, message: ''}));
      return {status: false, message:error};
    }
};

export const getSaveDataStore = (slug) => async (dispatch, getState) => {
	try{
		const { categoryProviders } = getState().services;

		dispatch(requestSuccess(LOADING_SEARCH, {status: true, message: 'Espere un momento'}));

		let payload = { detail: {}, isLoading: {status: false, message: ''} };

		let response = await API.getApi({
									endPoint: endPoints.store.providerBySlug(slug), 
									nameAPI: TOKEN_PROVIDER, 
									isToken: true,
									isFormData: false
								});

		let { data, success, message } = response.data;

		let titleStore = "";

		if(success){

			let localMap = formatListStore(data, categoryProviders);

			const provider = {
				...localMap,
				id: data.id,
				type: data.type
			};

			titleStore = provider.name;

			payload = { detail: provider, isLoading: {status: false, message: ''} };
		}

		dispatch(requestSuccess(CONCESSIONAIRE_DETAIL_BY_ID, payload));

		return { status: success, message, title: titleStore };
    }catch(error){
    	dispatch(requestSuccess(LOADING_SEARCH, {status: false, message: ''}));
      return {status: false, message:error};
    }
};


export const getListCars = () => async (dispatch, getState) => {
	try{		

		dispatch(requestSuccess(LOADING_SEARCH, {status: true, message: 'Espere un momento, estamos buscando los autos disponibles'}));

		const { detailConcessionare } = getState().concessionaire;
		const { listSuppliers } = getState().providers;
		const { categoryProviders } = getState().services;

		let statusResponse = false;
		let messageResponse = '';
		let listModels = [];

		let payload = { listCarsAssign: [], isLoading: {status: false, message: ''} };

		if(Object.keys(detailConcessionare).length>0){
			
			let nameConcessionare = detailConcessionare.search_name_api

			/**if(detailConcessionare.name.toLowerCase().startsWith("olx")){
				//nameConcessionare = "Movicenter"
				nameConcessionare = type_env==="production" ? "Movicenter" : "OLX Movicenter"
			}else if(detailConcessionare.name.toUpperCase()==="SALAZAR ISRAEL"){
				nameConcessionare = "Usados Movicenter SAIS"
			}else if(detailConcessionare.name.toUpperCase()==="PORTILLO"){
				nameConcessionare = "Usados Movicenter Portillo"
			}**/
		
			const body = { concessionaire: nameConcessionare };

			let response = await API.postApi({
								endPoint: endPoints.store.getCarsConcessionaire, 
								data: body,
								nameAPI: TOKEN_SITE_WEB, 
								isToken: true,
								isFormData: false
							});

			statusResponse= response.data.isSuccessFull;
			messageResponse= response.data.message;

			let resultCars = [];
			let arrInitial = [];
		
			if(detailConcessionare.name==="Omnia Fitness"){
		
				resultCars = data_omnia_fitness
			}else if(detailConcessionare.name==="CHILECARAVAN"){
				resultCars = vehicle_remolque
			}else{
		
				if(response.data.data && response.data.data.length>0){
					/**const dataCarFormat = [...response.data.data].map(item => {
					
						if(item?.type==="api_nuevos"){
							let listFeatures = [];// item.listFeatures;

							const sellerIndex = listFeatures?.findIndex(sel => sel.title === "Vende")
							if(sellerIndex!==-1){
								listFeatures[sellerIndex] = { title: "Vende", value: detailConcessionare.name}
							}
	
							return {
								...item,
								listFeatures
							}
						}
						
					})**/
					resultCars = formatResultCars(response.data.data, categoryProviders, '', listSuppliers);
					resultCars.sort(((a, b) => Number(a.price) - Number(b.price)))
					
					let arrvalueUSD= []
					listModels =getListModel(resultCars)
	
					for(let index in resultCars){
						let data = resultCars[index]
						if(data.listPriceView.includes("USD")){
							arrvalueUSD.push(data)
						}else{
							arrInitial.push(data)
						}
					}

					if(arrvalueUSD.length > 0){
						arrInitial = [...arrInitial, ...arrvalueUSD]
					}
				}
			}
			
			payload = { listCarsAssign: arrInitial, isLoading: {status: false, message: ''}, listModels };
		}

		dispatch(requestSuccess(LIST_CARS_BY_CONCESSIONAIRE, payload ));
		
		return { status: statusResponse, message: messageResponse };

    }catch(error){
    	dispatch(requestSuccess(LOADING_SEARCH, {status: false, message: ''}));
      	return { status: false, message: error };
    }
};

export const selectedCarQuote = (id) => (dispatch, getState) => {
	try{
	
		let { listCarsAssign, carSelectedQuote } = getState().concessionaire;
		let payload = [];

		const isFind = carSelectedQuote.find(car => car.id===id);
		
		if(typeof isFind === 'undefined'){
			const item = listCarsAssign.find(car => car.id===id);

			if(item){
				const information = { ...item, titleOption: item.name};
				payload = [...carSelectedQuote, information];
			}
			
		}else{
			payload = carSelectedQuote.filter(car => car.id!==id);
		}

		dispatch(requestSuccess(SELECTED_CARS_QUOTE, payload));
    }catch(error){
      return {status: false, message:error};
    }
};

export const resetSelectedCarQuote = () => (dispatch, getState) => {
	try{
		dispatch(requestSuccess(SELECTED_CARS_QUOTE, []));
    }catch(error){
      return {status: false, message:error};
    }
};

export const getListCarsCaravan = () => async (dispatch, getState) => {
	try{		

		dispatch(requestSuccess(LOADING_SEARCH, {status: true, message: 'Espere un momento, estamos buscando los autos disponibles'}));

		const { detailConcessionare } = getState().concessionaire;
		let statusResponse = false;
		let messageResponse = '';

		let payload = { listCarsAssign: [], isLoading: {status: false, message: ''} };
		dispatch(requestSuccess(LIST_CARS_BY_CONCESSIONAIRE, payload ));
		
		if(Object.keys(detailConcessionare).length>0){
			
			payload = { 
				listCarsAssign: detailConcessionare.name==="CHILECARAVAN" ? vehicle_remolque : data_omnia_fitness, 
				isLoading: {status: false, message: ''}, 
				listModels: [] 
			};
		}
		
		dispatch(requestSuccess(LIST_CARS_BY_CONCESSIONAIRE, payload ));
		
		return { status: statusResponse, message: messageResponse };

    }catch(error){
    	dispatch(requestSuccess(LOADING_SEARCH, {status: false, message: ''}));
      	return { status: false, message: error };
    }
};

export const filterByModel = (value) => (dispatch, getState) => {
	try{
	
		let { listCarsAssignOriginal } = getState().concessionaire;
	
		const listFilterCars = value!=="-" ? 
								listCarsAssignOriginal.filter(car => car.model===value)
								: listCarsAssignOriginal;
	
		const payload = {
			listCarsAssign: listFilterCars
		}
		
		dispatch(requestSuccess(UPDATE_FILTER_BY_MODEL, payload));
    }catch(error){
      return {status: false, message:error};
    }
};

export const updateOrderListCars = (type) => (dispatch, getState) => {
	try{
	
		let { listCarsAssign } = getState().concessionaire;

		if(type==="asc"){
			//Menor a mayor
			listCarsAssign.sort(((a, b) => Number(a.price) - Number(b.price)))
		}else{
			//Mayor a menor
			listCarsAssign.sort(((a, b) => Number(b.price) - Number(a.price)))
		}

	
		const payload = {
			listCarsAssign
		}
		
		dispatch(requestSuccess(UPDATE_FILTER_BY_MODEL, payload));
    }catch(error){
      return {status: false, message:error};
    }
};

export const saveParamsSearchUrl = (params) => (dispatch, getState) => {
	if(params!==""){
		const localParams = localStorage.getItem("params");
		if(localParams!==params){
			localStorage.setItem("params", params);
		}
	}
};
