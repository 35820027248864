import { combineReducers } from 'redux'
import blog from 'services/reducers/blogReducer'
import config from 'services/reducers/configReducer'
import search from 'services/reducers/searchReducer'

import cars from 'services/reducers/carReducer'
import marcas from 'services/reducers/marcaReducer' 
import services from 'services/reducers/serviceReducer'
import concessionaire from 'services/reducers/concessionaireReducer'
import providers from 'services/reducers/providerReducer'
import quotes_car from "services/reducers/quoteReducer"
import advertisings from 'services/reducers/advertisingReducer'
import sureSoap from "services/reducers/soapReducer"
import sureOlx from "services/reducers/sureOlxReducer"
import motorcycles from "services/reducers/motorcycleReducer"
import concourses from "services/reducers/concourseReducer"
import coupons from "services/reducers/couponReducer"
import electricals from "services/reducers/electricalReducer"
import notifications from "services/reducers/notificationReducer"
import offers from "services/reducers/offerReducer"
import secure from "services/reducers/secureReducer"

export default combineReducers({
	blog,
	config,
	search,
	cars,
	marcas,
	services,
	concessionaire,
	providers,
	advertisings,
	quotes_car,
	sureSoap,
	sureOlx,
	motorcycles,
	concourses,
	coupons,
	electricals,
	notifications,
	offers,
	secure
})