const api_key_google_recaptcha = process.env.REACT_APP_RECAPTCHA;

export const useGoogleRecaptcha = () => {

	const handleLoaded = (setFormToken, formElement) => {
		window.grecaptcha.ready(() => {
			window.grecaptcha
			.execute(api_key_google_recaptcha, { action: "formLeads" })
			.then(token => {
				const { btn, chk } = formElement;
				setFormToken(formToken => token);
				btn.element.disabled = btn.value;
                chk.element.disabled = chk.value;
			})
		})
	}

	const getToken = () => {
		return window.grecaptcha
		.execute(api_key_google_recaptcha, { action: "formLeads" })
	}

	return {
		handleLoaded,
		getToken
	}
}