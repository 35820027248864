import ModalAdd from 'components/Template/Layout/ModalAdd'

const ModalValidation = ({ isOpen,  closeModal }) => {

	return (
		<ModalAdd isOpen={isOpen} closeModal={closeModal}>
			<div className="modal__header text-center pl-5 pr-5">
				<p className="subtitle--modal">Lo sentimos, solo puedes comparar 3 autos </p>
			</div>
			<div className="modal__footer">
				<div className="w-100 mt-5">
		    		<button type="button" className="btn btn-primary btn-lg w-100" onClick={closeModal}>OK</button>
		    	</div>
			</div>
		</ModalAdd>
	)
}

export default ModalValidation