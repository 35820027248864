import ItemMotorcycleLoading from 'components/Customs/Skeleton/ItemMotorcycleLoading'
import styles from 'styles/Motorcycle.module.css'

const ListVehicleLoading = () => {

	return (
		<section className={styles["moto-findout__container"]}>
			{
				Array.from([1, 2, 3, 4]).map((item, key) => (
					<ItemMotorcycleLoading key={key} />
				))
			}
		</section>
	)
}

export default ListVehicleLoading