import Slider from 'components/Customs/Carousel/Slider'
import PanelInfo from 'components/Customs/Panels/PanelInfo'
import PanelInformation from 'components/Customs/CardVehicle/PanelInformation'
import ShareNetworks from 'components/Customs/CardVehicle/ShareNetworks'

import { SwiperSlide } from 'swiper/react'


const PanelDetailCar = ({ type, price, name, infoBrand, isCompare, id, photos, addCompareCar, viewCar,
	mileage, transmission, fuel, isMyRouter, typeKey, handleQuoteCar, goToConcesionaire,
	listPrice, priceLockDown,  listPriceView, idKey, status_id,
	photoMain, stock
}) => {

	return (
		<PanelInfo isClassCard="border-0 mt-5" isClassBody="padding-body">
			<section className="result-details">
				<ShareNetworks 
						id={id}
						isMyRouter={isMyRouter}
						idKey={idKey} 
						title={name}
						photo={photoMain}
						type="autos"
					/>
				<section className="container-info-row">
					<div className="result-details__slide" >
						<div className="slide-car" onClick={()=>viewCar(idKey)}>
							<Slider 
								options= {{
										className: "slider-car--height",
										navigation: false,
										slidesPerView: 1,
										slidesPerColumn: 1,
										spaceBetween: 1,
										pagination: false,
									}}
							>
								{
									photos.map((car, key) => (
												<SwiperSlide key={key}>
													<div className="result-details__image">
														<img src={car} alt={name}  />
													</div>
												</SwiperSlide>
											))
								}
							</Slider>
						</div>
					</div>
					<div className="info-car__view">
						
						<div className="result-info__top">
							<PanelInformation 
								type={type}
								name={name}
								price={price}
								infoBrand={infoBrand}
								isVisibleShare={false}
								mileage={mileage} 
								transmission={transmission} 
								fuel={fuel}
								classMain="info-car__description infor-car__result"
								viewCar={viewCar}
								id={id}
								typeKey={typeKey}
								goToConcesionaire={goToConcesionaire}
								listPrice={listPrice}
								priceLockDown={priceLockDown}
								listPriceView={listPriceView}
								idKey={idKey}
								status_id={status_id}
								stock={stock}
							/>
						</div>
						<div className="result-footer w-100">
							<div className="w-100">
								<button 
									type="button" 
									className="btn btn-primary btn-lg w-100"
									onClick={()=>handleQuoteCar(id, "")}
								>Cotizar</button>
							</div>
							<div className="result-footer__comparar">
								<div className="search-form">
									<input 
										className="search-form-check search-form-check--orange" 
										type="checkbox" 
										defaultChecked={isCompare}
										id={id}
										name="isCompare[]"
										onChange={(event)=>addCompareCar(event, id)}
									/>
									<label className="marca-form-label" htmlFor={id}>
									Comparar
									</label>
								</div>
							</div>
						</div>
					</div>
				</section>
				
			</section>
		</PanelInfo>
	)
}

export default PanelDetailCar