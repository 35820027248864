const CardSoap = ({ item, sendLinkSoap }) => {


    return (
        <div className="card card-item">
            <div className="card-body soap-item">
                {/**<div className="soap-item__recommend">
                    <p className="item__recommend">Recomendado</p>
                </div>**/}
                <div className="soap-item__images">
                    <img src={item?.company?.logo} alt={item?.company?.title} className={item?.company?.classLogo} />
                </div>
                <div className="soap-item__text">
                    <p className="soap-item__title">{item.title}</p>
                    { item.subtitle!=="" && <p className="soap-item__subtitle">{item.subtitle}</p>}
                </div>
                <p className="soap-item__price">Precio {item.price}</p>
                <p className="soap-item__validity"><b>Vigencia:</b> {item.validity}</p>
                <button
                    onClick={(event) => sendLinkSoap(event, item)} 
                    className="btn btn-primary soap-item__btn btn-block mt-3"
                >Comprar</button>
            </div>
        </div>
    )
}

export default CardSoap