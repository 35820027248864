import { useLocation } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import { savePageCurrent } from "services/actions/configActions"

export const useSearchPage = () => {
    
    const dispatch = useDispatch()
    const location = useLocation()
    const { pageLast  } = useSelector((state) => state.config)

    const saveLastPage = () => {
        dispatch(savePageCurrent(location?.pathname))
    }


    return {
        saveLastPage,
        pageLast
    }
}