import CaracteristTransportation from 'components/Customs/Information/CaracteristTransportation'


const PanelTypeTransportation = ({ type, title, infoBrand, salePrice, financingPrice, 
	isVisibleFinancingPrice, caracteristPanel }) => {


	
	return (
		<section className="info-car">
			<div>  
				<div className="info-car__description">
						<div className="info-car__description--info">
						<div className="info-car__description--type">{type}</div>
						<h2 className="info-car__description--modelo">{title}</h2>
					</div>
					<div className="info-car__marca">
						<img src={infoBrand?.icon} alt={infoBrand?.name} />
					</div>
				</div>
				<div className="price-car mt-1">
					<p className="info-car__description--price">
						<span>{financingPrice}</span>
						{isVisibleFinancingPrice && <span className="price-financiamiento">Precio con financiamiento</span>}
					</p>
					{isVisibleFinancingPrice && 
						<p className="info-car__description--price">
							<span>{salePrice}</span>
							<span className="price-financiamiento">Precio de lista desde:</span>
						</p>
					}
				</div>
			</div>
			<div className="info-car-features">
			{
				caracteristPanel?.length>0 &&
				<>
					<CaracteristTransportation
						classIcon={caracteristPanel[0].classIcon}
						title={caracteristPanel[0].title}
						value={caracteristPanel[0].value}
					 />
					 <CaracteristTransportation
						classIcon={caracteristPanel[1].classIcon}
						title={caracteristPanel[1].title}
						value={caracteristPanel[1].value}
					 />
					 <CaracteristTransportation
						classIcon={caracteristPanel[2].classIcon}
						title={caracteristPanel[2].title}
						value={caracteristPanel[2].value}
					 />
				</>
			}
			</div>
			<div className="disclaime-price">
				<p className="disclaime-price__text">
					* Precios, equipamiento, características e información sujetos a cambio sin previo aviso. Las imágenes son meramente referenciales y pueden no coincidir con los productos exhibidos y ofrecidos en concesionarios, ni su idoneidad para los fines que se pretende satisfacer. Contacte un vendedor para verificar los datos publicados.
				</p>
			</div>
		</section>
	)
}

export default PanelTypeTransportation