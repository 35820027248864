import { Modal } from "react-bootstrap"
//import FormOptions from "./FormOptions"
//import FormMainWithPatente from "./FormMainWithPatente"
import FormMainNotPatente from "components/QuoteCar/Sire/FormMainNotPatente"

import closed_menu from 'assets/images/icons/delete-result.svg'

import { useSire } from "hooks/Quotes/Sire/useSire"

const ModalSire = ({ visible, closeModal }) => {

    const { nextFormStep, nextFormNamePrev, backHome } = useSire(true)

    return (
        <Modal
            show={visible}
            onHide={closeModal}
            backdrop="static"
            keyboard={false}
            dialogClassName={nextFormStep==="form_end" ? "modal-90w" : "" }
        >
        <Modal.Body className="modal-quotes">
            {
                nextFormStep!=="form_end" && (
                    <>
                        <div className="modal__closed" onClick={closeModal}>
                            <img src={closed_menu} alt="Cerrar" />
                        </div>
                        <p className="quote-sure__title">Cotiza tu seguro de auto</p>
                        <p className="quote-sure__subtitle">Encuentra la mejor oferta en seguro para ti.</p>
                    </>
                )
            }
            
            {/**{nameFormPatent==="" && <FormOptions visibleFormPatent={visibleFormPatent} /> }
            {nameFormPatent==="withPatent" && 
                <FormMainWithPatente 
                    nextFormStep={nextFormStep}
                    nextFormNamePrev={nextFormNamePrev} 
                /> 
            }
            {nameFormPatent==="noPatent" && **/}
                <FormMainNotPatente 
                    nextFormStep={nextFormStep} 
                    nextFormNamePrev={nextFormNamePrev} 
                    backHome={backHome}
                    closeModal={closeModal}
                /> 
            
            
        </Modal.Body>
      </Modal>
    )
}

export default ModalSire