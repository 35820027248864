import { formatMonto } from 'utils/tools'

const FormResultSend = ({ closeModal, resultQuote, visibleTextZeroPrice }) => {

    return (
        <section>
            <section className="quote-result__title">
                <h4 className="quote-result__title-main">{`${resultQuote?.data?.brand} ${resultQuote?.data?.model}`}</h4>
                <p className="quote-result__subtitle">{`${resultQuote?.data?.year} - ${resultQuote?.data?.version}`}</p>
                <p className="quote-result__small">{resultQuote?.data?.mileage} km</p>
            </section>
            {
                (visibleTextZeroPrice===true) && (
                    <section className="p-2">
                        <p className="quote-result__empty font-30 mb-5">¡Hola!</p>

                        <p className="quote-result__empty text-zero mb-5">Sentimos no poder entregarte una oferta en línea 😥 , ¡pero no te preocupes ☺!</p>

                        <p className="quote-result__empty text-zero mb-5 pb-4">De todas manera una de nuestra ejecutiva se pondrá en contacto contigo. </p>
                        <hr className="quote-result__line" />
                        <p className="quote-result__empty font-20 mb-5">🚗 🚙 🚗 🚙</p>
                    </section>
                )
            }
            {
                (visibleTextZeroPrice===false) && (
                    <>
                        <section className="quote-price">
                            <p className="quote-price__title">Precio estimado de compra</p>
                            <h2 className="quote-price__subtitle">{`$${formatMonto(resultQuote?.value[0])} - $${formatMonto(resultQuote?.value[1])}`}</h2>
                        </section>
                        <p className="quote-result__info">* Los precios pueden variar tras una inspección física</p>
                        <p className="quote-result__link" onClick={()=>closeModal(false)}>Cotizar otro auto</p>
                        <p className="quote-result__completed">Un ejecutivo especialista de OLX se contactará contigo para coordinar la inspección.</p>
                    </>
                )
            }
            
            
        </section>
    )
}

export default FormResultSend