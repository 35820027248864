import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';
import { sendFormNotification } from 'services/actions/notificationAction';
import { resetSelectedCarMyRoute } from 'services/actions/carActions'
import { useHistory, useLocation } from 'react-router-dom'
import { useGoogleRecaptcha } from 'hooks/Contact/useGoogleRecaptcha';
import { formatDate } from "utils/tools"

export const useFormContactRoute = () => {

	const dispatch = useDispatch()	
	const history = useHistory()
	const location = useLocation()
	const { getToken } = useGoogleRecaptcha();

	const { register, reset, handleSubmit, errors } = useForm();
	const [ disabledBtn, setDisabledBtn ] = useState();
	const [ isOpen, setIsOpen ] = useState(false);
	const [ isLoading, setIsLoading ] = useState(false);
	const [ cotizar, setCotizar ] = useState(true);
	const newDateToday = formatDate(new Date())
	

	const [ message, setMessage ] = useState({
		error: false, 
		title: '¡Ups!', 
		subtitle: 'Ha ocurrido error en el envío del formulario. Por favor intenta nuevamente', 
		btnTitle: 'Reintentar',
		success: false
	})


	const closeModal = () => {
		setIsOpen(false)
		if(message.btnTitle==='Ok'){
			history.goBack()
		}
	}

	const handleActive = (event) => setDisabledBtn(!event.target.checked);


	const onSubmit =  async (data, infoSendItem) => {
		try{
			setIsLoading(true)
			setMessage({error: false, title: '', subtitle: '', btnTitle: '', success: false})
			setDisabledBtn(true)

			const tokenCaptcha = await getToken();

			const validItemSelected = infoSendItem.filter(item => item.isSend===true)
			const itemsFormSend = validItemSelected.length > 0 ? validItemSelected : infoSendItem

			let dataForm = {
				items: itemsFormSend.map(item => {
					return {
						...item,
						myRoute: true
					}
				}),
				name: data.name,
				telephone: data.telephone!=="" ? "+569"+data.telephone : "",
				email: data.email,
				myRoute: true,
				token: tokenCaptcha
			}

			if(data.surveillanceDate!==""){
				dataForm = { ...dataForm, surveillanceDate: data.surveillanceDate }
			}

			const response = await dispatch(sendFormNotification(dataForm, location.pathname))
			
			if(response.status){
				reset({
					name: '',
					telephone: '',
					email: '',
					surveillanceDate: ''
				})

				let listCarSelected = []
				if(infoSendItem.length!==itemsFormSend.length){

					listCarSelected = infoSendItem.filter(item => item.isSend===false)
				}

				dispatch(resetSelectedCarMyRoute(listCarSelected))
				
				setIsLoading(false)
				setDisabledBtn(false)
				history.push('/success')

			}else{
				setMessage({
					error: false, 
					title: '¡Ups!', 
					subtitle: 'Ha ocurrido error en el envío del formulario. Por favor intenta nuevamente',
					btnTitle: 'Reintentar',
					success: false
				})
				setIsLoading(false)
				setIsOpen(true)
				setDisabledBtn(false)
			}	
		}catch(error){
			setMessage({
				error: false, 
				title: '¡Ups!', 
				subtitle: 'Ha ocurrido un error en el envío del formulario. Por favor recargue la página e intenta nuevamente',
				btnTitle: 'Reintentar',
				success: false
			})
			setIsLoading(false)
			setIsOpen(true)
			setDisabledBtn(false)
		}
	}

	
	return {
		handleActive,
		disabledBtn,
		register, 
		handleSubmit, 
		onSubmit,
		message,
		isOpen, 
		closeModal,
		isLoading,
		errors,
		cotizar, 
		setCotizar,
		newDateToday
	}
}