import deleteI from 'assets/images/icons/delete.svg'

const DeleteItem = ({ item, deleteOption }) => {
	
	return (
		<div className="option-item-insterest">
			<p className="option-item-text">{item.title}</p>
			<div className="option-item-search--delete mr-3">
				<img src={deleteI} alt="Eliminar item" onClick={()=>deleteOption(item)} />
			</div>
		</div>
	)
}


export default DeleteItem