

const DetailCar = ({ 
	name, priceLockDown, mileage, type, listFeatures, transmission, 
	bodyWork, fuel, photos, traction, seatAmount, country
}) => {
	return (
		<div className="compare-result__item">
			<div className="compare__imagen">
				<img src={photos[0]} alt="Car 1" />
			</div>
			<p className="compare__title">{name}</p>
			<div className="compare__info active">
				<p>{priceLockDown}</p>
			</div>
			<div className="compare__info">
				<p>{type}</p>
			</div>
			<div className="compare__info active">
				<p>{bodyWork}</p>
			</div>
			<div className="compare__info">
				<p>{mileage}</p>
			</div>
			<div className="compare__info active">
				<p>{listFeatures[2].value}</p>
			</div>
			<div className="compare__info">
				<p>{transmission}</p>
			</div>
			<div className="compare__info active">
				<p>{fuel}</p>
			</div>
			<div className="compare__info">
				<p>{traction}</p>
			</div>
			<div className="compare__info active">
				<p>{seatAmount}</p>
			</div>
			<div className="compare__info">
				<p>No disponible</p>
			</div>
			<div className="compare__info active">
				<p>{country}</p>
			</div>
		</div>
	)
}

export default DetailCar