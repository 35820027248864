import { useState } from 'react'
import { Accordion } from 'react-bootstrap'
import CardAccordion from 'components/Customs/Accordion/CardAccordion'
import DetailFeature from 'components/CarFile/Information/DetailFeature'

const Features = ({ listFeatures, listSpecific }) => {

	const [activeKey, setActiveKey] = useState('0')

	const information = [
		{
			id: 1,
			title: "Detalle del vehículo",
			listado: listFeatures,
			isVisibleSubtitle: true
		},
		{
			id: 2,
			title: "Características específicas",
			listado: listSpecific,
			isVisibleSubtitle: false
		}
	]

	return (
		<div className="accordion-car__row">
			<div className="accordion-car">
				<Accordion activeKey={activeKey} onSelect={(event) => setActiveKey(event)}>
					{
						information.map((info, key) => (
							 <CardAccordion
							  		title={info.title}
							  		key={info.id}
							  		index={key}
							  		activeKey={activeKey}
							 	>
							 	<div className="details-car">
						      		{
						      			info.listado.map((item, key) => 
						      				<DetailFeature 
						      					key={key} 
						      					title={item.title} 
						      					value={item.value} 
						      					isVisibleSubtitle={info.isVisibleSubtitle} 
						      			/>)
						      		}
						      		{ info.listado.length===0 && 
						      			<p>No hay información disponible</p>
						      		}
						      	</div>	
							</CardAccordion>
						))
					}
				</Accordion>
			</div>
		</div>
	)
}

export default Features
