import { useState } from "react"
import { useForm } from 'react-hook-form'
import { saveFormDataContact, nextPrevForm, resetFormData, saveFormDataGlobal  } from "services/actions/quoteAction"
import { useDispatch, useSelector } from 'react-redux'

export const useDataContact = (isQr) => {

	const dispatch = useDispatch()
	const { dataFormQuote, listInfoCompara } = useSelector((state) => state.quotes_car)
	const [ proccessForm, setProcessForm ] = useState({ loading: false, message: "", status: false})

	const { register, control, handleSubmit, errors, setValue, setError } = useForm({defaultValues: {
        telephone: dataFormQuote.telephone,
        email: dataFormQuote.email,
        question: dataFormQuote.question
    }})

	const onSubmit = async (data) => {
		try {
			
			if(proccessForm.status===true){
				setProcessForm({ loading: false, message: `Debe aceptar los términos y condiciones`, status: true})
            	return false
			}

			setProcessForm({ loading: true, message: "", status: true })

			let regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/; //eslint-disable-line
			const isValidEmail = regex.test(data.email)
            if(!isValidEmail){
                setValue("email", "")
				setProcessForm({ loading: false, message: `El e-mail ${data.email} es inválido`, status: false})
            	return false
            }

            if(data.telephone.length<9){
				setError("telephone", { type: "manual", message: `El número ingresado es incorrecto`})
				setProcessForm({ loading: false, message: "", status: false })
            	return false
            }

			regex=/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g; //eslint-disable-line
			const isValidTelephone = regex.test("+56"+data.telephone)
            if(!isValidTelephone){
                setValue("telephone", "")
				setProcessForm({ loading: false, message: `El teléfono +56 ${data.telephone} es inválido`, status: false})
            	return false
            }

			const response = await dispatch(saveFormDataContact({
				...data,
				email: data.email.toLowerCase(),
				telephone: "+56"+data.telephone,
				isQr
			}))
			setProcessForm({ loading: false, message: response.message, status: false})

			if(response.status){
				//Visualizar el siguiente formulario "form_initial", "form_owner", "form_contact"
				dispatch(nextPrevForm(3))
				//Resetar el formulario
				dispatch(resetFormData())
			}
			window.scrollTo(0, 0)
		}catch(error){
			setProcessForm({ loading: false, message: "Hubo un error al procesar la cotización", status:false })
		}
	}

	const handleChangePhoneNumber = (event) => {

		if(event.target.value!==""){
			if(event.target.value.length===9){
				let regex=/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g; //eslint-disable-line
				const isValidTelephone = regex.test("+56"+event.target.value)

				if(!isValidTelephone){
					setValue("telephone", "")
					setError("telephone", { type: "manual", message: `El teléfono es inválido`})
				}else {
					setError("telephone", { type: "manual", message: ""})
				}
			}else{
				setError("telephone", { type: "manual", message: `El número ingresado es incorrecto`})
			}
		}		
	}

	const handleChangeEmail = (event) => {
		if(event.target.value!==""){
			let regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/; //eslint-disable-line
			const isValidEmail = regex.test(event.target.value)

			if(!isValidEmail){
				setValue("email", "")
				setError("email", { type: "manual", message: `El e-mail es inválido`})
			}else {
				setError("email", { type: "manual", message: ""})
			}
		}	
	}

	const onChangeInput = (event, type, name) => {
		const { value } = event.target
		dispatch(saveFormDataGlobal({ [name]: value!=="" ? value : ""}))
	}

	const handleActive = (event) => {
		setProcessForm({ loading: false, message: "", status: !event.target.checked})
	}

	return {
		register,
		onSubmit,
		handleSubmit,
        control,
		listInfoCompara,
		errors,
		proccessForm,
		handleChangePhoneNumber,
		handleChangeEmail,
		onChangeInput,
		handleActive
	}
}