const GroupModelByBrand = ({ listModels, handleClickUpdateResult, register }) => {

	return (
		<div className="filter__item">
			<p className="form-filter__title">Modelo</p>
			<div className="form-filter__container">
				{
					listModels?.length>0 &&
					listModels?.map((brand, key) => (
						<div key={key}>
							<p>{brand.title}</p>
							{
								brand?.modelos?.length>0 &&
								brand?.modelos?.map((model, index) => {
										return (
											<div className="form-group form-filter-checkbox" key={index}>
												<input
													ref={register}
													name={`${model.name}.${index}.name`}
													defaultValue={model?.value}
													type="hidden"
												/>
												<input
													ref={register}
													name={`${model.name}.${index}.slug`}
													defaultValue={model?.slug}
													type="hidden"
												/>
												<input 
													className="form__filter-check"
													type="checkbox" 
													name={`${model.name}.${index}.checked`} 
													id={model?.value} 
													defaultChecked={model?.checked}
													ref={register}
													onClick={(event)=>handleClickUpdateResult(event, model)}
												/>
												<label className="form__filter-label" htmlFor={model?.value}>
													{model?.title}
												</label>
											</div>	
										)
										
									}
									
								)
							}		
						</div>
					))
				}	
			</div>						
		</div>
	)
}

export default GroupModelByBrand