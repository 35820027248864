import API from 'services/context/api'
import { 
	LIST_ADVERTISING_INITIAL,
	 GET_BANNER_ID, 
	 GET_CONFIG_TIME_SLIDER,
	 LOADING_SLIDER
} from 'services/types/advertisingType'
import endPoints from 'services/routes/';
import { TOKEN_MAINTANER, TOKEN_SITE_WEB } from "data/variableKey";

export const requestSuccess = (type, payload) => {
    return { type, payload }
}

export const getListAdvertisingsInitial = () => async (dispatch, getState) => {
	try{
		dispatch(requestSuccess(LOADING_SLIDER, true))

		let advertisingHomeMain = [], advertisingHomeNew= [], advertisingHomeUsed= [], advertisingTire= []
		let advertisingServices=[], advertisingResult=[], advertisingCars=[]
		let listAdvertising=[], advertisingMoto=[], advertisingPageMoto=[], advertisingElectrical=[],
		advertisingSliderPrincipal=[], advertisingSliderNew=[], advertisingSliderUsed=[]

		let response = await API.getApi({
									endPoint: endPoints.advertising.getList, 
									nameAPI: TOKEN_MAINTANER, 
									isToken: true,
									isFormData: false
								})
		
		const { data, isSuccessFull } = response.data

		if(data.length>0){
			data.sort(((a, b) => a.order - b.order))
			listAdvertising = data.map(item => {
				let proxDate = false
				let urlDesktop = item.ic_desktop_main
				let urlMobile= item.ic_mobile_main
				if(item.start_date!=="" && item.end_date!==""){
					const dateCurrent = new Date()
					if(new Date(item.end_date).getTime()>=dateCurrent.getTime() && new Date(item.start_date).getTime()<=dateCurrent.getTime()){
						proxDate = true
						urlDesktop = item.ic_desktop_scheduled
						urlMobile = item.ic_mobile_scheduled
					}
				}
				return {
					...item,
					proxDate,
					ic_desktop_main: urlDesktop,
					ic_mobile_main: urlMobile 
				}
			})

			advertisingHomeMain = listAdvertising
									.filter(banner => banner.type==="home-main")
									
			
			advertisingHomeNew = listAdvertising
									.filter(banner => banner.type==="home-new")
									
			
			advertisingHomeUsed = listAdvertising
									.filter(banner => banner.type==="home-used")
									
			advertisingTire= listAdvertising
								.filter(banner => banner.type === "tire")
								
			advertisingServices= listAdvertising
									.filter(banner => banner.type==="servicios")
									
			advertisingResult = listAdvertising
									.filter(banner => banner.type==="result-search")
									
			advertisingCars = listAdvertising
									.filter(banner => banner.type==="ficha-auto")

			advertisingMoto = listAdvertising
									.filter(banner => banner.type==="motos")

			advertisingPageMoto = listAdvertising
									.filter(banner => banner.type==="ficha-moto")

			advertisingElectrical = listAdvertising
									.filter(banner => banner.type==="electrical")
			
			advertisingSliderPrincipal = listAdvertising
									.filter(banner => banner.type==="slider-principal")
									.filter(banner => !banner.is_banner_hidden)
									
			advertisingSliderNew = listAdvertising
									.filter(banner => banner.type==="slider-nuevos")
			
			advertisingSliderUsed = listAdvertising
									.filter(banner => banner.type==="slider-usados")
											
		}						

		let payload = { 
			listAdvertising,
			advertisingHomeMain,
			advertisingHomeNew, 
			advertisingHomeUsed, 
			advertisingTire, 
			advertisingServices,
			advertisingResult,
			advertisingCars,
			advertisingMoto,
			advertisingPageMoto,
			advertisingTypeMoto: advertisingPageMoto,
			advertisingElectrical,
			advertisingSliderPrincipal,
			advertisingSliderNew,
			advertisingSliderUsed,
			loadingSlider: false
		}

		dispatch(requestSuccess(LIST_ADVERTISING_INITIAL, payload))

		return { status: isSuccessFull }
    }catch(error){
      return {status: false, message:error}
    }
}

export const saveClicBanner = (banner) => async (dispatch, getState) => {
	try{

		let status = false
		let message = ""
		const body = { banner_id: typeof banner?.id!=="undefined" ? banner?.id : null }

		if(typeof banner?.id!=="undefined"){
			let response = await API.postApi({
								endPoint: endPoints.advertising.saveClic, 
								data: body,
								nameAPI: TOKEN_SITE_WEB, 
								isToken: true,
								isFormData: false
							})
			status = response.data.status
			message = response.data.message
		}
		
		const payload = body
		
		dispatch(requestSuccess(GET_BANNER_ID, payload))

		return { status: status, message }

    }catch(error){
      	return { status: false, message: error }
    }
}

export const getListPages = () => async (dispatch, getState) => {
	try{

		let status = false
		let message = ""

		let response = await API.getApi({
										endPoint: endPoints.advertising.getPages, 
										nameAPI: TOKEN_MAINTANER, 
										isToken: true,
										isFormData: false
									})

		const { data, isSuccessFull } = response.data

		if(isSuccessFull){
			let durationPrincipal = data.find(item => item.page==="slider-principal")
			let durationUsados = data.find(item => item.page==="slider-usados")
			let durationNuevos = data.find(item => item.page==="slider-nuevos")

			const payload = {
				pageDurationTimerPrincipal: durationPrincipal?.durationtime,
				pageDurationTimerNew: durationUsados?.durationtime,
				pageDurationTimerUsed: durationNuevos?.durationtime,
				listPages: data
			}
		
			dispatch(requestSuccess(GET_CONFIG_TIME_SLIDER, payload))
		}
		
		

		return { status: status, message }

    }catch(error){
      	return { status: false, message: error }
    }
}