import ItemService from 'components/Services/ResultSearchTire/ItemService'

import { selectedItemQuote } from 'services/actions/serviceActions'
import { useDispatch } from 'react-redux'

const ListItemService = ({ list }) =>{

	const dispatch = useDispatch()

	const selectedQuote = (item) => {
		dispatch(selectedItemQuote(item))
	}

	return (
		<div className="container">
			<div className="row">
				<div className="col-12 service-result">
					{
						list.length>0 ?
						<div className="service-result__title">
							<p className="service-result__h1">Búsqueda</p>
							<p className="service-result__small">{list.length} Resultados</p>
						</div>
						: <p className="service-result__h1">No hay resultados</p>
					}
					<div className="service-result__container">
						{
							list.map((item, key) => <ItemService key={key} item={item} selectedQuote={selectedQuote} />)
						}
					</div>
				</div>
			</div>
		</div>
	)
}

export default ListItemService