import InfiniteScroll from "react-infinite-scroll-component"
import PanelCarsLoading from "components/Result/Skeleton/PanelCarsLoading"
import BannerEvent from "components/Customs/Banner/Horizontal/BannerEvent"
import ModalValidation from 'components/Result/Modal/ModalValidation'
import PanelDetailCar from "components/Result/Cars/PanelDetailCar"
import MenuSortDesktop from 'components/Result/MenuSortDesktop'
import AlertSearchingCar from 'components/Result/Scheduled/AlertSearchingCar'

import MediaQuery from 'react-responsive'
import { useModal } from 'hooks/useModal'

const PanelViewRight = ({ listResultCar, fetchMoreData, hasMoreCar, handleQuoteCar, actionAdvertising, isLoadingResult,
    getFileCar, goToConcesionaire, addCompareCar, titleHeader, menuSort, setMenuSort, countResult }) => {
    
    const { isOpen, closeModal } = useModal(false)

    return (
        <div className="col-xs-12 col-md-9">
            <AlertSearchingCar />
            <div className="search-result">
                <div>
                    <h1 className="search-result__title">{titleHeader}</h1>
                    <p className="search-result__small">{countResult} Resultados</p>
                </div>
                <MediaQuery minWidth={768}>
                    <div className="options-search__container">
                        <p 
                            className="options-search__type options-search--order d-none d-md-block"
                            onClick={()=>setMenuSort(!menuSort)} 
                        >Ordenar</p>
                        {menuSort && <MenuSortDesktop setMenuSort={setMenuSort} />}
                    </div>
                </MediaQuery>
            </div>
            <InfiniteScroll
                dataLength={listResultCar.length}
                next={fetchMoreData}
                hasMore={hasMoreCar}
                loader={hasMoreCar && (
                    <div className="card-cars-flex">
                        {Array.from([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]).map(
                            item => <PanelCarsLoading key={item} />
                        )}
                    </div>
                    )
                }
            >
                {
                    !isLoadingResult && listResultCar?.length>0 &&
                    listResultCar.map((item, key) => {
                        if(item.isBanner===false){
                            return (<PanelDetailCar 
                                        key={key} 
                                        {...item} 
                                        idKey={item?.key}
                                        listado={[]}
                                        addCompareCar={addCompareCar}
                                        viewCar={getFileCar}
                                        handleQuoteCar={handleQuoteCar}
                                        goToConcesionaire={goToConcesionaire}
                                    />)
                        }else{
                            return (
                                <BannerEvent 
                                    key={key}
                                    imagen={item.banners}
                                    functionModal={actionAdvertising}
                                    title="Banner" 
                                    classContainer="mb-0" 
                                    classPicture="mb-0 car-banner__detail banner-result"
                                    classMain="banner-num__result"
                                />
                            )
                        }
                    })
                }
                {
                    isLoadingResult && (
                        Array.from([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]).map(
                            item => <PanelCarsLoading key={item} />
                        )
                    )
                }
            </InfiniteScroll> 
            {
                (!isLoadingResult && listResultCar.length===0) && 
                    <div className="error_not_search mt-5">
                        <img src="https://api-news.movicenter.cl/misc/no-encontrado.png" alt="No hay búsqueda" />
                    </div>
            }
            { isOpen && <ModalValidation isOpen={isOpen} closeModal={closeModal}  />}
        </div>
    )
}

export default PanelViewRight