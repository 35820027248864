export const calcularEdad = (birthDate) => {
	const dateCurrent = new Date()
	const yearCurrent = parseInt(dateCurrent.getFullYear())
	const monthCurrent = parseInt(dateCurrent.getMonth()+1)
	const dayCurrent = parseInt(dateCurrent.getDate())

	const yearBirth = parseInt(String(birthDate).substring(0,4))
	const monthBirth = parseInt(String(birthDate).substring(5,7))
	const dayBirth = parseInt(String(birthDate).substring(8,10))

	let edad = yearCurrent - yearBirth

	if(monthCurrent < monthBirth){
		edad--;
	}else if(monthCurrent === monthBirth){
		if(dayCurrent < dayBirth){
			edad--;
		}
	}

	return edad;
}

export const generateNameImage = () => {
	let dateCurrent = new Date();

	const yearCurrent = parseInt(dateCurrent.getFullYear())
	const monthCurrent = addZeroDate(parseInt(dateCurrent.getMonth()+1))
	const dayCurrent = addZeroDate(parseInt(dateCurrent.getDate()))

	const hourCurrent = addZeroDate(parseInt(dateCurrent.getHours()))
	const minuteCurrent = addZeroDate(parseInt(dateCurrent.getMinutes()))
	const secondCurrent = addZeroDate(parseInt(dateCurrent.getSeconds()))

	return `${yearCurrent}${monthCurrent}${dayCurrent}${hourCurrent}${minuteCurrent}${secondCurrent}`
	
}

const addZeroDate = (date) => {
	return date >= 10 ? date : `0${date}`
}

export const getLabelMonth = (nroMonth) => {
	const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
	return months[nroMonth] ?? ""
}