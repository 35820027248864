import { useState, useRef } from "react";

const url = process.env.REACT_APP_URL

export const useShare = () => {

	const refPopoverDsk = useRef(null);
	const [ showPopoverDsk, setShowPopoverDsk ] = useState(false)
	const [target, setTarget] = useState(null);
	const [ detailShare, setDetailShare ] = useState({})

	const handleSharing = async (event, idKey, title, photo, type) => {
		event.preventDefault()

		if(!showPopoverDsk){
			const description = document.querySelector("meta[name=description]")
		
			const infoShare = {
				title,
		        text: typeof description!=="undefined" ? description?.content : '',
		        url: `${url}${type}/${idKey}`
			}
			setDetailShare(infoShare)
			document.querySelector('meta[property="og:title"]').setAttribute("content", title)
			document.querySelector('meta[property="og:image"]').setAttribute("content", title)
		
		    if (navigator.share) {
		      try {
		        await navigator
		          .share(infoShare)
		          .then(() =>
		            console.log("Fue compartido con éxito")
		          );
		      } catch (error) {
		        console.log(`Lo sentimos pero no se puede compartir`);
		      }
		    } else {
		      // fallback code
		      	setShowPopoverDsk(true)
		    	setTarget(event.target);
		    }
		}
		setShowPopoverDsk(!showPopoverDsk)
	 };


	return {
		handleSharing,
		showPopoverDsk,
		refPopoverDsk,
		target,
		detailShare
	}
}