import { 
    LIST_OFFER,
    LOADING_OFFER
} from 'services/types/offerType'

const INITIAL_STATE = {
	listOfferAll: [],
	loadingOffer: "",
	offerMain: null
}

const notificationReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case LOADING_OFFER:
			return {
				...state,
				loadingOffer: action.payload
			}
        case LIST_OFFER:
            return {
                ...state,
                listOfferAll: action.payload.listOffer,
				offerMain: action.payload.offerMain
            }
		default: return state
	}
}

export default notificationReducer