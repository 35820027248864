export const generateListWithBanner = (motos, advertising, nro) => {
    let initial = 2;
    let cantidad = 0;
    let listResult = [];
    let index = 0;

    for (let clave in motos){
      
        listResult.push({...motos[clave], isBanner: false });
        cantidad++;

        if(cantidad===initial){
            listResult.push({ isBanner: true, banners: advertising[index] });
            initial = (initial===2) ? 3 : 2;
            cantidad=0;
            index++;
            if(index>=nro) index=0;
        }
    }

    return listResult;
}