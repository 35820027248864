import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

const ItemSpinner = ({ isDescriptionView }) => {


	return (
		<div className="blog-card">
			<div className="blog-card__image">
				
            <Skeleton count={1} className="card-image__spinner"  />

			</div>
			<div className="blog-card__description">
                <Skeleton count={1} height={30} width="100%"/>
            </div>
			{
					isDescriptionView && (
						<>
							<Skeleton count={1} height={30}  className="subtitle-main__blog" />

							<Skeleton count={1} height={30} width="100%" className="description-main__blog" />

							<Skeleton count={1} height={30}  className="blog__text--link" />
						</>
					)
				}
		</div>
	)
}

export default ItemSpinner