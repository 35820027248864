import Slider from "components/Customs/Photos/Slider/Slider"
import styles from "styles/vehicle.module.css"
import { SwiperSlide } from 'swiper/react'

const PanelCarVehicle = ({ photos, children }) => {

	return (
		<section className={styles["panel"]}>
			<div className="slide-vehicle" >
				<Slider 
					options= {{
							className: "slider-vehicle__options",
							navigation: false,
							slidesPerView: 1,
							slidesPerColumn: 1,
							spaceBetween: 0,
							pagination: false,
						}}
				>
					{
						photos.map((vehicle, key) => (
							<SwiperSlide key={key}>
								<div className="slide-vehicle__item">
						    		<img src={vehicle} alt="Example"  />
						    	</div>
							</SwiperSlide>
						))
					}
				</Slider>
			</div>
			<div className={styles["group"]}>
			{children}
			</div>
		</section>
	)
}

export default PanelCarVehicle