import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import styles from 'styles/Motorcycle.module.css'

const ItemTypeMotorcycle = ({ listTypeMotorcycle, isLoading, handleMoveTo }) => {

	return (
		<>
		{ isLoading && (
			Array.from([1, 2, 3, 4]).map((item, key) => (
				<div className={styles["moto-item"]} key={key}>
					<div className={styles["moto-item__image"]}>
						<Skeleton count={1} height={100} />
					</div>
					<p className="mt-5"><Skeleton count={1} /></p>
				</div>
			))
		)}
		{
			(!isLoading && listTypeMotorcycle.length>0) &&
			listTypeMotorcycle.map((item, key) => (

					<div className={styles["moto-item"]} key={key} moveTo={`#${item?.idPanel}`}  onClick={(event)=>handleMoveTo(event, item?.idPanel)}>
						<div className={styles["moto-item__image"]}>
							<img src={item.image} alt={item.title} className="img-fluid" />
						</div>
						<p className={styles["moto-item__title"]}>{item.title}</p>
					</div>
			
			))
		}
		</>
	)
}

export default ItemTypeMotorcycle