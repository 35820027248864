

import { useState } from "react";
import { useLocation } from "react-router-dom"


export default function useBreadcrumb() {

    const location = useLocation()
    const [ itemsBreadcrumb, setItemsBreadcrumb ] = useState([])
    const [ pageLast, setPageLast ] = useState("/")

    const getInfoBreadcrumb = (nameItemCurrent) => {
        let items = []
       
        if(typeof location?.state!=="undefined" && location?.state?.prevPath!==""){
            items.push({
                class: "item-list",
                name: location?.state?.name,
                url: location?.state?.prevPath
            })
        }

        if(nameItemCurrent){
            items.push({
                class: "item__active",
                name: nameItemCurrent,
                url: ""
            })
        }
        
        if(location?.state?.prevPath || typeof nameItemCurrent!=="undefined"){
            items.unshift({
                class: "item-list",
                name: "Home",
                url: "/"
            })
        }
     
        setItemsBreadcrumb(itemsBreadcrumb => items) 
        setPageLast(pageLast => location?.state?.prevPath ? location?.state?.prevPath : "/")
    }

    return { 
        getInfoBreadcrumb,
        itemsBreadcrumb,
        pageLast
    };
}