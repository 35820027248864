import { useEffect } from 'react'
import Cards from 'components/Customs/Panels/Cards'
import NormalSearch from 'components/Searching/Ordinary/NormalSearch'
import ModalOption from 'components/Searching/Interests/ModalOption'

//import { useBrandModel } from '../../hooks/useBrandModel'
import { useModal } from 'hooks/useModal'
import { verifyCheckedCloseModal } from 'services/actions/searchActions'
import { resetBrandModelChangeTab } from 'services/actions/marcaActions'

import { useDispatch, useSelector } from 'react-redux'
import { useSearchPage } from "hooks/SearchHome/useSearchPage"

const Search = ({ isClass, isVisibleTypeAuto, isVisibleSearch, typeHomeSelected,
routePathCurrent, lastPage }) => {

	const dispatch = useDispatch()
	const { saveLastPage, pageLast } = useSearchPage()
	const { isOpen, setIsOpen } = useModal(false)	

	const { initialDataNormal, 
		listYear, listKm, selectedTab,  
		interestSelected  
	} = useSelector((state) => state.search)

	const handleCloseModal = () => {
		dispatch(verifyCheckedCloseModal())
		setIsOpen(false)
	}
	
	/**const changeTypeSearch = (event) => {
		dispatch(getSelectedTab(event.target.value))
		dispatch(resetBrandModelChangeTab())

	}**/


	useEffect(() => {
		if(pageLast!=="/marca" && pageLast!=="/modelos"){
			//dispatch(getDataInitialSearch(typeHomeSelected))
			dispatch(resetBrandModelChangeTab())
		}		
		saveLastPage()	
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [routePathCurrent])

	return (
		<div className="row">
			<div className="col-12">
				<div className="container">
					<Cards classContainer={isClass} classBody="card-search">
						<section className="search__header">
							<p className="card__title card__title--normal">
								Encuentra aquí tu próximo auto
							</p>
						</section>

						{ 
							(Object.keys(initialDataNormal).length>0) && (
								<NormalSearch
									isVisibleTypeAuto={isVisibleTypeAuto} 
									isVisibleSearch={isVisibleSearch}
									defaultValues={initialDataNormal}
									listYear={listYear}
									listKm={listKm}
									valueTypeCar={selectedTab}
									typeHome={typeHomeSelected}
									lastPage={lastPage}
								/> 					
							)
						}
					
					</Cards>
					{ isOpen && <ModalOption isOpen={isOpen} closeModal={handleCloseModal} items={interestSelected} />}
				</div>
			</div>
		</div>
	)
}

export default Search 