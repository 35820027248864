import ReactPixel from "react-facebook-pixel";
import { sendFormEvenLoad } from 'services/actions/notificationAction';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom"
const type_env = process.env.REACT_APP_ENVIRONMENT;
const conversion_analytics = process.env.REACT_APP_CONVERSION_ANALYTICS;

export const useEvent = () => {

	const dispatch = useDispatch();
	const history = useHistory()
	const { dataFormNotification, urlLast } = useSelector((state) => state.notifications)	

	const validateSendAPIPixel = (message) => {
		
		if(!dataFormNotification?.eventId){
			history.push(localStorage.getItem("urlLast") ? localStorage.getItem("urlLast") : '/');
		}
		if(message.success){
	      	if(type_env==="production"){
				if (typeof window.gtag !== 'undefined'){
					window.gtag('event', 'conversion', {'send_to': conversion_analytics});
				}
				
				if (typeof window.ga !== 'undefined'){
					window.ga('send', 'event', 'envio formulario', 'clic', 'boton ok', 0);
					
				}

				ReactPixel.track('Lead'); 
				
				if(Object.keys(dataFormNotification).length>0){
					if (typeof window.fbq !== 'undefined'){
						window.fbq('track', 'Lead', {}, {eventID: dataFormNotification?.eventId })
					}

					dispatch(sendFormEvenLoad(dataFormNotification))
				}	          
	       }
	    }
	}

	const validateSendAPIPixelModal = (message) => {
		
		if(message.success){
	      	if(type_env==="production"){
				if (typeof window.gtag !== 'undefined'){
					window.gtag('event', 'conversion', {'send_to': conversion_analytics});
				}
				
				if (typeof window.ga !== 'undefined'){
					window.ga('send', 'event', 'envio formulario', 'clic', 'boton ok', 0);
					
				}

				ReactPixel.track('Lead'); 
				
				if(Object.keys(dataFormNotification).length>0){
					if (typeof window.fbq !== 'undefined'){
						window.fbq('track', 'Lead', {}, {eventID: dataFormNotification?.eventId })
					}

					dispatch(sendFormEvenLoad(dataFormNotification))
				}	          
	       }
	    }
	}

	return {
		validateSendAPIPixel,
		validateSendAPIPixelModal,
		urlLast
	}
}