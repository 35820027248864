import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import styles from "styles/store.module.css"


const SkeletonLayoutStore = ({ classStyle }) => {

	return (
		<div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="position-relative">
                        <div className={styles["header-container-photo"]}>
                            <Skeleton className={styles["header-photo"]}  />
                        </div>
                        <div className={styles["header-container-logo"]}>
                            <Skeleton circle={true} className={styles["header-logo"]} />
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className={styles[classStyle?.classTitle]}>
                        <Skeleton className={styles["header-title"]}  />
                        <Skeleton className={`${styles["header-category"]} mb-5 pb-5`}  />
                    </div>
                    <Skeleton className={styles["header-description"]}  />

                     <div className="row">
                        <div className="col-12">
                            <div className={`card ${styles["panel"]} ${styles["panel-card"]} mb-0`}>
                                <div className={styles["panel-body"]}>
                                    <div className={styles["panel-features"]}>
                                        <div className={styles["panel-item"]}>
                                            <Skeleton width={150} height={60} />
                                        </div>
                                        <div className={styles["panel-item"]}>
                                            <Skeleton width={150} height={60} />
                                        </div>
                                        <div className={styles["panel-item"]}>
                                            <Skeleton width={150} height={60} />
                                        </div>
                                    </div>
                                    <div className={`${styles['panel-container-buttom']}`}>
                                        <Skeleton width={250} height={60} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                     
                </div>
            </div>
        </div>
	)
}

export default SkeletonLayoutStore