import compare_desktop from 'assets/images/tire/icon-quote.png'

const ButtomQuote = ({ count }) => {

	return (
		<>
			<div className="result-foooter">
				<div className="search-foooter">
					<div className="container w-90">
			    		<a 
			    			type="button" 
			    			className="btn btn-primary btn-lg w-100" 
			    			href="#form"
			    		>{`Cotizar (${count})`}</a>
			    	</div>
				</div>
			</div>
			<a href="#form">
			<section className="d-result-compare__button">
				<div className="d-result-compare">
					<img src={compare_desktop} alt="Cotizas tus neumáticos" />
					<p>{`Cotiza tus neumáticos (${count})`}</p>
				</div>
			</section>
			</a>
		</>
	)
}

export default ButtomQuote