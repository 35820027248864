import Slider from 'components/Customs/Carousel/Slider'
import Item from 'components/Customs/DeleteItem/Item'
import { SwiperSlide } from 'swiper/react'

const CarouselItem = ({ isClass, listado, deleteItem }) => {

	return (
		<Slider
			options= {{
				 slidesPerView: 'auto', 
				 spaceBetween: 0,
				className:"mySwiper",
				pagination: false,
				navigation: false
			}}
		>
			{
				listado.map((option, key) => (
					<SwiperSlide key={key}>
						<Item 
							option={option}
							deleteOption={deleteItem}
						/>
					</SwiperSlide>
				))
			}
		</Slider>
	)
}

export default CarouselItem