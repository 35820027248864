

const TooltipLocal = ({ id, local_number, type, logo, name, style, showTooltip, styleTooltip }) => {


	return (
		<svg viewBox='0 0 491 611' aria-label="tooltip">
        <rect filter="url(#shadow)" width={390} height={70} x={styleTooltip.x} y={styleTooltip.y}  fill="white"/>
        <rect width={70} height={70} x={styleTooltip.x} y={styleTooltip.y}  fill="#e9d7d71d"/>
        <image width={70} height={70} x={styleTooltip.x} y={styleTooltip.y} href={logo} alt="Logo" />
        <text x={styleTooltip.x+75} y={styleTooltip.y+20} fill="#858584" fontSize="1.4em">{type}</text>
        <text x={styleTooltip.x+75} y={styleTooltip.y+40} fill="#7F8082" fontSize="1.4em">{name}</text>
        <text x={styleTooltip.x+75} y={styleTooltip.y+60} fill="#F47422" fontSize="1.4em">Nro local {local_number}</text>

        <filter id="shadow" x="0" y="0" width="200%" height="200%">
          <feDropShadow dx="-1" dy="-1" stdDeviation="1" floodColor="#0000001D" floodOpacity="1" />
          <feDropShadow dx="2" dy="1" stdDeviation="1" floodColor="#0000001D" floodOpacity="1" />
        </filter>
    </svg>
	)
}

export default TooltipLocal