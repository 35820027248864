import ItemResult from "components/QuoteCar/Sire/Form/ItemResult"
import car_suv from "assets/images/icons/suv.svg"
import { nextPrevForm } from "services/actions/quoteAction"
import { useDispatch, useSelector } from 'react-redux'

const FormResultPage = () => {

	const dispatch = useDispatch()
    
    const { listSireByCompara, titleResult } = useSelector((state) => state.quotes_car)

    const handleLastForm = () => {
        dispatch(nextPrevForm(0))
    }

	return (
		<>
			<section className="row row-quote__container">
	            <div className="col-12 bg__quote-patente__result">
	                <section className="quote-patente__result">
	                    <div className="quote-patente__result-image">
	                        <img src={car_suv} alt="Cotiza tu seguro" />
	                    </div>
	                    <div className="quote-patente__result-info">
	                        <p className="patente__result-info--title">{titleResult.primary}</p>
	                        <p className="patente__result-info--subtitle">{titleResult.secondary}</p>
	                    </div>
	                    <p className="quote-patente__result-link" onClick={()=>handleLastForm()}
	                        >Editar</p>
	                </section>
	            </div>
	        </section>
	        <section className="quote--item__result mt-5"> 
	        {
	            listSireByCompara.length>0 && listSireByCompara.map((item, key) => 
	                    <ItemResult 
	                        key={key} 
	                        item={item} 
	                        titleResult={titleResult}
	                    />)
	        }
	        </section>
        </>
	)
}

export default FormResultPage