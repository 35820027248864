export const INITIAL_DATA = 'INITIAL_DATA'
export const UPDATE_DATA_NORMAL = 'UPDATE_DATA_NORMAL'
export const SELECTED_TAB_SEARCH = 'SELECTED_TAB_SEARCH'
export const UPDATE_SELECTED_INTEREST = 'UPDATE_SELECTED_INTEREST'
export const SAVE_INSTEREST = 'SAVE_INSTEREST'
export const SAVE_INSTEREST_FORM = 'SAVE_INSTEREST_FORM'
export const VERIFY_SELECTED_INTEREST = 'VERIFY_SELECTED_INTEREST'
export const UPDATE_ITEMS_SEARCH = 'UPDATE_ITEMS_SEARCH'
export const UPDATE_SEARCH_NORMAL = 'UPDATE_SEARCH_NORMAL'
export const UPDATE_ITEMS_INTEREST_VALUE = 'UPDATE_ITEMS_INTEREST_VALUE'
export const UPDATE_SEARCH_INTEREST = 'UPDATE_SEARCH_INTEREST'
export const UPDATE_BRAND_MODEL_SEARCH = 'UPDATE_BRAND_MODEL_SEARCH'
export const SAVE_PREFERENCES_INTEREST = 'SAVE_PREFERENCES_INTEREST'
export const UPDATE_FILTER_NORMAL = 'UPDATE_FILTER_NORMAL'
export const REFRESH_DATA = 'REFRESH_DATA'
export const INITIAL_HOME = 'INITIAL_HOME'
export const UPDATED_BY_PARAMS_URL = 'UPDATED_BY_PARAMS_URL'
export const UPDATE_PARAMS_NOT_FOUND = 'UPDATE_PARAMS_NOT_FOUND'
export const UPDATE_PAGE_LAST = 'UPDATE_PAGE_LAST'
export const INITIAL_PANEL = 'INITIAL_PANEL'
export const LOADING_RESULT_SEARCH = 'LOADING_RESULT_SEARCH'
export const INITIAL_BRAND_SEARCH = 'INITIAL_BRAND_SEARCH'
export const UPDATE_BRAND_NORMAL_SEARCH = 'UPDATE_BRAND_NORMAL_SEARCH'
export const UPDATE_SELECTED_BRAND_COMBO = 'UPDATE_SELECTED_BRAND_COMBO'
export const DELETE_BRAND_SELECTED_COMBO = 'DELETE_BRAND_SELECTED_COMBO'
export const LIST_SELECTED_MODEL_COMBO = 'LIST_SELECTED_MODEL_COMBO'
export const DELETE_MODEL_SELECTED_COMBO = 'DELETE_MODEL_SELECTED_COMBO'
export const UPDATE_DATA_NORMAL_ITEM = 'UPDATE_DATA_NORMAL_ITEM'