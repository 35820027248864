import { useEffect } from "react"
import Layout from 'components/Layout/Layout'
import DescriptionServiceRutaMaipo from 'components/Services/DescriptionServiceRutaMaipo'
import SkeletonLayoutStore from 'components/Layout/Skeleton/SkeletonLayoutStore'
import Information from 'components/Services/Panel/Information'
import UbicationModal from 'components/Customs/Modal/UbicationModal'
import Breadcrumb from 'components/Customs/Breadcrumb/index'
import useBreadcrumb from "hooks/Main/useBreadcrumb"
import styles from "styles/store.module.css"
import { useModal } from 'hooks/useModal'

const BaseLayoutStore = ({ infoMetaHead, infoHeader, classStyle, isVisibleIcon, loading, children, handleMoveTo }) => {

    const { getInfoBreadcrumb, itemsBreadcrumb , pageLast} = useBreadcrumb()
    const { isOpen, closeModal, openModalMap } = useModal(false)

    //Funcionalidad del menú miga de pan
	useEffect(() => {
    
		if(Object.keys(infoHeader).length>0 && infoHeader?.name!=="undefined" && infoHeader?.name!==""){
			getInfoBreadcrumb(infoHeader?.name)
		}		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [infoHeader?.name])

	return (
		<Layout
			classHeader="row header__white"
			hiddenColor={true}
			isVisibleFooter={true}
			visibleLine={true}
			infoMetaHead={infoMetaHead}
			classDefaultHeader="row header-top header__white"
			classTopHeader=""
		>
            <div className="background-absolute" />
			<Breadcrumb 
				itemBreadcrumb={itemsBreadcrumb}
				isVisible={true}
				urlReturn={pageLast}
			/>
            {loading && <SkeletonLayoutStore classStyle={classStyle} />}
            <div className="row bg-gray">
                <div className="col-12">
                {!loading && (
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="position-relative">
                                    <div className={styles["header-container-photo"]}>
                                        <picture>
                                            <source media="(min-width: 768px)" srcSet={infoHeader?.cover_image} />
                                            <img src={infoHeader?.cover_image_mobile} alt={infoHeader?.name} className={styles["header-photo"]} />
                                        </picture>
                                    </div>
                                    <div className={styles["header-container-logo"]}>
                                        <img src={infoHeader?.icon} alt={infoHeader?.name} className={styles["header-logo"]} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className={styles[classStyle?.classTitle]}>
                                    <h3 className={styles["header-title"]}>{infoHeader?.name}</h3>
                                    {infoHeader?.name!=="" && (
                                        <h1 className={`${styles["header-category"]} ${(infoHeader?.categoryName==="Autos Usados") ? "mb-5 pb-5" : ""}`}>
                                            {`${(infoHeader?.categoryName==="Autos Usados" || infoHeader?.categoryName==="Autos Nuevos") ? "Concesionario" : "" }`} {infoHeader?.categoryName} 
                                        </h1>
                                    )}
                                </div>
                                {
                                    (infoHeader?.categoryName==="Autos Nuevos" && infoHeader?.description!=="") && (
                                        <p className={styles["header-description"]}>{infoHeader?.description}</p>
                                    )
                                }

                                <div className="row">
                                    <div className="col-12">
                                        <div className={`card ${styles["panel"]} ${styles["panel-card"]} mb-0`}>
                                            <div className={styles["panel-body"]}>
                                                <div className={styles["panel-features"]}>
                                                    <Information 
                                                        title="Categoría"
                                                        value={infoHeader?.categoryName}
                                                        isClass={isVisibleIcon ? "features__imagen--category" : ""}
                                                        isVisibleIcon={isVisibleIcon}
                                                        openModelMap={null}
                                                    />
                                                    <Information 
                                                        title="N.° de local"
                                                        value={infoHeader?.locals}
                                                        isClass={isVisibleIcon ? "features__imagen--local" : "" }
                                                        isVisibleIcon={isVisibleIcon}
                                                        openModelMap={openModalMap}
                                                    />
                                                    <Information 
                                                        title="Teléfono"
                                                        value={infoHeader?.telephoneFormat}
                                                        isClass={isVisibleIcon ? "features__imagen--phone" : "" }
                                                        isVisibleIcon={isVisibleIcon}
                                                        openModelMap={null}
                                                    />
                                                    </div>
                                                    <div className={`${styles[isVisibleIcon ? 'panel-container-buttom' : '']}`}>
                                                        {/**<div className={styles["panel-item"]}>
                                                            <p className={styles["panel-item__title"]}>Encuentra tu concesionario</p>
                                                        </div> */}
                                                        <div className={styles["panel-button"]}>
                                                            <button 
                                                                type="button" 
                                                                className={`btn btn-primary ${styles["panel-button"]}`} 
                                                                onClick={(event)=>handleMoveTo(event, "form")}
                                                            >Contáctanos</button>
                                                        </div>
                                                    </div>
                                                    <UbicationModal
                                                        show={isOpen}
                                                        handleClose={closeModal}
                                                        localMap={infoHeader?.localMap}
                                                    />
                                            </div>
                                        </div>
                                    </div>
                                </div>                     
                            </div>
                        </div>
                    </div>
                
                )}
                {infoHeader?.name?.toUpperCase()==="RUTA DEL MAIPO" && <DescriptionServiceRutaMaipo />}


                {children}
                </div>
            </div>
		</Layout>
	)
}

export default BaseLayoutStore