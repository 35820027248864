import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

const LoadingItem = ({ SlideClass }) => {


	return (

		<div className={`card card__container card__feature ${SlideClass}`}>
			<div className="card__body">
		    	<div className="featured__image">
		    		<Skeleton height={150}  />
		    	</div>
		    	<div className="featured__item__container">
		    		<p className="featured__type"><Skeleton count={1} /></p>
		    		<p className="featured__title"><Skeleton count={1} /></p>
		    		<p className="featured__price"><Skeleton count={1} /></p>
		    	</div>
			</div>
		</div>

	)
}

export default LoadingItem