import { useForm } from 'react-hook-form'
import { saveFormDataOwner, saveFormDataGlobal } from "services/actions/quoteAction"
import { validarRut } from "utils/rut"

import { useDispatch, useSelector } from 'react-redux'
import { calcularEdad } from 'utils/toolDate'

export const useDataOwner = () => {

	const dispatch = useDispatch()
	const { dataFormQuote, listInfoCompara } = useSelector((state) => state.quotes_car)

	const { register, control, handleSubmit, errors, setError } = useForm({defaultValues: {
        rut: dataFormQuote.rut,
        name: dataFormQuote.name,
        lastname: dataFormQuote.lastname,
        dateOfBirth: dataFormQuote.dateOfBirth,
        comuna: dataFormQuote.comuna,
		vehicle_use_type: dataFormQuote.vehicle_use_type
    }})

	const onSubmit = (data) => {
		let error = false
		if(data.dateOfBirth!==""){
			const edad = calcularEdad(data.dateOfBirth)	
			if(edad < 18 ){
				error = true
				setError("dateOfBirth", { type: "manual", message: "Su edad es menor de 18 años"})
			}
		}

		if(!error){
			dispatch(saveFormDataOwner(data))
			setError("dateOfBirth", { type: "manual", message: ""})
		}
		window.scrollTo(0, 0)
	}

	const handleBlurRut = (event) =>{
		if(event.target.value!==""){
			const response = validarRut(event.target.value)
			if(response?.status===false){
				setError("rut", { type: "manual", message: response?.message})
			}else{
				setError("rut", { type: "manual", message: ""})
			}
		}		
	}

	const openCalendar = async () => {
		const dateInput = document.querySelector("#dateOfBirth")
		await dateInput.showPicker();
	}

	const validateDate = (event) => {
		const { value } = event.target
		let error = false
		setError("dateOfBirth", { type: "manual", message: ""})
		if(value!==""){
			const edad = calcularEdad(value)	
			if(edad < 18 ){
				setError("dateOfBirth", { type: "manual", message: "Su edad debe ser mayor a 18 años"})
				error = true
			}
		}

		if(!error){
			dispatch(saveFormDataGlobal({ "dateOfBirth": value!=="" ? value : ""}))
		}
	}

	const onChangeInput = (event, type, name) => {
		const { value } = event.target
		dispatch(saveFormDataGlobal({ [name]: value!=="" ? value : ""}))
	}


	return {
		register,
		onSubmit,
		handleSubmit,
        control,
		listInfoCompara,
		errors,
		handleBlurRut,
		dataFormQuote,
		openCalendar,
		validateDate,
		onChangeInput
	}
}