import img_example from 'assets/images/tire/example.png'

const ItemService = ({ item, selectedQuote }) => {

	return (
		<div className="service-result-item">
			<p className="result-item__title">{`${item.brand} ${item.model}`}</p>
			<div className="result-item__image">
				{typeof item.image!=='undefined' && item.image!=="" ?
				 <img src={item.image} alt={item.model} />
				 : <img src={img_example} alt={item.model} />
				}
			</div>
			<p className="result-item__number">{item.width}/ {item.profile} / {item.ring}</p>
			<p className="result-item__number result-item--sku"></p>
			<div className="result-item__brand">
				<div className="result-item__bimage">
				{typeof item.photo!=='undefined' && item.photo.image!=="" &&  
				<img src={item.photo.image} alt={item.photo.title} />}
				</div>
			</div>
			<div className="result-item__quote">
			    <input 
			    	className="search-form-check result-item__quote-check" 
			    	type="checkbox" 
			    	value={item.id} 
			    	id={`tire_${item.id}`} 
			    	onChange={()=>selectedQuote(item)}
			    	/>
			    <label 
			    	className="result-item__quote-label" 
			    	htmlFor={`tire_${item.id}`} 
			    >Cotizar</label>
			</div>
		</div>
	)
}

export default ItemService