import { useEffect } from 'react'
import { getListInitialSoap, sendSelectedInsurance, openCloseModalSoap } from "services/actions/soapAction"
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
const url_main = process.env.REACT_APP_URL

export const useSoap = (initial) => {

	const dispatch = useDispatch()
	const location = useLocation()
	
	const sendLinkSoap = async (event, item) => {
        event.preventDefault()
		
		const data = {
			insurance: item,
			wasSuccessfull: false, 
			location: location?.pathname
		}
		
		const response = await dispatch(sendSelectedInsurance(data))
		if(response.status){
			window.location.href=`${item.link}?callback_url=${url_main}soap/${response?.insuranceId}/success`
		}
    }


	const getInitialLogin = async () => {
		await dispatch(getListInitialSoap())	
	}

	const closeModalSoap = () => {
		dispatch(openCloseModalSoap())
	}
	

	useEffect(()=>{
		getInitialLogin()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])


	return {
		closeModalSoap,
		sendLinkSoap
	}
}