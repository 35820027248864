import ItemSpinner from 'components/Blog/Spinner/ItemSpinner'
import Item from 'components/Blog/Item'
import Slider from 'components/Customs/Carousel/Slider'
import { SwiperSlide } from 'swiper/react'

import { Link } from 'react-router-dom';

const Blog = ({ isClass, title, subtitle, list, isLoop, isDescriptionView, classCarousel, loading, titleButton }) => {

	return (
		<div className={`row blog ${isClass}`}>
			<div className="col-12">
				<div className="container blog__info">
					<section className="blog-section-top">
						<div>
							<p className="blog__title mb-1">{title} </p>
							<p className="blog__title panel__title--orange">{subtitle}</p>
						</div>
						<div>
							<Link 
								to="/blog/" 
								className="menu__desktop--link"
							>
								<button type="button" className="btn btn-primary btn-lg blog-section__button">{titleButton}</button>
							</Link>
						</div>
					</section>
					
					<div className="blog__container">
						<Slider
							options= {{
								className: classCarousel,
								slidesPerView: 1, 
								spaceBetween: 0,
								direction: "vertical",
								grabCursor: true,
								autoplay: { delay: 3000 },
								speed: 6000,
								loop: false,
								navigation: false,
								pagination: false,
								breakpoints:{
									"768": {
										"slidesPerView":2,
										"spaceBetween": 15,
										direction: "horizontal",
									},
									"1024": {
										"slidesPerView":3,
										"spaceBetween": 5,
										direction: "horizontal",
									}
								}
							}}
							
						>
						{
							(!loading && list.length>0) &&
							list.map((blog, key) => (
								<SwiperSlide key={key}>
									<Item post={blog} isDescriptionView={isDescriptionView} />
								</SwiperSlide>
							))
						}
						{
							(loading && list.length===0) &&
							Array.from([1, 2, 3, 4, 5, 6, 7, 8]).map((blog, key) => (
								<SwiperSlide key={key}>
									<ItemSpinner isDescriptionView={isDescriptionView} />
								</SwiperSlide>
							))
						}
						</Slider>
							
					</div>
				</div>				
			</div>
		</div>
	)
}

export default Blog