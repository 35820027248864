import { useState } from 'react'
import { sendFormNotification, sendFormNotificationByBrand } from 'services/actions/notificationAction';
import { sendFormService, loadingFormTire } from 'services/actions/serviceActions'

import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useGoogleRecaptcha } from 'hooks/Contact/useGoogleRecaptcha';

export const useFormContactTire = () => {

	const dispatch = useDispatch()
	const history = useHistory()
	const location = useLocation()
	const { getToken } = useGoogleRecaptcha();

	const [ showVin, setShowVin ] = useState(false)
	const [ disabledBtn, setDisabledBtn ] = useState(false)
	const { register, reset, handleSubmit, errors } = useForm();
	const [ isOpen, setIsOpen ] = useState(false);
	const [ isLoading, setIsLoading ] = useState(false);
	const [ message, setMessage ] = useState({
		error: false, 
		title: '¡Ups!', 
		subtitle: 'Ha ocurrido error en el envío del formulario. Por favor intenta nuevamente', 
		btnTitle: 'Reintentar',
		success: false
	});


	const { categoryProviders } = useSelector((state) => state.services)

	const handleActive = (event) => {
		setDisabledBtn(!event.target.checked)
	}

	const closeModal = () => setIsOpen(false)

	const onSubmit =  async (data, infoSendItem, provider) => {
		try{
			setIsLoading(true)
			setDisabledBtn(true)
			setMessage({error: false, title: '', subtitle: '', btnTitle: '', success: false})
			dispatch(loadingFormTire())
			
	
			const tokenCaptcha = await getToken();
	
			let dataForm = {}, response = {}
			
			if(provider!==null && infoSendItem.length===0){
				dataForm = {
					name: data.name,
					telephone: "+569"+data.telephone,
					email: data.email,
					provider: provider,
					message: data.message,
					token: tokenCaptcha
				}
				
				response = await dispatch(sendFormNotificationByBrand(dataForm, location.pathname))
			}else{
				const summary = infoSendItem.map(item => item.titleOption).join('/n ')
				const category = categoryProviders.find(item => item.name==='Neumáticos')
	
				dataForm = {
					items: infoSendItem,
					name: data.name,
					telephone: "+569"+data.telephone,
					email: data.email,
					summary,
					message: data.message,
					category_provider_id: category?.id,
					token: tokenCaptcha
				}
	
				if(provider!==null){
					dataForm = {
						...dataForm,
						provider: infoSendItem.length>0 ? infoSendItem[0]?.provider : null
					}
				}
	
				if(data.vin!==""){
					dataForm= {
						...dataForm,
						vin: data.vin
					}
				}
				
				response = await dispatch(sendFormNotification(dataForm, location.pathname))
			}
	
			
			dispatch(sendFormService())
	
			if(response.status){
				/**setMessage({
					error: false, 
					title: '¡Gracias!', 
					subtitle: 'Tu solicitud ha sido enviada con éxito pronto tendrás noticias nuestras',
					btnTitle: 'Ok',
					success: true
				})**/
				reset({
					name: '',
					phoneNumber: '',
					email: '',
					vin: '',
					message: ''
				})
				if(document.querySelector('.item-resumen')){
					document.querySelector('.item-resumen').innerHTML = ''
				}
				setIsLoading(false)
				setDisabledBtn(false)
				history.push('/success')
			}else{
				setMessage({
					error: false, 
					title: '¡Ups!', 
					subtitle: 'Ha ocurrido error en el envío del formulario. Por favor intenta nuevamente',
					btnTitle: 'Reintentar',
					success: false
				})
				setIsOpen(true)
				setIsLoading(false)
				setDisabledBtn(false)
			}
		}catch(error){
			setMessage({
				error: false, 
				title: '¡Ups!', 
				subtitle: 'Ha ocurrido un error en el envío del formulario. Por favor recargue la página e intenta nuevamente',
				btnTitle: 'Reintentar',
				success: false
			})
			setIsOpen(true)
			setIsLoading(false)
			setDisabledBtn(false)
		}				
	}


	return {
		handleActive,
		disabledBtn,
		showVin,
		setShowVin,
		register, 
		handleSubmit, 
		errors,
		onSubmit,
		message,
		isLoading,
		isOpen,
		closeModal
	}
}