import { Modal } from 'react-bootstrap'
import LottieLoading from 'components/Template/Loading/LottieLoading'

const ModalSpinner = ({ message }) => {

	return (
		<Modal
        show={true}
        backdrop="static"
        keyboard={false}
        className="modal-content-spinner"
        dialogClassName="modal-spinner"
      >
        <LottieLoading 
				title={message}
				classLoading="loading-result"
				width={600}
			/>
      </Modal>
	)
}

export default ModalSpinner