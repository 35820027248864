import TagTitle from 'components/Customs/Titles/TagTitle'
import Slider from 'components/Customs/Carousel/Slider'
import ItemFeature from 'components/Customs/Featured/ItemFeature'
import LoadingItem from 'components/Customs/Featured/LoadingItem'
import { SwiperSlide } from 'swiper/react'


const Featured = ({ classContainer, title, SlideClass, classFeatured, listado, classSlide, visibleNavigation, lastPage }) => {

	return (
		<>
			<TagTitle 
				classContainer={classContainer}
				title={title} 
			/>
			<div className="row">
				<div className={`col-12 col_featured ${classFeatured}`}>
				
					<div className="featured__container">
						<Slider 
							options= {{
								className:  `container ${classSlide}`,
								navigation: false,
								pagination: visibleNavigation,
								spaceBetween: 15,
								autoplay: {
									delay: 3000,
								},
								loop: true,
								speed: 6000,
								breakpoints:{
										"300": {
										"slidesPerView": 2,
										"spaceBetween": 15,
										},
										"500": {
										"slidesPerView": 3,
										"spaceBetween": 15,
										},
										"768": {
										"slidesPerView": 3,
										"spaceBetween": 20,
										},
										"992": {
										"slidesPerView": 4,
										"spaceBetween": 30
										},
										"1200": {
										"slidesPerView": 4,
										"spaceBetween": 35
										}
								}
							}}
						>
							{
								listado.map((car, key) => 
										<SwiperSlide key={key}>
											<ItemFeature {...car} SlideClass={SlideClass} d_key={car.key} lastPage={lastPage} />
										</SwiperSlide>
								)
							}
							{
								listado.length===0 && Array.from([1, 2, 3, 4, 5, 6, 7]).map((car, key) => 
										<SwiperSlide key={key}>
											<LoadingItem SlideClass={SlideClass} />
										</SwiperSlide>
								)
							}
						</Slider>
					</div>
			
				</div>
			</div>
		</>
	)
}

export default Featured