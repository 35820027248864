import { useEffect, useRef } from 'react'
import GroupFilterItemResult from 'components/Result/Filter/Desktop/GroupFilterItemResult'
import GroupSelect from 'components/Result/Filter/Desktop/GroupSelect'
import InputSliderRange from 'components/Customs/InputForm/InputSliderRange'
import GroupModelByBrand from 'components/Result/Filter/Custom/GroupModelByBrand'
///import GroupTypeAuto from 'components/Result/Filter/Desktop/GroupTypeAuto'
import DeleteItem from 'components/Searching/Custom/DeleteItem'
import BannerUpRight from 'components/Customs/Banner/Vertical/BannerUpRight'

import { loadingSearchCombined } from 'services/actions/carActions'
import { 
	deleteItemSearchNormalInterest, generateUrlSearchNormal,
	selectedBrandSearch, getSelectedBrand, deleteItemBrandSelected,
	getVerifyStatusModel, deleteItemModelSelected, generateItemMarcaModelForm
} from 'services/actions/searchActions'
import { getUpdateVisibleBrand } from 'services/actions/marcaActions'

import { useForm, useFieldArray } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

const PanelParamsLeft = ({ defaultValues, itemOfSearch, advertisingResult, actionAdvertising, hiddenOrigin }) => {

	const dispatch = useDispatch()
	const history = useHistory()
	const minValRef = useRef(null);
	const maxValRef = useRef(null);
	//const [ visibleBrand, setVisibleBrand ] = useState(true)
	const { listYear, listKm, searchParamsView, isButtonClearAll } = useSelector((state) => state.search)


	const { register, control, reset, getValues, setValue } = useForm({defaultValues})

	const { fields: vTypeAuto } = useFieldArray({ control, name: "vTypeAuto" })
	const { fields: vCarroceria  } = useFieldArray({ control, name: "vCarroceria" })
	const { fields: vTransmision } = useFieldArray({ control, name: "vTransmision" })
	const { fields: vCombustible } = useFieldArray({ control, name: "vCombustible" })
	const { fields: vOrigin } = useFieldArray({ control, name: "vOrigin" })
	const { fields: vPerformance } = useFieldArray({ control, name: "vPerformance" })
	const { fields: vSeatMove } = useFieldArray({ control, name: "vSeatMove" })
	const { fields: vTraction } = useFieldArray({ control, name: "vTraction" })
	const { fields: vMarca } = useFieldArray({ control, name: "vMarca" })
	const { fields: vModelo } = useFieldArray({ control, name: "vModelo" })

	const getUpdateResultcar =  async (data) => {

		dispatch(loadingSearchCombined(true))

		let dataBrandModel = dispatch(generateItemMarcaModelForm())
		const listMarca = dataBrandModel?.data?.marcas
		const listModelo = dataBrandModel?.data?.modelos

		const body = { 
			...data, 
			vTypeRange: {min: Number(minValRef.current), max: Number(maxValRef.current)},
			vTypeKm: {min: Number(data?.vTypeKm[0]?.min), max: Number(data?.vTypeKm[1]?.max) },
			vTypeYear: {min: Number(data?.vTypeYear[0]?.min), max: Number(data?.vTypeYear[1]?.max) },
			vMarca: listMarca,
			vModelo: listModelo
		}
		
		let response = dispatch(generateUrlSearchNormal(body))
	
		if(response.status) {
			let slugUrl = `/autos/${response.slug}`
			if(response.slug==="nuevos/" || response.slug==="usados/"){
				slugUrl = `/s/autos/${response.slug}`
			}
			history.push(slugUrl)
		}	
	}

	const selectedTypeAuto = () => {
		
		const formData = getValues()
		
		if(Object.keys(formData).length>0){
			const filterSelected = formData?.vTypeAuto.filter(x=>x.checked===true)
			
			if(filterSelected.length===0 || filterSelected.length===2){
				dispatch(getUpdateVisibleBrand('other'))
			}else{
				
				dispatch(getUpdateVisibleBrand(filterSelected[0]?.name))
			}
			//setVisibleBrand(filterSelected.length > 0 ? true : false)
		}		
	}

	const deleteItem = (item, type="") => {

		const response = dispatch(deleteItemSearchNormalInterest(item, type))
		if(response.status){
			let url = `/autos/${response.url}`
			if(response.url==="nuevos") {
				url = `/s/autos/${response.url}/`
			}else if(response.url==="usados") {
				url = `/s/autos/${response.url}/`
			}
			history.push(url)
		}
	}

	const checkUpdateResult =  (event, item) => {

		if(item?.name==="vMarca"){
			dispatch(selectedBrandSearch(item))
			if(event.target.checked){
				dispatch(getSelectedBrand(item?.id, true))
			}else{
				dispatch(deleteItemBrandSelected(item))
			}
			
		}else if(item?.name==="vModelo"){
			if(event.target.checked){
				dispatch(getVerifyStatusModel(item, true))
			}else{
				dispatch(deleteItemModelSelected(item))
			}
			
		}else if(item?.name==="vTypeAuto"){
			selectedTypeAuto()
		}
		const data = getValues()
		getUpdateResultcar(data)
	}


	useEffect(() => {
		reset(defaultValues)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultValues])


	return (
		<div className="d-none d-md-block col-md-3">
			<section className="option-filter__container">
				<div className="filter-selected-container">
					<p className="option-filter__title mb-0">Tu búsqueda</p>
					{isButtonClearAll && 
						<button type="button" className="btn btn-primary btn-red-primary" onClick={()=> deleteItem({}, "all")}>Limpiar todos</button>
					} 
				</div>
				<div className="option-filter__item">
					{ itemOfSearch.map((item, key) => <DeleteItem key={key} item={item} deleteOption={deleteItem} />)}
					{ itemOfSearch.length===0 && <p className="option-filter__text">No hay búsqueda seleccionada</p>}
				</div>
			</section>
			<div className="filter__container">
				<form className="w-100">
					<section>
						<GroupFilterItemResult
							key={0}
							list={vTypeAuto}
							title="Tipo de Auto"
							register={register}
							handleClickUpdateResult={checkUpdateResult}
							setValue={setValue}
						/>

						{searchParamsView && <div className="filter__item">
							<InputSliderRange 
								register={register} 
								min={Number(defaultValues?.vTypeRange?.min)} 
								max={Number(defaultValues?.vTypeRange?.max)}
								minValRef={minValRef} 
								maxValRef={maxValRef} 
								control={control}
								setValue={setValue}
								classMain="filter-price"
								handleClickUpdateResult={checkUpdateResult} 
							/>						
						</div>}	

						<GroupFilterItemResult
							key={1}
							list={vCarroceria}
							title="Tipo de carrocería"
							register={register}
							handleClickUpdateResult={checkUpdateResult}
							setValue={setValue}
						/>

						<GroupFilterItemResult
							key={2}
							list={vMarca}
							title="Marcas"
							register={register}
							handleClickUpdateResult={checkUpdateResult}
							setValue={setValue}
						/>

						{
							vModelo?.length>0 && (
								<GroupModelByBrand
									listModels={vModelo}
									handleClickUpdateResult={checkUpdateResult}
									register={register}
								/>
							)
						}

						<GroupSelect
							title="Año"
							data={defaultValues.vTypeYear}
							register={register}
							list={listYear}
							titleLabelMin="Año Min"
							titleLabelMax="Año Max"
							titleSelect="Seleciona año"
							idMin="selectYearMin" 
							idMax="selectYearMax"  
							control={control}
							name="vTypeYear"
							setValue={setValue}
							handleClickUpdateResult={checkUpdateResult}
						/>

						<GroupSelect
							title="Kilometraje"
							data={defaultValues.vTypeKm}
							register={register}
							list={listKm}
							titleLabelMin="Km Min"
							titleLabelMax="Km Max"
							titleSelect="Seleciona km"
							idMin="selectKmMin" 
							idMax="selectKmMax" 
							control={control}
							name="vTypeKm"
							setValue={setValue}
							handleClickUpdateResult={checkUpdateResult}
						/>

						<GroupFilterItemResult
							key={3}
							list={vTransmision}
							title="Transmisión"
							register={register}
							handleClickUpdateResult={checkUpdateResult}
							setValue={setValue}
						/>

						<GroupFilterItemResult
							key={4}
							list={vCombustible}
							title="Combustible"
							register={register}
							handleClickUpdateResult={checkUpdateResult}
							setValue={setValue}
						/>
						
						{
							!hiddenOrigin && (
								<GroupFilterItemResult
									key={5}
									list={vOrigin}
									title="Procedencia"
									register={register}
									handleClickUpdateResult={checkUpdateResult}
									setValue={setValue}
								/>
							)
						}						

						<GroupFilterItemResult
							key={6}
							list={vPerformance}
							title="Rendimiento Km/L"
							register={register}
							handleClickUpdateResult={checkUpdateResult}
							setValue={setValue}
						/>

						<GroupFilterItemResult
							key={7}
							list={vSeatMove}
							title="Corridas de asientos"
							register={register}
							handleClickUpdateResult={checkUpdateResult}
							setValue={setValue}
						/>

						<GroupFilterItemResult
							key={8}
							list={vTraction}
							title="Tracción"
							register={register}
							handleClickUpdateResult={checkUpdateResult}
							setValue={setValue}
						/>	
					</section>
				</form>	
			</div>
			<div className="filter__footer mb-5">
				{ advertisingResult?.length>0 && (
					<BannerUpRight 
						imagen={advertisingResult[3]}
						functionModal={actionAdvertising}
						title="Banner" 
						//classBanner="banner-filter"
					/>
				)}
			</div>
		</div>
	)
}

export default PanelParamsLeft