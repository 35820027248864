import FormContact from "components/QuoteCar/Sire/Form/FormContact"
import FormOwner from "components/QuoteCar/Sire/Form/FormOwner"
import FormVehicle from "components/QuoteCar/Sire/Form/FormVehicle"
import FormResult from "components/QuoteCar/Sire/Form/FormResult"


const FormMainNotPatente = ({ nextFormStep, nextFormNamePrev, backHome, closeModal }) => {

    return (
        <>
            {nextFormStep==="form_initial" && <FormVehicle nextFormNamePrev={backHome} /> }
            {nextFormStep==="form_owner" && <FormOwner nextFormNamePrev={nextFormNamePrev} /> }
            {nextFormStep==="form_contact" && <FormContact nextFormNamePrev={nextFormNamePrev} isQr={false} /> }
            {nextFormStep==="form_end" && <FormResult 
            									nextFormNamePrev={nextFormNamePrev} 
            									closeModal={closeModal} 
            								/> 
            }
        </>
    )
}

export default FormMainNotPatente