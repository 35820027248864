import DeleteRoute from 'components/Customs/MyRoute/DeleteRoute'
import { Link } from 'react-router-dom'

const DetailCar = ({ infoBrand, type, name, id, photos, listPrice, listPriceView, priceLockDown, handleSelected, keyCar }) => {

	return (
		<div className={`card card__container mb-3`}>
			<div className={`card__body flex-row`}>
				<div className="route-selected">
					<div className="form-group form-filter-checkbox">
						<input
							name="name-car"
							defaultValue={name}
							type="hidden"
						/>
						<input 
							className="form__filter-check form__filter-route"
							type="checkbox" 
							name="id-key"
							id="id-key"
							value={keyCar} 
							onChange={(event) => handleSelected(event)}
						/>
					</div>
				</div>
				<section className="car-selected__container">
					<div className="car-selected__image">
						<img src={photos[0]} alt="Car" />
					</div>
					<div className="car-selected__details">
						<Link to={`/concesionario/${infoBrand?.slug}`}>
							<div className="car-selected__images">
								<img src={infoBrand?.icon} alt="Marca" className="car-selected__marca" />
							</div>
						</Link>
						<p className="info-car__description--type mt-3 mb-1">{type}</p>
						<p className="car-selected__title">{name}</p>
						<p className="car-selected__price">
							<span>{priceLockDown}</span>
							{(type==="new" && typeof listPrice!=='undefined') && <span>{listPriceView}</span>}
						</p>
					</div>
					<DeleteRoute id={id} />
				</section>
			</div>
		</div>
	)
}

export default DetailCar