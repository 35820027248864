import API from 'services/context/api';
import {
    SAVE_TOKEN_COMPARA,
	CHANGE_VISIBILITY_FORM_PATENTE,
    NEXT_FORM_PREV,
    LIST_INFO_COMPARA,
    LIST_MODELS_COMPARA,
    RESET_FORM_SIRE,
    SAVE_FORM_DATA_VEHICLE,    
    RESULT_SEND_FORM_COMPARA,
    INTERESTED_COMPARA,
    CHANGE_STATUS_MODAL_COMPARA,
    SAVE_FORM_DATA_PATENTE,
    SAVE_FORM_DATA_PATENTE_EDIT,
    SAVE_FORM_DATA_GLOBAL
} from 'services/types/quoteType';
import {  formatMonto } from 'utils/tools';
import endPoints from 'services/routes/';
import { TOKEN_COMPARA, TOKEN_SITE_WEB } from "data/variableKey";


const userCompara = process.env.REACT_APP_API_COMPARA_USER;
const passwordCompara = process.env.REACT_APP_API_COMPARA_PASSWORD;
const rutMovicenter = process.env.REACT_APP_RUT_MOVICENTER;
const razonSocialMovicenter = process.env.REACT_APP_RAZON_SOCIAL_MOVICENTER;


export const requestSuccess = (type, payload) => {
    return { type, payload };
};

//Login de la api de compara
export const loginApiQuote = () => async (dispatch, getState) => {
	try{

        const body = {
            username: userCompara,
            password: passwordCompara
        };
        let payload = { accessToken: "" };
        let status = false, message = "Error en la conexión de cotiza tu seguro";

        let response = await API.postApi({
                                endPoint: endPoints.compara.login, 
                                data: body,
                                nameAPI: "", 
                                isToken: false,
                                isFormData: false
                            });
        
        if(Object.keys(response.data ).length>3){
            payload = { accessToken: response.data?.accessToken };
            status =  true;
            message = "";

            localStorage.setItem('comparaMov', response.data?.accessToken ?? "");
        }
      
        dispatch(requestSuccess(SAVE_TOKEN_COMPARA, payload));

        return { status, message };

    }catch(error){
      	return { status: false, message: "Error en la conexión de cotiza tu seguro" };
    }
};

//Mostrar y ocultar formulario: Con patente(withPatent) y sin patente (noPatent)
export const showAndHiddenFormPatente = (nameForm) => (dispatch, getState) => {
    
	const payload = {
        nameFormPatent: nameForm
    };
    dispatch(requestSuccess(CHANGE_VISIBILITY_FORM_PATENTE, payload));
};

//Visualizar el siguiente formulario "form_initial", "form_owner", "form_contact"
export const nextPrevForm = (key) => (dispatch, getState) => {
	let form = ["form_initial", "form_owner", "form_contact", "form_end"];
    const payload = { nextForm: form[key]}; 
    dispatch(requestSuccess(NEXT_FORM_PREV, payload));
};

//Resetar el formulario
export const resetFormData = () => (dispatch, getState) => {
	const dataFormQuote = { 
		brand: "",
		model: "",
		year: "",
        rut: "",
        name: "",
        lastname: "",
        dateOfBirth: "",
        comuna: "",
        telephone: "",
        email: "",
        question: "1"
	};
    const payload = { dataFormQuote }; 
    dispatch(requestSuccess(RESET_FORM_SIRE, payload));
};

//Obtener las marcas, años y comunas
export const getBrandsYearComuna = () => async (dispatch, getState) => {
	try{   

        //const { tokenCompara } = getState().quotes_car;
        const { infoCar } = getState().cars;
 
        let payload = { brands: [], isProcess: false, years: [], comunas: [] };

        dispatch(requestSuccess(LIST_INFO_COMPARA, payload));  

        let status = true, message = "";
        let listBrands = [], listYears = [], listComunas = [], listModels = [];
        let brandSelected = { key: "", label: ""};

        //Api para obtener las marcas
        let responseBrands = await API.getApi({
                                    endPoint: endPoints.compara.getBrand, 
                                    nameAPI: TOKEN_COMPARA, 
                                    isToken: true,
                                    isFormData: false
                                });    

        if(responseBrands.data.length>0){
            listBrands = responseBrands.data.filter(item => item.attributes.active===true)
                            .map(item => { return { key: item.id, label: item.attributes.name }});
                            
            listBrands.sort((a, b) => {
                if(b.label < a.label) return 1;
                if(b.label > a.label) return -1;

                return 0;
            });

             if(typeof infoCar?.brand!=="undefined" && infoCar?.brand!==""){
                brandSelected = listBrands.find(item => item.label.toLowerCase()===infoCar?.brand?.toLowerCase());
             }            
        }

        //Api para obtener los modelos
        if(brandSelected.key!==""){
            let responseModel = await API.getApi({
                                            endPoint: endPoints.compara.getModel(brandSelected.key), 
                                            nameAPI: TOKEN_COMPARA, 
                                            isToken: true,
                                            isFormData: false
                                        });
            if(responseModel.data?.length>0){
                listModels = responseModel.data.filter(item => item.attributes.active===true)
                            .map(item => { return { key: item.id, label: item.attributes.name }});
                
                listModels.sort((a, b) => {
                    if(b.label < a.label) return 1;
                    if(b.label > a.label) return -1;
    
                    return 0;
                });
            }
        }

        //Api para obtener los años
        let responseYear = await API.getApi({
                                        endPoint: endPoints.compara.getYear, 
                                        nameAPI: TOKEN_COMPARA, 
                                        isToken: true,
                                        isFormData: false
                                    });  

        if(responseYear.data?.years.length>0){
            listYears = responseYear.data.years.map(item => { return { key: item, label: item }});
        }

        //Api para obtener los años
        let responseComuna = await API.getApi({
                                        endPoint: endPoints.compara.getComuna, 
                                        nameAPI: TOKEN_COMPARA, 
                                        isToken: true,
                                        isFormData: false
                                    });  
        if(responseComuna.data.length>0){
            listComunas = responseComuna.data.map(item => { return { key: item.id, label: item.attributes.name }});
            listComunas.sort((a, b) => {
                if(b.label < a.label) return 1;
                if(b.label > a.label) return -1;

                return 0;
            });
        }

        payload = { brands: listBrands, isProcess: false, years: listYears, comunas: listComunas, models: listModels  };

        dispatch(requestSuccess(LIST_INFO_COMPARA, payload));     

        return { status, message };

    }catch(error){
      	return { status: false, message: "Error en la conexión de cotiza tu seguro" };
    }
};

//Obtener los modelos
export const getModelByIdCompara = (brandId) => async (dispatch, getState) => {
	try{   

        //const { tokenCompara } = getState().quotes_car;

        let status = true, message = "";
        let listModels = [];

        //Api para obtener los modelos
        let response = await API.getApi({
                                    endPoint: endPoints.compara.getModel(brandId), 
                                    nameAPI: TOKEN_COMPARA, 
                                    isToken: true,
                                    isFormData: false
                                })    
        if(response.data.length>0){
            listModels = response.data.filter(item => item.attributes.active===true)
                            .map(item => { return { key: item.id, label: item.attributes.name }})
            listModels.sort((a, b) => {
                if(b.label < a.label) return 1;
                if(b.label > a.label) return -1;

                return 0;
            });
        }       

        const payload = { models: listModels  };

        dispatch(requestSuccess(LIST_MODELS_COMPARA, payload));     

        return { status, message };

    }catch(error){
      	return { status: false, message: "Error en la conexión de cotiza tu seguro" };
    }
};

//Guardar los datos del vehículos
export const saveFormDataVehicle = (body) => async (dispatch, getState) => {
	try{
        const { dataFormQuote } = getState().quotes_car;

        let dataForm = { ...dataFormQuote, ...body };
        let nextFormStep = "form_owner";

        const payload = { dataForm, nextFormStep };
        dispatch(requestSuccess(SAVE_FORM_DATA_VEHICLE, payload));

        return { status: true, next: true };

    }catch(error){
      	return { status: false, message: error };
    }
};

//Guardar los datos del formulario
export const saveFormDataGlobal = (body) => async (dispatch, getState) => {
    try{
        const { dataFormQuote } = getState().quotes_car;
        
        let dataForm = { ...dataFormQuote, ...body };
       
        const payload = dataForm;

        dispatch(requestSuccess(SAVE_FORM_DATA_GLOBAL, payload));

        return { status: true, message: "" };

    }catch(error){
        return { status: false, message: error };
    }
};

//Guardar los datos del propietario
export const saveFormDataOwner = (body) => async (dispatch, getState) => {
	try{
        const { dataFormQuote } = getState().quotes_car;
        
        let dataForm = { ...dataFormQuote, ...body };
        let nextFormStep = "form_contact";

        const payload = { dataForm, nextFormStep };

        dispatch(requestSuccess(SAVE_FORM_DATA_VEHICLE, payload));

        return { status: true, next: true };

    }catch(error){
      	return { status: false, message: error };
    }
};

//Envio de formulario desde contacto compara
export const saveFormDataContact = (body) => async (dispatch, getState) => {
	try{
        const { dataFormQuote, listInfoCompara, tokenCompara } = getState().quotes_car;
        const { banner_id } = getState().advertisings;

        let dataForm = { ...dataFormQuote, ...body };
        let status = false, message="Error al realizar la cotización";

        let brandInfo = listInfoCompara?.brands.find(item => item.key===dataForm.brand);
        let modelInfo = listInfoCompara?.models.find(item => item.key===dataForm.model);
        let comunaInfo = listInfoCompara?.comunas.find(item => item.key===dataForm.comuna);
        
        const data = {
            parner: "parner_compara",
            data: {
                modelId: dataForm.model,
                brandId: dataForm.brand,
                year: dataForm.year,
                carOwner: 1,
                nationalId: dataForm.rut,
                firstName: dataForm.name,
                lastName: dataForm.lastname,
                birthdate: dataForm.dateOfBirth,
                countyId: dataForm.comuna,
                phone: dataForm?.telephone,
                email: dataForm.email,
                carDealerData: {
                    name: razonSocialMovicenter,
                    agentIdentificationNumber: rutMovicenter
                },
                car: {
                    brandName: brandInfo?.label,
                    modelName: modelInfo?.label,
                    modelYear: dataForm.year
                },
                contactType: dataForm.question,
                carUseType: dataForm.vehicle_use_type
            },
            is_qr: body.isQr,
            banner_id,
            comuna_name: comunaInfo?.label,
            token: tokenCompara,
        };

        const response = await API.postApi({
                                        endPoint: endPoints.compara.registerLead, 
                                        data,
                                        nameAPI: TOKEN_SITE_WEB, 
                                        isToken: true,
                                        isFormData: false
                                    });

        if(response.data.status===200){

            const listSireByCompara = response.data.data.offers.map(item => {
                const optionsList = item?.product?.productAttributes
                                .filter(prod=> prod.enabled===true)
                                .filter(prod => prod.entityCode==='total-loss-cl' || prod.entityCode==='civil-responsability'
                                                || prod.entityCode==='auto-replacement' || prod.entityCode==='brand-workshop')
                                .map(prod => `${prod.entityLabel}: ${prod.value}`);
                let priceFormat = {};
                if(item.type==="seguro-prima-fija"){
                    priceFormat = {
                        price: `$${formatMonto(Math.round(item?.monthlyPriceCLP))}`,
                        title: item?.product?.mainAttribute?.entity?.label,
                        priceCLP: item?.monthlyPriceCLP
                    };
                }else if(item.type==="seguro-km"){
                    priceFormat = {
                        price: `$${formatMonto(Math.round(item?.monthlyPriceFixedPerKmCLP))} + $${formatMonto(Math.round(item?.monthlyPricePerKmCLP))}`,
                        title: "Precio Fijo + Precio KM",
                        priceCLP: item?.monthlyPriceFixedPerKmCLP
                    };
                }

                return {
                    name: item?.product?.name,
                    deductible: item?.deductible,
                    priceFormat,
                    picture: item?.product.division.logo,
                    title: item?.product.division.name,
                    options: optionsList,
                    interested: false,
                    offerId: item?.offerId,
                    type_insurance: item.type,
                    quoteId: response.data.data?.quoteId
                };
            })

            
            listSireByCompara.sort((a, b) => {
            	return a.priceFormat.priceCLP - b.priceFormat.priceCLP;
            });

            const payload = {
                nextFormStep: "form_end",
                listSireByCompara,
                titleResult: {
                    primary: `${brandInfo?.label} ${modelInfo?.label}`,
                    secondary: `Año ${dataForm.year}`,
                    email: dataForm.email
                }
            };
            
            dispatch(requestSuccess(RESULT_SEND_FORM_COMPARA, payload));
            status=true;
            message="";
        }

        return { status, message };

    }catch(error){
      	return { status: false, message: error };
    }
};

export const saveFormDataPatente= (body) => async (dispatch, getState) => {
	try{
        const { dataFormQuote } = getState().quotes_car;

        let dataForm = { ...dataFormQuote, ...body };

        const payload = { dataForm, isResultPatente: true, isInfoPatente: false  };
        dispatch(requestSuccess(SAVE_FORM_DATA_PATENTE, payload));

        return { status: true, next: true };

    }catch(error){
      	return { status: false, message: error };
    }
};

export const editFormDataPatente= (value) => async (dispatch, getState) => {
	try{

        const payload = { isInfoPatente: value  };
        dispatch(requestSuccess(SAVE_FORM_DATA_PATENTE_EDIT, payload));

        return { status: true, next: true };

    }catch(error){
      	return { status: false, message: error };
    }
};

//Me interesa
export const getInsuranceInterested = (body) => async (dispatch, getState) => {
    try{   
         const { listSireByCompara } = getState().quotes_car;
        let status = false, message = "";

        const listInsurances = listSireByCompara;

        let response = await API.postApi({
                                    endPoint: endPoints.compara.interested, 
                                    data: body,
                                    nameAPI: TOKEN_SITE_WEB, 
                                    isToken: true,
                                    isFormData: false
                                });   

        if(response.data.status===200){

	        const bodyInterested = {
	        	offerId: body?.insurance?.offerId,
	        	quoteId: body?.insurance?.quoteId
	        };
	        
            await API.post({
                            endPoint: endPoints.compara.offersSelected, 
                            data: bodyInterested,
                            nameAPI: TOKEN_COMPARA, 
                            isToken: true,
                            isFormData: false
                        });
	        
            const insuranceSelected = listInsurances.findIndex(item => item.offerId===body?.insurance?.offerId);
            if(insuranceSelected!==-1){
                listInsurances[insuranceSelected].interested = true;
            }

            const payload = { listInsurances  };
            dispatch(requestSuccess(INTERESTED_COMPARA, payload));
            status = true;
        }
             
        return { status, message };

    }catch(error){
        return { status: false, message: "Error en la conexión de cotiza tu seguro" };
    }
};

export const openCloseModalCompara = () => (dispatch, getState) => {
    try{
        
        const { isModalCompara } = getState().quotes_car;

        const payload = { isModalCompara: !isModalCompara };

        dispatch(requestSuccess(CHANGE_STATUS_MODAL_COMPARA, payload));
        
    }catch(error){
      return {status: false, message:error};
    }
};