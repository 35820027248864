export const getListData = (list, data) => {
    const listAll = list.map(item => {
        return { key: item.key, label: item.label }
    })

    listAll.sort((a, b) => {
        if(b.label < a.label) return 1;
        if(b.label > a.label) return -1;

        return 0;
    })

    let selected = {}
    if(data!=="" && typeof data!=="undefined"){
        selected = listAll.find(item => item.label.toLowerCase().replaceAll(" ", "")===data.toLowerCase().replaceAll(" ", ""))
        if(!selected){
            selected = { key: "", label: ""}
        }
    }

    return { listAll, selected }
}