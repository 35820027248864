import ItemRoute from 'components/Customs/MyRoute/ItemRoute';
import { useShare } from "hooks/Share/useShare";

import { Overlay, Popover } from 'react-bootstrap'
import {
 FacebookShareButton,
 FacebookIcon,
 WhatsappShareButton,
 WhatsappIcon,
 TwitterIcon,
 TwitterShareButton,
 EmailIcon,
 EmailShareButton
} from "react-share";


const ShareNetworks = ({ id, isMyRouter, idKey, title, photo, type }) => {

	const { handleSharing, showPopoverDsk, refPopoverDsk, target, detailShare } = useShare()


	return (
		<div className="share-container">
			<div ref={refPopoverDsk}>
				<a 
					href="!#" 
					onClick={(event) => handleSharing(event, idKey, title, photo, type)} 
					className="share-text"
				>Compartir</a>
				{
					showPopoverDsk && (
						<Overlay
					        show={showPopoverDsk}
					        target={target}
					        placement="bottom"
					        container={refPopoverDsk.current}
					        containerPadding={20}
					      >
					        <Popover id="popover-contained">
					          <Popover.Content>
					            <section className="icon-social">
							       	 <FacebookShareButton
									        url={detailShare?.url}
									        quote={detailShare?.text}
									        className="mr-3"
									        title={detailShare?.title}
									        imageURL={photo}
									      >
									        <FacebookIcon size={32} round />
									      </FacebookShareButton>
									      <WhatsappShareButton
									      	title={detailShare?.title}
									      	separator={''}
									      	className="mr-3"
									      	url={detailShare?.url}
									      >
									      	<WhatsappIcon size={32} round />
									      </WhatsappShareButton>
									      <TwitterShareButton
									      	title={detailShare?.title}
									      	separator={''}
									      	className="mr-3"
									      	url={detailShare?.url}
									      >
									      	<TwitterIcon size={32} round />
									      </TwitterShareButton>
									      <EmailShareButton
									      	subject={detailShare?.title}
									      	url={detailShare?.url}
									      >
									      	<EmailIcon size={32} round />
									      </EmailShareButton>
							       </section>
					          </Popover.Content>
					        </Popover>
					    </Overlay>
					)
				}
				
			</div>
			<ItemRoute id={id} isMyRouter={isMyRouter} />
		</div>
	);
};

export default ShareNetworks;