import { useEffect } from 'react'
import Layout from 'components/Layout/Layout'
import Breadcrumb from 'components/Customs/Breadcrumb/index'
import CarouselCar from 'components/Compare/CarouselCar'
import { compareCarsInfo } from "data/DescripcionHead.json"
import { useSelector } from 'react-redux'
import useBreadcrumb from "hooks/Main/useBreadcrumb"


const Cars = () => {

	const { listCompareCars } = useSelector((state) => state.cars)
	const { getInfoBreadcrumb, itemsBreadcrumb , pageLast} = useBreadcrumb()

	//Funcionalidad del menú miga de pan
	useEffect(() => {
		getInfoBreadcrumb("Comparar")	
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Layout
			classHeader="row header__white"
			hiddenColor={true}
			isVisibleFooter={true}
			visibleLine={true}
			infoMetaHead={compareCarsInfo}
			classDefaultHeader="row header-top header__white"
			classTopHeader=""
		>
			<Breadcrumb 
					itemBreadcrumb={itemsBreadcrumb}
					isVisible={true}
					urlReturn={pageLast}
				/>

			<div className="container compare mb-5">
				<div className="row">
					<div className="col-12">
						<div className="compare-title">
							<h1>Compara tus autos</h1>
						</div>
						<p className="compare-subtitle">{`Resultado ${listCompareCars.length} autos`}</p>
					</div>
					<div className="col-12">
						<div className="compare__container">
							<section>
								<div className="compare-temp">
									<p className="compare-temp__title">Modelo</p>
									<span className="compare-temp__fast"></span>
								</div>
								<div className="compare__info active">
									<p>Precio</p>
								</div>
								<div className="compare__info">
									<p>Estado</p>
								</div>
								<div className="compare__info active">
									<p>Carroceria</p>
								</div>
								<div className="compare__info">
									<p>Kilometraje</p>
								</div>
								<div className="compare__info active">
									<p>Año</p>
								</div>
								<div className="compare__info">
									<p>Transmisión</p>
								</div>
								<div className="compare__info active">
									<p>Combustible</p>
								</div>
								<div className="compare__info">
									<p>Tracción</p>
								</div>
								<div className="compare__info active">
									<p>Corridas asiento</p>
								</div>
								<div className="compare__info">
									<p>Consumo mixto</p>
								</div>
								<div className="compare__info active">
									<p>Procedencia</p>
								</div>
							</section>



							<section className="compare-result">
								<CarouselCar listado={listCompareCars} />
							</section>
						</div>
					</div>
				</div>
			</div>

			
			{/**<div className="container">
				<div className="row">
					<div className="col-12 compare-button">
	    				<button type="button" className="btn btn-primary btn-lg w-50">Enviar por correo</button>
	    				<button type="button" className="btn btn-primary btn-lg w-50">Descargar</button>
			    	</div>
				</div>
			</div>**/}
		</Layout>
	)
}

export default Cars