const DetailFeature = ({ title, value, isVisibleSubtitle}) => {

	return (
		<>
			{
				(title!=="") && (
					<div className="details-car__group">
			  			<p className={`details-car--title ${!isVisibleSubtitle ? 'w-100' : 'w-50'} `}>{title}</p>
			  			{isVisibleSubtitle  && <p className="details-car--title">{value}</p>}
			  		</div>
				)
			}	
		</>	
	)
}

export default DetailFeature