import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { saveFormDataVehicle, getModelByIdCompara } from "services/actions/quoteAction"
import { useDispatch, useSelector } from 'react-redux'

export const useDataVehicle = () => {

	const dispatch = useDispatch()
	const { quotes_car, cars } = useSelector((state) => {
		return {
			quotes_car: state.quotes_car,
			cars: state.cars
		}
	})

	const { dataFormQuote, listInfoCompara } = quotes_car
	const { infoCar } = cars
	
	const [ isProcess, setIsProcess ] = useState(false)

	const { register, control, handleSubmit, setValue, errors, reset } = useForm({
		defaultValues: {
			brand: dataFormQuote?.brand,
			year: dataFormQuote?.year,
			model: dataFormQuote?.model
		}
	})

	const onSubmit = async (data) => {
		dispatch(saveFormDataVehicle(data))  
		window.scrollTo(0, 0)      
	}


	const changeBrands = async (brandId) => {
		setIsProcess(true)
		setValue("brand", brandId)
		await dispatch(getModelByIdCompara(brandId))
		setIsProcess(false)
	}


	useEffect(()=> {
		if(listInfoCompara?.brands.length>0){
			if(infoCar?.brand!=="" && infoCar?.model!=="" && infoCar?.year!==""){
				let brandItem = listInfoCompara?.brands?.find(item => item.label.toLowerCase()===infoCar?.brand?.toLowerCase())
				let yearItem = listInfoCompara?.years?.find(item => item.label.toString()===infoCar?.year?.toString())
				let modelItem = listInfoCompara?.models?.find(item => item.label.toString()===infoCar?.model?.toString())

				reset({
					brand: brandItem?.key,
					year: yearItem?.key,
					model: modelItem?.key
				})
				//setValue("brand", brandItem?.key)
			}	
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listInfoCompara?.brands.length])

	
	

	return {
		register,
		onSubmit,
		handleSubmit,
        control,
		listInfoCompara,
		errors,
		changeBrands,
		isProcess,
		dataFormQuote
	}
}