import { useEffect } from "react"
import Layout from 'components/Layout/Layout'
import MenuSort from 'components/Result/MenuSort'
import FilterSelectedNormalMobile from 'components/Result/Filter/Mobile/FilterSelectedNormalMobile'
import Breadcrumb from 'components/Customs/Breadcrumb/index'
import useBreadcrumb from "hooks/Main/useBreadcrumb"
import MediaQuery from 'react-responsive'

const BaseLayoutSearch = ({ descriptionMeta, title, closeOpenMenuFilter, closeOpenMenuSort, setMenuSort, 
	menuFilter, initialDataNormal, setMenuFilter, itemOfSearch, menuSort, children }) => {

    const { getInfoBreadcrumb, itemsBreadcrumb , pageLast } = useBreadcrumb()
    

    //Funcionalidad del menú miga de pan
	useEffect(() => {
		if(title!=="undefined" && title!==""){
			getInfoBreadcrumb(title)
		}		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [title])

	return (
		<Layout
            classHeader="row header__white"
            hiddenColor={true}
            isVisibleFooter={true}
            visibleLine={true}
            infoMetaHead={descriptionMeta}
            classDefaultHeader="row header-top header__white"
            classTopHeader=""
		>
			<div className="row options-search m-6">
				<div className="col-12">
					<div className="options-search__container">
						<p 
							className="options-search__type options-search--filter" 
							onClick={closeOpenMenuFilter}
						>Filtro</p>
						<p 
							className="options-search__type options-search--order" 
							onClick={closeOpenMenuSort}
						>Ordenar</p>
					</div>
			
					{ menuSort && <MenuSort 
						closeMenuSort={closeOpenMenuSort} 
						setMenuSort={setMenuSort} /> 
					}
					<MediaQuery maxWidth={768}>
						<FilterSelectedNormalMobile
							menuFilter={menuFilter} 
							initialDataNormal={initialDataNormal} 
							setMenuFilter={setMenuFilter}
							itemOfSearch={itemOfSearch}
						/>
					</MediaQuery>
				</div>
			</div>

			<Breadcrumb 
				itemBreadcrumb={itemsBreadcrumb}
				isVisible={true}
				urlReturn={pageLast}
                classCol="col-result-crumb"
			/>
           


			{children}

		</Layout>
	)
}

export default BaseLayoutSearch