import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import { sendFormNotification, sendFormNotificationScheduledSearch } from 'services/actions/notificationAction';
import { useGoogleRecaptcha } from 'hooks/Contact/useGoogleRecaptcha';

export const useFormContactGlobal = () => {

	const dispatch = useDispatch()	
	const history = useHistory()
	const location = useLocation()
	const { getToken } = useGoogleRecaptcha();

	const { register, handleSubmit, errors, reset } = useForm();
	const [ disabledBtn, setDisabledBtn ] = useState(false);
	const [ isOpen, setIsOpen ] = useState(false);
	const [ isLoading, setIsLoading ] = useState(false);
	const [ message, setMessage ] = useState({
		error: false, 
		title: '¡Ups!', 
		subtitle: 'Ha ocurrido error en el envío del formulario. Por favor intenta nuevamente', 
		btnTitle: 'Reintentar',
		success: false
	})



	const closeModal = () => setIsOpen(false)

	const handleActive = (event) => {
		setDisabledBtn(!event.target.checked)
	};

	const formatItem = (item) => {
		let data = item
		if(data.type==="Moto"){
			data.listFeatures = data.listFeatures[0]?.listado
		}

		return data
	}

	const onSubmit =  async (data, infoSendItem) => {

		try{
			setIsLoading(true)
			setMessage({error: false, title: '', subtitle: '', btnTitle: '', success: false})
			setDisabledBtn(true)
			/**if(infoSendItem?.type==="Nuevo" || infoSendItem?.type==="Usado"){
				if(infoSendItem?.status_id!==1){
					setMessage({
						error: true, 
						title: '¡Ups!', 
						subtitle: 'El Auto no se encuentra disponible, contacta a un vendedor para mayor información',
						btnTitle: 'Cerrar',
						success: false
					})
					setIsLoading(false)
					setIsOpen(true)
	
					return false;
				}
			}**/

			const tokenCaptcha = await getToken();

			let response = { status: false}
	
			if((infoSendItem?.type==="Nuevo" || infoSendItem?.type==="Usado") && infoSendItem?.stock===false){
			
				response = await dispatch(sendFormNotificationScheduledSearch(data, location.pathname))
	
			}else{
				let listItem = formatItem(JSON.parse(JSON.stringify(infoSendItem)))
	
				const dataForm = {
					items: [ listItem ],
					name: data.name,
					telephone: "+569"+data.telephone,
					email: data.email,
					message: data.message,
					token: tokenCaptcha
				}
	
				response = await dispatch(sendFormNotification(dataForm, location.pathname))
			}
					

			if(response.status){

				reset({
					name: '',
					email: '',
					telephone: '',
					message: ''
				})
				setIsLoading(false)
				setDisabledBtn(false)
				history.push('/success')
			}else{
				setMessage({
					error: true, 
					title: '¡Ups!', 
					subtitle: 'Ha ocurrido un error en el envío del formulario. Por favor intenta nuevamente',
					btnTitle: 'Reintentar',
					success: false
				})
				setIsLoading(false)
				setIsOpen(true)
				setDisabledBtn(false)
			}	
		}catch(error){
			setMessage({
				error: true, 
				title: '¡Ups!', 
				subtitle: 'Ha ocurrido un error en el envío del formulario. Por favor recargue la página e intenta nuevamente',
				btnTitle: 'Reintentar',
				success: false
			})
			setIsLoading(false)
			setIsOpen(true)
			setDisabledBtn(false)
		}
			
	}

	return {
		handleActive,
		disabledBtn,
		register, 
		handleSubmit, 
		onSubmit,
		message,
		isOpen, 
		closeModal,
		isLoading,
		errors
	}
}