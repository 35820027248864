import axios from 'axios'

import {
	TOKEN_MAINTANER,
	APP_TOKEN_FORM,
	TOKEN_PROVIDER,
	TOKEN_COMPARA,
	TOKEN_SITE_WEB
} from "data/variableKey";


const axiosInstance = axios.create({
	validateStatus() {
		return true
	},
	withCredentials: false
})

const getToken = (nameAPI) => {
	let token =  null;

	if (typeof window !== 'undefined') {
		try {
			if(nameAPI===TOKEN_MAINTANER){
				token = localStorage.getItem("userMovAdmin") ? localStorage.getItem("userMovAdmin") : null;
			}else if(nameAPI===TOKEN_PROVIDER){
				token = APP_TOKEN_FORM
			}else if(nameAPI===TOKEN_COMPARA){
				token = localStorage.getItem("comparaMov") ? localStorage.getItem("comparaMov") : null;
			}else if(nameAPI===TOKEN_SITE_WEB){
				token = localStorage.getItem("userMovSite") ? localStorage.getItem("userMovSite") : null;
			}		
		} catch (error) {
			token =  null;
		}
	}
	return token;
};


const getHeaders = ({ nameAPI, isToken, isFormData }) => {

	let headers = { 
		"Content-type": !isFormData ? "application/json" : "application/x-www-form-urlencoded" 
	};

	if(nameAPI===TOKEN_MAINTANER || nameAPI===TOKEN_SITE_WEB){
		headers = { 
			...headers,
			"secret": "emptytoken"
		};
	}

	if(isToken){
		headers = { 
			...headers, 
			"Authorization":  `Bearer ${getToken(nameAPI)}` 
		};
	}

    return { headers }
};

const API = {
	getApi: (params) => {
		return axiosInstance.get(params.endPoint, getHeaders(params));
	},
	postApi: (params) => {
		return axiosInstance.post(params.endPoint, params.data, getHeaders(params));
	},
	putApi: (params) => {
	    return axiosInstance.put(params.endPoint, params.data, getHeaders(params))
	},
	postFiles: (params) => {
		return axiosInstance.post(params.endPoint, params.data, getHeaders(params))
	},
}

export default API