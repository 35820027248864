import { useEffect } from 'react'
import { showAndHiddenFormPatente, nextPrevForm, resetFormData, 
	loginApiQuote, getBrandsYearComuna, openCloseModalCompara } from "services/actions/quoteAction"
import { useDispatch, useSelector } from 'react-redux'

export const useSire = (initial) => {

	const dispatch = useDispatch()
	
	const { nameFormPatent, nextFormStep, listInfoCompara } = useSelector((state) => state.quotes_car)


	const getInitialLogin = async () => {
		if(initial){
			await dispatch(loginApiQuote())
			if(listInfoCompara?.brands.length===0 && listInfoCompara?.years.length===0 && listInfoCompara?.comunas.length===0){
				await dispatch(getBrandsYearComuna())
			}
			closeModalSire()
		}		
	}

	const closeModalSire = () => {
		//Mostrar y ocultar formulario: Con patente(withPatent) y sin patente (noPatent)
		dispatch(showAndHiddenFormPatente("noPatent"))
		//Visualizar el siguiente formulario "form_initial", "form_owner", "form_contact"
		dispatch(nextPrevForm(0))
		//Resetar el formulario
		dispatch(resetFormData())
	}

	const visibleFormPatent = (nameForm) => {
		//Mostrar y ocultar formulario: Con patente(withPatent) y sin patente (noPatent)
		dispatch(showAndHiddenFormPatente(nameForm))
	}

	const nextFormNamePrev = (key) => {
		//Visualizar el siguiente formulario "form_initial", "form_owner", "form_contact"
		dispatch(nextPrevForm(key))
	}	

	const backHome = () => {
		//Mostrar y ocultar formulario: Con patente(withPatent) y sin patente (noPatent)
		dispatch(showAndHiddenFormPatente(""))
		//Visualizar el siguiente formulario "form_initial", "form_owner", "form_contact"
		dispatch(nextPrevForm(0))
		//Resetar el formulario
		dispatch(resetFormData())
	}	

	

	
	const closeModalCompara = () => {
		dispatch(openCloseModalCompara())
		closeModalSire()
	}

	useEffect(()=>{
		getInitialLogin()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	

	return {
		visibleFormPatent,
		nameFormPatent,
		nextFormStep,
		nextFormNamePrev,
		backHome,
		closeModalCompara
	}
}