import { 
	INITIAL_DATA,
	UPDATE_DATA_NORMAL,
	UPDATE_ITEMS_SEARCH,
	UPDATE_SEARCH_NORMAL,
	SELECTED_TAB_SEARCH,
	UPDATE_SELECTED_INTEREST,
	SAVE_INSTEREST_FORM,
	SAVE_INSTEREST,
	VERIFY_SELECTED_INTEREST,
	UPDATE_ITEMS_INTEREST_VALUE,
	UPDATE_SEARCH_INTEREST,
	UPDATE_BRAND_MODEL_SEARCH,
	SAVE_PREFERENCES_INTEREST,
	UPDATE_FILTER_NORMAL,
	REFRESH_DATA,
	INITIAL_HOME,
	UPDATED_BY_PARAMS_URL,
	UPDATE_PARAMS_NOT_FOUND,
	UPDATE_PAGE_LAST,
	INITIAL_PANEL,
	INITIAL_BRAND_SEARCH,
	UPDATE_BRAND_NORMAL_SEARCH,
	UPDATE_SELECTED_BRAND_COMBO,
	DELETE_BRAND_SELECTED_COMBO,
	DELETE_MODEL_SELECTED_COMBO,
	LIST_SELECTED_MODEL_COMBO,
	UPDATE_DATA_NORMAL_ITEM
} from 'services/types/searchType'
import {busquedaInicial} from "data/busquedaInicial"

const { valueNormal, years, kms, valueInterest } = busquedaInicial()

const INITIAL_STATE = {
	initialDataNormal: valueNormal,
	initialNormalDefault: valueNormal,
	updateDataNormal: valueNormal,
	initialDataInterest: valueInterest,
	initialInterestDefault: valueInterest,
	updateDataInterest: {},
	listYear: years,
	listKm: kms,
	listInterestNew: [],
	listInterestUsed: [],
	listInterest: {},
	allInterest: [],
	selectedTab: 'search-normal',
	itemOfSearch: [],
	itemsForm: [],
	typeHome: "home",
	allBodyWorkUsed: [],
	allBodyWorkNew: [],
	visibleModal: false,
	typeHomeInitial: JSON.parse(localStorage.getItem("home-initial")) || "home",
	titleHeader: "Resultados de tu búsqueda",
	searchParamsView: false,
	urlLastPage: "/",
	isPanelSearch: true,
	itemSelectedBrandANDModel: { brands: [], models: []},
	isButtonClearAll: true,
	hiddenOrigin: false
}

const searchReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case INITIAL_DATA:
			return {
				...state,
				initialDataNormal: action.payload.normal,
				initialNormalDefault: action.payload.normalDefault,
				updateDataNormal: action.payload.normal,
				initialDataInterest: action.payload.insterest,
				initialInterestDefault: action.payload.insterest,
				updateDataInterest: action.payload.insterest,
				listInterestNew: action.payload.listInterestNew,
				listInterestUsed: action.payload.listInterestUsed,
				allInterest: action.payload.listInterestAll,
				typeHome: action.payload.typeHome,
				selectedTab: action.payload.selectedTab,
				allBodyWorkNew: action.payload.allBodyWorkNew,
				allBodyWorkUsed: action.payload.allBodyWorkUsed,
				itemOfSearch: action.payload.itemOfSearch,
				itemsForm: action.payload.itemsForm,
				itemSelectedBrandANDModel: action.payload.itemSelectedBrandANDModel
			}
		case UPDATE_DATA_NORMAL:
			return {
				...state,
				initialDataNormal: action.payload.values,
				updateDataNormal:  action.payload.values,
				itemOfSearch: action.payload.items,
				itemsForm: action.payload.itemsForm,
				initialNormalDefault: action.payload.initialData,
				visibleModal: action.payload.visibleModal,
				titleHeader: action.payload.titleHeader,
				searchParamsView: action.payload.searchParamsView,
				isButtonClearAll: action.payload.isButtonClearAll,
				hiddenOrigin: action.payload.hiddenOrigin
			}
		case SELECTED_TAB_SEARCH:
			return {
				...state,
				selectedTab: action.payload.tabSelected,
				initialDataNormal: action.payload.dataNormal,
				updateDataNormal: action.payload.dataNormal,
				itemOfSearch: action.payload.itemOfSearch,
				itemsForm: action.payload.itemsForm,
				allInterest: action.payload.allInterest,
				listInterest: action.payload.listInterestSelected,
				initialDataInterest: action.payload.dataInterest,
				updateDataInterest: action.payload.dataInterest
			}
		case UPDATE_SELECTED_INTEREST:
			return {
				...state,
				allInterest: action.payload.listadoUpdate,
				interestSelected: action.payload.interestSelected
			}
		case SAVE_INSTEREST_FORM:
			return {
				...state,
				initialDataInterest: action.payload.valueInterest,
				updateDataInterest: action.payload.valueInterest,
				itemsForm: action.payload.data,
				itemOfSearch: action.payload.items,
				visibleModal: action.payload.visibleModal,
				listInterest: action.payload.listInterest,
				titleHeader: action.payload.titleHeader
			}
		case SAVE_INSTEREST: 
			return {
				...state,
				listInterest: action.payload.listInterest,
				allInterest: action.payload.insterests,
				initialDataInterest: action.payload.dataInterest,
				updatedataInterest: action.payload.dataInterest
			}
		case VERIFY_SELECTED_INTEREST:
			return {
				...state,
				allInterest: action.payload.interests
			}
		case UPDATE_ITEMS_SEARCH:
			return {
				...state,
				itemOfSearch: action.payload.items,
				isButtonClearAll: action.payload.isButtonClearAll
				//initialDataNormal: action.payload.values
			}
		case UPDATE_SEARCH_NORMAL:
			return {
				...state,
				initialDataNormal: action.payload.values
			}
		case UPDATE_ITEMS_INTEREST_VALUE:
			return {
				...state,
				itemOfSearch: action.payload.itemsSelected,
				updateAllInterest: action.payload.values,
				updateInitialDataInterest: action.payload.updateDataInterest
			}
		case UPDATE_SEARCH_INTEREST:
			return {
				...state,
				allInterest: action.payload.allInterest,
				initialDataInterest: action.payload.values,
				listInterest: action.payload.listInterest
			}
		case UPDATE_BRAND_MODEL_SEARCH:
			return {
				...state,
				updateInitialDataNormal: action.payload
			}
		case SAVE_PREFERENCES_INTEREST:
			return {
				...state,
				allInterest: action.payload.list,
				listInterest: action.payload.selectedInterest
			}
		case UPDATE_FILTER_NORMAL:
			return {
				...state,
				updateDataNormal: action.payload.data,
				initialDataNormal: action.payload.data
			}
		case REFRESH_DATA:
			return {
				...state,
				itemsForm: action.payload.itemsForm,
				selectedTab: action.payload.selectedTab,
				initialDataNormal: action.payload.initialDataNormal,
				updateDataNormal: action.payload.initialDataNormal,
				initialDataInterest: action.payload.initialDataInterest,
				updateInitialDataInterest: action.payload.initialDataInterest,
				itemOfSearch: action.payload.itemOfSearch
			}
		case INITIAL_HOME:
			return {
				...state,
				typeHomeInitial: action.payload
			}
		case UPDATED_BY_PARAMS_URL:
			return {
				...state,
				initialDataNormal: action.payload.dataUpdated
			}
		case UPDATE_PARAMS_NOT_FOUND:
			return {
				...state,
				titleHeader: action.payload.titleHeader
			}
		case UPDATE_PAGE_LAST:
			return {
				...state,
				urlLastPage: action.payload.urlLastPage
			}
		case INITIAL_PANEL:
			return {
				...state,
				isPanelSearch: action.payload.panel
			}
		case INITIAL_BRAND_SEARCH:
			return {
				...state,
				initialDataNormal: action.payload.normal,
				initialNormalDefault: action.payload.normal
			}
		case UPDATE_BRAND_NORMAL_SEARCH:
			return {
				...state,
				initialDataNormal: action.payload.data,
				updateDataNormal: action.payload.data
			}
		case UPDATE_SELECTED_BRAND_COMBO:
			return {
				...state,
				initialDataNormal: {
					...state.initialDataNormal,
					vMarca: action.payload.brands,
					vModelo: action.payload.models
				},
				itemSelectedBrandANDModel: action.payload.itemSelected
			}
		case DELETE_BRAND_SELECTED_COMBO:
			return {
				...state,
				initialDataNormal: {
					...state.initialDataNormal,
					vMarca: action.payload.listBrands,
					vModelo: action.payload.listModels
				},
				itemSelectedBrandANDModel: action.payload.items
			}
		case LIST_SELECTED_MODEL_COMBO:
			return {
				...state,
				itemSelectedBrandANDModel: {
					...state.itemSelectedBrandANDModel,
					models: action.payload.modeloSelected
				},
				initialDataNormal: {
					...state.initialDataNormal,
					vModelo: action.payload.models
				},
			}
		case DELETE_MODEL_SELECTED_COMBO:
			return {
				...state,
				initialDataNormal: {
					...state.initialDataNormal,
					vModelo: action.payload.listModels
				},
				itemSelectedBrandANDModel: {
					...state.itemSelectedBrandANDModel,
					models: action.payload.itemsModels
				}
			}
		case UPDATE_DATA_NORMAL_ITEM:
			return {
				...state,
				initialDataNormal: action.payload.values
			}
		default: return state
	}
}

export default searchReducer


