import PanelInfo from "components/Customs/Panels/PanelInfo"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

const PanelCarsLoading = () => {

    return (
        <PanelInfo isClassCard="border-0 mt-5" isClassBody="padding-body">
			<section className="result-details result-details-loading">
				<div className="result-details__slide" >
					<div className="slide-car">
						<Skeleton height={200} />
					</div>
					<div className="favorite-car">
						<Skeleton circle={true} height={40} width={40} />
					</div>
				</div>
				<div className="info-car__view ml-md-5">
					<div className="mt-5">
						<div className="info-car__description infor-car__result">
							<div className="info-car__description--info">
								<Skeleton count={2}/>
							</div>
							<div className="info-car__marca">
								<Skeleton count={1} height={40} />
							</div>
						</div>
						<div className="price-car mt-1">
							<p className="info-car__description--price">
								<span className="mb-0"><Skeleton count={1} width={150}  /></span>
								<span className="price-financiamiento mt-0"><Skeleton count={1} width={150} /></span>
							</p>
						</div>
						<div className="info-car-features">
							<div className="car-features">
								<span className="car-features__imagen"></span>
								<div className="car-feature--details">
									<p className="car-feature__title"><Skeleton count={1}/></p>
									<p className="car-feature__value"><Skeleton count={1}/></p>
								</div>
							</div>
							<div className="car-features">
								<span className="car-features__imagen"></span>
								<div className="car-feature--details">
									<p className="car-feature__title"><Skeleton count={1}/></p>
									<p className="car-feature__value"><Skeleton count={1}/></p>
								</div>
							</div>
							<div className="car-features">
								<span className="car-features__imagen"></span>
								<div className="car-feature--details">
									<p className="car-feature__title"><Skeleton count={1}/></p>
									<p className="car-feature__value"><Skeleton count={1}/></p>
								</div>
							</div>
						</div>
					</div>
					<div className="result-footer w-100">
						<div>
							<Skeleton count={1} height={30} width={100}/>
				    	</div>
						<div>
							<Skeleton count={1} height={30} width={100} />
				    	</div>
					</div>
				</div>
			</section>
		</PanelInfo>
    )
}

export default PanelCarsLoading