import ModalScheduleSearching from 'components/Result/Modal/ModalScheduleSearching'
import NotificationForm from 'components/Customs/Modal/NotificationForm'


import { useFormSchedule } from 'hooks/Contact/useFormSchedule'

const AlertSearchingCar = () => {

	const { register, handleSubmit, onSubmit, message, isOpen, closeModal, errors, closeModalNoti, isOpenNoti } = useFormSchedule()


	return (
		<>
            <div className="search-programada mb-5">
                <h5 className="search-programada__title">Mantente informardo sobre tu búsqueda <br/>
                <button type="button" className="search-programada__small" onClick={closeModal}>Programar búsqueda</button></h5>
            </div>
			{ isOpen && 
                <ModalScheduleSearching 
                    isOpen={isOpen} 
                    closeModal={closeModal} 
                    onSubmit={onSubmit} 
                    register={register}
                    errors={errors}
                    handleSubmit={handleSubmit}
				/>
			}
			{ isOpenNoti && 
                <NotificationForm 
					show={isOpenNoti} 
					handleClose={closeModalNoti} 
					message={message}
				/>
			}
		</>
	)
}

export default AlertSearchingCar