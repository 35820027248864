import icon_displacement from "assets/images/motorcycle/icon-displacement.png"
import icon_engine from "assets/images/motorcycle/icon-engine.png"
import icon_transmission from "assets/images/motorcycle/icon-transmision.png"
import { formatMonto } from 'utils/tools'

export const formatDataMoto = (motos, providers, categoryProviders) => {

    const listMoto = motos.map(moto => {

        const { data } = moto

        const listFeatures = generateListFeatures(data)

        let caracteristPanel = generateCaracteristPanel(data)

        const infoBrand = providers.find(item => item.name?.toLowerCase()===data.brand?.toLowerCase())

        const category = categoryProviders.find(item => item.name==="Motos")

        return {
            id: moto.id,
            brand: data.brand,
            model: data.model,
            financingPrice: `$${formatMonto(data.price)} (iva incluido)`,
            salePrice: "",
            isVisibleFinancingPrice: false,
            type: "Moto",
            typeMoto: data.type,
            title: `MODELO ${data.model}`,
            photos: data.photos,
            listFeatures,
            isMyRouter: false,
            icon: infoBrand?.icon,
            photoMain: data?.photos[0],
            caracteristPanel,
            infoBrand: {...infoBrand, localMap: {...infoBrand?.localMap, type: "Motos"}},
            categoryProviderId: category.id,
            fuel: data.fuel,
            finalTransmission: data.finalTransmission
        }
    })

    return listMoto
}

const generateListFeatures = (moto) => {
    let listFeatures = []

    if(typeof moto?.displacement!=="undefined" && moto?.displacement!==null){
        listFeatures.push({title: "Cilindrada", value: moto.displacement})
    }

    if(typeof moto?.engine!=="undefined" && moto?.engine!==null){
        listFeatures.push({title: "Motor", value: moto.engine})
    }

    if(typeof moto?.refrigeration!=="undefined" && moto?.refrigeration!==null){
        listFeatures.push({title: "Refrigeración", value: moto.refrigeration})
    }

    if(typeof moto?.gearbox!=="undefined" && moto?.gearbox!==null){
        listFeatures.push({title: "Caja de Cambios", value: moto.gearbox})
    }

    if(typeof moto?.finalTransmission!=="undefined" && moto?.finalTransmission!==null){
        listFeatures.push({title: "Transmisión Final", value: moto.finalTransmission})
    }

    if(typeof moto?.frontBrake!=="undefined" && moto?.frontBrake!==null){
        listFeatures.push({title: "Freno Delantero", value: moto.frontBrake})
    }

    if(typeof moto?.rearBrake!=="undefined" && moto?.rearBrake!==null){
        listFeatures.push({title: "Freno Trasero", value: moto.rearBrake})
    }

    if(typeof moto?.feedingSystem!=="undefined" && moto?.feedingSystem!==null){
        listFeatures.push({title: "Sistema de alimentación", value: moto.feedingSystem})
    }

    if(typeof moto?.departureSystem!=="undefined" && moto?.departureSystem!==null){
        listFeatures.push({title: "Sistema de Partida", value: moto.departureSystem})
    }

    if(typeof moto?.frontTire!=="undefined" && moto?.frontTire!==null){
        listFeatures.push({title: "Neumático Delantero", value: moto.frontTire})
    }

    if(typeof moto?.rearTire!=="undefined" && moto?.rearTire!==null){
        listFeatures.push({title: "Neumático Trasero", value: moto.rearTire})
    }

    if(typeof moto?.fuel!=="undefined" && moto?.fuel!==null){
        listFeatures.push({title: "Combustible", value: moto.fuel})
    }

    if(typeof moto?.pond14!=="undefined" && moto?.pond14!==null){
        listFeatures.push({title: "Estanque", value: moto.pond14})
    }

    if(typeof moto?.dryWeight!=="undefined" && moto?.dryWeight!==null){
        listFeatures.push({title: "Peso en seco", value: moto.dryWeight})
    }

    if(typeof moto?.recommendation!=="undefined" && moto?.recommendation!==null){
        listFeatures.push({title: "Recomendación", value: moto.recommendation})
    }

    listFeatures.push({title: "Vende", value: moto.brand})

    const features = [{
        id: 1,
        title: "Características",
        listado: listFeatures,
        isVisibleSubtitle: true
    }]

    return features
}

const generateCaracteristPanel = (moto) => {
    let caracteristPanel = [
        {
            title: "Cilindrada",
            value: moto?.displacement,
            icon: icon_displacement
        },
        {
            title: "Motor",
            value: moto?.engine,
            icon: icon_engine
        },
        {
            title: "Caja de cambio",
            value: `${moto?.finalTransmission} ${moto?.gearbox}`,
            icon: icon_transmission
        }
    ]

    return caracteristPanel
}
