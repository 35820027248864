import camper from "assets/images/camper.jpg"
import caravana from "assets/images/caravana.png"
import carro_carpa from "assets/images/carro-carpa.jpg"
import motorhome from "assets/images/motorhome.png"
import equipamiento from "assets/images/equipamiento.png"

import f_repuestos from "assets/images/f-repuestos.png"
import f_accesorios from "assets/images/f-accesorios.png"
import f_baterias from "assets/images/f-baterias.png"
import f_herramientas from "assets/images/f-herramientas.png"
import f_iluminacion from "assets/images/f-iluminacion.png"
import f_limpieza_y_cuidado from "assets/images/f-limpieza-y-cuidado.png"
import f_lubricantes_y_aditivos from "assets/images/f-lubricantes-y-aditivos.png"

import g_clases_dirigidas from "assets/images/gym/clases-dirigidas.jpg"
import g_sala_de_maquinas from "assets/images/gym/sala-de-maquinas.jpg"
import g_kinesiologia from "assets/images/gym/kinesiologia.jpg"
import g_masoterapeuta from "assets/images/gym/masoterapeuta.jpg"
import g_nutricionista from "assets/images/gym/nutricionista.jpg"
import g_personal_trainer from "assets/images/gym/personal-trainer.jpg"
import g_clases_de_baile from "assets/images/gym/clases-de-baile.jpg"


export const data_omnia_fitness = [
    {
        name: "Clases dirigidas",
        concessionaire: "Omnia Fitness",
        id: 1,
        localIdMap: "",
        photoMain: g_clases_dirigidas,
        categoryProviderId: 27,
        listFeatures: [
            {title: 'Vende', value: 'Omnia Fitness'}
        ],
        status_id: 1
    },
    {
        name: "Sala de máquinas",
        concessionaire: "Omnia Fitness",
        id: 2,
        localIdMap: "",
        photoMain: g_sala_de_maquinas,
        categoryProviderId: 27,
        listFeatures: [
            {title: 'Vende', value: 'Omnia Fitness'}
        ],
        status_id: 1
    },
    {
        name: "Kinesiología",
        concessionaire: "Omnia Fitness",
        id: 3,
        localIdMap: "hp22",
        photoMain: g_kinesiologia,
        categoryProviderId: 27,
        listFeatures: [
            {title: 'Vende', value: 'Omnia Fitness'}
        ],
        status_id: 1
    },
    {
        name: "Masoterapeuta",
        concessionaire: "Omnia Fitness",
        id: 4,
        localIdMap: "hp22",
        photoMain: g_masoterapeuta,
        categoryProviderId: 27,
        listFeatures: [
            {title: 'Vende', value: 'Omnia Fitness'}
        ],
        status_id: 1
    },
    {
        name: "Nutricionista",
        concessionaire: "Omnia Fitness",
        id: 5,
        localIdMap: "hp22",
        photoMain: g_nutricionista,
        categoryProviderId: 27,
        listFeatures: [
            {title: 'Vende', value: 'Omnia Fitness'}
        ],
        status_id: 1
    },
    {
        name: "Personal Trainer",
        concessionaire: "Omnia Fitness",
        id: 6,
        localIdMap: "hp22",
        photoMain: g_personal_trainer,
        categoryProviderId: 27,
        listFeatures: [
            {title: 'Vende', value: 'Omnia Fitness'}
        ],
        status_id: 1
    },
    {
        name: "Clases de baile",
        concessionaire: "Omnia Fitness",
        id: 7,
        localIdMap: "hp22",
        photoMain: g_clases_de_baile,
        categoryProviderId: 27,
        listFeatures: [
            {title: 'Vende', value: 'Omnia Fitness'}
        ],
        status_id: 1
    }
]


export const vehicle_remolque = [
    {
        bodyWork: "",
        name: "Motorhome",
        brand: "",
        key: "",
        concessionaire: "CHILECARAVAN",
        country: "",
        fuel: "",
        id: 1,
        model: "",
        performance: "",
        price: "",
        seatAmount: "",
        traction: "",
        transmission: "",
        year: "",
        type: "",
        localIdMap: "hp22",
        photoMain: motorhome,
        isMyRouter: false,
        isCompare: false,
        isBanner: false,
        typeKey: "",
        categoryProviderId: 22,
        typeFeaturedOrRecommended: "",
        infoBrand: {},
        typekeyRecommended: "",
        priceLockDown: "",
        listPriceView: "",
        mileage: "",
        listFeatures: [
            {title: 'Vende', value: 'CHILECARAVAN'}
        ],
        listSpecific: [],
        photos: [
            motorhome
        ],
        status_id: 1
    },
    {
        bodyWork: "",
        name: "Caravana",
        brand: "",
        key: "",
        concessionaire: "CHILECARAVAN",
        country: "",
        fuel: "",
        id: 2,
        model: "",
        performance: "",
        price: "",
        seatAmount: "",
        traction: "",
        transmission: "",
        year: "",
        type: "",
        localIdMa: "",
        localIdMap: "hp22",
        photoMain: caravana,
        isMyRouter: false,
        isCompare: false,
        isBanner: false,
        typeKey: "",
        categoryProviderId: 22,
        typeFeaturedOrRecommended: "",
        infoBrand: {},
        typekeyRecommended: "",
        priceLockDown: "",
        listPriceView: "",
        mileage: "",
        listFeatures: [
            {title: 'Vende', value: 'CHILECARAVAN'}
        ],
        listSpecific: [],
        photos: [
            caravana
        ],
        status_id: 1
    },
    {
        bodyWork: "",
        name: "Camper",
        brand: "",
        key: "",
        concessionaire: "CHILECARAVAN",
        country: "",
        fuel: "",
        id: 3,
        model: "",
        performance: "",
        price: "",
        seatAmount: "",
        traction: "",
        transmission: "",
        year: "",
        type: "",
        localIdMa: "",
        localIdMap: "hp22",
        photoMain: camper,
        isMyRouter: false,
        isCompare: false,
        isBanner: false,
        typeKey: "",
        categoryProviderId: 22,
        typeFeaturedOrRecommended: "",
        infoBrand: {},
        typekeyRecommended: "",
        priceLockDown: "",
        listPriceView: "",
        mileage: "",
        listFeatures: [
            {title: 'Vende', value: 'CHILECARAVAN'}
        ],
        listSpecific: [],
        photos: [
            camper
        ],
        status_id: 1
    },
    {
        bodyWork: "",
        name: "Carro carpa",
        brand: "",
        key: "",
        concessionaire: "CHILECARAVAN",
        country: "",
        fuel: "",
        id: 4,
        model: "",
        performance: "",
        price: "",
        seatAmount: "",
        traction: "",
        transmission: "",
        year: "",
        type: "",
        localIdMa: "",
        localIdMap: "hp22",
        photoMain: carro_carpa,
        isMyRouter: false,
        isCompare: false,
        isBanner: false,
        typeKey: "",
        categoryProviderId: 22,
        typeFeaturedOrRecommended: "",
        infoBrand: {},
        typekeyRecommended: "",
        priceLockDown: "",
        listPriceView: "",
        mileage: "",
        listFeatures: [
            {title: 'Vende', value: 'CHILECARAVAN'}
        ],
        listSpecific: [],
        photos: [
            carro_carpa
        ],
        status_id: 1
    },
    {
        bodyWork: "",
        name: "Equipamiento",
        brand: "",
        key: "",
        concessionaire: "CHILECARAVAN",
        country: "",
        fuel: "",
        id: 5,
        model: "",
        performance: "",
        price: "",
        seatAmount: "",
        traction: "",
        transmission: "",
        year: "",
        type: "",
        localIdMa: "",
        localIdMap: "hp22",
        photoMain: equipamiento,
        isMyRouter: false,
        isCompare: false,
        isBanner: false,
        typeKey: "",
        categoryProviderId: 22,
        typeFeaturedOrRecommended: "",
        infoBrand: {},
        typekeyRecommended: "",
        priceLockDown: "",
        listPriceView: "",
        mileage: "",
        listFeatures: [
            {title: 'Vende', value: 'CHILECARAVAN'}
        ],
        listSpecific: [],
        photos: [
            equipamiento
        ],
        status_id: 1
    }
]

export const data_ferripaldi = [
    {
        name: "Repuestos",
        concessionaire: "Ferripaldi",
        id: 1,
        localIdMap: "",
        photoMain: f_repuestos,
        categoryProviderId: 22,
        listFeatures: [
            {title: 'Vende', value: 'Ferripaldi'}
        ],
        status_id: 1
    },
    {
        name: "Accesorios",
        concessionaire: "Ferripaldi",
        id: 2,
        localIdMap: "",
        photoMain: f_accesorios,
        categoryProviderId: 22,
        listFeatures: [
            {title: 'Vende', value: 'Ferripaldi'}
        ],
        status_id: 1
    },
    {
        name: "Baterías",
        concessionaire: "Ferripaldi",
        id: 3,
        localIdMap: "hp22",
        photoMain: f_baterias,
        categoryProviderId: 22,
        listFeatures: [
            {title: 'Vende', value: 'Ferripaldi'}
        ],
        status_id: 1
    },
    {
        name: "Herramientas",
        concessionaire: "Ferripaldi",
        id: 4,
        localIdMap: "hp22",
        photoMain: f_herramientas,
        categoryProviderId: 22,
        listFeatures: [
            {title: 'Vende', value: 'Ferripaldi'}
        ],
        status_id: 1
    },
    {
        name: "Iluminación",
        concessionaire: "Ferripaldi",
        id: 5,
        localIdMap: "hp22",
        photoMain: f_iluminacion,
        categoryProviderId: 22,
        listFeatures: [
            {title: 'Vende', value: 'Ferripaldi'}
        ],
        status_id: 1
    },
    {
        name: "Limpieza y cuidado",
        concessionaire: "Ferripaldi",
        id: 6,
        localIdMap: "hp22",
        photoMain: f_limpieza_y_cuidado,
        categoryProviderId: 22,
        listFeatures: [
            {title: 'Vende', value: 'Ferripaldi'}
        ],
        status_id: 1
    },
    {
        name: "Lubricantes y Aditivos",
        concessionaire: "Ferripaldi",
        id: 7,
        localIdMap: "hp22",
        photoMain: f_lubricantes_y_aditivos,
        categoryProviderId: 22,
        listFeatures: [
            {title: 'Vende', value: 'Ferripaldi'}
        ],
        status_id: 1
    }
]

