import CarouselItem from 'components/Customs/DeleteItem/CarouselItem'

const SelectedMarcaAndModelo = ({items, title, text, deleteItem, visibleMarcas, isOpenPanel}) => {


	return (
		<>
			{
				(items?.length===0) ? (
					<div className="form-select__details">
						<p 
							className={`form-select__subtitle ${isOpenPanel ? 'search-form-selected' : ''}`}
							onClick={visibleMarcas}>
							{title}
						</p>						
						<span className={`form-select__imagen ${isOpenPanel ? 'form-rotate__imagen' : 'form-select__imagenY'}`}></span>
					</div>
				)
				: (
					<section className="option-search__container">
						<CarouselItem 
							isClass="option-search__slider"
							listado={items}
							deleteItem={deleteItem}
						/>
						<span className={`form-select__imagen ${isOpenPanel ? '' : 'form-select__imagenY'}`}></span>
					</section>
				)
			}
		</>
	)
}

export default SelectedMarcaAndModelo