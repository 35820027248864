import { Link } from 'react-router-dom'

import { SwiperSlide } from 'swiper/react'

import Slider from 'components/Customs/Carousel/Slider'

const MarqueUrlFriendly = ({ url_friendly }) => {

	return (
		<div className="row marque-link">
			<div className="col-12">
				<section className="container">
					<Slider
						options= {{
							className: 'marque-link__container',
					        autoplay: {
							    delay: 1,
							},
							loop: true,
							speed: 6000,
							slidesPerView:'auto',
							breakpoints:{
					              "768": {
					                "slidesPerView": 3,
					                "spaceBetween": 15,
					              },
					              "1024": {
					                "slidesPerView":3,
					                "spaceBetween": 5,
					              }
					        }
						}}
						
				    >
				  		{
				  			url_friendly.map((item,key) => 
							  	<SwiperSlide key={key}>
									<Link to={item.urlFriendly} className="marque-link__title">{item.titleH1}</Link>
								</SwiperSlide>				  				
				  			)
				  		}
				  	</Slider>
				</section>
			</div>
		</div>
	)
}

export default MarqueUrlFriendly