import camioneta from 'assets/images/carroceria/camioneta.png'
import city from 'assets/images/carroceria/city.png'
import coupe from 'assets/images/carroceria/coupe.png'
import hatchback from 'assets/images/carroceria/hatchback.png'
import sedan from 'assets/images/carroceria/sedan.png'
import station_wagon from 'assets/images/carroceria/station_wagon.png'
import suv from 'assets/images/carroceria/suv.png'

import rendidor from 'assets/images/interests/nuevos/rendidor.png'
import ecofrendly from 'assets/images/interests/nuevos/ecofrendly.png'
import familiar from 'assets/images/interests/nuevos/familiar.png'
import img_4x4 from 'assets/images/interests/nuevos/4x4.png'
import automatico from 'assets/images/interests/nuevos/automatico.png'
import bajo_kilometraje from 'assets/images/interests/usado/bajo_kilometraje.png'
import poca_antiguedad from 'assets/images/interests/usado/poca_antiguedad.png'

export const allInterestNew = [
		{
			id: 1,
			name: "Rendidor",
			params: {
				vTypeAuto: [
				 	{ name: "car-new", checked: true },
   					{ name: "car-used", checked: false }
				],
				vPerformance: [
					{
						name: "0-10",
						slug: "p-9-kilometros",
						checked: false
					},
					{
						name: "10-15",
						slug: "p-15-kilometros",
						checked: false
					},
					{
						name: "15-100",
						slug: "p-16-kilometros",
						checked: true
					}
				],
				vTypeRange: {min: 0, max: 100000000}
			},
			typeSearch: "search-normal",
			background_image: rendidor,
			urlFriendly: '/autos/nuevos/p-16-kilometros'	
		},
		{
			id: 2,
			name: "Eco-Friendly",
			params: {
				vTypeAuto: [
				 	{ name: "car-new", checked: true },
   					{ name: "car-used", checked: false }
				],
				vCombustible: [
					{
						name: "diesel",
						slug: "diesel",
						checked: false
					},
					{
						name: "bencina",
						slug: "bencina",
						checked: false
					},
					{
						name: "hibrido",
						slug: "hibrido",
						checked: true
					},
					{
						name: "electrico",
						slug: "electrico",
						checked: true
					}
				],
				vTypeRange: {min: 0, max: 100000000}
			},
			typeSearch: "search-normal",
			background_image: ecofrendly,
			urlFriendly: '/autos/nuevos/hibrido/electrico'
		},
		{
			id: 3,
			name: "Familiar",
			params: {
				vTypeAuto: [
					{ name: "car-new", checked: true },
    				{ name: "car-used", checked: false }
				],
				vSeatMove: [
					{
						name: "2",
						slug: "2-corridas",
						checked: false
					},
					{
						name: "3",
						slug: "3-corridas",
						checked: false
					}
				],
				vTypeRange: {min: 0, max: 100000000}
			},
			typeSearch: "search-normal",
			background_image: familiar,
			urlFriendly: '/autos/nuevos/camioneta/suv/station-wagon'
		},
		{
			id: 4,
			name: "4x4",
			params: {
				vTypeAuto: [
				 	{ name: "car-new", checked: true },
   					{ name: "car-used", checked: false }
				],
				vTraction: [
					{ id: 2, value: '4x4' }
				],
				vTypeRange: {min: 0, max: 100000000}
			},
			typeSearch: "search-normal",
			background_image: img_4x4,
			urlFriendly: '/autos/nuevos/4x4'
		},
		{
			id: 5,
			name: "Automático",
			params: {
				vTypeAuto: [
				 	{ name: "car-new", checked: true },
   					{ name: "car-used", checked: false }
				],
				vTransmission: [
					{
						name: "automatico",
						slug: "automaticos",
						checked: true
					},
					{
						name: "mecanico",
						slug: "mecanica",
						checked: false
					}
				],
				vTypeRange: {min: 0, max: 100000000}
			},
			typeSearch: "search-normal",
			background_image: automatico,
			urlFriendly: '/autos/nuevos/automaticos'
		}
]

export const allInterestUsed = [
		{
			id: 1,
			name: "Rendidor",
			params: {
				vTypeAuto: [
				 	{ name: "car-new", checked: false },
   					{ name: "car-used", checked: true }
				],
				vPerformance: [
					{ id: 3, title: 'Más de 15 Km/L' }
				],
				vTypeRange: {min: 0, max: 100000000}
			},
			typeSearch: "search-normal",
			background_image: rendidor,
			urlFriendly: '/autos/usados/hasta-20-mil-kilometraje'	
		},
		{
			id: 2,
			name: "Bajo Kilometraje",
			params: {
				vTypeAuto: [
					{ name: "car-new", checked: false },
    				{ name: "car-used", checked: true }
				],
				vTypeKm: {min: 0, max: 10000},
				vTypeRange: {min: 0, max: 100000000}
			},
			typeSearch: "search-normal",
			background_image: bajo_kilometraje,
			urlFriendly: '/autos/usados/hasta-10-mil-kilometraje'	
		},
		{
			id: 3,
			name: "Familiar",
			params: {
				vTypeAuto: [
					{ name: "car-new", checked: false },
    				{ name: "car-used", checked: true }
				],
				vCarroceria: [
					{name: 'Camioneta', checked: true},
					{name: 'Station Wagon', checked: true},
					{name: 'SUV', checked: true}
				],
				vTypeRange: {min: 0, max: 100000000}
			},
			typeSearch: "search-normal",
			background_image: familiar,
			urlFriendly: '/autos/usados/camioneta/station-wagon/suv'
		},
		{
			id: 4,
			name: "4x4",
			params: {
				vTypeAuto: [
				 	{ name: "car-new", checked: false },
   					{ name: "car-used", checked: true }
				],
				vTraction: [
					{ id: 2, value: '4x4' }
				],
				vTypeRange: {min: 0, max: 100000000}
			},
			typeSearch: "search-normal",
			background_image: img_4x4,
			urlFriendly: '/autos/usados/4x4'
		},
		{
			id: 5,
			name: "Poca Antiguedad",
			params: {
				vTypeAuto: "car-used",
				vTypeRange: {min: 0, max: 100000000},
				vTypeYear:{min: 2017, max: 2022}
			},
			typeSearch: "search-normal",
			background_image: poca_antiguedad,
			urlFriendly: '/autos/usados/ano-2022'	
		}
]

export const allBodyWorkNew = [
		{
			id: 1,
			name: "Camioneta",
			params: {
				vTypeAuto: [
					{ name: "car-new", checked: true },
    				{ name: "car-used", checked: false }
				],
				vCarroceria: [
					{name: 'Camioneta', checked: true},
				],
				vTypeRange: {min: 0, max: 100000000}
			},
			typeSearch: "search-normal",
			background_image: camioneta,
			urlFriendly: '/autos/nuevos/camioneta'
		},
		{
			id: 2,
			name: "City",
			params: {
				vTypeAuto: [
					{ name: "car-new", checked: true },
    				{ name: "car-used", checked: false }
				],
				vCarroceria: [
					{name: 'City', checked: true},
				],
				vTypeRange: {min: 0, max: 100000000}
			},
			typeSearch: "search-normal",
			background_image: city,
			urlFriendly: '/autos/nuevos/citycar'	
		},
		{
			id: 3,
			name: "Coupé",
			params: {
				vTypeAuto: [
					{ name: "car-new", checked: true },
    				{ name: "car-used", checked: false }
				],
				vCarroceria: [
					{name: 'Coupé', checked: true},
				],
				vTypeRange: {min: 0, max: 100000000}
			},
			typeSearch: "search-normal",
			background_image: coupe,
			urlFriendly: '/autos/nuevos/coupe'	
		},
		{
			id: 4,
			name: "Hatchback",
			params: {
				vTypeAuto: [
					{ name: "car-new", checked: true },
    				{ name: "car-used", checked: false }
				],
				vCarroceria: [
					{name: 'Hatchback', checked: true},
				],
				vTypeRange: {min: 0, max: 100000000}
			},
			typeSearch: "search-normal",
			background_image: hatchback,
			urlFriendly: '/autos/nuevos/hatchback'	
		},
		{
			id: 5,
			name: "Sedan",
			params: {
				vTypeAuto: [
					{ name: "car-new", checked: true },
    				{ name: "car-used", checked: false }
				],
				vCarroceria: [
					{name: 'Sedan', checked: true},
				],
				vTypeRange: {min: 0, max: 100000000}
			},
			typeSearch: "search-normal",
			background_image: sedan,
			urlFriendly: '/autos/nuevos/sedan'	
		},
		{
			id: 6,
			name: "Station Wagon",
			params: {
				vTypeAuto: [
					{ name: "car-new", checked: true },
    				{ name: "car-used", checked: false }
				],
				vCarroceria: [
					{name: 'Station Wagon', checked: true},
				],
				vTypeRange: {min: 0, max: 100000000}
			},
			typeSearch: "search-normal",
			background_image: station_wagon,
			urlFriendly: '/autos/nuevos/station-wagon'	
		},
		{
			id: 7,
			name: "SUV",
			params: {
				vTypeAuto: [
					{ name: "car-new", checked: true },
    				{ name: "car-used", checked: false }
				],
				vCarroceria: [
					{name: 'SUV', checked: true},
				],
				vTypeRange: {min: 0, max: 100000000}
			},
			typeSearch: "search-normal",
			background_image: suv,
			urlFriendly: '/autos/nuevos/suv'	
		},
]

export const allBodyWorkUsed = [
		{
			id: 1,
			name: "Camioneta",
			params: {
				vTypeAuto: [
					{ name: "car-new", checked: false },
    				{ name: "car-used", checked: true }
				],
				vCarroceria: [
					{name: 'Camioneta', checked: true},
				],
				vTypeRange: {min: 0, max: 100000000}
			},
			typeSearch: "search-normal",
			background_image: camioneta,
			urlFriendly: '/autos/usados/camioneta'	
		},
		{
			id: 2,
			name: "City",
			params: {
				vTypeAuto: [
					{ name: "car-new", checked: false },
    				{ name: "car-used", checked: true }
				],
				vCarroceria: [
					{name: 'City', checked: true},
				],
				vTypeRange: {min: 0, max: 100000000}
			},
			typeSearch: "search-normal",
			background_image: city,
			urlFriendly: '/autos/usados/citycar'		
		},
		{
			id: 3,
			name: "Coupé",
			params: {
				vTypeAuto: [
					{ name: "car-new", checked: false },
    				{ name: "car-used", checked: true }
				],
				vCarroceria: [
					{name: 'Coupé', checked: true},
				],
				vTypeRange: {min: 0, max: 100000000}
			},
			typeSearch: "search-normal",
			background_image: coupe,
			urlFriendly: '/autos/usados/coupe'		
		},
		{
			id: 4,
			name: "Hatchback",
			params: {
				vTypeAuto: [
					{ name: "car-new", checked: false },
    				{ name: "car-used", checked: true }
				],
				vCarroceria: [
					{name: 'Hatchback', checked: true},
				],
				vTypeRange: {min: 0, max: 100000000}
			},
			typeSearch: "search-normal",
			background_image: hatchback,
			urlFriendly: '/autos/usados/hatchback'		
		},
		{
			id: 5,
			name: "Sedan",
			params: {
				vTypeAuto: [
					{ name: "car-new", checked: false },
    				{ name: "car-used", checked: true }
				],
				vCarroceria: [
					{name: 'Sedan', checked: true},
				],
				vTypeRange: {min: 0, max: 100000000}
			},
			typeSearch: "search-normal",
			background_image: sedan,
			urlFriendly: '/autos/usados/sedan'			
		},
		{
			id: 6,
			name: "Station Wagon",
			params: {
				vTypeAuto: [
					{ name: "car-new", checked: false },
    				{ name: "car-used", checked: true }
				],
				vCarroceria: [
					{name: 'Station Wagon', checked: true},
				],
				vTypeRange: {min: 0, max: 100000000}
			},
			typeSearch: "search-normal",
			background_image: station_wagon,
			urlFriendly: '/autos/usados/station-wagon'			
		},
		{
			id: 7,
			name: "SUV",
			params: {
				vTypeAuto: [
					{ name: "car-new", checked: false },
    				{ name: "car-used", checked: true }
				],
				vCarroceria: [
					{name: 'SUV', checked: true},
				],
				vTypeRange: {min: 0, max: 100000000}
			},
			typeSearch: "search-normal",
			background_image: suv,
			urlFriendly: '/autos/usados/suv'				
		},
]