import { Modal } from 'react-bootstrap'
import closed_menu from 'assets/images/icons/delete-menu.svg'
import email from 'assets/images/icons/email-welcome.svg'

const ModalScheduleSearching = ({ isOpen,  closeModal, errors, register, handleSubmit, onSubmit }) => {

	

	return (
		<Modal show={isOpen} onHide={closeModal} className="modal-result" centered>
        <div>
        	<div className="modal__closed" onClick={closeModal}>
				<img src={closed_menu} alt="Cerrar" />
			</div>
        </div>
        <Modal.Body>
        	<div className="modal__header">
				<p className="modal-result--header">¡Entérate primero!</p>
			</div>
			<div className="modal__container">
				<p className="modal-result__title">Si quieres que te avisemos cuando se suba un auto que cumpla con tus criterios de búsqueda, déjanos tu correo y te notificaremos en tiempo real</p>		  
				<form className="modal-result__form" onSubmit={handleSubmit(onSubmit)}>
					<div className="input-group modal-result__form__input">
					  	<input 
					  		type="text" 
					  		className="form-control" 
					  		aria-label="Ingresa tu correo" 
					  		aria-describedby="email"
					  		placeholder="Ingresa tu correo"
					  		name="email"
					  		id="email"
					  		ref={register({required: {value: true, message: 'Requerido'}})}
					  	/>
						<div className="input-group-append">
							<button type="submit" className="btn btn-secondary btn-send-email">
								<img src={email} alt="Ingresa tu correo" />
							</button>
						</div>
					</div>
					 {errors.email && (
					    	<p className="text-white">
					      		{errors?.email?.message}
					    	</p>
					    )} 
				</form>
			</div>
			<div className="modal__footer">
				<div className="modal-result__footer">
		    		<button type="button" className="btn btn-link" onClick={closeModal}>No gracias</button>
		    	</div>
			</div>
        </Modal.Body>
      </Modal>
	)
}

export default ModalScheduleSearching