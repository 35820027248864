const GroupFilterItemResult = ({ list, title, register, handleClickUpdateResult, setValue }) => {
	
	return (
		<div className="filter__item">
			<p className="form-filter__title">{title}</p>
			<div className="form-filter__container">
				{
					list.map((item, key) =>(
						<div className="form-group form-filter-checkbox" key={key}>
							<input
								ref={register}
								name={`${item.name}[${key}].name`}
								defaultValue={item?.value}
								type="hidden"
							/>
							<input
								ref={register}
								name={`${item.name}[${key}].slug`}
								defaultValue={item?.slug}
								type="hidden"
							/>
							<input 
								className="form__filter-check"
								type="checkbox" 
								name={`${item.name}[${key}].checked`} 
								id={item?.value} 
								defaultChecked={item.checked}
								ref={register}
								onClick={(event)=>handleClickUpdateResult(event, item)}
							/>
							<label className="form__filter-label" htmlFor={item?.value}>
								{item?.title}
							</label>
						</div>			
					))
				}	
			</div>					
		</div>	
	)
}

export default GroupFilterItemResult