import API from 'services/context/api';
import { 
    CHANGE_STATUS_MODAL_OLX,
	LIST_BRAND_OLX,
	LIST_MODEL_OLX,
    LIST_YEAR_OLX,
    LIST_VERSION_OLX,
    SEND_FORM_OLX
 } from 'services/types/sureOlxType';
import { getListData } from "utils/toolQuote";
import { generateKm } from 'utils/tools';
import endPoints from 'services/routes/';
import { TOKEN_SITE_WEB } from "data/variableKey";

const RANGE_MAX_KM = 100000;

export const requestSuccess = (type, payload) => {
    return { type, payload };
};

export const showAndHiddenFormOLX = (isVisibleForm) => (dispatch, getState) => {
    
	const { isModalOLX } = getState().sureOlx;
    
    const payload = { 
        isVisibleFormOLX: isVisibleForm,
        isModalOlx: !isModalOLX 
    };
  
    dispatch(requestSuccess(CHANGE_STATUS_MODAL_OLX, payload));
};

export const getBrandOlx = (body) => async (dispatch, getState) => {

    const listKms = generateKm(0, RANGE_MAX_KM).map(item => {
        return { key: item.value, label: item.title };
    });

    try{
    	
    	const { listBrandOlx } = getState().sureOlx;

    	let listBrands = listBrandOlx?.list;
    	let errorBrand = false, infoBrands={selected: {}, listAll: []};
    	let positionTab = "", valueSearchResult={};
		
   		if(listBrands.length===0){
   			const response = await API.getApi({
                                        endPoint: endPoints.olx.getBrand,
                                        nameAPI: TOKEN_SITE_WEB, 
                                        isToken: true,
                                        isFormData: false
                                    });

        	if(response.status===200){
	            listBrands = response.data.data.list;
	        }else{
	        	errorBrand = true;
	        	listBrands = [];
	        }
   		}

        if(listBrands.length>0){
            infoBrands = getListData(listBrands, body?.value);
            listBrands = infoBrands.listAll;
            valueSearchResult = {...valueSearchResult, brand: infoBrands.selected};
            positionTab="";
        }
        
        const payload = {
        	listBrand: { isProcess: false, list: listBrands, error: errorBrand },
            positionTab,
            valueSearchResult,
            listModel: { isProcess: false, list: [], error: false },
            listYear: { isProcess: false, list: [], error: false },
            listMileagesOlx: { isProcess: false, list: listKms}
        };
 
        dispatch(requestSuccess(LIST_BRAND_OLX, payload));

        return { status: errorBrand, selected: infoBrands.selected, positionTab };

    }catch(error){
        const payload = {
        	listBrand: { isProcess: false, list: [], error: true },
            positionTab: "",
            valueSearchResult: "",
            listModel: { isProcess: false, list: [], error: false },
            listYear: { isProcess: false, list: [], error: false },
            listMileagesOlx: { isProcess: false, list: listKms}
        };
        dispatch(requestSuccess(LIST_BRAND_OLX, payload));
      	return { status: false, message: "Error en la conexión de cotiza tu seguro" };
    }
};

export const getModelOlx = (body, marca) => async (dispatch, getState) => {
    
    const { listModelOlx, valueSearch } = getState().sureOlx;

    try{

    	let listModels = listModelOlx?.list;
    	let errorModel = false, infoModels={selected: {}, listAll: []};
    	let positionTab = "0", valueSearchResult= valueSearch;
		
        const formData = { marca }
        const response = await API.postApi({
                                        endPoint: endPoints.olx.getModel,
                                        data: formData,
                                        nameAPI: TOKEN_SITE_WEB, 
                                        isToken: true,
                                        isFormData: false
                                    })

        if(response.status===200){
            listModels = response.data.data.list;
        }else{
            errorModel = true;
            listModels = [];
        }
        
        if(listModels.length>0){
            infoModels = getListData(listModels, body.value);
            listModels = infoModels.listAll;
            valueSearchResult = {...valueSearchResult, model: infoModels.selected};
            positionTab="0";
        }
        
        const payload = {
        	listModel: { isProcess: false, list: listModels, error: errorModel },
            positionTab,
            valueSearchResult,
            listYear: { isProcess: false, list: [], error: false }
        };
        
        dispatch(requestSuccess(LIST_MODEL_OLX, payload));

        return { status: errorModel, selected: infoModels.selected, positionTab };

    }catch(error){
        const payload = {
        	listModel: { isProcess: false, list: [], error: true },
            positionTab: "0",
            valueSearchResult: valueSearch,
            listYear: { isProcess: false, list: [], error: false }
        };
        dispatch(requestSuccess(LIST_MODEL_OLX, payload));
      	return { status: false, message: "Error en la conexión de cotiza tu seguro" };
    }
};

export const getYearOlx = (body, marca, modelo) => async (dispatch, getState) => {
    
    const { listYearOlx, valueSearch } = getState().sureOlx;

    try{

    	let listYears = listYearOlx?.list;
    	let errorYear = false, infoYear={selected: {}, listAll: []};
    	let positionTab = "0", valueSearchResult= valueSearch;
		
        const formData = { marca, modelo };
        const response = await API.postApi({
                                        endPoint: endPoints.olx.getYear,
                                        data: formData,
                                        nameAPI: TOKEN_SITE_WEB, 
                                        isToken: true,
                                        isFormData: false
                                    });

        if(response.status===200){
            listYears = response.data.data.list;
        }else{
            errorYear = true;
            listYears = [];
        }
        
        if(listYears.length>0){
            infoYear = getListData(listYears, body.value);
            listYears = infoYear.listAll;
            valueSearchResult = {...valueSearchResult, model: infoYear.selected};
            positionTab="1"
        }
        
        const payload = {
        	listYears: { isProcess: false, list: listYears, error: errorYear },
            positionTab,
            valueSearchResult,
            listVersion: { isProcess: false, list: [], error: true }
        };
        
        dispatch(requestSuccess(LIST_YEAR_OLX, payload));

        return { status: errorYear, selected: infoYear.selected, positionTab };

    }catch(error){
        const payload = {
        	listYears: { isProcess: false, list: [], error: true },
            positionTab: "0",
            valueSearchResult: valueSearch,
            listVersion: { isProcess: false, list: [], error: true }
        };
        dispatch(requestSuccess(LIST_YEAR_OLX, payload));
      	return { status: false, message: "Error en la conexión de cotiza tu seguro" };
    }
};

export const getVersionOlx = (body, marca, modelo, year) => async (dispatch, getState) => {
    
    const { listVersionOlx, valueSearch } = getState().sureOlx;

    try{

    	let listVersions = listVersionOlx?.list;
    	let errorYear = false, infoYear={selected: {}, listAll: []};
    	let positionTab = "0", valueSearchResult= valueSearch;
		
        const formData = { marca, modelo, año: year };
        const response = await API.postApi({
                                    endPoint: endPoints.olx.getVersion,
                                    data: formData,
                                    nameAPI: TOKEN_SITE_WEB, 
                                    isToken: true,
                                    isFormData: false
                                });

        if(response.status===200){
            listVersions = response.data.data.list;
        }else{
            errorYear = true;
            listVersions = [];
        }
        
        if(listVersions.length>0){
            infoYear = getListData(listVersions, body.value);
            listVersions = infoYear.listAll;
            valueSearchResult = {...valueSearchResult, model: infoYear.selected};
            positionTab="2";
        }
        
        const payload = {
        	listVersions: { isProcess: false, list: listVersions, error: errorYear },
            positionTab,
            valueSearchResult
        };
        
        dispatch(requestSuccess(LIST_VERSION_OLX, payload));

        return { status: errorYear, selected: infoYear.selected, positionTab };

    }catch(error){
        const payload = {
        	listVersions: { isProcess: false, list: [], error: true },
            positionTab: "1",
            valueSearchResult: valueSearch
        };
        dispatch(requestSuccess(LIST_VERSION_OLX, payload));
      	return { status: false, message: "Error en la conexión de cotiza tu seguro" };
    }
};

export const saveFormDataOLX = (body) => async (dispatch, getState) => {
	try{
        const { listBrandOlx, listModelOlx, listYearOlx, listVersionOlx } = getState().sureOlx;
        const { banner_id } = getState().advertisings;

        let status = false, message="Error al realizar la cotización";
        let request = "{quote(country:\"CL\", make:\"BRAND_NAME\", model:\"MODEL_NAME\", trim: \"TRIM_NAME\", year:YEAR_NAME) {\n    quote,variant,priceText\n  } \n}\n";

        const brandValue = listBrandOlx.list.find(item => item.key===body.brand);
        const modelValue = listModelOlx.list.find(item => item.key===body.model);
        const yearValue = listYearOlx.list.find(item => item.key===body.year);
        const versionValue = listVersionOlx.list.find(item => item.key===body.version);

        const dataCar = {
            brand: brandValue.label,
            model: modelValue.label,
            year: yearValue.label,
            version: versionValue.label,
            mileage: body.mileage
        };

        request = request.replace("BRAND_NAME", dataCar.brand);
        request = request.replace("MODEL_NAME", dataCar.model);
        request = request.replace("TRIM_NAME", dataCar.version);
        request = request.replace("MILEAGE_NAME", body.mileage);
        request = request.replace("YEAR_NAME", body.year);

        const data = {
            parner: "parner_olx",            
            data: request,
            user: {
                name: body.name,
                email: body.email,
                phone: body.telephone,
            },
            car: dataCar,
            banner_id
        };
       
        const response = await API.postApi({
                                        endPoint: endPoints.compara.registerLead,
                                        data,
                                        nameAPI: TOKEN_SITE_WEB, 
                                        isToken: true,
                                        isFormData: false
                                    })
    
        if(response.data.status===200){
           

            const { quote } = response.data.data;
            const payload = { 
                isVisibleFormOLX: false, 
                resultQuote: {
                    data: dataCar,
                    value: [
                        quote.length>0 ? quote[0] : 0,
                        quote.length>=2 ? quote[1] : 0,
                    ]
                },
                visibleTextZeroPrice: quote.length ===2 ? false : true
            };
            
            dispatch(requestSuccess(SEND_FORM_OLX, payload));
            status=true;
            message="";
        }

        return { status, message };

    }catch(error){
          return { status: false, message: error };
    }
};