import { useEffect } from "react"
import { useLocation } from "react-router-dom"
 
import analytics from "routes/analytics"
import pixelFacebook from "routes/pixelFacebook"
const type_env = process.env.REACT_APP_ENVIRONMENT


export default function useGoogleAnalytics() {
  const location = useLocation()

  useEffect(() => {
    if(type_env==="production"){
        analytics.init()
        pixelFacebook.init()        
    }
    
  }, [])
 
  useEffect(() => {
    if(type_env==="production"){
      const currentPath = location.pathname + location.search
      analytics.sendPageview(currentPath)
      pixelFacebook.sendPageview()      
    }
    
  }, [location])
}