import { 
	LIST_SCOOTER_ELECTRICAL,
	LIST_BICYCLE_ELECTRICAL,
	LOADING_ALL_ELECTRICAL,
	SELECTED_ITEM_ELECTRICAL,
	LIST_FEATURED_ELECTRICAL
} from 'services/types/electricalType'

const INITIAL_STATE = {
	listScooter: [],
	isLoadingScooter: false,
	listBicycle: [],
	isLoadingBicycle: false,
	selectedItemElectrical: [],
	typeElectrical: "",
	listFeaturedElectrical: [],
	isLoadingFeatured: false
}

const electricalReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case LIST_SCOOTER_ELECTRICAL:
			return {
				...state,
				listScooter: action.payload.listScooter,
				isLoadingScooter: action.payload.isLoadingScooter
			}
		case LIST_BICYCLE_ELECTRICAL:
			return {
				...state,
				listBicycle: action.payload.listBicycle,
				isLoadingBicycle: action.payload.isLoadingBicycle
			}
		case LOADING_ALL_ELECTRICAL:
			return {
				...state,
				isLoadingScooter: action.payload.isLoadingScooter,
				isLoadingBicycle: action.payload.isLoadingBicycle,
				isLoadingFeatured: action.payload.isLoadingFeatured
			}
		case SELECTED_ITEM_ELECTRICAL:
			return {
				...state,
				selectedItemElectrical: action.payload.selected,
				typeElectrical: action.payload.type
			}
		case LIST_FEATURED_ELECTRICAL:
			return {
				...state,
				listFeaturedElectrical: action.payload.features,
				isLoadingFeatured: action.payload.isLoadingFeatured
			}
		default: return state
	}
}

export default electricalReducer