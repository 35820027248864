import Skeleton from "react-loading-skeleton"
import ListVehicleLoading from "components/Motorcycle/Skeleton/ListVehicleLoading"
import "react-loading-skeleton/dist/skeleton.css"
import styles from 'styles/Motorcycle.module.css'

const InfoTypeMotorcycleLoading = ({ bgColor, classTitle, orderText, orderImage }) => {

	return (
		<section className="row">
			<div className={`col-12 ${styles[bgColor]}`}>
				<div className="container">
					<section className={`${styles["moto-type__container"]} ${styles[classTitle]}`}>
						<div className={`${orderText} ${styles["moto-type__item"]}`}>
							<div className={styles["moto-type__group"]}>
								<p className={styles["moto-type__subtitle"]}><Skeleton count={1}/></p>
								<h3 className={styles["moto-type__title"]}><Skeleton count={1}/></h3>
							</div>
							<p className={styles["moto-type__info"]}><Skeleton count={4}/></p>
						</div>
						<div className={`${orderImage} ${styles["moto-type__image"]}`}>
							<Skeleton count={1} className={styles["moto-type__img"]} height={250} />
						</div>
					</section>

					<section className={styles["moto-type__findout"]}>
						<hr className={styles["moto-findout__line"]} />
						<p className={`${styles["moto-findout__title"]} ${styles[bgColor]}`}>Descubre</p>
						<ListVehicleLoading />
					</section>	
				</div>
			</div>
		</section>
	)
}

export default InfoTypeMotorcycleLoading