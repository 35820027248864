import delete_img from 'assets/images/icons/delete-result.svg'

import { typeSortByCar } from 'services/actions/carActions'
import { useDispatch } from 'react-redux'

const MenuSort = ({ closeMenuSort, setMenuSort }) => {

	const dispatch = useDispatch()

	const searchSort = (type) => {
		dispatch(typeSortByCar(type))
		setMenuSort(false)
	}

	return (
		<div className="options-sort bg-white">
			<div className="options-sort__header">
				<p>Ordenar</p>
				<img src={delete_img} alt="Cerrar" onClick={closeMenuSort} />
			</div>
			<div className="options-sort__menu">
				<p onClick={()=>searchSort("lower_price")}>Menor Precio</p>
			</div>
			<div className="options-sort__menu">
				<p onClick={()=>searchSort("higher_price")}>Mayor Precio</p>
			</div>
			<div className="options-sort__menu">
				<p onClick={()=>searchSort("lower_km")}>Menor KM</p>
			</div>
			<div className="options-sort__menu">
				<p onClick={()=>searchSort("higher_km")}>Mayor KM</p>
			</div>
			<div className="options-sort__menu">
				<p onClick={()=>searchSort("lower_year")}>Menor año</p>
			</div>
			<div className="options-sort__menu">
				<p onClick={()=>searchSort("higher_year")}>Mayor año</p>
			</div>
		</div>
	)
}

export default MenuSort