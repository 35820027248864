import { 
	SAVE_TOKEN_COMPARA,
    CHANGE_VISIBILITY_FORM_PATENTE,
	NEXT_FORM_PREV,
	RESET_FORM_SIRE,
	LIST_INFO_COMPARA,
	LIST_MODELS_COMPARA,
	SAVE_FORM_DATA_VEHICLE,
	RESULT_SEND_FORM_COMPARA,
	SAVE_FORM_DATA_PATENTE,
	SAVE_FORM_DATA_PATENTE_EDIT,
	INTERESTED_COMPARA,
	CHANGE_STATUS_MODAL_COMPARA,
	SAVE_FORM_DATA_GLOBAL
} from 'services/types/quoteType'

const INITIAL_STATE = {
	tokenCompara: "",
	nameFormPatent: "noPatent",
	dataFormQuote: { 
		brand: "",
		model: "",
		year: "",
        rut: "",
        name: "",
        lastname: "",
        dateOfBirth: "",
        comuna: "",
        telephone: "",
        email: "",
        question: "1"		
	},
	nextFormStep: "form_initial",
	listInfoCompara: { 
		isProcess: false,
		brands: [],
		models: [],
		years: [],
		comunas: []
	},
	listSireByCompara: [],
	isResultPatente: false,
	isInfoPatente: false,
	dataFormQuoteCar: {},
	labelResultCar: "",
	titleResult: { primary: "", secondary: "" }, 
	isModalCompara: false,
}

const quoteReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case SAVE_TOKEN_COMPARA:
			return {
				...state,
				tokenCompara: action.payload.accessToken
			}
		case CHANGE_VISIBILITY_FORM_PATENTE:
			return {
				...state,
				nameFormPatent: action.payload.nameFormPatent
			}
		case NEXT_FORM_PREV:
			return {
				...state,
				nextFormStep: action.payload.nextForm
			}
		case RESET_FORM_SIRE:
			return {
				...state,
				dataFormQuote: action.payload.dataFormQuote
			}
		case LIST_INFO_COMPARA:
			return {
				...state,
				listInfoCompara: {
					...state.listInfoCompara,
					brands: action.payload.brands,
					years: action.payload.years,
					comunas: action.payload.comunas,
					isProcess: action.payload.isProcess,
					models: action.payload.models
				}
			}
		case LIST_MODELS_COMPARA:
			return {
				...state,
				listInfoCompara: {
					...state.listInfoCompara,
					models: action.payload.models
				}
			}
		case SAVE_FORM_DATA_VEHICLE:
			return {
				...state,
				dataFormQuote: action.payload.dataForm,
				nextFormStep: action.payload.nextFormStep
			}
		case RESULT_SEND_FORM_COMPARA:
			return {
				...state,
				nextFormStep: action.payload.nextFormStep,
				listSireByCompara: action.payload.listSireByCompara,
				titleResult: action.payload.titleResult
			}
		
		case SAVE_FORM_DATA_PATENTE:
			return {
				...state,
				dataFormQuote: action.payload.dataForm,
				isResultPatente: action.payload.isResultPatente,
				isInfoPatente: action.payload.isInfoPatente
			}
		case SAVE_FORM_DATA_PATENTE_EDIT:
			return {
				...state,
				isInfoPatente: action.payload.isInfoPatente
			}
		case INTERESTED_COMPARA:
			return {
				...state,
				listSireByCompara: action.payload.listInsurances
			}
		case CHANGE_STATUS_MODAL_COMPARA:
			return {
				...state,
				isModalCompara: action.payload.isModalCompara
			}	
		case SAVE_FORM_DATA_GLOBAL:
			return {
				...state,
				dataFormQuote: action.payload
			}
		default: return state
	}
}

export default quoteReducer