export const SAVE_TOKEN_COMPARA = "SAVE_TOKEN_COMPARA"
export const CHANGE_VISIBILITY_FORM_PATENTE = "CHANGE_VISIBILITY_FORM_PATENTE"
export const NEXT_FORM_PREV = "NEXT_FORM_PREV"
export const RESET_FORM_SIRE = "RESET_FORM_SIRE"
export const LIST_INFO_COMPARA = "LIST_INFO_COMPARA"
export const LIST_MODELS_COMPARA = "LIST_MODELS_COMPARA"
export const SAVE_FORM_DATA_VEHICLE = "SAVE_FORM_DATA_VEHICLE"
export const RESULT_SEND_FORM_COMPARA = "RESULT_SEND_FORM_COMPARA"
export const INTERESTED_COMPARA = "INTERESTED_COMPARA"
export const CHANGE_STATUS_MODAL_COMPARA = "CHANGE_STATUS_MODAL_COMPARA"


export const SAVE_FORM_DATA_PATENTE = "SAVE_FORM_DATA_PATENTE"
export const SAVE_FORM_DATA_PATENTE_EDIT = "SAVE_FORM_DATA_PATENTE_EDIT"
export const SAVE_FORM_DATA_GLOBAL = "SAVE_FORM_DATA_GLOBAL"
    