import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import send_email from 'assets/images/icon/send-mail.svg';
import error_send_email from 'assets/images/icon/error_send_email.png';
import { useEvent } from "hooks/Contact/useEvent"


const NotificationForm = ({ show, handleClose, message, dialogClassName }) => {

  const { validateSendAPIPixelModal } = useEvent()

  useEffect(() => {

    validateSendAPIPixelModal(message)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName={dialogClassName}
      >
        <Modal.Body className="notification__container">
          <div className="notification-icon">
            <img 
              src={message.error ? error_send_email : send_email} 
              alt="Enviar email" 
            />
          </div>
          <div className="notification-text">
            <h1 className="notification-text__title">{message.title}</h1>
            <p className="notification-text__subtitle">
              {message.subtitle}
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="w-100 mt-5 mb-4">
        <button 
          type="button" 
          className="btn btn-primary btn-lg w-100 btn-formulario" 
          onClick={()=>handleClose()}
        >
          {message.btnTitle}
        </button>
      </div>
        </Modal.Footer>
      </Modal>
  );
};

export default NotificationForm;