import { 
	CONCESSIONAIRE_DETAIL_BY_ID,
	LOADING_SEARCH,
	SELECTED_CARS_QUOTE,
	LIST_CARS_BY_CONCESSIONAIRE,
	UPDATE_FILTER_BY_MODEL
} from 'services/types/concessionaireType'

const INITIAL_STATE = {
	detailConcessionare: {},
	isLoadingSearch: { status: false, message: ''},
	carSelectedQuote: [],
	listCarsAssign: [],
	listModels: [],
	listCarsAssignOriginal: []
}

const concessionaireReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case CONCESSIONAIRE_DETAIL_BY_ID:
			return {
				...state,
				isLoadingSearch: action.payload.isLoading,
				detailConcessionare: action.payload.detail
			}
		case LOADING_SEARCH:
			return {
				...state,
				isLoadingSearch: action.payload
			}
		case SELECTED_CARS_QUOTE:
			return {
				...state,
				carSelectedQuote: action.payload
			}
		case LIST_CARS_BY_CONCESSIONAIRE: 
			return {
				...state,
				listCarsAssign: action.payload.listCarsAssign,
				isLoadingSearch: action.payload.isLoading,
				listModels: action.payload.listModels,
				listCarsAssignOriginal: action.payload.listCarsAssign,
			}
		case UPDATE_FILTER_BY_MODEL:
			return {
				...state,
				listCarsAssign: action.payload.listCarsAssign,
			}
		default: return state
	}
}

export default concessionaireReducer