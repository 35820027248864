export const LIST_INITIAL_BRAND = 'LIST_INITIAL_BRAND'
export const UPDATE_SELECTED_BRAND = 'UPDATE_SELECTED_BRAND'
export const VISIBLE_LIST_MODEL = 'VISIBLE_LIST_MODEL'
export const LIST_BRAND_MODEL_SELECTED ='LIST_BRAND_MODEL_SELECTED'
export const LIST_SELECTED_MODEL = 'LIST_SELECTED_MODEL'
export const DELETE_BRAND_MODEL_SELECTED = 'DELETE_BRAND_MODEL_SELECTED'
export const TYPE_BRAND_SELECTED = 'TYPE_BRAND_SELECTED'
export const UPDATE_VISIBLE_BRAND = 'UPDATE_VISIBLE_BRAND'
export const UPDATE_LIST_MODEL = 'UPDATE_LIST_MODEL'
export const RESET_TAB_BRAND_MODEL_SELECTED = 'RESET_TAB_BRAND_MODEL_SELECTED'
export const RESET_SEARCH_BY_URL_BRAND_MODEL = 'RESET_SEARCH_BY_URL_BRAND_MODEL'
export const LOADING_SEARCH_BRANDS = 'LOADING_SEARCH_BRANDS'
export const LOADING_BRAND = 'LOADING_BRAND'