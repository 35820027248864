import source from 'assets/images/interests/naranja/source.png'
import source_checked from 'assets/images/interests/blanco/source.png'
import tachometer from 'assets/images/interests/naranja/tachometer.svg'
import tachometer_checked from 'assets/images/interests/blanco/tachometer.png'
import gas_station from 'assets/images/interests/naranja/gas-station.svg'
import gas_station_checked from 'assets/images/interests/blanco/gas_station.png'
import transmission from 'assets/images/interests/naranja/transmission.svg'
import transmission_checked from 'assets/images/interests/blanco/transmission.png'
import eco_car from 'assets/images/interests/naranja/eco-car.svg'
import eco_car_checked from 'assets/images/interests/blanco/eco-car.png'
import car_seat from 'assets/images/interests/naranja/car-seat.svg'
import car_seat_checked from 'assets/images/interests/blanco/car-seat.png'
import drive_on_mountain from 'assets/images/interests/naranja/drive-on-mountain.svg'
import drive_on_mountain_checked from 'assets/images/interests/blanco/drive-on-mountain.png'

export const listInterestAll = [
		{ 
			id:2, 
			title:'Procedencia', 
			value:'procedencia', 
			info: {imagen: source, title: "Procedencia", subtitle: "País de procedencia"},
			imagenDefault: source, 
			imagenChecked: source_checked,
			isChecked: false,
			name: 'vOrigin',
			type: 'interest',
			listado: [
				{ id: 1, title: 'Alemania', idInterest: 2, isChecked: false, value: 'Alemania', name: 'vOrigin', valueForm: 'Alemania', slug: 'alemania' },
				{ id: 2, title: 'China', idInterest: 2, isChecked: false, value:'China', name: 'vOrigin', valueForm:'China', slug: 'china' },
				{ id: 3, title: 'Corea del Sur', idInterest: 2, isChecked: false, value: 'Corea del Sur', name: 'vOrigin', valueForm: 'Corea del Sur', slug: 'corea-del-sur' },
				{ id: 4, title: 'España', idInterest: 2, isChecked: false, value: 'Espana', name: 'vOrigin', valueForm: 'Espana', slug: 'espana' },
				{ id: 5, title: 'Estados Unidos', idInterest: 2, isChecked: false, value: 'Estados Unidos', name: 'vOrigin', valueForm: 'Estados Unidos', slug: 'estados-unidos' },
				{ id: 6, title: 'Francia', idInterest: 2, isChecked: false, value: 'Francia', name: 'vOrigin', valueForm: 'Francia', slug: 'francia' },
				{ id: 7, title: 'India', idInterest: 2, isChecked: false, value: 'India', name: 'vOrigin', valueForm: 'India', slug: 'india' },
				{ id: 8, title: 'Inglaterra', idInterest: 2, isChecked: false, value: 'Inglaterra', name: 'vOrigin', valueForm: 'Inglaterra', slug: 'inglaterra' },
				{ id: 9, title: 'Italia', idInterest: 2, isChecked: false, value: 'Italia', name: 'vOrigin', valueForm: 'Italia', slug: 'italia' },
				{ id: 10, title: 'Japón', idInterest: 2, isChecked: false, value: 'Japon', name: 'vOrigin', valueForm: 'Japon', slug: 'japon' },
				{ id: 11, title: 'Malasia', idInterest: 2, isChecked: false, value: 'Malasia', name: 'vOrigin', valueForm: 'Malasia', slug: 'malasia' },
				{ id: 12, title: 'República Checa', idInterest: 2, isChecked: false, value: 'Republica Checa', name: 'vOrigin', valueForm: 'Republica Checa', slug: 'republica-checa' },
				{ id: 13, title: 'Rusia', idInterest: 2, isChecked: false, value: 'Rusia', name: 'vOrigin', valueForm: 'Rusia', slug: 'rusia' },
				{ id: 14, title: 'Serbia', idInterest: 2, isChecked: false, value: 'Serbia', name: 'vOrigin', valueForm: 'Serbia', slug: 'serbia' },
				{ id: 15, title: 'Suecia', idInterest: 2, isChecked: false, value: 'Suecia', name: 'vOrigin', valueForm: 'Suecia', slug: 'suecia' }
			]	
		},
		{ 
			id:3, 
			title:'Rendimiento Km/L', 
			value:'rendimiento', 
			info: {imagen: tachometer, title: "Rendimiento Km/L", subtitle: "Km/L"},
			imagenDefault: tachometer, 
			imagenChecked: tachometer_checked,
			isChecked: false,
			name: 'vPerformance',
			type: 'interest',
			listado: [
				{ id: 1, title: 'Menos de 10 Km/L ', slug: '9-kilometros', idInterest: 3, isChecked: false, value: {min: 0, max: 10}, name: 'vPerformance', valueForm: 'performance_1'  },
				{ id: 2, title: '10 a 15 Km/L', slug: '15-kilometros', idInterest: 3, isChecked: false, value: {min: 10, max: 15}, name: 'vPerformance', valueForm: 'performance_2' },
				{ id: 3, title: 'Más de 15 Km/L', slug: '16-kilometros', idInterest: 3, isChecked: false, value: {min: 15, max: 100}, name: 'vPerformance', valueForm: 'performance_3'  }
			]
		},
		{
			id:4, 
			title:'Combustible', 
			value:'combustible', 
			info: {imagen: gas_station, title: "Combustible", subtitle: ""},
			imagenDefault: gas_station, 
			imagenChecked: gas_station_checked,
			isChecked: false,
			name: 'vFuel',
			type: 'interest',
			listado:  [
				{ id: 1, title: 'Bencina', idInterest: 4, isChecked: false, value: 'gasolina,bencina', name: 'vFuel', valueForm: 'bencina', slug: 'bencina' },
				{ id: 2, title: 'Diesel', idInterest: 4, isChecked: false, value: 'diesel', name: 'vFuel', valueForm: 'diesel', slug: 'diesel' }
			]
		},
		{ 
			id:5, 
			title:'Transmisión', 
			value:'transmision', 
			info: {imagen: transmission, title: "Transmisión", subtitle: ""},
			imagenDefault: transmission, 
			imagenChecked: transmission_checked,
			isChecked: false,
			name: 'vTransmission',
			type: 'interest',
			listado: [
				{ id: 1, title: 'Automático', idInterest: 5, isChecked: false, value:  'automatica,transmision automatica', name: 'vTransmission', valueForm: 'automatico', slug: 'automatico' },
				{ id: 2, title: 'Mecánico', idInterest: 5, isChecked: false, value: 'mecanica,manual,transmision mecanica', name: 'vTransmission', valueForm: 'mecanico', slug: 'mecanico' }
			]
		},
		{ 
			id:6, 
			title:'Eco-Friendly', 
			value:'eco-friendly', 
			info: {imagen: eco_car, title: "Eco-Friendly", subtitle: ""},
			imagenDefault: eco_car, 
			imagenChecked: eco_car_checked,
			isChecked: false,
			name: 'vEcoFriendly',
			type: 'interest',
			listado: [
				{ id: 1, title: 'Híbridos', idInterest: 6, isChecked: false, value: 'hibrido', name: 'vEcoFriendly', valueForm: 'hibrido', slug: 'hibrido' },
				{ id: 2, title: 'Eléctrico', idInterest: 6, isChecked: false, value: 'electrico', name: 'vEcoFriendly', valueForm: 'electrico', slug: 'electrico' }
			]
		},
		{ 
			id:7, 
			title:'Corridas de asientos', 
			value:'corridas-asientos', 
			info: {imagen: car_seat, title: "Corridas de asientos", subtitle: ""},
			imagenDefault: car_seat, 
			imagenChecked: car_seat_checked,
			isChecked: false,
			name: 'vSeatMove',
			type: 'interest',
			listado: [
				{ id: 1, title: '2 corridas', idInterest: 7, isChecked: false, value: 2, name: 'vSeatMove', valueForm: '2-corridas', slug: '2-corridas' },
				{ id: 2, title: '3 corridas', idInterest: 7, isChecked: false, value: 3, name: 'vSeatMove', valueForm: '3-corridas', slug: '3-corridas' }
			]
		},
		{ 
			id:8, 
			title:'Tracción', 
			value:'traccion', 
			info: {imagen: drive_on_mountain, title: "Traccións", subtitle: ""},
			imagenDefault: drive_on_mountain, 
			imagenChecked: drive_on_mountain_checked,
			isChecked: false,
			name: 'vTraction',
			type: 'interest',
			listado: [
				{ id: 1, title: '4x2', idInterest: 8, isChecked: false, value: '4x2', name: 'vTraction', valueForm: '4x2', slug: '4x2' },
				{ id: 2, title: '4x4', idInterest: 8, isChecked: false, value: '4x4', name: 'vTraction', valueForm: '4x4', slug: '4x4' }
			]
		}
	]