import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import logo_orange from 'assets/images/logo.svg'


export default function LoadingUserScreen({ isLoading }) {
    const [showNone, setShowNone] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShowNone(!isLoading);
        }, 200);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);
    
    return (
        <section className={ `LoadingUserScreen ${!isLoading ? 'LoadingUserScreenOpacityNone' : ''} ${showNone ? 'LoadingUserScreenNone' : ''}` }>
            <div className="LoadingContainer">
                <img
                    src={logo_orange}
                    alt="Movicenter"
                />
                <Spinner animation="border" role="status" className='LoadingCircle'>
                    <span className="sr-only">Cargando...</span>
                </Spinner>
            </div>
        </section>
    );
};