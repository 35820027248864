import Slider from 'components/Customs/Carousel/Slider';
import { Link } from 'react-router-dom';
import { SwiperSlide } from 'swiper/react';


const SliderImage = ({ banners, functionModal, options }) => {

	return (
		<section className="container">
			<div className="row header__template--default">
				<div className="col-12">
					<div className="header-home">
						<Slider 
							options= {options}
						>
							{
								banners.length>0 &&
								banners.map((item, key) => (
									<SwiperSlide key={key}>
										{
											(item?.action_type==="N/A" || item?.action_type==="url" || item?.url_banner!=="") ? (
												<>
												{
													item.url_banner?.includes("https://") ?
													<a href={item?.url_banner} target="blank">
														<div className="slider-home">
															<div className="slider-home__image">
																<picture>
																	<source media="(min-width: 600px)" srcSet={item?.ic_desktop_main} />
																	<img 
																		src={item?.ic_mobile_main}
																		width="100%"
																		alt="Movicenter"
																		className="header-home__img"
																	/>
																</picture>
															</div>															
														</div>
													</a>
													:
													<Link to={`/${item?.url_banner}`}>
														<div className="slider-home">
															<div className="slider-home__image">
																<picture>
																	<source media="(min-width: 600px)" srcSet={item?.ic_desktop_main} />
																	<img 
																		src={item?.ic_mobile_main}
																		width="100%"
																		alt="Movicenter"
																		className="header-home__img"
																	/>
																</picture>
															</div>															
														</div>
													</Link>
												}
												</>
											) : (
												<div className="slider-home" onClick={()=>functionModal(item)}>
													<div className="slider-home__image">
														<picture>
															<source media="(min-width: 600px)" srcSet={item?.ic_desktop_main} />
															<img 
																src={item?.ic_mobile_main}
																width="100%"
																alt="Movicenter"
																className="header-home__img"
															/>
														</picture>
													</div>
												</div>
											)
										}
										
									</SwiperSlide>
								))
							}
						</Slider>
					</div>
				</div>
			</div>
		</section>
	)
}

export default SliderImage