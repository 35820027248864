import API from 'services/context/api';
import { 
	LIST_INITIAL_SOAP,
	CHANGE_STATUS_MODAL_SOAP,
	CHANGE_LIST_SOAP
 } from 'services/types/soapType';

import { dataSoap, listCategory } from 'data/sureSoap.json';
import logo_hdi from 'assets/images/logo-hdi.png';
import logo_mapfre from 'assets/images/logo-mapfre.webp';
import logo_renta_sm from 'assets/images/logo-renta-sm.svg';
import endPoints from 'services/routes/';
import { TOKEN_SITE_WEB } from "data/variableKey";

export const requestSuccess = (type, payload) => {
    return { type, payload };
};

export const getListInitialSoap = () => (dispatch, getState) => {
	try{
	  	
	  	let dataInicial = [];

        for(let index in dataSoap){
        	let item = dataSoap[index];
        	
        	let logo = "";
        	let classLogo="";
        	if(item?.company?.title==="HDI"){
        		logo = logo_hdi;
        		classLogo = "img-logo__hdi";
        	}else if(item?.company?.title==="Mapfre"){
        		logo = logo_mapfre;
        		classLogo = "img-logo__mapfre";
        	}else if(item?.company?.title==="Renta Nacional"){
        		logo = logo_renta_sm;
        		classLogo = "img-logo__renta";
        	}

        	let data = {
        		...item,
        		company: {
        			...item.company,
        			logo,
        			classLogo
        		}
        	};
        	
        	dataInicial.push(data);
        }
		const listInitial = dataInicial.filter(item => item.category===listCategory[0]?.slug);

		setTimeout(()=> {
			const payload = { 
				listSureSoap: listInitial, 
				isLoadingSoap: false, 
				listFiltered: listCategory,
				listOriginalSureSoap:  dataInicial
			};
			
			dispatch(requestSuccess(LIST_INITIAL_SOAP, payload));
		}, 1000)

		return { status: true, message: "" };
		
    }catch(error){
      return {status: false, message:error};
    }
};

export const sendSelectedInsurance = (dataInsurance) => async (dispatch, getState) => {
	try{
	  	
		const body = {
			insurance: dataInsurance.insurance,
			wasSuccessfull: dataInsurance.wasSuccessfull
		};

		let response = await API.postApi({
									endPoint: endPoints.soap.registerInsurance,
									data: body,
									nameAPI: TOKEN_SITE_WEB, 
									isToken: true,
									isFormData: false
								});

		let { data, status, message } = response.data;
		
		let insuranceId = data?.id;
		let statusCode = status===200 ? true : false;

		if(status===200){
			localStorage.setItem("location-current", JSON.stringify(dataInsurance.location));
		}

        return { status: statusCode, message, insuranceId };
		
    }catch(error){
      return {status: false, message:error};
    }
};

export const sendSuccesInsurance= (id) => async (dispatch, getState) => {
	try{
		const body = { id };
		let response = await API.putApi({
									endPoint: endPoints.soap.updateInsurance,
									data: body,
									nameAPI: TOKEN_SITE_WEB, 
									isToken: true,
									isFormData: false
								});

		let { status, message } = response.data;

		let statusCode = status===200 ? true : false;
		let messageDescription = message==="Successfull" ? "Exitoso" : "Error al actualizar el estatus del seguro";
        
		return { status: statusCode, message: messageDescription };
		
    }catch(error){
      return {status: false, message:error};
    }
};

export const openCloseModalSoap = () => (dispatch, getState) => {
	try{
		
		const { isModalSoap } = getState().sureSoap;

		const payload = { isModalSoap: !isModalSoap };

		dispatch(requestSuccess(CHANGE_STATUS_MODAL_SOAP, payload));
		
    }catch(error){
      return {status: false, message:error};
    }
};


export const changeTypeSoap = (typeSoap) => (dispatch, getState) => {
	try{
		
		const { listOriginalSureSoap } = getState().sureSoap;

		let listFilteredSoap = listOriginalSureSoap.filter(item => item.category===typeSoap);

		const payload = { listSureSoap: listFilteredSoap };

		dispatch(requestSuccess(CHANGE_LIST_SOAP, payload));
		
    }catch(error){
      return {status: false, message:error};
    }
};