export const TOKEN_MAINTANER = "API-MAINTANER";
export const TOKEN_PROVIDER = "API-PROVIDER";
export const TOKEN_COMPARA = "API-COMPARA";
export const TOKEN_SITE_WEB = "API-SITE-WEB";
export const API_SITE_WEB = process.env.REACT_APP_API_URL;
export const API_FORM = process.env.REACT_APP_API_FORM
export const API_BLOG = process.env.REACT_APP_API_BLOG
export const API_MAINTANER = process.env.REACT_APP_API_BANNER
export const API_COMPARA = process.env.REACT_APP_API_COMPARA
export const APP_TOKEN_FORM = process.env.REACT_APP_TOKEN
export const urlOlx = "http://localhost:5005"
export const enviromment = process.env.REACT_APP_ENVIRONMENT==="development" ? "api-node" : "api-notifications"