import API from 'services/context/api';
import {
	LIST_SUPPLIER,
	LIST_PROVIDER_AUTORIZED
} from 'services/types/providerType';
import { formatListStore, groupStorePerTwo } from 'utils/StoreFunction/toolStore';
import endPoints from 'services/routes/';
import { TOKEN_PROVIDER } from "data/variableKey";


export const requestSuccess = (type, payload) => {
    return { type, payload };
};

export const getListSupplier = (value) => async (dispatch, getState) => {
	try{
		const { categoryProviders } = getState().services;
	
		let response = await API.getApi({
									endPoint: endPoints.store.providers(value), 
									nameAPI: TOKEN_PROVIDER, 
									isToken: true,
									isFormData: false
								});

		let { data, success } = response.data;

		let payload = { listSuppliers: [], usedBrands: [], newBrands: [], groupListStore: [], groupListStoreNew: [], groupListStoreUsed: [] };

		if(success){

			data.sort((a, b) => {
			    if(a.name > b.name) return 1;
			    if(a.name < b.name) return -1;

			    return 0;
			});

			let storeUpdate = ["COMERCIAL DAHER", "FERRIPALDI", "LLANTAS DEL PACIFICO", "NEUMAMERICA", "NEUMASHOP", "NEUMATON"];

			const list = data.map(item => {
				let store = formatListStore(item, categoryProviders)
				store.isData = false
				if(store.name==="RUTA DEL MAIPO"){
					store.url = "/servicio-automotriz/autopistas/ruta-del-maipo";
				}else if(storeUpdate.includes(store.name)){
					store.url = `/servicio-automotriz/neumaticos/${store.slug}`;
					store.isData = true
				}else {
					store.url = `/concesionario/${store.slug}`;
				}
				return store;
			});

			let listNew = list.filter(item => item.type === 1);
			let listUsed = list.filter(item => item.type === 2);
			let listOther = list.filter(item => item.type !== 2 && item.type !== 1);

			let listStoreAll = [...listNew, ...listUsed, ...listOther]

			payload = {
				listSuppliers: listStoreAll, 
				usedBrands: listUsed, 
				newBrands: listNew,
				groupListStore: groupStorePerTwo(listStoreAll),
				groupListStoreNew: groupStorePerTwo(listNew),
				groupListStoreUsed: groupStorePerTwo(listUsed)
			};
		}
		
		dispatch(requestSuccess(LIST_SUPPLIER, payload ));

		return { status: success };
    }catch(error){
      	return { status: false, message: error };
    }
};

export const getListProviderAutorized = (value) => async (dispatch, getState) => {
	try{
		const { categoryProviders } = getState().services;
	
		let response = await API.getApi({
									endPoint: endPoints.store.providers(value), 
									nameAPI: TOKEN_PROVIDER, 
									isToken: true,
									isFormData: false
								});

		let { data, success } = response.data;

		let payload = { listProviderAutorized: [] };

		if(success){

			data.sort((a, b) => {
			    if(a.name > b.name) return 1;
			    if(a.name < b.name) return -1;

			    return 0;
			});
		
			const list = data.map(item => {
				let store = formatListStore(item, categoryProviders)
				store.isData = false
				return store;
			});

			payload = {
				listProviderAutorized: list, 
			};
		}
		
		dispatch(requestSuccess(LIST_PROVIDER_AUTORIZED, payload ));

		return { status: success };
    }catch(error){
      	return { status: false, message: error };
    }
};