import { Link } from 'react-router-dom'
import styles from 'styles/offer.module.css';

const MenuItem = ({ menu, services }) => {
    
    if(menu.link){
        return <li>
            <Link to={menu.to} className="menu__desktop--link">
                {menu.text} {menu.isNew && <span className={`badge ${styles["badge-new"]}`}>Nuevo</span>}
            </Link>
        </li>
    }

    if(!menu.link){
        return (
            <li className="menu-dropdown">
                <Link to={menu.to} className="menu__desktop--link dropdown-service">
                    {menu.text}
                </Link>
                <div className="dropdown-container">
                    <section className="dropdown-content">
                        {services.listOne.length > 0 && (
                            <div className="dropdown-content--left">
                                {services.listOne.map((item, key) => 
                                    <Link key={key} to={item.url} className="menu-option menu-option--first">
                                        <span className={item?.isNew ? "menu-bold" : ""}>{item.name}</span> {item.isNew && <span className={`badge ${styles["badge-new"]}`}>Nuevo</span>}
                                    </Link>
                                )}
                            </div>
                        )}
                        {services.listTwo.length > 0 && (
                            <div className="dropdown-content--right">
                                {services.listTwo.map((item, key) => 
                                    <Link key={key} to={item.url} className="menu-option menu-option--first">
                                        <span className={item?.isNew ? "menu-bold" : ""}>{item.name}</span> {item.isNew && <span className={`badge ${styles["badge-new"]}`}>Nuevo</span>}
                                    </Link>
                                )}
                            </div>
                        )}
                   </section> 
                </div>
            </li>
        )
    }
}

export default MenuItem