import Slider from 'components/Customs/Carousel/Slider'
import { SwiperSlide } from 'swiper/react'
import { Link } from 'react-router-dom'

const ServiceCarousel = ({ title, listado, classColor,classContainer, classTitle, spanTitle, lastPage }) => {

	return (
		<div className={`row container__interest ${classColor} pb-md-5 mb-md-5`}>
			<div className="col-12">
				<p className={`container__interest__title ${classTitle}`}>{title}
					<span className="panel__title--orange">{spanTitle}</span>
				</p>
			</div>
			<div className="col-12">
				
					<div className={classContainer}>
						<Slider 
							options= {{
									className: "slide-service container",
									slidesPerView: 4, 
									slidesPerColumn: 1,
									spaceBetween: 5,
									grabCursor: true,
									navigation: true,
									pagination: false,
									breakpoints:{
			                              "300": {
			                                "slidesPerView": 2,
			                                "spaceBetween": 15,
			                              },
			                              "500": {
			                                "slidesPerView": 3,
			                                "spaceBetween": 15,
			                              },
			                              "768": {
			                                "slidesPerView": 3,
			                                "spaceBetween": 20,
			                              },
			                              "992": {
			                                "slidesPerView": 4,
			                                "spaceBetween": 30
			                              },
			                              "1200": {
			                                "slidesPerView": 5,
			                                "spaceBetween": 22
			                              }
			                        }
								}}
						>
							{
								listado.map((item, index) => 
									<SwiperSlide key={index}>
										<Link 
											to={{
												pathname: item.urlFriendly,
												state: lastPage
											}}
											className="service__details--link"
										>
											<div className="service__details--used">
												<img src={item.background_image} alt={item.name} className="service__image"/>
												<div className="opacity-services--used"></div>
												<span className="service__title">{ item.name }</span>
											</div>
										</Link>
									</SwiperSlide>)
							}
						</Slider>
					</div>
			
			</div>
		</div>

	)
}

export default ServiceCarousel 