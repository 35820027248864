import icon_delete from 'assets/images/icons/icon-delete.svg'
import { addRemoveMyRoute } from 'services/actions/carActions'

import { useDispatch } from 'react-redux'


const DeleteRoute = ({ id }) => {

	const dispatch = useDispatch()

	const addRemoveRoute = (id) => {
		dispatch(addRemoveMyRoute(id, false))
	}

	return (
		<div className="car-selected__deleted" onClick={()=>addRemoveRoute(id)}>
			<img src={icon_delete} alt="Eliminar" />
		</div>
	)
}

export default DeleteRoute