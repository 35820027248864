import { Helmet } from 'react-helmet'
import logo from 'assets/images/favicon.png'

const MetaHead = ({ title, description, url }) => (
  <Helmet>
    {typeof title!=="undefined" && <title>{`${title}`}</title>}
    <meta name="description" content={description} />
    <meta
      name="robots"
      content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
    />
    <link rel="canonical" href={url} />

     <meta property="og:locale" content="es_ES" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:quote" content={description} />
    <meta property="og:url" content={url} />
    <meta property="og:site_name" content={url} />
    <meta property="og:image:width" content="1280" />
    <meta property="og:image:height" content="720" />
    <meta content="image/*" property="og:image:type" />

    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="@Movicenter" />
    <meta name="twitter:creator" content="@Movicenter" />
    <meta name="twitter:title" content={`Movicenter ${title}`} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content={logo} />

    <meta name="theme-color" content="#F47422"/>
    <meta name="msapplication-navbutton-color" content="#F47422"/>
    <meta name="apple-mobile-web-app-status-bar-style" content="#F47422"/>
  </Helmet>
);

export default MetaHead;
