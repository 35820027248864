import { addNewInsterest } from 'services/actions/searchActions'
import { useDispatch } from 'react-redux'

const ListItems = ({ items }) => {
	
	const dispatch = useDispatch()

	const saveOption = (item) => {
		dispatch(addNewInsterest(item, "add"))
	}

	return (
		<section className="options-interest">
			{
				items.map((option, index) => (
					<div className="search-form" key={index}>
					  	<input 
					  		className="search-form-check search-form-check--orange" 
					  		type="checkbox" 
					  		checked={option.isChecked}
					  		id={option.id}
					  		onChange={()=>saveOption(option)}
					  	/>
						<label className="marca-form-label" htmlFor={option.id} >{option.title}</label>
					</div>
				))
			}
		</section>
	)
}

export default ListItems