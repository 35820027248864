import { Fragment } from "react"
import CardVehicle from 'components/Customs/CardVehicle/CardVehicle'
import BannerEventFill from "components/Customs/Banner/Horizontal/BannerEventFill"

const ListMotoByMobile = ({ infoTypeMoto, actionAdvertising }) => {

	return (
		<Fragment>
			{
				infoTypeMoto.length>0 &&
				infoTypeMoto.map((item, key) => {
					if(item.isBanner===false){
						return (
							<CardVehicle key={key} {...item} />
						)
					}else {
						return (
							<BannerEventFill 
                                key={key}
                                imagen={item.banners}
                                functionModal={actionAdvertising}
                                title="Banner" 
                                classContainer="moto-result" 
                                classPicture="moto-result"
                                classMain=""
                            />
                        )
                    }
                })
            }
		</Fragment>
	)
}

export default ListMotoByMobile