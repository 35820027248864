import { Controller } from "react-hook-form"


const TypeSelect = ({ 
	titleLabel, titleSelect, name, id, listado, isLabel=true, isOptionValue=true,
	 control, defaultValue, handleClickUpdateResult, setValue
}) => {


	return (
		<div className={`search-advanced_form ${!isLabel && 'w-100'}`}>
			{isLabel && <label htmlFor={id} className="search-advanced--label">{titleLabel}</label>}
			<Controller
				control={control}
				name={name}
				rules={{ required: true }}
				id={id} 
				render = {(props) => {
					return (
					<select 
						id={props.id} 
						value={props.value}
						className="form-control search-advanced_form__control"                     
						onChange={e => {                              
							props.onChange(e.target.value);
							handleClickUpdateResult({name})
						}}                            
					>
						{ isOptionValue && <option value="">{titleSelect}</option> }
						{
							listado.map((item, index) => <option key={index} value={item.value}>{item.title}</option>)
						}
					</select>
				)}}
				defaultValue={defaultValue}
			/>	
			
		</div>
	)
}

export default TypeSelect