import API from 'services/context/api';
import { 
	STATUS_SCANEO,
	LOADING_SCANEO
} from 'services/types/couponType';
import endPoints from 'services/routes/';
import { TOKEN_PROVIDER } from "data/variableKey";

export const requestSuccess = (type, payload) => {
    return { type, payload };
};

export const verifiedCheckQR = (tokenQR) => async (dispatch, getState) => {
	
	try{

		dispatch(requestSuccess(LOADING_SCANEO, true));

		let titleError = "¡Ups!";
		let subtitleError = "Tu cupón de descuento es inválido. Ha sido utilizado anteriormente.";
		let btnTitleError = "Ok";

		let titleSuccess = "¡Gracias!";
		let subtitleSuccess = "Tu cupón de descuento ha sido escaneado con éxito, pronto tendrás noticias nuestras.";
		let btnTitleSuccess = "Ok";
		
		let body = { c: tokenQR };

		let response = await API.postApi({
									endPoint: endPoints.coupon.exchange, 
									data: body,
									nameAPI: TOKEN_PROVIDER, 
									isToken: true,
									isFormData: false
								});
		
		let isSuccessFull = response.data.success;

		const message = { 
			success: isSuccessFull, 
			title: isSuccessFull ? titleSuccess : titleError, 
			subtitle: isSuccessFull ? subtitleSuccess : subtitleError, 
			btnTitle: isSuccessFull ? btnTitleSuccess : btnTitleError
		};

		const payload = { message, loadingScaneo: false };

		dispatch(requestSuccess(STATUS_SCANEO, payload));

		return { status: isSuccessFull, message: "" };
	}catch(error){
        return { status: false, message: error };
    }
};