import { Modal } from 'react-bootstrap'
import car_insurance from 'assets/images/car-insurance.png'
import car_insurance_desktop from 'assets/images/car-insurance@2x.png'

const ModalSuccess = ({ show, handleClose }) => {


	return (
		<Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w__soap"
        centered
      >
        <Modal.Body className="notification__container">
          <div className="notification-icon notification-soap">
            <picture>
                <source media="(min-width: 768px)" srcSet={car_insurance_desktop} />
                <img src={car_insurance} alt="Auto asegurado" />
            </picture>
          </div>
          <div className="notification-text">
          	<h1 className="notification-text__title notification-text__title--soap">¡Todo Listo!</h1>
          	<p className="notification-text__subtitle pl-3 pr-3">
                El pago de tu Seguro Obligatorio SOAP 2022 se realizó exitosamente
          	</p>
            <p className="notification-text__small mb-5">En los próximos minutos recibirás un correo de Compara Online con tu póliza</p>
            <div>
            <button 
              type="button" 
              className="btn btn-primary btn-lg w-100 btn-formulario" 
              onClick={()=>handleClose()}
            >Ok</button>
            </div>

          </div>
        </Modal.Body>
      </Modal>
	)
}

export default ModalSuccess