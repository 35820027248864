import { Accordion, Card, useAccordionToggle } from "react-bootstrap"
import Arrow from 'assets/images/icons/down-arrow-2.svg'

const CardAccordion =({ title, index, activeKey, children, classHeader, classBody }) => {

    const CustomerHead = ({children, eventKey, callback}) => {
        const decoratedOnClick = useAccordionToggle(
           eventKey,
           () => callback && callback(eventKey),
         );
       return (<div className={`accordion-header card-header d-flex w-100 justify-content-between ${activeKey === eventKey ? "card-header--selected" : "" }`}
                    onClick={decoratedOnClick}>
                   <p>{children}</p>
                   <img 
                        src={Arrow} 
                        alt=""  
                        className={`accordion-quote__imagen ${activeKey === eventKey ? "accordion-quote__imagen-top" : "accordion-quote__imagen-bottom"}`}/>
               </div>)
     }

    return (
        <Card>
            <CustomerHead as={Card.Header} eventKey={`${index}`} classHeader={classHeader}>
                {title}
            </CustomerHead>
            <Accordion.Collapse eventKey={`${index}`}>
                <Card.Body className={`${classBody!=="" ? `${classBody}` : ""}`}>
                    {children}
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}

export default CardAccordion