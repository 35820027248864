import API from 'services/context/api';
import { SET_DATA_FORM } from 'services/types/notificationType'
import endPoints from 'services/routes/';
import { TOKEN_PROVIDER, TOKEN_SITE_WEB } from "data/variableKey";

export const requestSuccess = (type, payload) => {
    return { type, payload };
};

export const sendFormNotification = (body, urlLast) => async (dispatch, getState) => {
	try{

		///dispatch(requestSuccess(LOADING_SEND_MY_ROUTE, true ))
		let dataSend = {...body};
		
		let params = localStorage.getItem("params");
		if(params){
			const listParamsSend = ["utm_source", "utm_medium", "utm_campaign", "utm_content", "fbclid"];

			params = params?.replaceAll("?","");
			const paramsSplit = params.split("&");
			let listParamsGet = [];
			for(let index in paramsSplit){

				const dataParams = paramsSplit[index].split("=");
				listParamsGet = [
					...listParamsGet,
					{
						title: dataParams[0],
						value: decodeURIComponent(dataParams[1])?.trim()
					}
				];				
			}
			for(let index in listParamsSend){

				const validSendParam = listParamsGet.find(item => item.title === listParamsSend[index]);
				if(validSendParam){
					dataSend = {
						...dataSend,
						[listParamsSend[index]]: validSendParam?.value ?? null
					}
				}
			}
		}
		
		let response = await API.postApi({
							endPoint: endPoints.car.formContacts, 
							data: dataSend,
							nameAPI: TOKEN_PROVIDER, 
							isToken: true,
							isFormData: false
						});

		let { success, message, data } = response.data;

		if(success){
			const dataForm = {
				name: dataSend.name,
				telephone: dataSend.telephone,
				email: dataSend.email,
				message: dataSend.message,
				eventId: data?.dataCreate.length>0 ? data?.dataCreate[0]?.id : null
			}
			localStorage.setItem('urlLast', urlLast)
			const payload = { data: dataForm, urlLast }
			dispatch(requestSuccess(SET_DATA_FORM, payload));
		}

		return { status: success, message };

    }catch(error){
      	return { status: false, message: error };
    }
};

export const sendFormEvenLoad = (body) => async (dispatch, getState) => {
	try{
		let response = await API.postApi({
									endPoint: endPoints.events.sendLoads, 
									data: body,
									nameAPI: TOKEN_SITE_WEB, 
									isToken: true,
									isFormData: false
								});

		let { isSuccessFull, message } = response.data;

		return { status: isSuccessFull, message };
    }catch(error){
      return {status: false, message:error};
    }
};

export const sendFormNotificationByBrand = (body, urlLast) => async (dispatch, getState) => {
	try{

		let response = await API.postApi({
									endPoint: endPoints.contact.formSend, 
									data: body,
									nameAPI: TOKEN_PROVIDER, 
									isToken: true,
									isFormData: false
								});

		let { success, message, data } = response.data;

		if(success){
			const dataForm = {
				name: body.name,
				telephone: body.telephone,
				email: body.email,
				message: body.message,
				eventId: data?.id ?? null
			}
			localStorage.setItem('urlLast', urlLast)
			const payload = { data: dataForm, urlLast }
			dispatch(requestSuccess(SET_DATA_FORM, payload));
		}

		return { status: success, message };

    }catch(error){
      	return { status: false, message: error };
    }
};

export const sendFormNotificationScheduledSearch = (data, urlLast) => async (dispatch, getState) => {
	try{

		let { itemsForm } = getState().search;
		let { listKeys } = getState().cars;

		let body = { 
			email: data.email, 
			searched: { ...itemsForm, isFromInterest: false, keys: listKeys }
		};

		let response = await API.postApi({
									endPoint: endPoints.car.scheduledSearch, 
									data: body,
									nameAPI: TOKEN_SITE_WEB, 
									isToken: true,
									isFormData: false
								});

		let { isSuccessFull, message } = response.data;

		if(isSuccessFull){
			const dataForm = {
				name: body.name,
				telephone: body.telephone,
				email: body.email,
				message: body.message,
				eventId: data?.contestId ?? null
			}
		
			localStorage.setItem('urlLast', urlLast)
			const payload = { data: dataForm, urlLast }
			dispatch(requestSuccess(SET_DATA_FORM, payload));
		}

		return { status: isSuccessFull, message };
    }catch(error){
      return {status: false, message:error};
    }
}