import { useEffect } from "react";
import { getListInitialTires, getListInitialServices, getTireByStore } from 'services/actions/serviceActions'
import { getDetailById } from 'services/actions/concessionaireActions'
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom'

export const useSearchByStore = (slug) => {

	const dispatch = useDispatch()
    const history = useHistory()

	const { services, concessionaire } = useSelector((state) => {
        return {
            services: state.services,
            concessionaire: state.concessionaire,
        }
    })
    const { measures, resultTires, tireSelectedQuote, categoryProviders } = services
    const { detailConcessionare } = concessionaire


	const getDataInitial = async (slug) => {
		dispatch(getListInitialTires())
		if(categoryProviders.length===0){
			await dispatch(getListInitialServices())
		}

		const response = await dispatch(getDetailById(slug))
        if(response.status){
            await dispatch(getTireByStore(response.title))
        }
	}

    const historyBack = () => {
		history.go(-1)
	}

	useEffect(() => {
		getDataInitial(slug)
		//ReactGA.pageview(window.location.pathname)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [slug])

	

	const state = {
		measures,
		tireSelectedQuote,
		resultTires,
        detailConcessionare
	}

	const actions = {
		historyBack
	}

	return {
		state,
		actions
	}
}