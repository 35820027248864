import CardTitle from "components/QuoteCar/Sire/Custom/CardTitle"
import CustomVehicle from "components/QuoteCar/Sire/Custom/CustomVehicle"
import { useDataVehicle } from "hooks/Quotes/Sire/useDataVehicle"

const FormVehicle = ({ nextFormNamePrev }) => {

    const { onSubmit, handleSubmit, control, listInfoCompara, 
        errors, changeBrands, isProcess, register, dataFormQuote } = useDataVehicle()

    return (
        <CardTitle title="Datos del vehículo" activeKey={1}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <CustomVehicle 
                    control={control}
                    changeBrands={changeBrands}
                    listInfoCompara={listInfoCompara}
                    errors={errors}
                    isProcess={isProcess}
                    register={register}
                    data={dataFormQuote}
                />
                <button type="submit" className="btn btn-primary btn-quote-sire mb-4">Continuar</button>
            </form>
        </CardTitle>
    )
}

export default FormVehicle