const CardTitle = ({ title, activeKey, children }) => {

    return (
        <section className="quote-sure__main">
            <p className="quote-sure__main__title">{ title }</p>
            <div className="quote-sure__process">
                <div className={`sure-process__item ${activeKey>=1 ? 'active' : ''}`}></div>
                <div className={`sure-process__item ${activeKey>=2 ? 'active' : ''}`}></div>
                <div className={`sure-process__item ${activeKey>=3 ? 'active' : ''}`}></div>
            </div>
            {children}
        </section>
    )
}

export default CardTitle