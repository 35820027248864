const CaracteristTransportation = ({ classIcon, title, value}) => {
	return (
		<div className="car-features">
			<span className={classIcon}></span>
			<div className="car-feature--details">
				<p className="car-feature__title">{title}</p>
				<p className="car-feature__value">{value}</p>
			</div>
		</div>
	)
}

export default CaracteristTransportation