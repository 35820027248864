import { LIST_NEW_BLOG, POST_INFO, LOADING_POST } from 'services/types/blogType'

const INITIAL_STATE = {
	news: [],
	lastPost: {},
	listViewBlog: [],
	infoPost: {},
	listPostNews: [], 
	loadingPost: true
}

const blogReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case LIST_NEW_BLOG:
			return {
				...state,
				news: action.payload.list,
				lastPost: action.payload.lastNew,
				listViewBlog: action.payload.listViewBlog,
				listPostNews: action.payload.listPostNews,
				loadingPost: action.payload.loadingPost
			}
		case POST_INFO:
			return {
				...state,
				infoPost: action.payload
			}
		case LOADING_POST:
			return {
				...state,
				loadingPost: action.payload
			}
		default: return state
	}
}

export default blogReducer