import { verifiedCheckQR } from 'services/actions/couponAction'
import { useDispatch, useSelector } from 'react-redux'

export const useCoupon = () => {

	const dispatch = useDispatch()
	const { loadingScaneo, message } = useSelector((state) => state.coupons)	

	const verifiedInitial = async (token) => {
		await dispatch(verifiedCheckQR(token))
	}

	return {
		message,
		loadingScaneo,
		verifiedInitial
	}
}