import { useState } from 'react'
import { addRemoveMyRoute } from 'services/actions/carActions'

import { useDispatch } from 'react-redux'

const ItemRoute = ({id, isMyRouter }) => {

	const dispatch = useDispatch()
	const [ visible, setVisible ] = useState(false)

	const addRemoveRoute = (id, isMyRouter) => {

		dispatch(addRemoveMyRoute(id, !isMyRouter))
		if(!isMyRouter){
			setVisible(true)
			setTimeout(()=>setVisible(false), 2000)
		}
		
	}

	return (
		<div className="favorite-car">
			{visible && (
				<div className="message-result">
					<span></span>
					<p>Tu auto se agrego a favorito satisfactoriamente</p>
				</div>
			)}
			<p 
				className={isMyRouter ? 'my-route__icon--orange' : 'my-route__icon--gray'}
				onClick={()=>addRemoveRoute(id, isMyRouter)}

			>Favorito</p> 
		</div>
	)
}

export default ItemRoute