import NotificationForm from 'components/Customs/Modal/NotificationForm'
import { useFormContactRoute } from "hooks/Contact/useFormContactRoute";

const FormContactRoute = ({ infoSendItem, valueBtnSend }) => {

	const { handleActive, disabledBtn, register, handleSubmit, 
		onSubmit, isOpen, closeModal, message, 
		isLoading, errors, newDateToday  } = useFormContactRoute(valueBtnSend);
	


	return (
		<div className="mt-5 mb-5 route-form-contact">
			<div className={`card card__container bg-gray border-0`}>
				<div className={`card__body`}>
					<form 
						className="route-contact w-100 pt-3 pb-4 text-left" 
						onSubmit={handleSubmit(data => onSubmit(data, infoSendItem))}
					>
						<p className="route-opciones__title mb-0">Contacto</p>
						<div className="route-contact__text">
							<p className="route-opciones__subtitle">Rellena tus datos</p>
							<p className="route-opciones__help">* Campos obligatorios</p>
						</div>
						<div className="route-opciones__form">
							<div className="form-group route-group">
								<label htmlFor="name">Nombre*</label>
								<input 
									type="text" 
									className="form-control route__form-input" 
									id="name" 
									name="name"
									aria-describedby="nameHelp"
									readOnly={valueBtnSend} 
									maxLength={160}
									ref={register({required: {value: true, message: 'Requerido'}})} 
									onKeyPress={(event) => {
										if (!/[a-zA-Z Ññ]/.test(event.key)) {
										event.preventDefault();
										}
									}}
								/>
								{errors.name && (
									<span className="text-danger">
										{errors?.name?.message}
									</span>
								)} 
							</div>
							<div className="form-group route-group">
								<label htmlFor="telephone">Teléfono</label>
								<p className="span-telephone-contact"> +569</p>
								<input 
									type="text" 
									className="form-control route__form-input form__contact-input--telephone-route" 
									id="telephone" 
									name="telephone"
									aria-describedby="telephoneHelp" 
									readOnly={valueBtnSend} 
									maxLength={8}
									ref={register({
										required: {value: true, message: 'Requerido'},
										minLength: { value: 8, message: 'Mínimo 8 Caracteres'},
										maxLength: { value: 8, message: 'Máximo 8 Caracteres'},
									})} 
									pattern="[0-9]+"
									onKeyPress={(event) => {
										if (!/[0-9]/.test(event.key)) {
										event.preventDefault();
										}
									}} 
								/>
								{errors.telephone && (
									<span className="text-danger">
										{errors?.telephone?.message}
									</span>
								)}
							</div>
							<div className="form-group route-group">
								<label htmlFor="email">Email*</label>
								<input 
									type="email" 
									className="form-control route__form-input" 
									id="email" 
									name="email"
									aria-describedby="emailHelp" 
									readOnly={valueBtnSend} 
									maxLength={160}
									ref={register({
										required: {value: true, message: 'Requerido'},
										//eslint-disable-next-line
										pattern: { value: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, message: 'Email inválido'}
									})} 
									onKeyPress={(event) => {
										if (!/[0-9@a-zA-ZÑñ.]/.test(event.key)) {
										event.preventDefault();
										}
									}}
								/>
								{errors.email && (
									<span className="text-danger">
										{errors?.email?.message}
									</span>
								)} 
							</div>
							<div className="form-group route-group">
								<label htmlFor="surveillanceDate">Agenda tu visita</label>
								<input 
									type="date" 
									className="form-control route__form-input" 
									id="surveillanceDate"
									name="surveillanceDate" 
									aria-describedby="surveillanceDateHelp" 
									readOnly={valueBtnSend} 
									ref={register} 
									min={newDateToday}
								/>
							</div>
							<div className="form-group route-check">
								<input 
									className="search-form-check search-form-check--route route__form-check btn-captcha-check" 
									type="checkbox" 
									value={true}
									id="acceptTerms" 
									name="acceptTerms"
									defaultChecked 
									disabled={valueBtnSend} 
									ref={register} 
									onChange={(event)=>handleActive(event)}
								/>
								<label 
									className="route__form-label" 
									htmlFor="acceptTerms">
									Acepto <a 
										href="https://movicenter-web.s3.us-east-2.amazonaws.com/docs/TEERMINOS+Y+CONDICIONES+MOVICENTER.pdf" 
										target="_blank"
										rel="noreferrer"
									>términos y condiciones</a> y políticas de privacidad
									
								</label>
							</div>
							<div className="form-group">
								{ message.visible  && <p className="message-error">{message.text}</p>}
								<button 
									type="submit" 
									className="btn btn-primary btn-lg w-100 btn-captcha-form"
									disabled={valueBtnSend || disabledBtn} 
								>{isLoading ? 'Procesando...' : 'Enviar' }</button>
							</div>
						</div>
					</form>
				</div>
			</div>
			

			<div className={`card card__container bg-gray border-0 mt-5`}>
				<div className={`card__body`}>
					<div className="route-opciones">
						<p className="route-opciones__title text-center">¿Como llegar a Movicenter?</p>
						<p className="route-opciones__subtitle">Visita el tour virtual</p>
						<div className="route__btn">
							<div className="w-100">
								<a 
									href="https://goo.gl/maps/K6qgqme31d4JfowT8"
									target="_blank"
									className="btn btn-primary btn-lg btn-primary-size"
									rel="noreferrer"
								>Visitar</a>
							</div>
						</div>
					</div>
				</div>
			</div>

			{ isOpen && 
				<NotificationForm 
					show={isOpen} 
					handleClose={closeModal} 
					message={message}
					dialogClassName=""
				/>
			}
		</div>
	)
}

export default FormContactRoute