import { useState } from "react"
import logo_white from 'assets/images/logo-white.svg'
import closed_menu from 'assets/images/icons/delete-menu.svg'
import { listMenu } from "routes/route-main"

import { useHistory } from 'react-router-dom'

const Menu = ({ handleClosed, hiddenColor, openActionModal }) => {

	const history = useHistory();
	const [ menuServiceOpen, setMenuServiceOpen ] = useState(false)
	const [ listItem, setListItem ] = useState(false)


	const openService = (event, menu) => {
		event.preventDefault()
		setListItem(menu)
	
		setMenuServiceOpen(!menuServiceOpen)		
	}

	const closeMenu = (event, url) => {
		event.preventDefault()
		setMenuServiceOpen(false)
		handleClosed()
		history.push(url)
	}

	const closeMenuLink = (event, url) => {
		event.preventDefault()
		setMenuServiceOpen(false)
		handleClosed()
		history.push(url)
	}

	return (
		<div className="menu bg-orange">
			<div className="menu__logo">
				<div className="menu__logo--closed">
					<img src={closed_menu} alt="Cerrar" onClick={()=>handleClosed()} />
				</div>
				<div className="menu__logo--white">
					<a href="/"><img src={logo_white} alt="Logo" /></a>
				</div>
			</div>
			{
				!menuServiceOpen && (
					<div className="menu__item">
						<ul>
							{
								listMenu.length>0 && listMenu.filter(item=> item.visibleMob===true).map((menu, key) => (
									menu.link ? (
										<li key={key}>
											<a href="!#" className="menu__item--link" onClick={(event)=>closeMenuLink(event, menu?.to)}>
												{menu.textMob}
											</a>
										</li>
									) : (
										<li key={key}>
											<a href="!#" className="menu__item--link" onClick={(event)=>openService(event, menu)}>
												{menu.textMob}
											</a>
										</li>
									)
								))
							}
							{/**<li><button 
										className={`menu__desktop--link btn-soap__header--white`}
										onClick={()=>openActionModal("olx")}
								>Vende tu auto</button></li>**/}
							{/**<li><button 
										className={`menu__desktop--link btn-soap__header--white`}
										onClick={()=>openActionModal("compara")}
								>Cotiza tu seguro</button></li>**/}
							{/**<li className="list-event">
								<button type="button" 
									className="btn footer-event"
									onClick={()=>openActionModal("compara")}
								>Cotiza tu seguro</button>
							</li>
							<li className="list-event">
								<a 
									className={`menu__desktop--link btn-soap__header--white mb-4`}
									href="https://boffice.clicar.cl/citapisowpyapo/index?comeFrom=wp"
									target="_blank"
									rel="noreferrer"
								>Vende tu auto</a>
							</li>**/}
						</ul>
					</div>
				)
			}
			{
				menuServiceOpen && (
					<div className="menu__item">
						{
							Object.keys(listItem).length>0 && (
								<>
									<p className="menu__item--service">{listItem.textMob}</p>
									<ul>
										{listItem.listOne.length>0 && listItem.listOne.map((item,key)=>
											<li key={key}>
												<a href="!#" onClick={(event) =>closeMenu(event, item.to)} className="menu__item--link">{item.text}</a>
											</li>
										)}
										{listItem.listTwo.length>0 && listItem.listTwo.map((item,key)=>
											<li key={key}>
												<a  href="!#" onClick={(event) =>closeMenu(event, item.to)} className="menu__item--link">{item.text}</a>
											</li>
										)}
									</ul>
								</>
							)
						}
					</div>
				)
			}
			
		</div>
	)
}

export default Menu