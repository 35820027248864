import API from 'services/context/api';
import endPoints from 'services/routes/';
import {
	LOADING_TOKEN
} from 'services/types/secureType';
import CryptoJS from 'crypto-js'
import { TOKEN_MAINTANER, TOKEN_SITE_WEB } from "data/variableKey";

export const requestSuccess = (type, payload) => {
    return { type, payload }
}

export const createTokenSite = () => async (dispatch, getState) => {
    try{
       
        const body = {
            email: "admin@example.com",
            password: CryptoJS.AES.encrypt("Wingsoft.2021$", "emptytoken").toString()
        }
   
		let response = await  API.postApi({
                                    endPoint: endPoints.secure.generateTokenSite, 
                                    data: body,
                                    nameAPI: TOKEN_SITE_WEB, 
                                    isToken: true,
                                    isFormData: false
                                });

        const { data, status, message } = response.data;
        localStorage.setItem('userMovSite', data ?? "");

        return {
            status: status===200 ? true : false,
            message
        }
		
	}catch(error){
      	return { status: false, message: error }
    }
}

export const createTokenMaintaner = () => async (dispatch, getState) => {
    try{
        
        const body = {
            email: "admin@example.com",
            password: CryptoJS.AES.encrypt("Wingsoft.2021$", "emptytoken").toString()
        }
   
		let response = await  API.postApi({
                                    endPoint: endPoints.secure.generateTokenManintaner, 
                                    data: body,
                                    nameAPI: TOKEN_MAINTANER, 
                                    isToken: true,
                                    isFormData: false
                                });

        const { data, status, message } = response.data;
        localStorage.setItem('userMovAdmin', data ?? "");

        return {
            status: status===200 ? true : false,
            message
        }
		
	}catch(error){
      	return { status: false, message: error }
    }
}



export const loadingGenerateToken = (value) => async (dispatch, getState) => {
    dispatch(requestSuccess(LOADING_TOKEN, value))
}

