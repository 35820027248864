import React from 'react';
import ReactDOM from 'react-dom';
import App from 'routes/App';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'assets/css/normalize.css'
import 'assets/css/mobile/m-template.css'
import 'assets/css/mobile/m-estilos.css'
import 'assets/css/mobile/m-blog.css'
import 'assets/css/mobile/m-PageResult.css'
//import 'assets/css/detailCar.css'
import 'assets/css/mobile/m-services.css'
import 'assets/css/mobile/m-compare.css'
import 'assets/css/desktop/d-template.css'
//import 'assets/css/desktop/d-home.css'
import 'assets/css/desktop/d-home.css'
import 'assets/css/mobile/m-tire-1.css'
import 'assets/css/mobile/m-tire.css'
import 'assets/css/desktop/d-tire.css'
import 'assets/css/mobile/m-result-car.css'
import 'assets/css/desktop/d-result-car.css'

import 'assets/css/desktop/d-my-route.css'
import 'assets/css/desktop/d-compare.css'

import 'assets/css/desktop/d-service.css'
import 'assets/css/mobile/m-message.css'
import 'assets/css/mobile/m-quote-car.css'
import 'assets/css/mobile/m-soap.css'

import 'assets/css/mobile/m-blogs.css'


import { Provider } from "react-redux"
import { createStore, applyMiddleware } from "redux"
import rootReducers from "services/reducers/"
import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"

const store = createStore(
	rootReducers,
	{},
	composeWithDevTools(applyMiddleware(thunk))
)




ReactDOM.render(
  	<Provider store={ store }>
    	<App />
  	</Provider>,
  	document.getElementById('root')
);
