import Cards from 'components/Customs/Panels/Cards'
import DetailService from 'components/CarFile/Services/DetailService'
import DetailServiceEvent from 'components/CarFile/Services/DetailServiceEvent'
import business_car_mobile from 'assets/images/car-file/background_service_mobile.png'
import business_car_desktop from 'assets/images/car-file/background_service_desktop.png'
import business_moto_desktop from 'assets/images/motorcycle/service-moto.png'
import business_moto_mobile from 'assets/images/motorcycle/service-moto-mobile.png'
import sell_motorcycle from 'assets/images/motorcycle/sell-motorcycle.png'
import financing_mobile from 'assets/images/car-file/financing_mobile.svg'
import sell_motorcycle_orange from 'assets/images/motorcycle/sell-motorcycle-orange.png'
//import autofact_mobile from '../../../assets/images/car-file/autofact_mobile.png'
import safe_mobile from 'assets/images/car-file/safe_mobile.svg'
import olxautos from 'assets/images/car-file/olxautos-logo.png'
import olxautos_mobile from 'assets/images/car-file/olxautos_mobile.png'
import car_green_mobile from 'assets/images/car-file/car_green_mobile.svg'
import patente_mobile from 'assets/images/car-file/patente_mobile.png'

import financing_desktop from 'assets/images/car-file/financing_desktop.png'
//import autofact_desktop from 'assets/images/car-file/autofact_desktop.png'
import safe_desktop from 'assets/images/car-file/safe_desktop.png'
import patente_desktop from 'assets/images/car-file/patente_desktop.png'
import car_green_desktop from 'assets/images/car-file/car_green_desktop.png'

const Services = ({ functionModal, type="auto", visibleSimulador=true }) => {
	
	const handleEventInsurance = (action_type) => {
		functionModal({ action_type})
	}

	return (
			<div className="row page-car">
				<div className="col-12 pl-md-0 pr-md-0">
					<Cards classContainer="car-financing" classBody="p-0">
						<div className="car-service">
							<div className="car-service__image">
								<picture>
									<source media="(min-width: 768px)" srcSet={type==="auto" ? business_car_desktop : business_moto_desktop} />
									<img src={type==="auto" ? business_car_mobile : business_moto_mobile} alt="Servicios pensados en ti" />
								</picture>
								<p className="d-sm-none car-service__title_1">Servicios pensados en ti</p>
							</div>
							<div className="car-service__description">
								<section className="car-service__container">
									<p className="car-service__title_2 d-none d-sm-block">Servicios pensados en ti</p>
									<p className="car-service__title">{`En Movicenter podrás encontrar todos los servicios para que compres tu ${type} con total tranquilidad.`}</p>

									<DetailService 
										title="Financiamiento"
										subtitle="Encuentra múltiples alternativas para financiar el auto de tus sueños"
										imagenMobile={financing_mobile}
										imagenDesktop={financing_desktop}
									/>
									{/**<DetailService 
										title="Autofact"
										subtitle="Completo historial del vehículo"
										imagenMobile={autofact_mobile}
										imagenDesktop={autofact_desktop}
									/>**/}
									<DetailService
										title={`Seguro de ${type}`}
										subtitle="Encuentra el seguro que mejor se adapte a tus necesidades"
										imagenMobile={safe_mobile}
										imagenDesktop={safe_desktop}
										//handleEventInsurance={handleEventInsurance}
										//type="compara"
									/>

									<DetailService 
										title="Cotiza tu patente"
										subtitle={`Obten el valor de tu patente para que compres tu ${type} nuevo informado`}
										imagenMobile={patente_mobile}
										imagenDesktop={patente_desktop}
									/>

									{visibleSimulador &&
										<DetailService 
											//title="Simulador de impuesto verde"
											title="Simulador de impuesto verde"
											//subtitle="*Solo autos nuevos"
											subtitle="Consulta el valor de este impuesto al comprar tu auto nuevo"
											imagenMobile={car_green_mobile}
											imagenDesktop={car_green_desktop}
										/>
									}
									{type==="moto" ?
										<DetailService 
											title={`Vende tu ${type}`}
											subtitle={`Elije la mejor alternativa y vende tu ${type} en menos de 1 hora`}
											imagenMobile={sell_motorcycle_orange}
											imagenDesktop={sell_motorcycle}
										/>
									: 
										<DetailServiceEvent 
											title={`Vende tu ${type}`}
											subtitle={`Elije la mejor alternativa y vende tu ${type} en menos de 1 hora`}
											imagenMobile={type==="moto" ? sell_motorcycle_orange : olxautos_mobile}
											imagenDesktop={type==="moto" ? sell_motorcycle : olxautos}
											handleEventInsurance={handleEventInsurance}
											type="olx"
										/>
									}	
									
								</section>
							</div>
						</div>
					</Cards>
				</div>
			</div>

		
	)
}

export default Services