import { useState } from "react";
import ShareNetworks from 'components/Customs/CardVehicle/ShareNetworks'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/swiper.min.css'
import 'swiper/components/pagination/pagination.min.css'
import 'swiper/components/navigation/navigation.min.css'

// import Swiper core and required modules
import SwiperCore, {
  Navigation,Thumbs
} from 'swiper';

// install Swiper modules
SwiperCore.use([Navigation,Thumbs]);

const CarouselThumb = ({ photos, id, isMyRouter, idKey, title, type }) => {

	const [thumbsSwiper, setThumbsSwiper] = useState(null);

	return (
		<div className="carousel-car">
			<div className="carousel-thumb">
				<ShareNetworks 
					id={id} 
					isMyRouter={isMyRouter} 
					idKey={idKey} 
					title={title} 
					photo={photos[0]} 
					type={type}
				/>
				<Swiper 
					  	style={{'--swiper-navigation-color': '#858584','--swiper-pagination-color': '#858584'}} 
					  	loop={true} 
					  	spaceBetween={10} 
					  	navigation={true} 
					  	thumbs={{ swiper: thumbsSwiper }}
					  	className="mySwiperMain"
					>
					{
						photos.map((item, key) => 
							<SwiperSlide key={key}>
								<img src={item} alt="Imagen 1" />
							</SwiperSlide>
						)
					}		
				</Swiper>
			</div>
			<Swiper 
			  	onSwiper={setThumbsSwiper} 
			  	loop={true} 
			  	spaceBetween={10} 
			  	slidesPerView={4} 
			  	freeMode={true} 
			  	watchSlidesProgress={true} 
			  	className="mySwiperSecondary"
			>
				{
					photos.map((item, key) => 
						<SwiperSlide key={key}>
							<img src={item} alt="Imagen 1" />
						</SwiperSlide>
					)
				} 		
			</Swiper>
			{/**<SliderThumb>
				{
					photos.map((item, key) => 
						<SwiperSlide key={key}>
							<img src={item} alt="Imagen 1" />
						</SwiperSlide>
					)
				}
			</SliderThumb>**/}
		</div>
	)
}

export default CarouselThumb