import { 
	LIST_RESULT_CARS,
	UPDATE_RESULT_CAR,	
	DETAILS_CAR,
	ADD_CAR_MY_ROUTE,
	LOADING_SEARCH_LOADING,
	ADD_COMPARE_CARS,
	LOADING_SEND_MY_ROUTE,
	LIST_FEATURED_CARS,
	LIST_RECOMMENDED_CARS,
	SELECTED_CAR_QUOTE,
	SELECTED_BRAND_VIEW,
	SELECTED_CARS_QUOTES,
	UPDATE_INSTEREST_FORM,
	SELECTED_CARS_ROUTE,
	UPDATE_INFINITE_SCROLL_CAR,
	INITIAL_DATA_CAR_FILE,
	SELECTED_SEND_FORM_MY_ROUTE
} from 'services/types/carType'

const INITIAL_STATE = {
	listCarsOriginal: [],
	listResultCar: [],
	hasMoreCar: true,
	initialViewCar: true,
	initialDataNormal: {},
	updateInitialDataNormal: {},
	initialDataInterest: {},
	updateInitialDataInterest: {},
	itemOfSearch: [],
	infoCar: {},
	listCarMyRoute: JSON.parse(localStorage.getItem("my-route")) || [],
	countResult: 0,
	listYear: [],
	listKm: [],
	isLoadingResult: false,
	listCompareCars: [],
	itemsForm: [],
	isLoadingMyRoute: false,
	listFeaturedCars: { isLoading: false, list: []},
	listRecommendedCars: { isLoading: false, list: []},
	selectedCarQuote: {},
	selectedBrandPage: {details: {}, results: [], selected: []},
	selectedTab: 'search-normal',
	listInterestNew: [],
	listInterestUsed: [],
	allInterest: [],
	updateAllInterest: [],
	listInterest: [],
	interestSelected: {},
	listKeys: []
}

const carReducer1 = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case LIST_RESULT_CARS:
			return {
				...state,
				listResultCar: action.payload.results,
				countResult: action.payload.countResult,
				isLoadingResult: action.payload.isLoading,
				listKeys: action.payload.listKeys,
				listCarsOriginal: action.payload.listCarsOriginal,
				hasMoreCar: action.payload.hasMoreCar,
				initialViewCar: action.payload.initialViewCar
			}
		case UPDATE_INFINITE_SCROLL_CAR:
			return {
				...state,
				listResultCar: action.payload.listCarItem,
				hasMoreCar: action.payload.hasMoreCar
			}
		case UPDATE_RESULT_CAR:
			return {
				...state,
				listCompareCars: action.payload.listCompareCars
			}
		case DETAILS_CAR:
			return {
				...state,
				infoCar: action.payload.infoCar,
				listKeys: action.payload.listKeys
			}
		case ADD_CAR_MY_ROUTE:
			return {
				...state,
				listCarMyRoute: action.payload.carMyRote,
				infoCar: action.payload.detailCar
			}
		case LOADING_SEARCH_LOADING:
			return {
				...state,
				isLoadingResult: action.payload
			}
		case ADD_COMPARE_CARS:
			return {
				...state,
				listCompareCars: action.payload.listCompareCars
			}
		case LOADING_SEND_MY_ROUTE:
			return {
				...state,
				isLoadingMyRoute: action.payload
			}
		case LIST_FEATURED_CARS:
			return {
				...state,
				listFeaturedCars: action.payload
			}
		case LIST_RECOMMENDED_CARS:
			return {
				...state,
				listRecommendedCars: action.payload
			}
		case SELECTED_CAR_QUOTE:
			return {
				...state,
				selectedCarQuote: action.payload
			}
		case SELECTED_BRAND_VIEW:
			return {
				...state,
				selectedBrandPage: action.payload
			}
		case SELECTED_CARS_QUOTES:
			return {
				...state,
				selectedBrandPage: {
					...state.selectedBrandPage,
					selected: action.payload
				}
			}
		case UPDATE_INSTEREST_FORM:
			return {
				...state,
				initialDataInterest: action.payload
			}
		case SELECTED_CARS_ROUTE:
			return {
				...state,
				listCarMyRoute: action.payload
			}
		case INITIAL_DATA_CAR_FILE:
			return {
				...state,
				infoCar: action.payload.infoCar
			}
		case SELECTED_SEND_FORM_MY_ROUTE:
			return {
				...state,
				listCarMyRoute: action.payload.listCarMyRouteUpdate
			}
		default: return state
	}
}

export default carReducer1