import { 
	INFO_INITIAL_CONCOURSE,
	NEXT_FORM_PREV_CONCOURSE,
	SAVE_FORM_CONCOURSE,
	SAVE_FORM_DATA_GLOBAL_CONCOURSE
} from 'services/types/concourseType'

const INITIAL_STATE = {
	nextFormNameConcourse: "",
	dataTypeConcourse: {
		rut: "",
		name: "",
		surname: "",
		phone: "",
		email: "",
		pictures: null,
		typePictures: ""
	},
	messageConcourse: { success: true, title: "", subtitle: "", btnTitle: ""}
}

const concourseReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case INFO_INITIAL_CONCOURSE:
			return {
				...state,
				nextFormNameConcourse: action.payload.nextFormNameConcourse,
				dataTypeConcourse: action.payload.dataTypeConcourse
			}
		case NEXT_FORM_PREV_CONCOURSE:
			return {
				...state,
				nextFormNameConcourse: action.payload.nextForm,
			}
		case SAVE_FORM_CONCOURSE:
			return {
				...state,
				messageConcourse: action.payload.message
			}
		case SAVE_FORM_DATA_GLOBAL_CONCOURSE:
			return {
				...state,
				dataTypeConcourse: action.payload
			}
		default: return state
	}
}

export default concourseReducer