import BotonUbication from 'components/Customs/Mapa/BotonUbication'


const MapaAuto = ({ children, imagen, title, bgColor="" }) => {
	
	return (
		<div className={`row ${bgColor}`}>
			<div className="col-12">
				<div className="container">
					{ children }
					<div className="blog__map">
							<div className="imagen-map">
								<img src={imagen} alt={title} />
							</div>
						</div>
						<div className="card card__container border-20 card-ubication">
								<div className="card__body">
							    	<p className="blog__text--mapa">Av. Américo Vespucio 1155, Huechuraba, Santiago, Chile.</p>
						    		<BotonUbication 
										url="https://www.waze.com/ul?preview_venue_id=189597114.1896233287.588586&navigate=yes"  
										isClass="btn__icon btn__icon--waze"
										title="Como llegar con waze"
										btnColor="btn-primary"
									/>
						    		<BotonUbication 
										url="https://www.google.com/maps/dir//-33.3708241,-70.6639784/@-33.370824,-70.663978,16z?hl=es-ES"
										isClass="btn__icon btn__icon--pin"
										title="Como llegar Google Maps"
										btnColor="btn-primary"
									/>
						    		<BotonUbication 
										//url="https://www.google.com/maps/@-33.3732341,-70.6638325,3a,90y,25.97h,88.9t/data=!3m7!1e1!3m5!1sAF1QipPptkiCa-jBiFQddSH_ujfB21NDW9HlCtvyPOpz!2e10!3e12!7i3840!8i1920"
										url="https://goo.gl/maps/K6qgqme31d4JfowT8"
										isClass="btn__icon btn__icon--grados"
										title="Hacer tour virtual"
										btnColor="btn-secondary"
									/>
								</div>
						</div>
				</div>
			</div>
		</div>
	)
}

export default MapaAuto