import { LIST_SUPPLIER, LIST_PROVIDER_AUTORIZED } from 'services/types/providerType'

const INITIAL_STATE = {
	listSuppliers: [],
	listBrandsConcessionaireUsed: [],
	listBrandsConcessionaireNew: [],
	groupListStore: [],
	groupListStoreNew: [],
	groupListStoreUsed: [],
	listProviderAutorized: []
}

const providerReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case LIST_SUPPLIER:
			return {
				...state,
				listSuppliers: action.payload.listSuppliers,
				listBrandsConcessionaireUsed: action.payload.usedBrands,
				listBrandsConcessionaireNew: action.payload.newBrands,
				groupListStore: action.payload.groupListStore,
				groupListStoreNew: action.payload.groupListStoreNew,
				groupListStoreUsed: action.payload.groupListStoreUsed
			}
		case LIST_PROVIDER_AUTORIZED:
			return {
				...state,
				listProviderAutorized: action.payload.listProviderAutorized
			}
		default: return state
	}
}

export default providerReducer