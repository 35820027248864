import { useEffect } from "react"
import logo_white from "assets/images/logo.png"
import styles from 'styles/concourse.module.css'
import no_scan from 'assets/images/icon/no-scan.svg'
import coupon_llantas from 'assets/images/coupon-llantas.jpeg'
import { useParams, Link } from 'react-router-dom'
import { useCoupon } from "hooks/Coupon/useCoupon"

const HomeCoupon = () => {

	const { token } = useParams()
	const { message, loadingScaneo, verifiedInitial } = useCoupon(token)

	useEffect(() => {
		if(typeof token!=="undefined" && token!==""){
			verifiedInitial(token)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		 <div className={`row`}>
            <div className={`col-12 ${styles["column"]}`}>
                <div className={`container ${styles["container"]}`}>
                    <section className={styles["main"]}>
                        <div className={styles["image-container__coupon"]}>
                            <img src={message?.success ? coupon_llantas : no_scan} alt="Llantas del pacifico" />
                        </div>
                       	<section className="mt-5">
					        <div className="notification-text">
					            <h1 className={styles["notification-text__title"]}>{loadingScaneo ? "Procesando..." : message.title}</h1>
					            <p className={styles["notification-text__subtitle"]}>
					             {loadingScaneo ? "" : message?.subtitle}
					            </p>					            
					        </div>
					        <div className="w-100 mt-5 mb-4">
					        	<Link 
					        		to="/"
					          		className={`btn btn-primary pt-3 ${styles["btn-next"]}`} 
					        		>
					        		Ok
					        	</Link>
					      	</div>
					     </section>
                        <div className={styles["footer-coupon"]}>
                            <img src={logo_white} alt="Logo" className="w-100" />
                        </div>
                        { message?.success && (
                        	<section className="mt-5">
		                        <h1 className={styles["title-coupon"]}>¡Descuento ha sido aplicado!</h1>
		                        <p className={styles["subtitle-coupon"]}>Muchas gracias por preferir nuestros servicios.</p>
	                        </section>
	                    )}
                    </section>
                </div>
            </div>
        </div>
	)
}

export default HomeCoupon