import { useAdvertising } from 'hooks/useAdvertising';
import { getListInitialServices, searchServiceBySlug, resetServiceSelected, getServicesByAutorized } from 'services/actions/serviceActions'
import { useFormContactServiceProvider } from "hooks/Contact/useFormContactServiceProvider";
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

export const useServiceByAuthorizedStore = () => { 

    const dispatch = useDispatch()
    const history = useHistory()
    const { resultServices, servicesAll, serviceSelected, itemSelectedListForm, loadingCategoriesProvider,
      isDataService  } = useSelector((state) => state.services)
    const { advertisingServices, actionAdvertising, getAdvertisingInitial } = useAdvertising()

    const { handleActive, disabledBtn, register,  handleSubmit, 
		onSubmit, message, isOpen, closeModal, isLoading,
		isOpenForm, handleQuoteCar, closeModalForm, errors } = useFormContactServiceProvider()


    const getDataService = async (slug) => {
      dispatch(resetServiceSelected());
      await getAdvertisingInitial();
      
      if(servicesAll.length===0){
        await dispatch(getListInitialServices())
      }		
      await dispatch(searchServiceBySlug(slug))
    }

    const gotToBack = () => {
		history.go(-1)
	}

    const getDataServiceProvider = async (slug) => {
      dispatch(resetServiceSelected())
      if(servicesAll.length===0){
        await dispatch(getListInitialServices())
      }

      await dispatch(getServicesByAutorized(slug))
    }

    const selectedQuote = (event, id, name) => {
      if(name!==""){
              handleQuoteCar(id, name, "services")
      }		
    }

    const handleMoveTo = (event, idPanel) => {
      event.preventDefault()
      document.getElementById(idPanel).scrollIntoView(true)
    }

    const state = {
        resultServices,
        advertisingServices,
        serviceSelected,
        itemSelectedListForm,
        loadingCategoriesProvider,
        isDataService
    }

    const actions = {
        getDataService,
        gotToBack,
        actionAdvertising,
        getDataServiceProvider,
        selectedQuote,
        handleMoveTo
    }

    const formElement = {
		handleActive, disabledBtn, register,  handleSubmit, 
		onSubmit, message, isOpen, closeModal, isLoading,
		isOpenForm, handleQuoteCar, closeModalForm, errors
	}

    return {
        state,
        actions,
        formElement
    }
}