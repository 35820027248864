import { Modal } from 'react-bootstrap'
import LottieMotorcycle from 'components/Template/Loading/LottieMotorcycle'

const ModalSpinnerMotorcycle = ({ message }) => {

	return (
		<Modal
        show={true}
        backdrop="static"
        keyboard={false}
        className="modal-content-spinner"
        dialogClassName="modal-spinner"
      >
        <LottieMotorcycle 
				title={message}
				classLoading="loading-result"
				width={600}
			/>
      </Modal>
	)
}

export default ModalSpinnerMotorcycle