import { useEffect, useRef, useState } from "react";
import { getSelectedBrand, getVerifyStatusModel } from 'services/actions/searchActions'
import { useDispatch } from 'react-redux'


export default function useComponentVisible(initialIsVisible) {

    const dispatch = useDispatch()

    const ref = useRef(null);
    const refInput = useRef(null)
    const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
    const [ valueOptions, setValueOptions ] = useState("")
    
    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsComponentVisible(false);
        }
    };

    const openPanelItem = () => {
      setIsComponentVisible(!isComponentVisible)
    }

    const handleSelectBrand = (event) => {

      if(event && event.length===1){
          dispatch(getSelectedBrand(event[0].id, true))
          setValueOptions("")
          openPanelItem()
      }
    }

    const handleSelectModel = (event) => {
      if(event && event.length===1){
        dispatch(getVerifyStatusModel(event[0], true))
        setValueOptions("")
        openPanelItem()
      }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    useEffect(() => {
      let input = document.querySelector(".multiple__brand__input")
      if(input){
        input.focus()
      }
  }, [isComponentVisible]);

    return { 
      ref, 
      isComponentVisible, 
      setIsComponentVisible, 
      openPanelItem,
      handleSelectModel,
      handleSelectBrand,
      valueOptions,
      refInput
    };
}