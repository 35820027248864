import Layout from 'components/Layout/Layout'
import MainPanel from 'components/Services/Custom/MainPanel'
import ModalSpinnerTire from 'components/Customs/Modal/ModalSpinnerTire'
import FormContactTire from 'components/Customs/Contact/FormContactTire'
import BannerEvent from 'components/Customs/Banner/Horizontal/BannerEvent'
import PanelSearchMeasure from 'components/Services/PanelTire/PanelSearchMeasure'

import imgMobile from 'assets/images/background/tire.png'
import imgDsk from 'assets/images/background/home_tire_desktop.jpg'

import { useMainTires } from "hooks/Tires/useMainTires"

const BaseLayoutTire = ({ infoMetaHead, title, children }) => {
	
	const { state, actions } = useMainTires()

	const { measures, advertisingTire, tireSelectedQuote , resultTires} = state 

	const { actionAdvertising } = actions

	return (
		<Layout
			classHeader="row header__white"
			hiddenColor={true}
			isVisibleFooter={true}
			visibleLine={true}
			infoMetaHead={infoMetaHead}
			classDefaultHeader="row header-top header__white"
			classTopHeader=""
		>
			
			<MainPanel
				title={title}
				imgMobile={imgMobile}
				imgDsk={imgDsk}
			>
				{Object.keys(measures)?.length>0 &&  
					<PanelSearchMeasure 
						listWidth={measures?.listWidth}
						listProfile={measures?.listProfile}
						listRing={measures?.listRing}
						isLoading={false}
						isFilterByMarca={false} 
					/>
				}
			</MainPanel>

			

			{children}

			{
				advertisingTire?.length>0 && (
					<BannerEvent 
						imagen={advertisingTire[1]}
						functionModal={actionAdvertising}
						title="Banner" 
						classContainer="mb-5 mt-4" 
						classPicture="car-banner__tired pl-0 pr-0"
					/>
				)
			}


			<FormContactTire 
				isVisibleVIN={true} 
				classForm="service-form-contact service-form-contact--white" 
				classRow="gray-form-contact"
				infoSendItem={tireSelectedQuote}
				provider={null}
			/>
			{
				resultTires.isLoading && (
					<ModalSpinnerTire 
						message="Espera un momento, estamos buscando tus neumáticos" 
					/>
				)
			}
		</Layout>
	)
}

export default BaseLayoutTire