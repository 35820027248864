import { Controller } from "react-hook-form"

const CustomVehicle = ({ control, changeBrands, listInfoCompara, errors, isProcess, data }) => {
    

    return (
        <>
            <div className="form-group quote-contact__form mt-5">
                <label htmlFor="brand" className="quote-contact__label">Marca</label>
                <Controller
                    control={control}
                    name="brand" 
                    rules={{ required: {value: true, message: 'Requerido'} }}
                    render = {(props) => {
                        return (
                        <select 
                            value={props.value}
                            className="form-control form-quote__select form-quote__select--height"  
                            id={props.name}                    
                            onChange={e => {                              
                                changeBrands(e.target.value);
                            }}                            
                        >
                            <option value="">{listInfoCompara.isProcess ? "Cargando data" : "Seleccione marca" }</option>
                        {
                            listInfoCompara?.brands?.length>0 && 
                            listInfoCompara?.brands?.map((item, index) =>
                                <option key={index} value={item.key}>{item.label}</option>
                            )
                        }
                        </select>
                    )}}
                    defaultValue={data?.brand}
                />
                {errors.brand && (
                    <p className="text-error">
                        {errors?.brand?.message}
                    </p>
                )}
            </div>
            <div className="form-group quote-contact__form mt-4">
                <label htmlFor="model" className="quote-contact__label">Modelo</label>
                <Controller
                    control={control}
                    name="model" 
                    rules={{ required: {value: true, message: 'Requerido'} }}
                    render = {(props) => {
                        return (<select 
                            value={props.value}
                            className="form-control form-quote__select form-quote__select--height"  
                            id="model"   
                            onChange={e => {                              
                                props.onChange(e.target.value);
                            }}                       
                        >
                            <option value="">{isProcess ? "Cargando data" : "Seleccione modelo" }</option>
                            {
                                listInfoCompara?.models?.length>0 && 
                                listInfoCompara?.models?.map((item, index) =>
                                    <option key={index} value={item.key}>{item.label}</option>
                                )
                            }
                        </select>
                    )}}
                    defaultValue={data?.model}
                />
                {errors.model && (
                    <p className="text-error">
                        {errors?.model?.message}
                    </p>
                )}
            </div>
            <div className="form-group quote-contact__form mt-4">
                <label htmlFor="year" className="quote-contact__label">Año</label>
                <Controller
                        control={control}
                        name="year" 
                        rules={{ required: {value: true, message: 'Requerido'} }}
                        render = {(props) => {
                            return (<select 
                                value={props.value}
                                className="form-control form-quote__select form-quote__select--height"  
                                id="year"   
                                onChange={e => {                              
                                    props.onChange(e.target.value);
                                }}                         
                            >
                                <option value="">{listInfoCompara?.isProcess ? "Cargando data" : "Seleccione año" }</option>
                                {
                                    listInfoCompara?.years?.length>0 && 
                                    listInfoCompara?.years?.map((item, index) =>
                                        <option key={index} value={item.key}>{item.label}</option>
                                    )
                                }
                            </select>
                        )}}
                        defaultValue={data?.year}
                />
                {errors.year && (
                    <p className="text-error">
                        {errors?.year?.message}
                    </p>
                )}
            </div>
        </>
    )
}

export default CustomVehicle