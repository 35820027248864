import card_tag from 'assets/images/tag-card.png'
import card_time from 'assets/images/clock-symbol.png'
import { useHistory } from 'react-router-dom'
import BannerEvent from "components/Customs/Banner/Horizontal/BannerEvent"

const TagAndDiscover = ({ banners, actionAdvertising }) => {

	const history = useHistory()

	const handleService = () => {
		history.push("/servicio-automotriz/autopistas/ruta-del-maipo")
	}

	return (
		<>
			<div className="row">
				<div className="col-12">
					<div className="container card_tag__items">
						<div className="tag__container tag-pointer" onClick={handleService}>
							<div className="card card__tag mt-md-4 mb-4 mb-md-3 card-height">
								<div className="card-body card__body card__tag">
							    	<div className="tag__image tag__image tag__image--fast">
							    		<img src={card_tag} alt="Card" />
							    	</div>
							    	<div>
							    		<p className="tag__title">Obtén tu TAG</p>
							    		<p className="tag__subtitle">De forma rápida y sencilla. O obtén tu pase diario</p>
							    	</div>
								</div>
							</div>
							<div className="card card__tag time--background mt-4 mb-0 mb-md-3 card-height">
								<div className="card-body card__body card__tag">
							    	<div className="tag__image tag__image--width">
							    		<img src={card_time} alt="Card" />
							    	</div>
							    	<div>
							    		<p className="tag__title">Por que tu tiempo es oro</p>
							    		<p className="tag__subtitle">¡Dile adiós a las filas! Olvídate de las notarias, y transfiere en el momento</p>
							    	</div>
								</div>
							</div>
						</div>


							{
								banners.length>0 && (
									<BannerEvent 
										imagen={banners[5]}
										functionModal={actionAdvertising}
										title="Banner" 
										classContainer="" 
										classPicture="grafic-home__electric"
									/>
								)
							}

						{
								banners.length>0 && (
									<BannerEvent 
										imagen={banners[6]}
										functionModal={actionAdvertising}
										title="Banner" 
										classContainer="" 
										classPicture="grafic-home__electric"
									/>
								)
							}
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					
					<div className="container discover__container">
						<p className="discover__title">En Movicenter  
							<span className="panel__title--orange pl-2 pr-2">descubre más de 500 autos</span>
							todos en un mismo lugar.
						</p>
						{/**<div className="discover__image"></div>**/}
					</div>
				</div>
			</div>
		</>
	)
}

export default TagAndDiscover