import { Modal } from "react-bootstrap"
import CardSoap from "components/QuoteCar/Soap/CardSoap"
import closed_menu from 'assets/images/icons/delete-result.svg'
import { changeTypeSoap } from "services/actions/soapAction"
import { useSelector, useDispatch } from 'react-redux'


const ModalSoap = ({ visible, closeModal, sendLinkSoap }) => {
    
    const dispatch = useDispatch()
    const { listSureSoap, listFiltered } = useSelector((state) => state.sureSoap)

    const changeSelectTypeSoap = (event) => {
        dispatch(changeTypeSoap(event.target.value))
    }

    return (
        <Modal
            show={visible}
            onHide={closeModal}
            backdrop="static"
            keyboard={false}
            dialogClassName="modal-90w"
            centered
        >
        <Modal.Body className="modal-quotes modal-soap">
            <div className="modal__closed" onClick={closeModal}>
                <img src={closed_menu} alt="Cerrar" />
            </div>
            <h3 className="soap-title">Encuentra tu seguro</h3>
            <p className="soap-subtitle">Movicenter en alianza con <span className="soap-subtitle__link">Compara</span></p>
            <div className="soap-filter">
                <div className="form-group soap-filter__group">
                    <label htmlFor="soap-select" className="soap-filter__label">Tipo de Vehículo</label>
                    <select 
                        className="form-control soap-filter__select" 
                        id="soap-select" 
                        name="soap-select"
                        onChange={(event)=>changeSelectTypeSoap(event)}
                    >
                    {
                        listFiltered.length>0 && listFiltered.map((item, key) =>
                                <option key={key} value={item.slug}>{item.title}</option>
                            )
                    }
                    </select>
                </div>
            </div>
            <section className="soap-container">
                {
                    listSureSoap.length>0 &&
                    listSureSoap.map((item,key) => 
                        <CardSoap key={key} item={item} sendLinkSoap={sendLinkSoap} />
                    )
                }
            </section>
        </Modal.Body>
      </Modal>
    )
}

export default ModalSoap