

const TagTitle = ({ classContainer, title, isClassTitle }) => {

	return (
		<div className={`row ${isClassTitle ? isClassTitle : ""}`}>
			<div className="col-12 title-col">
				<div className="container title-container">
					<div className={`tag_container ${classContainer}`}>
						<p className="tag_container__text">{title}</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default TagTitle