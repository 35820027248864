import { useState } from "react"
import { getInsuranceInterested } from "services/actions/quoteAction"
import { useDispatch } from 'react-redux'

const ItemResult = ({ item, titleResult }) => {

    const dispatch = useDispatch()
    const [ isLoadingInterested, setLoadingInterested ]= useState(false)

    const handleInterested = async (item, email) => {

		setLoadingInterested(true)

		const body = {
			insurance: item, 
			email
		}

		await dispatch(getInsuranceInterested(body))

		setLoadingInterested(false)
	}

    return (
        <>
            <section className="quote--item__offers">
                <div className="pl-md-5 pr-md-5 pb-md-5">
                    <div className="quote-result__header pt-4">
                        <div className="quote-result__picture">
                            <img src={item.picture} alt={item.title} />
                        </div>
                        <div>
                            <div className="text-center">
                                <p className="quote-result-price">
                                    <span className="mr-4">{item?.priceFormat?.price}</span> 
                                </p>
                                <p className="quote-result-muted">
                                    {
                                        item?.type_insurance === "seguro-km" ?
                                            <>
                                                <span className="ml-2 mr-5">Precio Fijo</span>
                                                <span className="ml-2">Precio KM</span>
                                            </>
                                        : <span className="mr-4 ml-4">{item?.priceFormat?.title}</span>
                                    }
                                </p>
                            </div>
                            <div className="quote-result-uf">
                                <p className="quote-result-uf__title">Deducible {item.deductible}</p>
                            </div>
                        </div>
                    </div>
                    <div className="quote-result__body">
                        <p className="quote-result__body-title">{item.name}</p>
                        <ul className="quote-result__body-list">
                            {
                                item.options.map((opt, index) => <li key={index} className="quote-result__body-item">{opt}</li>)
                            }
                        </ul>
                    </div>
                    <div className="quote-result__footer text-right mt-5">
                        {
                            (item.interested===false) ? (
                                    <button 
                                    type="button" 
                                    className="btn btn-primary btn-lg btn-fs-16"
                                    disabled={isLoadingInterested}
                                    onClick={()=>handleInterested(item, titleResult?.email)}
                                >Me interesa</button>
                            ) : (
                                <>
                                    <button 
                                        type="button" 
                                        className="btn btn-secondary btn-lg pl-5 btn-fs-16"
                                        disabled
                                    >Me interesa</button>
                                    <div className="check-mark-point"></div>
                                </>
                            )
                        }
                    </div>
                </div>
            </section>
            <section className="row row-quote__bg">
            </section>
        </>
    )
}

export default ItemResult