import { useRef, useEffect } from "react"
import FormNormal from 'components/Result/Filter/Mobile/Ordinary/FormNormal'
import { getUpdateVisibleBrand } from 'services/actions/marcaActions'
import { generateUrlSearchNormal, selectedBrandSearch, getSelectedBrand, deleteItemBrandSelected,
	deleteItemModelSelected, getVerifyStatusModel, generateItemMarcaModelForm } from 'services/actions/searchActions'
import { loadingSearchCombined } from 'services/actions/carActions'
import { useForm, useFieldArray } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

const FilterNormal = ({ defaultValues, typeModule, visibleItem, visible }) => {

	const dispatch = useDispatch()
	const history = useHistory()
	const minValRef = useRef(null);
	const maxValRef = useRef(null);

	const { listYear, listKm } = useSelector((state) => state.search)
	
	const { register, control,  setValue, getValues, reset } = useForm({ defaultValues })

	const { fields: vTypeAuto } = useFieldArray({ control, name: "vTypeAuto" })
	const { fields: vTransmision } = useFieldArray({ control, name: "vTransmision" })
	const { fields: vCombustible } = useFieldArray({ control, name: "vCombustible" })
	const { fields: vCarroceria } = useFieldArray({ control, name: "vCarroceria" })
	const { fields: vMarca } = useFieldArray({ control, name: "vMarca" })
	const { fields: vModelo } = useFieldArray({ control, name: "vModelo" })
	const { fields: vOrigin } = useFieldArray({ control, name: "vOrigin" })
	const { fields: vPerformance } = useFieldArray({ control, name: "vPerformance" })
	const { fields: vSeatMove } = useFieldArray({ control, name: "vSeatMove" })
	const { fields: vTraction } = useFieldArray({ control, name: "vTraction" })
	

	const getUpdateResultcar =  async (data) => {

		dispatch(loadingSearchCombined(true))
	
		let dataBrandModel = dispatch(generateItemMarcaModelForm())

		const listModelo = dataBrandModel?.data?.modelos

		let body = {
			...defaultValues,
			...data,
			vModelo: listModelo
		}

		if(data?.price){
			body = {
				...body,
				vTypeRange: {min: Number(data?.price[0]), max: Number(data?.price[1]) } 
			}
		}else if(data?.vTypeKm){
			body = {
				...body,
				vTypeKm: {min: Number(data?.vTypeKm[0]?.name), max: Number(data?.vTypeKm[1]?.name) } 
			}
		}else if(data?.vTypeYear){
			body = {
				...body,
				vTypeYear: {min: Number(data?.vTypeYear[0]?.name), max: Number(data?.vTypeYear[1]?.name) } 
			}
		}

		let response = dispatch(generateUrlSearchNormal(body))
	
		if(response.status) {
			let slugUrl = `/autos/${response.slug}`
			if(response.slug==="nuevos/" || response.slug==="usados/"){
				slugUrl = `/s/autos/${response.slug}`
			}
			history.push(slugUrl)
		}	
	}

	const selectedTypeAuto = () => {
		
		const formData = getValues()
		
		if(Object.keys(formData).length>0){
			const filterSelected = formData?.vTypeAuto.filter(x=>x.checked===true)
			
			if(filterSelected.length===0 || filterSelected.length===2){
				dispatch(getUpdateVisibleBrand('other'))
			}else{
				
				dispatch(getUpdateVisibleBrand(filterSelected[0]?.name))
			}
			//setVisibleBrand(filterSelected.length > 0 ? true : false)
		}		
	}

	const checkUpdateResult =  (event, item) => {

		if(item?.name==="vMarca"){
			dispatch(selectedBrandSearch(item))
			if(event.target.checked){
				dispatch(getSelectedBrand(item?.id, true))
			}else{
				dispatch(deleteItemBrandSelected(item))
			}
			
		}else if(item?.name==="vModelo"){
			if(event.target.checked){
				dispatch(getVerifyStatusModel(item, true))
			}else{
				dispatch(deleteItemModelSelected(item))
			}
			
		}else if(item?.name==="vTypeAuto"){
			selectedTypeAuto()
		}
		const data = getValues()

		getUpdateResultcar(data)
	}

	useEffect(() => {
		reset(defaultValues)
		minValRef.current = Number(defaultValues.vTypeRange?.min)
	  	maxValRef.current = Number(defaultValues.vTypeRange?.max)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultValues])



	return (
		<div>
			{
				!visible && (
					<section className="options-filter__container">
						<div className="options-filter__menu" onClick={()=>visibleItem("vTypeAuto")}>
							<p>Tipo de Auto</p>
							<span onClick={()=>visibleItem("vTypeAuto")}></span>
						</div>
						<div className="options-filter__menu" onClick={()=>visibleItem("vTypeRange")}>
							<p>Precio</p>
							<span onClick={()=>visibleItem("vTypeRange")}></span>
						</div>
						<div className="options-filter__menu" onClick={()=>visibleItem("vCarroceria")}>
							<p>Tipo de Carrocería</p>
							<span onClick={()=>visibleItem("vCarroceria")}></span>
						</div>
						<div className="options-filter__menu" onClick={()=>visibleItem("vMarca")}>
							<p>Marca</p>
							<span onClick={()=>visibleItem("vMarca")}></span>
						</div>
						{
							vModelo.length > 0 && (
								<div className="options-filter__menu" onClick={()=>visibleItem("vModelo")}>
									<p>Modelo</p>
									<span onClick={()=>visibleItem("vModelo")}></span>
								</div>
							)
						}						
						<div className="options-filter__menu" onClick={()=>visibleItem("vTypeYear")}>
							<p>Año</p>
							<span onClick={()=>visibleItem("vTypeYear")}></span>
						</div>
						<div className="options-filter__menu" onClick={()=>visibleItem("vTypeKm")}>
							<p>Kilometraje</p>
							<span onClick={()=>visibleItem("vTypeKm")}></span>
						</div>
						<div className="options-filter__menu" onClick={()=>visibleItem("vTransmision")}>
							<p>Transmisión</p>
							<span onClick={()=>visibleItem("vTransmision")}></span>
						</div>
						<div className="options-filter__menu" onClick={()=>visibleItem("vCombustible")}>
							<p>Combustible</p>
							<span onClick={()=>visibleItem("vCombustible")}></span>
						</div>
						<div className="options-filter__menu" onClick={()=>visibleItem("vOrigin")}>
							<p>Procedencia</p>
							<span onClick={()=>visibleItem("vOrigin")}></span>
						</div>
						<div className="options-filter__menu" onClick={()=>visibleItem("vPerformance")}>
							<p>Rendimiento Km/L</p>
							<span onClick={()=>visibleItem("vPerformance")}></span>
						</div>
						<div className="options-filter__menu" onClick={()=>visibleItem("vSeatMove")}>
							<p>Corridas de asientos</p>
							<span onClick={()=>visibleItem("vSeatMove")}></span>
						</div>
						<div className="options-filter__menu" onClick={()=>visibleItem("vTraction")}>
							<p>Tracción</p>
							<span onClick={()=>visibleItem("vTraction")}></span>
						</div>
					</section>
				)
			}
			{
				visible && (
					<form  className="w-100">
						<FormNormal 
							vTypeAuto={vTypeAuto} 
							register={register} 
							typeModule={typeModule}
							control={control}
							vTypeYear={defaultValues.vTypeYear}
							listYear={listYear}
							listKm={listKm} 
							vTypeKm={defaultValues.vTypeKm}
							vTransmision={vTransmision}
							vCombustible={vCombustible}
							vCarroceria={vCarroceria}
							vTypeRange={defaultValues?.vTypeRange}
							minValRef={minValRef} 
							maxValRef={maxValRef} 
							setValue={setValue}
							checkUpdateResult={checkUpdateResult}
							vMarca={vMarca}
							vModelo={vModelo}
							vOrigin={vOrigin}
							vPerformance={vPerformance} 
							vSeatMove={vSeatMove} 
							vTraction={vTraction}
						/>
					</form>
				)
			}
		</div>
	)
}

export default FilterNormal