const PanelInfo = ({ isClassCard, isClassBody, children }) => {

	return (
		<div className="row">
			<div className="col-12">
				<div className={`card card__container ${isClassCard}`}>
					<div className={`card__body ${isClassBody}`}>
				    	{ children }
					</div>
				</div>
			</div>
		</div>
	)
}
export default PanelInfo