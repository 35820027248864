const DetailServiceEvent = ({ imagenMobile, title, subtitle, imagenDesktop, handleEventInsurance, type }) => {

	return (
		<div className="car-service-button" onClick={()=>handleEventInsurance(type)}>
			<div className="car-service-button--image">
				<picture>
					<source media="(min-width: 768px)" srcSet={imagenDesktop} />
					<img src={imagenMobile} alt={title} />
				</picture>
			</div>
			<div className="car-service-button--text">
				<p className="car-service-button--title">{title}</p>
				<p className="car-service-button--subtitle">{subtitle}</p>
			</div>
			<span className="car-service-button--arrow"></span>
		</div>
	)
}

export default DetailServiceEvent