import { Controller } from "react-hook-form"
import { Accordion } from "react-bootstrap"
import CardAccordion from "components/QuoteCar/Cars/Custom/CardAccordion"

const FormOptions = ({ activeKey, setActiveKey, getValues, control, listModelOlx, searchYearGlobal, listYearOlx,
    listVersionOlx, register, searchKmGlobal, listMileagesOlx, searchVersionGlobal, errors }) => {
        
    return (
        <Accordion activeKey={activeKey} onSelect={(event) => setActiveKey(event)} className="accordion-quote">
            <CardAccordion title="Modelo" index={"0"} activeKey={activeKey} classHeader={getValues("model")}>
                <div className="form-group form-group__quote mt-3">
                <Controller
                        control={control}
                        name="model" 
                        rules={{ required: {value: true, message: 'Requerido'} }}
                        render = {(props) => {
                            return (
                            <select 
                                value={props.value}
                                className="form-control form-quote__select form-quote__select--top"  
                                id={props.name}                    
                                onChange={e => {                              
                                    //changeBrands(e.target.value);
                                    searchYearGlobal(e, false, "", "")
                                }}                            
                            >
                                <option value="">{listModelOlx.isProcess ? "Cargando data" : "Seleccione modelo" }</option>
                            {
                                listModelOlx?.list?.length>0 && 
                                listModelOlx?.list?.map((item, index) =>
                                    <option key={index} value={item.key}>{item.label}</option>
                                )
                            }
                            </select>
                        )}}
                        defaultValue={""}
                    />
                    { listModelOlx.error===true && <p className="text-error mt-3">Se ha generado un error, vuelva a intentarlo en unos minutos</p>}
                </div>               
            </CardAccordion>
            <CardAccordion title="Año" index={"1"} activeKey={activeKey} classHeader={getValues("year")}>
                <div className="form-group form-group__quote mt-3">
                    <Controller
                        control={control}
                        name="year" 
                        rules={{ required: {value: true, message: 'Requerido'} }}
                        render = {(props) => {
                            return (
                            <select 
                                value={props.value}
                                className="form-control form-quote__select form-quote__select--top"  
                                id={props.name}                    
                                onChange={e => {                              
                                    //changeBrands(e.target.value);
                                    searchVersionGlobal(e, false, "")
                                }}                            
                            >
                                <option value="">{listYearOlx.isProcess ? "Cargando data" : "Seleccione año" }</option>
                            {
                                listYearOlx?.list?.length>0 && 
                                listYearOlx?.list?.map((item, index) =>
                                    <option key={index} value={item.key}>{item.label}</option>
                                )
                            }
                            </select>
                        )}}
                        defaultValue={""}
                    />
                    { listYearOlx.error===true && <p className="text-error mt-3">Se ha generado un error, vuelva a intentarlo en unos minutos</p>}
                </div>
            </CardAccordion>
            <CardAccordion title="Versión del auto" index={"2"} activeKey={activeKey} classHeader={(getValues("version")===null || getValues("version")!=="") ? true : false}>
                <div className="quote-version__scroll">
                    <section className="quote-sire__inspection">
                        {
                            listVersionOlx?.list.length>0 &&
                            listVersionOlx?.list.map((item, key) => 
                                <div className="quote-sire__item" key={key}>
                                <input 
                                    type="radio" 
                                    className="quire-car__input" 
                                    name="version" 
                                    id={`version${key}`}
                                    value={item.key}
                                    ref={register({required: {value: true, message: 'Requerido'}})}
                                    onChange={(event)=>searchKmGlobal(event, "version","3")}
                                />
                                <label className="quote-sire__selected" htmlFor={`version${key}`}>
                                    <span className="quote-sire__item-title" >{item.label}</span>
                                </label>
                            </div>
                            )
                        }
                        { listVersionOlx.error===true && <p className="text-error mt-3">Se ha generado un error, vuelva a intentarlo en unos minutos</p>}
                    </section>
                </div>
            </CardAccordion>
            <CardAccordion title="Kilometraje" index={"3"} activeKey={activeKey} classHeader={getValues("mileage")}>
                <div className="form-group form-group__quote mt-3">
                    <Controller
                        control={control}
                        name="mileage" 
                        rules={{ required: {value: true, message: 'Requerido'} }}
                        render = {(props) => {
                            return (
                                <select 
                                    value={props.value}
                                    className="form-control form-quote__select form-quote__select--top"  
                                    id={props.name}                    
                                    onChange={event => {                              
                                        searchKmGlobal(event, "mileage", "4")
                                    }}                            
                                >
                                    <option value="">{listMileagesOlx.isProcess ? "Cargando data" : "Seleccione km" }</option>
                                    {
                                        listMileagesOlx?.list?.length>0 && 
                                        listMileagesOlx?.list?.map((item, index) =>
                                            <option key={index} value={item.key}>{item.label}</option>
                                        )
                                    }
                                </select>
                        )}}
                        defaultValue={""}
                    />
                </div>
            </CardAccordion>
            <CardAccordion title="Información contacto" index={"4"} activeKey={activeKey} classHeader={getValues("telephone") || getValues("email")} classBody="pb-0 mb-0">
                <section className="quote-contact mb-0">
                    <p className="quote-contact__title">¡Solo un paso más para obtener el precio del automóvil!</p>
                    <div className="form-group quote-contact__form">
                        <label htmlFor="name" className="quote-contact__label">Nombre y Apellido</label>
                        <input 
                            type="text" 
                            className="form-control quote-contact__input"  
                            id="name" 
                            name="name"
                            ref={register({required: {value: true, message: 'Requerido'}})}
                        />
                        {errors.name && (
                            <p className="text-error">
                                {errors?.name?.message}
                            </p>
                        )}
                    </div>
                    <div className="form-group quote-contact__form">
                        <label htmlFor="telephone" className="quote-contact__label">Número de teléfono</label>
                        <input 
                            type="text" 
                            className="form-control quote-contact__input"  
                            id="telephone" 
                            name="telephone"
                            placeholder="+56"
                            ref={register({required: {value: true, message: 'Requerido'}})}
                            //onChange={()=>checkedInput()}
                        />
                        {errors.telephone && (
                            <p className="text-error">
                                {errors?.telephone?.message}
                            </p>
                        )}
                    </div>
                    <div className="form-group quote-contact__form">
                        <label htmlFor="email" className="quote-contact__label">Correo</label>
                        <input 
                            type="email" 
                            className="form-control quote-contact__input"  
                            id="email" 
                            name="email"
                            placeholder="Ingresa correo electrónico"
                            ref={register({required: {value: true, message: 'Requerido'}})}
                            //onChange={()=>checkedInput()}
                        />
                        {errors.email && (
                            <p className="text-error">
                                {errors?.email?.message}
                            </p>
                        )}
                    </div>
                </section>
            </CardAccordion>
         </Accordion>
    )
}

export default FormOptions