import { Link } from "react-router-dom"

const BannerEvent = ({ functionModal, classContainer, classPicture, imagen, title, classMain="banner" }) => {

    return (
		<>
			{
				imagen?.is_banner_hidden===false && (
					<div className={`row ${classContainer}`}>
						<div className="col-12">
							{
								(imagen?.action_type==="url" && imagen?.url_banner!=="") ? (
									<div 
										className={`container ${classMain} ${classPicture} ${imagen?.action_type==="N/A" ? "" : "advertising-cursor"}`} 
										onClick={()=>functionModal(imagen)}
									>
										{
											imagen.url_banner?.includes("https://") ?
											<a href={imagen?.url_banner} target="blank">
												<picture>
													<source media="(min-width: 768px)" srcSet={imagen?.ic_desktop_main} />
													<img src={imagen?.ic_mobile_main} alt={title} />
												</picture>
											</a>
											:
											<Link to={`/${imagen?.url_banner}`}>
												<picture>
													<source media="(min-width: 768px)" srcSet={imagen?.ic_desktop_main} />
													<img src={imagen?.ic_mobile_main} alt={title} />
												</picture>
											</Link>
										}										
									</div>
								) : (
									<div 
										className={`container ${classMain} ${classPicture} ${imagen?.action_type==="N/A" ? "" : "advertising-cursor"}`} 
										onClick={()=>functionModal(imagen)}
									>
											<picture>
												<source media="(min-width: 768px)" srcSet={imagen?.ic_desktop_main} />
												<img src={imagen?.ic_mobile_main} alt={title} />
											</picture>
									</div>
								)
							}
							
						</div>
					</div>
				)
			}
		</>
    )
}

export default BannerEvent