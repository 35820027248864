import ItemMotorcycle from 'components/Customs/CardVehicle/ItemMotorcycle'
import styles from 'styles/Motorcycle.module.css'

const ListVehicle = ({ listMoto, lastPage }) => {

	return (
		<section className={styles["moto-findout__container"]}>
			{
				listMoto.length>0 &&
				listMoto.map((item, key) => (
					<ItemMotorcycle 
						key={key}
						{...item}
						lastPage={lastPage}
					/>
				))
			}
		</section>
	)
}

export default ListVehicle