import { 
	CHANGE_STATUS_MODAL_OLX,
	LIST_BRAND_OLX,
	LIST_MODEL_OLX,
	LIST_YEAR_OLX,
	LIST_VERSION_OLX,
	SEND_FORM_OLX
 } from 'services/types/sureOlxType'

const INITIAL_STATE = {
	isVisibleFormOLX: true,
	isModalOLX: false,
	resultQuote: {},
	listBrandOlx: { isProcess: false, list: [], error: false},
	listModelOlx: { isProcess: false, list: [], error: false},
	listYearOlx: {isProcess: false, list: [], error: false},
	listVersionOlx: {isProcess: false, list: [], error: false},
	listMileagesOlx: {isProcess: false, list: [] },
	positionTab: "",
	valueSearch: {},
	isProcessOLX: 0,
	isChangeValue: false,
	visibleTextZeroPrice: false
}

const sureOlxReducer = (state=INITIAL_STATE, action) => {
	switch(action.type){
		case CHANGE_STATUS_MODAL_OLX:
			return {
				...state,
				isModalOLX: action.payload.isModalOlx,
				isVisibleFormOLX: action.payload.isVisibleFormOLX
			}
		case LIST_BRAND_OLX:
			return {
				...state,
				listBrandOlx: action.payload.listBrand,
				positionTab: action.payload.positionTab,
				valueSearch: action.payload.valueSearchResult,
				listModelOlx: action.payload.listModel,
				listYearOlx: action.payload.listYear,
				listMileagesOlx: action.payload.listMileagesOlx
			}
		case LIST_MODEL_OLX:
			return {
				...state,
				listModelOlx: action.payload.listModel,
				positionTab: action.payload.positionTab,
				valueSearch: action.payload.valueSearchResult,
				listYearOlx: action.payload.listYear
			}
		case LIST_YEAR_OLX:
			return {
				...state,
				listYearOlx: action.payload.listYears,
				positionTab: action.payload.positionTab,
				valueSearch: action.payload.valueSearchResult,
				listVersionOlx: action.payload.listVersion
			}
		case LIST_VERSION_OLX:
			return {
				...state,
				listVersionOlx: action.payload.listVersions,
				positionTab: action.payload.positionTab,
				valueSearch: action.payload.valueSearchResult
			}
		case SEND_FORM_OLX:
			return {
				...state,
				isVisibleFormOLX: action.payload.isVisibleFormOLX,
				resultQuote:action.payload.resultQuote,
				visibleTextZeroPrice: action.payload.visibleTextZeroPrice
			}
		default: return state
	}
}

export default sureOlxReducer