
const DescriptionServiceRutaMaipo = () => {

	return (
		<div className="row">
			<div className="col-12 services-details">
				<div className="container">
					<p className="services-details__title services-details__title--top">¿CÓMO OBTENER TU TAG EN RUTA DEL MAIPO?</p>
					<p className="services-details__subtitle">TIPO DE CLIENTE</p>
					<p className="services-details__subtitle--orange">PERSONA NATURAL</p>
					<p className="services-details_text-1">Acreditar dominio del vehículo con padrón o con uno de los siguientes documentos:</p>
					<ul className="services-details_text-1">
						<li>Certificado de anotaciones vigentes (60 días de vigencia).</li>
						<li>Factura de compra con timbre del RNVM, (vehículos nuevos, 60 días de vigencia).</li>
						<li>Contrato de compra-venta ante notario (60 días de vigencia).</li>
						<li>Solicitud de inscripción o transferencia ante el RNVM (60 días de vigencia).</li>
						<li>Factura de remate con timbre de martillero público y legalizada ante notario (60 días de vigencia).</li>
						<li>Factura compra venta vehículos usados legalizada ante notario (60 días de vigencia).</li>
						<li>Compra Venta AUTOFACT legalizada ante notario (60 días de vigencia).</li>
					</ul>
					<p className="services-details_text-1">Anexo a ello cliente, debe presentar:</p>
					<ul className="services-details_text-1">
						<li>Fotocopia legible Cedula de identidad de quien contrata.</li>
						<li>Fotocopia legible boleta de servicio u otro comprobante de domicilio.</li>
						<li>Si el trámite lo realiza un tercero, además de la documentación antes indicada, se debe presentar poder simple que autorice a suscribir el contrato de Arrendamiento en Ruta del Maipo, más Cedula de identidad del tercero. (El poder debe tener una vigencia máxima de 60 días).</li>
						<li>En el caso de las personas naturales, contribuyente de primera categoría que requiera factura, deben presentar la copia del Rol Único Tributario.</li>
					</ul>
					<hr/>
					<p className="services-details__subtitle--orange">PERSONA JURIDICA</p>
					<p className="services-details_text-1">Acreditar dominio del vehículo con padrón o con uno de los siguientes documentos:</p>
					<ul className="services-details_text-1">
						<li>Certificado de anotaciones vigentes (60 días de vigencia).</li>
						<li>Factura de compra con timbre del RNVM (vehículos nuevos, 60 días de vigencia).</li>
						<li>Contrato de compra-venta ante notario (60 días de vigencia).</li>
						<li>Solicitud de inscripción o transferencia ante el RNVM (60 días de vigencia).</li>
						<li>Factura de remate con timbre de martillero público y legalizada ante notario (60 días de vigencia).</li>
						<li>Factura compra venta vehículos usados legalizada ante notario (60 días de vigencia).</li>
						<li>Compra Venta AUTOFACT legalizada ante notario (60 días de vigencia).</li>
					</ul>
					<p className="services-details_text-1">Anexo a ello cliente, debe presentar:</p>
					<ul className="services-details_text-1">
						<li>Fotocopia legible RUT de la empresa.</li>
						<li>Fotocopia legible Cedula de Identidad del Representante Legal.</li>
						<li>Fotocopia legible boleta de servicio u otro comprobante de domicilio.</li>
						<li>Copia legible simple o Extracto de escritura de la empresa que indique los datos del representante legal.</li>
						<li>Si el trámite lo realiza un tercero, además de la documentación antes indicado, se debe presentar poder simple que autorice a suscribir el contrato de Arrendamiento en Ruta del Maipo, más Cedula de Identidad del tercero. (El poder debe tener una vigencia máxima de 2 meses).</li>
					</ul>
				</div>
			</div>
		</div>
	)
}

export default DescriptionServiceRutaMaipo