import FormVehicle from "components/QuoteCar/Sire/Form/FormVehicle"
import FormOwner from "components/QuoteCar/Sire/Form/FormOwner"
import FormContact from "components/QuoteCar/Sire/Form/FormContact"
import FormResultPage from "components/QuoteCar/Sire/Form/FormResultPage"

import logo_orange from "assets/images/logo-orange.png"

import styles from 'styles/insurance.module.css'
import { useSire } from "hooks/Quotes/Sire/useSire"

const HomeCompara = () => {

	const { nextFormStep, nextFormNamePrev, backHome } = useSire(true)

	return (
		<div className={`row ${styles["bg-compara"]}`}>
			<div className="col-12">
				{
					nextFormStep!=="form_end" && (
						<div className={styles["compara-main"]}>
							<div className={styles["compara-main__logo"]}>
								<img src={logo_orange} alt="Movicenter" />
							</div>
							<p className="quote-sure__title">Cotiza tu seguro de auto</p>
						    <p className="quote-sure__subtitle">Encuentra la mejor oferta en seguro para ti.</p>
					        {nextFormStep==="form_initial" && <FormVehicle nextFormNamePrev={backHome} /> }
					        {nextFormStep==="form_owner" && <FormOwner nextFormNamePrev={nextFormNamePrev} /> }
					        {nextFormStep==="form_contact" && <FormContact nextFormNamePrev={nextFormNamePrev} isQr={true} /> }            
						</div>
					)
				}
				{nextFormStep==="form_end" && (
					<div className={`container ${styles["mtb-page"]}`}>
					 <FormResultPage 
							nextFormNamePrev={nextFormNamePrev} 
							closeModal={null} 
						/> 
					</div>
					)
				}
			</div>
		</div>

	)
}

export default HomeCompara