import InputSliderRange from 'components/Customs/InputForm/InputSliderRange'
import TypeSelect from 'components/Customs/InputForm/TypeSelect'
import GroupFilterItemResult from 'components/Result/Filter/Desktop/GroupFilterItemResult'

const FormNormal = ({ 
	vTypeAuto, register, typeModule, control, vTypeYear, 
	listYear, listKm, vTypeKm, vTransmision, vCombustible,
	vCarroceria, vTypeRange, minValRef, maxValRef, setValue,  checkUpdateResult,
	vMarca, vModelo, vOrigin, vPerformance, vSeatMove, vTraction
 }) => {

	return (
		<div className="options-filter__item">
			{typeModule==="vTypeAuto" && (
				<GroupFilterItemResult
					key={0}
					list={vTypeAuto}
					title="Tipo de Auto"
					register={register}
					handleClickUpdateResult={checkUpdateResult}
					setValue={setValue}
				/>
			)}

			{typeModule==="vTypeRange" && (
				<InputSliderRange 
					register={register} 
					min={Number(vTypeRange?.min)} 
					max={Number(vTypeRange?.max)}
					minValRef={minValRef} 
					maxValRef={maxValRef} 
					control={control}
					setValue={setValue}
					classMain="search_type_price"
					handleClickUpdateResult={checkUpdateResult}
				/>
			)}

			{typeModule==="vTypeYear" && (
				<div>
					<p className="title-filter-group">Año</p>
					<div className="search-advanced__details mt-xs-4 mt-md-0">
						<TypeSelect
							key={0}
							titleLabel="Año Min"
							titleSelect="Seleciona año" 
							register={register}
							name={`vTypeYear[0].name`}
							id="selectYearMin" 
							listado={listYear}
							isOptionValue={false}
							control={control}
							defaultValue={vTypeYear.min}
							setValue={setValue}
							handleClickUpdateResult={checkUpdateResult}
						/>
						<TypeSelect
							key={1}
							titleLabel="Año Max"
							titleSelect="Seleciona año" 
							register={register}
							name={`vTypeYear[1].name`}
							id="selectYearMax" 
							listado={listYear}
							isOptionValue={false}
							control={control}
							defaultValue={vTypeYear.max}
							setValue={setValue}
							handleClickUpdateResult={checkUpdateResult}
						/>
					</div>
				</div>
			)}

			{typeModule==="vTypeKm" && (
				<div>
					<p className="title-filter-group">Kilometraje</p>
					<div className="search-advanced__details mt-xs-4 mt-md-0">
						<TypeSelect
							key={2}
							titleLabel="Kilometraje Min"
							titleSelect="Seleciona km" 
							register={register}
							name={`vTypeKm[0].name`}
							id="selectKmMin" 
							listado={listKm}
							isOptionValue={false}
							control={control}
							defaultValue={vTypeKm.min}
							setValue={setValue}
							handleClickUpdateResult={checkUpdateResult}
						/>
						<TypeSelect
							key={3}
							titleLabel="Kilometraje Max"
							titleSelect="Seleciona km" 
							register={register}
							name={`vTypeKm[1].name`}
							id="selectKmMax" 
							listado={listKm}
							isOptionValue={false}
							control={control}
							defaultValue={vTypeKm.max}
							setValue={setValue}
							handleClickUpdateResult={checkUpdateResult}
						/>
					</div>
				</div>
			)}

			{typeModule==="vTransmision" && (
				<GroupFilterItemResult
					key={1}
					list={vTransmision}
					title="Transmisión"
					register={register}
					handleClickUpdateResult={checkUpdateResult}
					setValue={setValue}
				/>
			)}

			{typeModule==="vCombustible" && (
				<GroupFilterItemResult
					key={2}
					list={vCombustible}
					title="Combustible"
					register={register}
					handleClickUpdateResult={checkUpdateResult}
					setValue={setValue}
				/>	
			)}

			{typeModule==="vCarroceria" && (
				<GroupFilterItemResult
					key={3}
					list={vCarroceria}
					title="Tipo de Carrocería"
					register={register}
					handleClickUpdateResult={checkUpdateResult}
					setValue={setValue}
				/>
			)}

			{typeModule==="vMarca" && (
				<GroupFilterItemResult
					key={4}
					list={vMarca}
					title="Marcas"
					register={register}
					handleClickUpdateResult={checkUpdateResult}
					setValue={setValue}
				/>
			)}
			{typeModule==="vModelo" && (
				vModelo.length>0 && (
					<div>
						<p className="form-filter__title">Modelo</p>
						<div className="form-filter__container">
							{
								vModelo.map((brand, key) => (
									<div key={key}>
										<p>{brand.title}</p>
										{
											brand.modelos.map((model, index) => (
												<div className="form-group form-filter-checkbox" key={index}>
													<input
														ref={register}
														name={`${model.name}.${index}.name`}
														defaultValue={model?.value}
														type="hidden"
													/>
													<input
														ref={register}
														name={`${model.name}.${index}.slug`}
														defaultValue={model?.slug}
														type="hidden"
													/>
													<input 
														className="form__filter-check"
														type="checkbox" 
														name={`${model.name}.${index}.checked`} 
														id={model?.value} 
														defaultChecked={model?.checked}
														ref={register}
														onClick={(event)=>checkUpdateResult(event, model)}
													/>
													<label className="form__filter-label" htmlFor={model?.value}>
														{model?.title}
													</label>
												</div>	
											))
										}		
									</div>
								))
							}	
						</div>						
					</div>
				)
			)}

			{typeModule==="vOrigin" && (
				<GroupFilterItemResult
					key={5}
					list={vOrigin}
					title="Procedencia"
					register={register}
					handleClickUpdateResult={checkUpdateResult}
					setValue={setValue}
				/>
			)}

			{typeModule==="vPerformance" && (
				<GroupFilterItemResult
					key={6}
					list={vPerformance}
					title="Rendimiento Km/L"
					register={register}
					handleClickUpdateResult={checkUpdateResult}
					setValue={setValue}
				/>
			)}

			{typeModule==="vSeatMove" && (
				<GroupFilterItemResult
					key={7}
					list={vSeatMove}
					title="Corridas de asientos"
					register={register}
					handleClickUpdateResult={checkUpdateResult}
					setValue={setValue}
				/>
			)}

			{typeModule==="vTraction" && (
				<GroupFilterItemResult
					key={8}
					list={vTraction}
					title="Tracción"
					register={register}
					handleClickUpdateResult={checkUpdateResult}
					setValue={setValue}
				/>
			)}
    	</div>
	)
}

export default FormNormal