import { Fragment } from "react"
import ListVehicle from 'components/Customs/CardVehicle/ListVehicle'
import BannerEventFill from 'components/Customs/Banner/Horizontal/BannerEventFill'

const ListMotoByDsk = ({ listMoto, actionAdvertising, advertising  }) => {

	return (
		<Fragment>
			<ListVehicle listMoto={listMoto}/>
			{ advertising?.length>0 && (
				<BannerEventFill 
					imagen={advertising[0]}
					functionModal={actionAdvertising}
					title="Banner" 
					classContainer="mb-0" 
					classPicture="banner-moto__main"
					classMain=""
				/>
			)}
		</Fragment>
	)
}

export default ListMotoByDsk