import Layout from 'components/Layout/Layout'
import { homeInitialCar } from "data/DescripcionHead.json"
import { useLocation, useHistory } from 'react-router-dom'

const NotFound = () => {

    const location = useLocation();
    const history = useHistory();

    if(location?.pathname==="/servicio-automotriz"){
        history.push("/");
    }

    return (
        <Layout
            classHeader="row header__white"
            hiddenColor={false}
            isVisibleFooter={true}
            visibleLine={true}
            infoMetaHead={homeInitialCar}
            classDefaultHeader="row header-top header__white"
			classTopHeader=""
        >
        <div className='row'>
            <div className='col-12'>
                <div className='container not-found'>
                    <picture>
                        <source media="(min-width: 768px)" srcSet="https://movicenter-web.s3.us-east-2.amazonaws.com/404/404-d.png" />
                        <img src="https://movicenter-web.s3.us-east-2.amazonaws.com/404/404-m.png" alt="Página no encontrada" />
                    </picture>
                </div>
            </div>
        </div>    
        </Layout>
    )
}

export default NotFound