import Lottie from 'react-lottie'
import animationData from 'components/Template/Loading/loading.json'

const LottieLoading = ({ title, classLoading, width=300, height=300 }) => {

 
    const defaultOptions = {
      loop: true,
      autoplay: true, 
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

	return (
		<div className="row">
			<div className="col-12">
				<div className={classLoading}>
			      <Lottie options={defaultOptions}
			              height={height}
			              width={width}
			              />
			        <p className="loading-car__text">{title}</p>
			    </div>
			</div>
		</div>
	)
}

export default LottieLoading