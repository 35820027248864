import { useSearchMeasure } from "hooks/Tires/useSearchMeasure"

import measure_dsk from 'assets/images/measure-dsk.png';
import measure_mobile from 'assets/images/measure-mobile.png';

const PanelSearchMeasure = ({ listWidth, listProfile, listRing, isLoading, isFilterByMarca }) => {
	
	const { register, handleSubmit, onSubmit, valueSearchMeasure } = useSearchMeasure(isFilterByMarca)

	return (
		<div className={`card card__container service-search`}>
			<div className="service-search__tab">
				<p className="active">Encuentra tu neumático</p>
			</div>
			<div className={`card__body service-search__body`}>
				<form className="service-search__container" onSubmit={handleSubmit(onSubmit)}>
					<div className="service-search__form">
						<div className="service-search__control">
							<div className="service-search__row">
								<div className="service-search__column w-100">
									<label>Ancho</label>
									<select 
										className="form-control service-search__select"
										name="width" 
										id="width"
										ref={register}
										defaultValue={valueSearchMeasure?.width}
									>
									<option value="">Selecione</option> 
									{
										listWidth.map((item, key) => <option key={key} value={item}>{item}</option> )
									}
									</select>
								</div>
							</div>

							<div className="service-search__row">
								<div className="service-search__column w-100">
									<label>Perfil</label>
									<select 
										className="form-control service-search__select"
										name="profile" 
										id="profile"
										ref={register}
										defaultValue={valueSearchMeasure?.profile}
									>
									<option value="">Selecione</option> 
										{
											listProfile.map((item, key) => <option key={key} value={item}>{item}</option> )
										}
									</select>
								</div>
							</div>

							<div className="service-search__row">
								<div className="service-search__column w-100">
									<label>Aro</label>
									<select 
										className="form-control service-search__select"
										name="ring" 
										id="ring"
										ref={register}
										defaultValue={valueSearchMeasure?.ring}
									>
									<option value="">Selecione</option> 
										{
											listRing.map((item, key) => <option key={key} value={item}>{item}</option> )
										}
									</select>
								</div>
							</div>
						</div>
						<div className="service-search__button">
							<button 
								type="submit" 
								className="btn btn-primary btn-lg w-100"
								disabled={isLoading}
							>Buscar neumático</button>
						</div>
					</div>
					<div className="info__measure">
						<div className="service-image__measure">
							<picture>
								<source media="(min-width: 768px)" srcSet={measure_dsk} />
								<img src={measure_mobile} alt="Medidas de neumáticos" className="w-100" />
							</picture>
						</div>				
					</div>
				</form>
			</div>
		</div>
	)
}

export default PanelSearchMeasure

   