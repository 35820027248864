import { useState } from 'react'

export const useModal = (initialValue = false) => {
	const [ isOpen, setIsOpen ] = useState(initialValue)

	const openModal = () => setIsOpen(true)

	const closeModal = () => setIsOpen(false)

	const openModalMap = (event) => {
		event.preventDefault()
		setIsOpen(!isOpen)
	}

	return {
		isOpen,
		openModal,
		closeModal,
		setIsOpen,
		openModalMap
	}
}