export const LIST_INITIAL_SERVICE = 'LIST_INITIAL_SERVICE'
export const RESULT_SERVICES_SEARCH = 'RESULT_SERVICES_SEARCH'
export const LOADING_SEARCH_SERVICE = 'LOADING_SEARCH_SERVICE'


export const SELECTED_SERVICE = 'SELECTED_SERVICE'
export const LIST_INITIAL_TIRE = 'LIST_INITIAL_TIRE'
export const FILTER_MEASURE_TIRES = 'FILTER_MEASURE_TIRES'
export const LOADING_RESULT_TIRES = 'LOADING_RESULT_TIRES'
export const SELECTED_TIRE_QUOTE = 'SELECTED_TIRE_QUOTE'
export const SEND_MEASURE_TIRES = 'SEND_MEASURE_TIRES'

export const SELECTED_VALUE_FORM = 'SELECTED_VALUE_FORM'
export const SELECT_ITEM_LIST_FORM = 'SELECT_ITEM_LIST_FORM'