import API from 'services/context/api';
import { LIST_NEW_BLOG, POST_INFO, LOADING_POST } from 'services/types/blogType';
import endPoints from 'services/routes/';

export const requestSuccess = (type, payload) => {
    return { type, payload };
};

export const getListNews = () => async (dispatch, getState) => {
    try{
		
		dispatch(requestSuccess(LOADING_POST, true));

		let response = await API.getApi({
								endPoint: endPoints.blog.getList, 
								nameAPI: "", 
								isToken: false,
								isFormData: false
							});

		let payload = { list: [], lastNew: {}, listViewBlog: [] };

		if(response.data.length>0){
			let lastNew = {};
			const listNews = [];
		
			const listBlog = response.data.filter(item => item.acf.imagen_de_cabecera_movil!==false);
	
			for(const key in listBlog){
				if(Number(key)===0){
					lastNew=listBlog[0];
				}else{
					listNews.push(listBlog[key]);
				}
			}
			
			let typeSearch = "Novedades"
			let listPostNews = listBlog.filter(item => item.acf.etiquetas.some(item1 => item1.name.toLowerCase()===typeSearch.toLocaleLowerCase()))

			payload = {
				list: listBlog,
				lastNew,
				listViewBlog: listNews,
				listPostNews,
				loadingPost: false
			};
		}
		dispatch(requestSuccess(LIST_NEW_BLOG, payload));
		
	}catch(error){
      	return { status: false, message: error };
    }
};

export const getInfoPost = (slug) => async (dispatch, getState) => {
    try{

		const { news } = getState().blog;

		let payload = { };

		if(news.length>0){
			const post = news.find(item => item.slug === slug);
			payload=post;
		}
		dispatch(requestSuccess(POST_INFO, payload));

		return { payload };
		
	}catch(error){
      	return { status: false, message: error };
    }
};