//import { useEffect } from 'react'
import { getListAdvertisingsInitial, saveClicBanner, getListPages } from 'services/actions/advertisingAction'
//import { getListAdvertisingsInitial } from 'services/actions/advertisingAction'
import { openCloseModalSoap } from "services/actions/soapAction"
import { openCloseModalCompara } from "services/actions/quoteAction"
import { showAndHiddenFormOLX } from "services/actions/sureOlxActions"
import { resetDataCarSelected } from "services/actions/carActions"
import { useDispatch, useSelector } from 'react-redux'
import { useLocation }from "react-router-dom"

export const useAdvertising = () => {
	
	const dispatch = useDispatch()
	const location = useLocation()
	const { listAdvertising, advertisingHomeMain, advertisingHomeNew, advertisingHomeUsed,
		advertisingTire, advertisingServices, advertisingCars, advertisingResult, 
		advertisingMoto, advertisingPageMoto, advertisingTypeMoto, advertisingElectrical,
		bannerSliderHome, bannerSliderHomeUsed, bannerSliderHomeNew,advertisingSliderPrincipal,
		advertisingSliderNew, advertisingSliderUsed, listPages,
		pageDurationTimerPrincipal, pageDurationTimerNew, pageDurationTimerUsed, loadingSlider  } = useSelector((state) => state.advertisings)

	const getAdvertisingInitial = async () => {
		await getListAdvertising()
		if(location.pathname==="/"){
			dispatch(resetDataCarSelected())
		}
	}

	const actionAdvertising = async (banner) => {
	
		if(banner?.action_type==="soap"){
			dispatch(openCloseModalSoap())
		}else if(banner?.action_type==="compara"){
			dispatch(openCloseModalCompara())
		}else if(banner?.action_type==="olx"){
			dispatch(showAndHiddenFormOLX(true))
		}
		
		await dispatch(saveClicBanner(banner))
	}

	/**useEffect(() => {
		getListAdvertising()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])**/

	const getListAdvertising = async () => {
		if(listAdvertising.length===0){
			return await dispatch(getListAdvertisingsInitial())
		}
		return false
	}

	const getPages = async () => {
		if(listPages.length===0){
			await dispatch(getListPages())
		}
		return false
	}

	return {
		listAdvertising,
		advertisingHomeMain,
		advertisingHomeNew,
		advertisingHomeUsed,
		advertisingTire,
		advertisingServices,
		advertisingCars,
		actionAdvertising,
		advertisingResult,
		advertisingMoto,
		advertisingPageMoto,
		advertisingTypeMoto,
		getListAdvertising,
		getAdvertisingInitial,
		advertisingElectrical,
		bannerSliderHome,
		bannerSliderHomeUsed, 
		bannerSliderHomeNew,
		advertisingSliderPrincipal,
		advertisingSliderNew,
		advertisingSliderUsed,
		getPages,
		pageDurationTimerPrincipal, 
		pageDurationTimerNew, 
		pageDurationTimerUsed,
		loadingSlider
	}
}