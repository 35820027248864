import { createTokenMaintaner, loadingGenerateToken, createTokenSite } from "services/actions/secureAction"
import { useDispatch, useSelector } from 'react-redux'


export const useSecure = () => {
    
    const dispatch = useDispatch();
    const { loadingSecure } = useSelector((state) => state.secure)
    
    const getValidateToken =  async (data) => {
        dispatch(loadingGenerateToken(true))
        await dispatch(createTokenSite());
        await dispatch(createTokenMaintaner());
        
        dispatch(loadingGenerateToken(false))
    }    

	return {
		getValidateToken,
        loadingSecure
	}
}