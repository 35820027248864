import API from 'services/context/api';
import { 
	LIST_TYPE_MOTORCYCLE,
	LIST_BRAND_MOTORCYCLE,
    LIST_FEATURED_MOTO,
    DETAIL_MOTORCYCLE,
    LOADING_MOTORCYCLE,

    LOADING_HOME_MOTORCYCLE,
    LOADING_FEATURED_MOTORCYCLE
 } from 'services/types/motorcycleType';

import moto_scooter_mobile from 'assets/images/motorcycle/scooter.svg';
import moto_sport_mobile from 'assets/images/motorcycle/sport.svg';
import moto_trail_mobile from 'assets/images/motorcycle/trail.svg';
import moto_atv_mobile from 'assets/images/motorcycle/atv.svg';

import brand_lifan from 'assets/images/motorcycle/lifan.png';
import brand_bashan from 'assets/images/motorcycle/bashan.png';

import info_scooter from 'assets/images/motorcycle/info-scooter.png';
import info_sport from 'assets/images/motorcycle/info-sport.png';
import info_trail from 'assets/images/motorcycle/info-trail.png';
import info_atv from 'assets/images/motorcycle/info-atv.png';

import { formatDataMoto } from 'utils/MotorcycleFunction/TypeMoto';
import { PathTypeCurve } from 'utils/MotorcycleFunction/TypeCurve';
import { generateListWithBanner } from 'utils/global';
import { TOKEN_SITE_WEB } from "data/variableKey";
import endPoints from 'services/routes/';

export const requestSuccess = (type, payload) => {
    return { type, payload };
};

export const getListTypeMotorcycle = () => async (dispatch, getState) => {
    try{

        const { listSuppliers } = getState().providers;
        const { categoryProviders } = getState().services;
        const { advertisingTypeMoto } = getState().advertisings;
        
        let response = await API.getApi({
                                endPoint: endPoints.motorcyle.getMotorcyles, 
                                nameAPI: TOKEN_SITE_WEB, 
                                isToken: true,
                                isFormData: false
                            });
        
        const { data } = response.data;
       
        const listMotoGlobal = formatDataMoto(data, listSuppliers, categoryProviders);

        const listMotoTrail = listMotoGlobal.filter(item => item.typeMoto==="Trail");
        const listMotoScooter = listMotoGlobal.filter(item => item.typeMoto==="Scooter");
        const listMotoSport = listMotoGlobal.filter(item => item.typeMoto==="Sport");
        const listMotoATV = listMotoGlobal.filter(item => item.typeMoto==="ATV");

    	const listTypeMotorcycle = [
    		{
                idPanel: "panel-scooter",
    			title: "Scooter",
    			image: moto_scooter_mobile,
    			url: "",
                info_image: info_scooter,
                listGlobal: listMotoScooter,
                listMoto: listMotoScooter.slice(0, 4),
                listGlobalMobile: generateListWithBanner(listMotoScooter, advertisingTypeMoto),
                description: "Explora la mejor selección de Scooters y encuentra el modelo perfecto para imponer tu personalidad en la ruta que quieras, con un diseño sofisticado, elegante y urbano.",
                pathPicture: PathTypeCurve.picture3
    		},
    		{
                idPanel: "panel-sport",
    			title: "Sport",
    			image: moto_sport_mobile,
    			url: "",
                info_image: info_sport,
                listGlobal: listMotoSport,
                listMoto: listMotoSport.slice(0, 4),
                listGlobalMobile: generateListWithBanner(listMotoSport, advertisingTypeMoto),
                description: "Si lo que buscas es una motocicleta ágil y rápida, lo ideal es ir por una deportiva. Prometen ofrecer una experiencia de pilotaje emocionante desde la salida hasta la puesta de sol.",
                pathPicture: PathTypeCurve.picture1
    		},
    		{
                idPanel: "panel-trail",
    			title: "Trail",
    			image: moto_trail_mobile,
    			url: "",
                info_image: info_trail,
                listGlobal: listMotoTrail,
                listMoto: listMotoTrail.slice(0, 4),
                listGlobalMobile: generateListWithBanner(listMotoTrail, advertisingTypeMoto),
                description: "Las motocicletas Trail son ideales para los amantes de la aventura. Versátiles, cómodas y ligeras, estas motos son capaces de llevarte a cualquier parte, dentro y fuera de la ciudad.",
                pathPicture: PathTypeCurve.picture3
    		},
    		{
                idPanel: "panel-atv",
    			title: "ATV",
    			image: moto_atv_mobile,
    			url: "",
                info_image: info_atv,
                listGlobal: listMotoATV,
                listMoto: listMotoATV.slice(0, 4),
                listGlobalMobile: generateListWithBanner(listMotoATV, advertisingTypeMoto),
                description: "Nuestra selección de cuatrimotos te permitirá viajar por los senderos más complicados, pudiendo llegar a lugares con los que hasta ahora solo habías soñado.",
                pathPicture: PathTypeCurve.picture2
    		}
    	];

        const motoScooter = listTypeMotorcycle.find(item =>item.title==="Scooter");
        const motoSport = listTypeMotorcycle.find(item =>item.title==="Sport");
        const motoTrail= listTypeMotorcycle.find(item =>item.title==="Trail");
        const motoATV = listTypeMotorcycle.find(item =>item.title==="ATV");

    	const payload = { 
            listTypeMotorcycle, 
            motoScooter, 
            motoSport, 
            motoTrail,
            motoATV,
            loading: false
        };

		dispatch(requestSuccess(LIST_TYPE_MOTORCYCLE, payload));
		
	}catch(error){
      	return { status: false, message: error };
    }
};

export const getListTBrandMotorcycle = () => (dispatch, getState) => {
    try{

    	const listBrandMotorcycle = [
    		{
    			brand: "Lifan",
    			icon: brand_lifan
    		},
    		{
    			brand: "Bashan",
    			icon: brand_bashan
    		}
    	];

    	const payload = { listBrandMotorcycle };

		dispatch(requestSuccess(LIST_BRAND_MOTORCYCLE, payload));
		
	}catch(error){
      	return { status: false, message: error };
    }
};

export const getListFeatured = () => async (dispatch, getState) => {
    try{
        const { listSuppliers } = getState().providers;
        const { categoryProviders } = getState().services;

        let response = await API.getApi({
                                endPoint: endPoints.motorcyle.getFeatured, 
                                nameAPI: TOKEN_SITE_WEB, 
                                isToken: true,
                                isFormData: false
                            });

        const { data } = response.data;

        const listMotoGlobal = formatDataMoto(data, listSuppliers, categoryProviders);

        const featuredMoto = listMotoGlobal;

        const payload = { 
            featuredMoto: { isLoading: false, list: featuredMoto }
        };

        dispatch(requestSuccess(LIST_FEATURED_MOTO, payload));
        
    }catch(error){
        return { status: false, message: error };
    }
};

export const getInfoMotorcycle = (id) => async (dispatch, getState) => {
    try{

        const { listSuppliers } = getState().providers;
        const { categoryProviders } = getState().services;

        const body = { id };
 
        let response = await API.postApi({
                                    endPoint: endPoints.motorcyle.getMotorcycleById, 
                                    data: body,
                                    nameAPI: TOKEN_SITE_WEB, 
                                    isToken: true,
                                    isFormData: false
                                });

        const { data } = response.data;

        const infoMotorcycle = formatDataMoto([data], listSuppliers, categoryProviders);

        const payload = { 
            infoMotorcycle: infoMotorcycle[0],
            isLoading: false
        };
 
        dispatch(requestSuccess(DETAIL_MOTORCYCLE, payload))
        
    }catch(error){
        return { status: false, message: error };
    }
};

export const loadingInfoMotorcycle = (value, list) =>  (dispatch, getState) => {
    const { featuredMoto } = getState().motorcycles;

    dispatch(requestSuccess(LOADING_FEATURED_MOTORCYCLE, { isLoading: value, list: featuredMoto?.list }));
    dispatch(requestSuccess(LOADING_HOME_MOTORCYCLE, { loading: value }));
    dispatch(requestSuccess(LOADING_MOTORCYCLE, value));
};