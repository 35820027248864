import CardTitle from "components/QuoteCar/Sire/Custom/CardTitle"
import { Controller } from "react-hook-form"
import { useDataContact } from "hooks/Quotes/Sire/useDataContact"

const FormContact = ({ nextFormNamePrev, isQr }) => {

    const { register, onSubmit, handleSubmit, control, errors, proccessForm,
        handleChangePhoneNumber, handleChangeEmail, onChangeInput, handleActive } = useDataContact(isQr)

    return (
        <CardTitle title="Contacto" activeKey={3}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group quote-contact__form mt-5">
                    <label htmlFor="telephone" className="quote-contact__label pl-5">Tu celular</label>
                    <p className="span-telephone"> +56</p>
                    <input 
                            type="text" 
                            className="form-control quote-contact__input telephone--left"  
                            id="telephone" 
                            name="telephone"
                            ref={register({required: {value: true, message: 'Requerido'}})}
                            maxLength={9}
                            pattern="[0-9]+"
                            onChange={(event)=>handleChangePhoneNumber(event)}
                            onBlur={(event) => onChangeInput(event, "text", "telephone")}
                        />
                    {errors.telephone && (
                        <p className="text-error">
                            {errors?.telephone?.message}
                        </p>
                    )}
                </div>
                <div className="form-group quote-contact__form">
                    <label htmlFor="email" className="quote-contact__label">Tu correo</label>
                    <input 
                        type="text" 
                        className="form-control quote-contact__input text-lowercase"  
                        id="email" 
                        name="email"
                        ref={register({required: {value: true, message: 'Requerido'}})}
                        onBlur={(event)=>handleChangeEmail(event)}
                        onChange={(event) => onChangeInput(event, "text", "email")}
                    />
                    {errors.email && (
                        <p className="text-error">
                            {errors?.email?.message}
                        </p>
                    )}
                </div>
                <div className="form-group quote-contact__form">
                    <label htmlFor="question" className="quote-contact__label">¿Te gustaría que te contactemos?</label>
                    <Controller
                        render={(props) => (
                            <select 
                                className="form-control form-quote__select form-quote__select--height" 
                                id="question" 
                                name="question"  
                                onChange={(value)=>{
                                    props.onChange(value)
                                    onChangeInput(value, "select", "question")
                                }}
                            >
                                <option value="1">Si, por WhatsApp (Recomendado)</option>
                                <option value="2">Llamada telefónica</option>
                            </select>
                        )}
                        control={control}
                        name="question"  
                    />
                </div>
                <div className="form-group form-group-checkbox quote-group__form">
                    <input 
                        className="form__contact-check" 
                        type="checkbox" 
                        value={true}
                        id="acceptTerm" 
                        name="acceptTerm"
                        defaultChecked 
                        ref={register}
                        onChange={(event)=>handleActive(event)}
                     />
                     <label 
                        className="form__contact-label" 
                        htmlFor="acceptTerm">
                        Acepto <a 
                            href="https://movicenter-web.s3.us-east-2.amazonaws.com/docs/TEERMINOS+Y+CONDICIONES+MOVICENTER.pdf" 
                            target="_blank"
                            rel="noreferrer"
                        >términos y condiciones</a> y políticas de privacidad
                        
                    </label>
                </div>
                <p className="mt-4 text-error text-center">{proccessForm.message}</p>
                <button type="submit" className="btn btn-primary btn-quote-sire mb-4" disabled={proccessForm?.status}>
                    { proccessForm?.loading ? "Procesando..." : "Continuar" }    
                </button>
                <p className="quote-sire__step" onClick={()=>nextFormNamePrev(1)}>Paso anterior</p>
            </form>
        </CardTitle>
    )
}

export default FormContact