export const PathTypeCurve = {
	picture1: {
		mobile: "M-0.27,21.22 C149.83,128.80 340.00,125.83 499.72,19.25 L500.00,0.00 L0.00,0.00 Z",
		dsk: "M0.00,49.98 C487.87,31.09 380.64,241.30 499.72,43.92 L500.00,0.00 L0.00,0.00 Z"
	},
	picture2: {
		mobile: "M-0.27,21.22 C149.83,128.80 340.00,125.83 499.72,19.25 L500.00,0.00 L0.00,0.00 Z",
		dsk: "M0.28,55.77 C114.84,288.66 19.47,1.50 501.97,22.22 L503.67,-1.45 L-1.41,-6.39 Z"
	},
	picture3: {
		mobile: "M0.00,49.98 C167.32,177.14 329.28,178.13 500.00,49.98 L500.00,0.00 L0.00,0.00 Z",
		dsk: "M0.00,49.98 C487.87,31.09 380.64,241.30 499.72,43.92 L500.00,0.00 L0.00,0.00 Z"
	}
}