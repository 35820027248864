
export const chunkArray = (arr, size) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  );

  export const formatNumberMiles = (numero) => { 

   let  formatNumber = (typeof numero !== 'undefined' && numero!==null) ? numero.toString().trim().split("").reverse() : 0;
   
   let newNumber = ''

   for(let i=0; i<formatNumber.length; i++){
      
       if( i%3===0 && i!==0 )
           newNumber="."+newNumber;
       
       newNumber=formatNumber[i]+newNumber;
   }
   
   return newNumber;
}

export const verifyCheckList = (listForm, listDefault) => {

  for (let key in listForm){
      const index = listDefault.findIndex(item => item.value===listForm[key].name)
      if(typeof index !=='undefined' && index!==-1){
        listDefault[index].checked = listForm[key].checked
      }      
  }

  return listDefault
}

export const searchItemSelected = (list, type, items, name) => {
  let listItems = items

  if(type==="list-object"){
    
    const itemsSelected = list.filter(item => item.checked===true)
    if(itemsSelected.length > 0){
      listItems = [ ...items, ...itemsSelected ]
    }

  }else if(type === "range-price"){
    const title = `${list.min} - ${list.max}`
    const select = [{value: name, checked: true, name: name, title }]
    listItems = [ ...items, ...select ]

  }else if(type === "range"){

      const min = list[0].name!=="" ? Number(list[0].name) : ''
      const max = list[1].name!=="" ? Number(list[1].name) : ''

      if(min!=="" && max!==""){
        const select = [{value: name, checked: true, name: name, title: `${min} - ${max}` }]
        listItems = [ ...items, ...select ]
      }

  }else if(type=== "cars"){
      const selectedMarcaModelo = list.map(item => {
          const data = { value: item.title, checked: true, name, title: item.title, id: item.id, id_marca: item?.id_marca}
          return data
      })
      if(selectedMarcaModelo.length > 0){
        listItems = [ ...items, ...selectedMarcaModelo ]
      }
  }

  return listItems
}


export const getItemsOfSelected = (valueNormal, type) => {
  let itemsOfSearchSelected = []

  itemsOfSearchSelected = searchItemSelected(valueNormal?.vTypeAuto, "list-object", itemsOfSearchSelected, "vTypeAuto")
  itemsOfSearchSelected = searchItemSelected(valueNormal?.vTypeCarroceria, "list-object", itemsOfSearchSelected, "vTypeCarroceria")
  itemsOfSearchSelected = searchItemSelected(valueNormal?.vTypeTransmision, "list-object", itemsOfSearchSelected, "vTypeTransmision")
  itemsOfSearchSelected = searchItemSelected(valueNormal?.vTypeCombustible, "list-object", itemsOfSearchSelected, "vTypeCombustible")
  itemsOfSearchSelected = searchItemSelected(valueNormal?.vTypeRange, "range-price", itemsOfSearchSelected, "vTypeRange")
  itemsOfSearchSelected = searchItemSelected(valueNormal?.vTypeYear, "range", itemsOfSearchSelected, "vTypeYear")
  itemsOfSearchSelected = searchItemSelected(valueNormal?.vTypeKm, "range", itemsOfSearchSelected, "vTypeKm")
  itemsOfSearchSelected = searchItemSelected(valueNormal?.vMarca, "cars", itemsOfSearchSelected, "vMarca")
  itemsOfSearchSelected = searchItemSelected(valueNormal?.vModelo, "cars", itemsOfSearchSelected, "vModelo")

  return itemsOfSearchSelected
}

const formartItemResult = (items, itemsOfSearchSelected) => {

  let listItems = itemsOfSearchSelected

  for(let key in items){

    if(items[key].isChecked){
      const { valueForm, isChecked, name, title, value, idInterest, type } = items[key]
      listItems.push({valueForm, isChecked, name, title, value, idInterest, type })
    }

  }
  return listItems
}

export const getInterestOfSelected = (valueNormal, type) => {
  let itemsOfSearchSelected = []

  itemsOfSearchSelected = searchItemSelected(valueNormal?.vTypeAuto, "list-object", itemsOfSearchSelected, "vTypeAuto")
  itemsOfSearchSelected = formartItemResult(valueNormal?.vOrigin, itemsOfSearchSelected)
  itemsOfSearchSelected = formartItemResult(valueNormal?.vTransmission, itemsOfSearchSelected)
  itemsOfSearchSelected = formartItemResult(valueNormal?.vFuel, itemsOfSearchSelected)
  itemsOfSearchSelected = formartItemResult(valueNormal?.vEcoFriendly, itemsOfSearchSelected)
  itemsOfSearchSelected = formartItemResult(valueNormal?.vSeatMove, itemsOfSearchSelected)
  itemsOfSearchSelected = formartItemResult(valueNormal?.vTraction, itemsOfSearchSelected)
  itemsOfSearchSelected = formartItemResult(valueNormal?.vPerformance, itemsOfSearchSelected)

  return itemsOfSearchSelected
}

export const dataSendForm = (list, type, items, name) => {
  let listItems = items

  if(type==="list-object"){
    let selected = []
    const itemsSelected = list.filter(item => item.checked===true)
    if(itemsSelected.length > 0){
      for(let clave in itemsSelected){
        if(itemsSelected[clave].valueForm.includes(',')){
          selected = [ ...selected, ...itemsSelected[clave].valueForm.split(',') ]
        }else{
          selected = [ ...selected, itemsSelected[clave].valueForm ]
        }
      }
    }
    listItems = {...items, [name]: selected }

  }else if(type === "range-price"){    
    listItems = { ...items, [name]: list  }

  }else if(type === "range"){
    const min = list[0].name!=="" ? Number(list[0].name) : ''
    const max = list[1].name!=="" ? Number(list[1].name) : ''

    if(min!=="" && max!==""){
      listItems = { ...items,  [name]: {min, max} }
    }
    
  }else if(type === "cars"){
      let selected = []

      for(let clave in list){
        selected = [ ...selected, list[clave].title ]
      }
      listItems = {...items, [name]: selected }      
  }

  return listItems
}

export const getItemsSendForm = (valueNormal, type) => {
  let itemsOfSearchSelected = {}

  itemsOfSearchSelected = dataSendForm(valueNormal?.vTypeAuto, "list-object", itemsOfSearchSelected, "vTypeAuto")
  itemsOfSearchSelected = dataSendForm(valueNormal?.vTypeCarroceria, "list-object", itemsOfSearchSelected, "vCarroceria")
  itemsOfSearchSelected = dataSendForm(valueNormal?.vTypeTransmision, "list-object", itemsOfSearchSelected, "vTransmision")
  itemsOfSearchSelected = dataSendForm(valueNormal?.vTypeCombustible, "list-object", itemsOfSearchSelected, "vCombustible")
  itemsOfSearchSelected = dataSendForm(valueNormal?.vTypeRange, "range-price", itemsOfSearchSelected, "vTypeRange")
  itemsOfSearchSelected = dataSendForm(valueNormal?.vTypeYear, "range", itemsOfSearchSelected, "vTypeYear")
  itemsOfSearchSelected = dataSendForm(valueNormal?.vTypeKm, "range", itemsOfSearchSelected, "vTypeKm")
  itemsOfSearchSelected = dataSendForm(valueNormal?.vMarca, "cars", itemsOfSearchSelected, "vMarca")
  itemsOfSearchSelected = dataSendForm(valueNormal?.vModelo, "cars", itemsOfSearchSelected, "vModelo")

  return itemsOfSearchSelected
}

export const generateCars = (listcars, banners) => {
    let initial = 2;
    let cantidad = 0;
    let listResult = []
    let index = 0

    for (let clave in listcars){
      
      listResult.push({...listcars[clave]})
      cantidad++

      if(cantidad===initial){
          listResult.push({ isBanner: true, banners: banners[index] })
          initial = (initial===2) ? 3 : 2
          cantidad=0
          index++
          if(index>2){
            index=0
          }
      }
    }

    return listResult
}


export const generateYear = (min, max) => {

  let yearSelectList = []

  for(let i = min; i >= max; i--){
      yearSelectList.push({value: i, title: i})
  }

  return yearSelectList
}


export const generateKm = (min, max) => {

  let kmSelectList = []

  for(let i = min; i <= max; i+=10000){
      kmSelectList.push({value: i, title: formatMonto(i), slug: i })
  }

  kmSelectList.push({value: 1000000, title: "100.000 o más"})

  return kmSelectList
}

export const formatMonto = (totalMonto) => {
    let inicial = "";
    if ((totalMonto.toString().indexOf("-") === 0)) {
        totalMonto = totalMonto.toString().replace("-", "");
        inicial = "-";
    }
    totalMonto = parseFloat(totalMonto).toFixed(2);
    let ingresoTotal = totalMonto.toString().split(".");
    let viewTotal = inicial + decimales(ingresoTotal, formatMontoDecimal(ingresoTotal[0]));
    

    return viewTotal;
}

const decimales = (resultado, numero) => {
    let decimal = "00";
    if (resultado.length === 2) {
        decimal = resultado[1];
    }
    if(parseInt(decimal)===0){
        return numero
    }
    return numero + "," + decimal;
}

const formatMontoDecimal = (num) => {
    if (!isNaN(num)) {
        num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.')
        num = num.split('').reverse().join('').replace(/^[\.]/, '') //eslint-disable-line
        return num;
    }
}

export const formatFloat = (valor) => {
    while (valor.toString().indexOf(".") !== -1)
        valor = valor.toString().replace(".", "");

    return parseInt(valor.toString().replace(",", "."));
}

export const formatDate = (date) => {
  const datestring = date.getFullYear() + "-" + ("0"+(date.getMonth()+1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);

  return datestring;

}