import TypeSelect from 'components/Customs/InputForm/TypeSelect'

const GroupSelect = ({ title, data, name, list, register, titleLabelMin, titleLabelMax, 
	titleSelect, idMin, idMax, control, setValue, handleClickUpdateResult }) => {

	return (
		<div className="filter__item">
			<p className="form-filter__title">{title}</p>
			<div className="filter__select">
				<TypeSelect
	    			key={0}
	    			titleLabel={titleLabelMin}
	    			titleSelect={titleSelect} 
	    			register={register}
	    			name={`${name}[0].min`}
	    			id={idMin}
	    			listado={list}
	    			isOptionValue={false}
	    			control={control}
	    			defaultValue={data?.min}
					setValue={setValue}
					handleClickUpdateResult={handleClickUpdateResult}
	    		/>
	    		<TypeSelect
	    			key={1}
	    			titleLabel={titleLabelMax}
	    			titleSelect={titleSelect} 
	    			register={register}
	    			name={`${name}[1].max`}
	    			id={idMax} 
	    			listado={list}
	    			isOptionValue={false}
	    			control={control}
	    			defaultValue={data?.max}
					setValue={setValue}
					handleClickUpdateResult={handleClickUpdateResult}
	    		/>
			</div>					
		</div>	
	)
}

export default GroupSelect