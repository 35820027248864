import styles from "styles/breadcrumb.module.css";
import down_arrow_return from "assets/images/icons/down-arrow-return.svg";
import { Link } from "react-router-dom";

const Breadcrumb = ({ itemBreadcrumb, isVisible, urlReturn, classCol="col" }) =>  {
   
    return (
        <section className="container">
            <div className={`row ${styles[classCol]}`}>
                <div className={`col-12  ${styles["container"]}`}>
                    <nav aria-label="breadcrumb">
                        <ol className={`breadcrumb ${styles["nav-ol"]}`}>
                            {
                                itemBreadcrumb.length > 0 &&
                                itemBreadcrumb.map((item, key) => 
                                    <li key={key} className={`breadcrumb-item ${styles[item.class]}`}>
                                        {
                                            item.url!=="" ?
                                                <Link to={item?.url}>{item.name}</Link>
                                            :
                                                item?.name
                                        }
                                    </li>
                                )
                            }
                        </ol>
                    </nav>
                    {
                        isVisible && (
                            <Link to={urlReturn}>
                                <img src={down_arrow_return} alt="Atrás" />
                            </Link>
                        )
                    }                    
                </div>                
            </div>
        </section>
       
    )
}

export default Breadcrumb