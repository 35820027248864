import Caracterist from 'components/Customs/CardVehicle/Caracterist'

const PanelInformation = ({ 
	type, name, infoBrand, mileage, 
	transmission, fuel, classMain, typeKey, viewCar,
	goToConcesionaire, priceLockDown, listPriceView, idKey,
	status_id, stock
}) => {


	return (
		<section className="info-car">
			<div>  
				<div className={classMain ? classMain : ""}>
						<div className="info-car__description--info" onClick={()=>viewCar(idKey)}>
						<div className="container-stock">
							<div className="info-car__description--type">
								{type} 
							</div>
							{(typeKey==="new") && stock!==true && <p className="label-stock">(Agotado)</p>}
						</div>
						<h2 className="info-car__description--modelo">{name}</h2>
					</div>
					<div className="info-car__marca" onClick={()=>goToConcesionaire(infoBrand?.slug)}>
						<img src={infoBrand?.icon} alt={infoBrand?.name} />
					</div>
				</div>
				<div className="price-car mt-1" onClick={()=>viewCar(idKey)}>
					<p className="info-car__description--price">
						<span>{priceLockDown}</span>
						{(typeKey==="new") && <span className="price-financiamiento">Precio con financiamiento</span>}
					</p>
					{(typeKey==="new") && 
						<p className="info-car__description--price">
							<span className={stock!==true ? "price-line" : ""}>{listPriceView}</span>
							<span className="price-financiamiento">Precio de lista desde:</span>
						</p>
					}
				</div>
			</div>
			<div className="info-car-features" onClick={()=>viewCar(idKey)}>
				<Caracterist
					isClass="car-features__imagen--km"
					title="Kilometraje"
					value={mileage}
				 />
				 <Caracterist
					isClass="car-features__imagen--transmision"
					title="Transmisión"
					value={transmission}
				 />
				 <Caracterist
					isClass="car-features__imagen--combustible"
					title="Combustible"
					value={fuel}
				 />
			</div>
			<div className="disclaime-price">
				<p className="disclaime-price__text">
					* Precios, equipamiento, características e información sujetos a cambio sin previo aviso. Las imágenes son meramente referenciales y pueden no coincidir con los productos exhibidos y ofrecidos en concesionarios, ni su idoneidad para los fines que se pretende satisfacer. Contacte un vendedor para verificar los datos publicados.
				</p>
			</div>
			
		</section>
	)
}

export default PanelInformation
