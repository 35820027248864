import { Modal } from 'react-bootstrap'
import close_img from 'assets/images/icons/delete-modal.svg'


const ModalForm = ({ 
	show, handleClose, register, handleActive, disabledBtn,
	handleSubmit, onSubmit, isLoading, infoSendItem, provider, errors,
	checkValue=true
}) => {


	return (
		<Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body className="">
	        <div className="row">
		        <div className="col-12">
		        	<div className="text-right btn-close-modal" onClick={handleClose}>
		        		<img src={close_img} alt="Cerrar" />
		        	</div>
					<form 
						className="route-contact w-100 pt-3 text-left" 
						onSubmit={handleSubmit(data => onSubmit(data, infoSendItem, provider))}
					>
						<p className="route-opciones__title mb-0">Cotizar</p>
						<div className="text-right mb-5">
							<p className="route-opciones__help ">* Campos obligatorios</p>
						</div>
						<div className="route-opciones__form">
							<div className="form-group route-group">
							    <label htmlFor="name">Nombre*</label>
							    <input 
							    	type="text" 
							    	className="form-control route__form-input" 
							    	id="name" 
							    	name="name"
							    	aria-describedby="nameHelp" 
							    	maxLength={160}
									ref={register({required: {value: true, message: 'Requerido'}})} 
									onKeyPress={(event) => {
										if (!/[a-zA-Z Ññ]/.test(event.key)) {
										event.preventDefault();
										}
									}}
							    />
								{errors.name && (
									<span className="text-danger">
										{errors?.name?.message}
									</span>
								)}
							</div>
							<div className="form-group route-group">
							    <label htmlFor="telephone">Teléfono *</label>
								<p className="span-telephone-contact"> +569</p>
							    <input 
							    	type="text" 
							    	className="form-control route__form-input form__contact-input--telephone-route" 
							    	id="telephone" 
							    	name="telephone"
							    	aria-describedby="telephoneHelp" 
							    	maxLength={8}
									ref={register({
										required: {value: true, message: 'Requerido'},
										minLength: { value: 8, message: 'Mínimo 8 Caracteres'},
										maxLength: { value: 8, message: 'Máximo 8 Caracteres'},
									})} 
									pattern="[0-9]+"
									onKeyPress={(event) => {
										if (!/[0-9]/.test(event.key)) {
										event.preventDefault();
										}
									}}
							    />
								{errors.telephone && (
									<span className="text-danger">
										{errors?.telephone?.message}
									</span>
								)}
							</div>
							<div className="form-group route-group">
							    <label htmlFor="email">Email*</label>
							    <input 
							    	type="email" 
							    	className="form-control route__form-input" 
							    	id="email" 
							    	name="email"
							    	aria-describedby="nameHelp" 
									maxLength={160}
									ref={register({
										required: {value: true, message: 'Requerido'},
										//eslint-disable-next-line
										pattern: { value: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, message: 'Email inválido'}
									})} 
									onKeyPress={(event) => {
										if (!/[0-9@a-zA-ZÑñ.]/.test(event.key)) {
										event.preventDefault();
										}
									}} 
							    />
								{errors.email && (
									<span className="text-danger">
										{errors?.email?.message}
									</span>
								)}
							</div>
							<div className="form-group route-group">
							    <label htmlFor="message">Mensaje*</label>
							    <textarea 
							    	rows="2" 
							    	cols="2" 
							    	className="form-control route__form-input" 
							    	id="message" 
							    	name="message"
							    	aria-describedby="messageHelp"
							    	 ref={register({required: {value: true, message: 'Requerido'}})}
							    />
								{errors.message && (
									<span className="text-danger">
										{errors?.message?.message}
									</span>
								)}
							</div>
							
							 <div className="form-group route-check mt-4">
							     <input 
							     	className="search-form-check search-form-check--route route__form-check btn-captcha-check" 
							     	type="checkbox" 
							    	value={true} 
							    	id="acceptTerm1" 
							    	name="acceptTerm1"
							    	defaultChecked={checkValue}
							    	onChange={(event)=>handleActive(event)}
							     />
							    <label 
							    	className="route__form-label" 
							    	htmlFor="acceptTerm1"
							    >Acepto <a 
								    	href="https://movicenter-web.s3.us-east-2.amazonaws.com/docs/TEERMINOS+Y+CONDICIONES+MOVICENTER.pdf" 
										target="_blank"
										rel="noreferrer"
									>términos y condiciones</a> y políticas de privacidad</label>
							  </div>


							<div className="form-group mb-5">
								<button 
									type="submit" 
									className="btn btn-primary btn-lg w-100 btn-captcha-form"
									disabled={disabledBtn}
								>{isLoading ? 'Enviando...' : 'Enviar' }</button>
							</div>
						</div>
					</form>
				</div>
			</div>
        </Modal.Body>
      </Modal>
	)
}

export default ModalForm