import CardService from 'components/Customs/Featured/CardService'

const Services = ({ title, listado, classColor,classContainer, classTitle, subtitle }) => {

	

	return (
		<div className={`row container__interest ${classColor}`}>
			<div className="col-12">
				<div className="container container__interest_container">
					<p className={`container__interest__title container__interest__title--width ${classTitle}`}>
						{title}
						<span className="panel__title--orange">{subtitle}</span>
					</p>

					<div className={classContainer}>
						{
							listado.map((service, index) => 
								<CardService 
									key={index} 
									item={service} 
									classContainer="service__details" 
								/>
							)
						}
					</div>
				</div>
			</div>
		</div>

	)
}

export default Services 