export const LIST_RESULT_CARS = 'LIST_RESULT_CARS'
export const UPDATE_RESULT_CAR = 'UPDATE_RESULT_CAR'
export const DETAILS_CAR = 'DETAILS_CAR'
export const ADD_CAR_MY_ROUTE = 'ADD_CAR_MY_ROUTE'
export const LOADING_SEARCH_LOADING = 'LOADING_SEARCH_LOADING'
export const ADD_COMPARE_CARS = 'ADD_COMPARE_CARS'
export const UPDATE_SEARCH_NORMAL = 'UPDATE_SEARCH_NORMAL'
export const LOADING_SEND_MY_ROUTE = 'LOADING_SEND_MY_ROUTE'
export const LIST_FEATURED_CARS = 'LIST_FEATURED_CARS'
export const LIST_RECOMMENDED_CARS = 'LIST_RECOMMENDED_CARS'
export const SELECTED_CAR_QUOTE = 'SELECTED_CAR_QUOTE'
export const SELECTED_BRAND_VIEW = 'SELECTED_BRAND_VIEW'
export const SELECTED_CARS_QUOTES = 'SELECTED_CARS_QUOTES'


export const UPDATE_SELECTED_INTEREST = 'UPDATE_SELECTED_INTEREST'
export const SAVE_INSTEREST_FORM = 'SAVE_INSTEREST_FORM'
export const UPDATE_INSTEREST_FORM = 'UPDATE_INSTEREST_FORM'


export const SELECTED_CARS_ROUTE = 'SELECTED_CARS_ROUTE'

export const UPDATE_INFINITE_SCROLL_CAR = 'UPDATE_INFINITE_SCROLL_CAR'

export const INITIAL_DATA_CAR_FILE = 'INITIAL_DATA_CAR_FILE'

export const SELECTED_SEND_FORM_MY_ROUTE = 'SELECTED_SEND_FORM_MY_ROUTE'