import { Link } from 'react-router-dom'

const CardService = ({ item, classContainer }) => {

	return (
		<Link to={item.url}>
			<div className={classContainer}>
				<img src={item.background_image} alt={item.name} className="service__image"/>
				<div className="opacity-services"></div>
				<span className="service__title">{ item.name }</span>
			</div>
		</Link>
	)
}

export default CardService