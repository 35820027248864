import { useEffect } from "react";
import IconSocialMedia from 'components//Layout/IconSocialMedia'
import twitter from 'assets/images/icons/twitter.svg'
import instagram from 'assets/images/icons/instagram.svg'
import facebook from 'assets/images/icons/facebook.svg'
import youtube from 'assets/images/icons/youtube.svg'
import icon_car from "assets/images/icons/car.svg"

import bg_mob from "assets/images/thank-mobile.png"
import bg_dsk from "assets/images/thank-dsk.png"
import styles from 'styles/thank.module.css'
import { Link } from 'react-router-dom'
import { useEvent } from "hooks/Contact/useEvent"

const ThankSuccessForm = () => {

    const { validateSendAPIPixel, urlLast } = useEvent()

    useEffect(() => {
        validateSendAPIPixel({ success: true })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

	return (
		<>
			<div className={`row `}>
	            <div className={`col-12 ${styles["col-12-p"]}`}>
                    <section className="w-100">
                        <div className={`container ${styles["container-p"]} ${styles["container-h"]}`}>
                            <picture>
                                <source media="(min-width: 768px)" srcSet={bg_dsk} />
                                <img src={bg_mob} alt="¡MUCHAS GRACIAS POR TU SOLICITUD!" className={styles["image"]} />
                            </picture>
                        </div>
                        <div className="container text-center">
                            <div className={styles["title-container"]}>
                                <h1 className={styles["title"]}>¡MUCHAS GRACIAS POR TU SOLICITUD!</h1>
                                <h4 className={styles["sub-title"]}>PRONTO TENDRÁS NOTICIAS DE NOSOTROS</h4>
                            </div>
                            {
                                urlLast==="/vehiculos/favoritos" ? 
                                    <p className={styles["sub-title-new"]}>Entérate de nuestras últimas noticias</p>
                                : urlLast.includes("servicio-automotriz/neumaticos") ? 
                                    <p className={styles["sub-title-new"]}>¿Te gustaría cotizar otro neumático adicional?</p>
                                : urlLast.includes("servicio-automotriz") ? 
                                    <p className={styles["sub-title-new"]}>¿Ya conoces los nuevos modelos 2023?</p>
                                :
                                    <p className={styles["sub-title-new"]}>¿Te gustaría cotizar algún modelo adicional?</p>
                            }

                            
                            {
                                urlLast==="/vehiculos/favoritos" ? 
                                    <Link to="/blog" className={`btn btn-primary ${styles["btn-go"]}`} >BLOG</Link>
                                : urlLast.includes("servicio-automotriz/neumaticos") ? 
                                    <Link to="/servicio-automotriz/neumaticos" className={`btn btn-primary ${styles["btn-go"]}`} >NEUMÁTICOS</Link>
                                : urlLast.includes("servicio-automotriz") ? 
                                    <Link to="/autos/nuevos" className={`btn btn-primary ${styles["btn-go"]}`} >AUTOS NUEVOS</Link>
                                : urlLast.includes("motos") ? 
                                    <Link to="/motos" className={`btn btn-primary ${styles["btn-go"]}`} >COTIZAR MOTOS</Link>
                                :
                                 <Link to="/" className={`btn btn-primary ${styles["btn-go"]}`} >SEGUIR COTIZANDO</Link>
                            }
                            
                            <p className={styles["sub-title-footer"]}>No te olvides de seguirnos en nuestras redes sociales para no perderte de nada</p>

                            <div className={styles["msocial_media"]}>
                                <IconSocialMedia imagen={instagram} title="Instagram" url="https://www.instagram.com/movicenter/" />
                                <IconSocialMedia imagen={facebook} title="Facebook" url="https://www.facebook.com/Movicenter/" />
                                <IconSocialMedia imagen={twitter} title="Twitter" url="https://twitter.com/movicenter/" />
                                <IconSocialMedia imagen={youtube} title="Youtube" url="https://www.youtube.com/user/MovicenterChile" />
                            </div>
                        </div>
                    </section>
                    
                    <div className={`container ${styles["container-p"]} ${styles["m-footer"]}`}>
                        <div className={styles["m-footer--p"]}>
                            <img src={icon_car} alt="Auto" className={styles["m-footer--image"]} />
                        </div>
                        <div className={styles["m-footer_bg"]}></div>
                    </div>
	            </div>
	        </div>
	         
        </>
	)
}

export default ThankSuccessForm