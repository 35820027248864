const BotonUbication = ({ url, isClass, title, btnColor }) => {
	return (
		<a 
			href={url} 
			className={`btn btn-lg w-100 mb-4 btn__text ${btnColor}`}
			target="_blank"
			rel="noreferrer"
		>
			<div className="btn_container">
				<span className={isClass}></span>
			</div> 
			<span className="btn__text--align">{title}</span>
		</a>
	)
}

export default BotonUbication