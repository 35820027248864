import { Modal } from "react-bootstrap"
import FormMain from "components/QuoteCar/Cars/Formulario/FormMain"
import FormResultSend from "components/QuoteCar/Cars/Formulario/FormResultSend"
import closed_menu from 'assets/images/icons/delete-result.svg'
import { useOlxMain } from "hooks/Quotes/Car/useOlxMain"

const ModalCar = ({ visible, closedModalOLX }) => {
    
    const { isVisibleFormOLX, resultQuote, visibleTextZeroPrice } = useOlxMain(true)

    return (
        <Modal
            show={visible}
            onHide={()=>closedModalOLX(false)}
            backdrop="static"
            keyboard={false}
        >
        <Modal.Body className="modal-quotes">
            <div className="modal__closed" onClick={()=>closedModalOLX(false)}>
                <img src={closed_menu} alt="Cerrar" />
            </div>
            {isVisibleFormOLX===true ? 
                <FormMain /> : 
                <FormResultSend closeModal={closedModalOLX} resultQuote={resultQuote} visibleTextZeroPrice={visibleTextZeroPrice} /> }
            
        </Modal.Body>
      </Modal>
    )
}

export default ModalCar